import * as React from "react";

import {
  IconButton,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ConvertToArabicNumbers, getWeekDay, renderDate } from "../../../utils";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Alerto from "../../../components/UI/toaster";
import UpdateDeposit from "../../treasury/components/UpdateDeposit";
import { updateDeposits } from "../../treasury/treasurySlice";
import { useDispatch } from "react-redux";
import { updateExpenses } from "../../expenses/expensesSlice";
import {
  deleteSellCommissionItem,
  updateSellCommisionItem,
} from "../../loads/loadsSlice";
import { updateInvoiceExpense } from "../../invoice/invoiceSlice";
import { padding } from "@mui/system";
import { isDayPast } from "../../../components/Helpers/InsputSorting";

const useStyles = makeStyles((theme) => ({
  table: {
    // borderCollapse: "separate",
    borderCollapse: "Collapse",
    borderSpacing: "2px 4px",
    border: "1px solid #555",
    margin: "auto",
    with: "100%",
  },
  edit: { color: theme.palette.info.light },
  tableHead: {
    backgroundColor: "#FAA829",
    width: "100%",
    padding: 0,
  },
  tableCell: {
    border: "1px solid #919191",
    padding: 3,
    fontSize: "14px",
  },
}));
function getFetchApi(row) {
  switch (row.stateId) {
    case 18:
    case 19:
    case 34:
    case 35:
      return `Selling/GetASelling?truckTypeId=${
        row.stateId == 18 || row.stateId == 19 ? 1 : 2
      }&sellingId=${row.click.id}`;

    case 6:
    case 8:
    case 22:
    case 26:
    case 28:
    case 32:
      return `Deposits/GetADeposit/${row.click.id}`;
    case 4:
    case 5:
    case 7:
    case 24:
    case 29:
    case 31:
      return `GeneralExpenses/GetAGeneralExpense/${row.click.id}`;

    default:
      return false;
  }
}
function getUpdateApi(row) {
  switch (row.stateId) {
    case 18:
    case 19:
    case 34:
    case 35:
      return updateSellCommisionItem;
    case 8:
    case 6:
    case 22:
    case 26:
    case 28:
    case 32:
      return updateDeposits;
    case 4:
    case 5:
    case 7:
    case 24:
    case 29:
    case 31:
      if (row.stateName == "دفعرهنمشتريات") {
        return updateInvoiceExpense;
      } else {
        return updateExpenses;
      }

    default:
      return false;
  }
}
function getDeleteApi(row) {
  switch (row.stateId) {
    case 18:
    case 19:
    case 34:
    case 35:
      return `Selling?truckTypeId=${
        row.stateId == 18 || row.stateId == 19 ? 1 : 2
      }&sellId=${row.click.id}`;
    case 8:
    case 6:
    case 22:
    case 26:
    case 28:
    case 32:
      return `Deposits?id=${row.click.id}`;
    case 4:
    case 5:
    case 7:
    case 24:
    case 29:
    case 31:
      if (row.stateName == "دفعرهنمشتريات") {
        return `ExpenseContoller/RemoveExpenseById?isDamage=false&id=${row.click.id}`;
      } else {
        return `GeneralExpenses?id=${row.click.id}`;
      }

    default:
      return false;
  }
}
const getStateColor = (stateId, name, isPrint) => {
  if (isPrint) {
    return { backgroundColor: "#fff" };
  }
  switch (stateId) {
    case 25:
    case 27:
    case 23:
    case 30:
    case 33:
    case 16:
    case 14:
    case 12:
    case 11:
    case 9:
    case 1:
    case 2:
    case 5:
    case 28:
    case 7:
    case 32:
    case 34:
    case 35:
    case 18:
    case 19:
    case 24:
    case 22:
    case 26:
    case 29:
    case 31:
    case 8:
    case 4:
    case 6:
    case 15:
    case 17:
    case 20:
    case 21:
    case 13:
    case 3:
    case 10:
      if (name == "رصيد إفتتاحي") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "نقلة مشتريات") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "نقلة عمولة") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "عملة مقدمة") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "دفع رهن مشتريات") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "مبيع") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "سلف") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "رهن عدة") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "راتب") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "جملة") {
        return { backgroundColor: "#FEE600", color: "black" };
      }
      if (name == "فاتورة") {
        return { backgroundColor: "#FEE600", color: "black" };
      }
      if (name == "إجمالي عملة") {
        return { backgroundColor: "#FEE600", color: "black" };
      }
      if (name == "إجمالي رهن مشتريات") {
        return { backgroundColor: "#FEE600", color: "black" };
      }
      if (name == "إجمالي وجبة") {
        return { backgroundColor: "#FEE600", color: "black" };
      }
      if (name == "إجمالي سلف بائع") {
        return { backgroundColor: "#FEE600", color: "black" };
      }
      if (name == "إجمالي رهن بائع") {
        return { backgroundColor: "#FEE600", color: "black" };
      }
      if (name == "إجمالي الرواتب") {
        return { backgroundColor: "#FEE600", color: "black" };
      }

      if (name == "إجمالي نقلات") {
        return { backgroundColor: "#ff9a03", color: "black" };
      }
      if (name == "إجمالي المبيع") {
        return { backgroundColor: "#ff9a03", color: "black" };
      }
      if (name == "دفع") {
        return { backgroundColor: "#EE3024", color: "white" };
      }
      if (name == "سداد عملة") {
        return { backgroundColor: "#EE3024", color: "white" };
      }
      if (name == "تسديد رهن مشتريات") {
        return { backgroundColor: "#EE3024", color: "white" };
      }
      if (name == "دفع مبيع") {
        return { backgroundColor: "#EE3024", color: "white" };
      }
      if (name == "سماح") {
        return { backgroundColor: "#EE3024", color: "white" };
      }
      if (name == "سداد سلف") {
        return { backgroundColor: "#EE3024", color: "white" };
      }
      if (name == "ارجاع رهن عدة") {
        return { backgroundColor: "#EE3024", color: "white" };
      }
      if (name == "دفع راتب") {
        return { backgroundColor: "#EE3024", color: "white" };
      }
      if (name == "متبقي نقلات") {
        return { backgroundColor: "#0047BB", color: "white" };
      }
      if (name == "متبقي عملة") {
        return { backgroundColor: "#0047BB", color: "white" };
      }
      if (name == "متبقي رهن مشتريات") {
        return { backgroundColor: "#0047BB", color: "white" };
      }
      if (name == "متبقي المبيع") {
        return { backgroundColor: "#0047BB", color: "white" };
      }
      if (name == "متبقي سلف بائع") {
        return { backgroundColor: "#0047BB", color: "white" };
      }
      if (name == "متبقي_رهن_بائع") {
        return { backgroundColor: "#0047BB", color: "white" };
      }
      if (name == "متبقي الرواتب") {
        return { backgroundColor: "#0047BB", color: "white" };
      }
      if (name == "عمولة") {
        return { backgroundColor: "#05fc26", color: "black" };
      }
      if (name == "مشال") {
        return { backgroundColor: "#05fc26", color: "black" };
      }
      if (name == "ثلاجة") {
        return { backgroundColor: "#05fc26", color: "black" };
      }
      if (name == "مكتب") {
        return { backgroundColor: "#05fc26", color: "black" };
      }
      if (name == "إيجار عدة") {
        return { backgroundColor: "#05fc26", color: "black" };
      }
      if (name == "رهن_مشتريات") {
        return { backgroundColor: "#05fc26", color: "black" };
      }
      if (name == "نولون") {
        return { backgroundColor: "#05fc26", color: "black" };
      }
      if (name == "نقدية") {
        return { backgroundColor: "#05fc26", color: "black" };
      }
      if (name == "مصروفات_أخري_منصرف") {
        return { backgroundColor: "#05fc26", color: "black" };
      }
      if (name == "خصومات") {
        return { backgroundColor: "#0aa31e", color: "black" };
      }
    default:
      return { backgroundColor: "#fff" };
  }
};
const getBStateColor = (stateId, name, isPrint) => {
  if (isPrint) {
    return { backgroundColor: "#fff" };
  }
  switch (stateId) {
    case 25:
    case 27:
    case 23:
    case 30:
    case 33:
    case 16:
    case 14:
    case 12:
    case 11:
    case 9:
    case 1:
    case 2:
    case 5:
    case 28:
    case 7:
    case 20:
    case 21:
    case 32:
    case 34:
    case 35:
    case 18:
    case 19:
    case 24:
    case 31:
      if (name == "رصيد إفتتاحي") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "نقلة مشتريات") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "نقلة عمولة") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "عملة مقدمة") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "دفع رهن مشتريات") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "مبيع") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "سلف") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "رهن عدة") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "راتب") {
        return { backgroundColor: "#a0a5ad", color: "black" };
      }
      if (name == "جملة") {
        return { backgroundColor: "#FEE600", color: "black" };
      }
      if (name == "فاتورة") {
        return { backgroundColor: "#FEE600", color: "black" };
      }
      if (name == "إجمالي عملة") {
        return { backgroundColor: "#FEE600", color: "black" };
      }
      if (name == "إجمالي رهن مشتريات") {
        return { backgroundColor: "#FEE600", color: "black" };
      }
      if (name == "إجمالي وجبة") {
        return { backgroundColor: "#FEE600", color: "black" };
      }
      if (name == "إجمالي سلف بائع") {
        return { backgroundColor: "#FEE600", color: "black" };
      }
      if (name == "إجمالي رهن بائع") {
        return { backgroundColor: "#FEE600", color: "black" };
      }
      if (name == "إجمالي الرواتب") {
        return { backgroundColor: "#FEE600", color: "black" };
      }
      if (name == "إجمالي نقلات") {
        return { backgroundColor: "#ff9a03", color: "black" };
      }
      if (name == "إجمالي المبيع") {
        return { backgroundColor: "#ff9a03", color: "black" };
      }
      if (name == "دفع راتب") {
        return { backgroundColor: "#EE3024", color: "white" };
      }
      if (name == "متبقي الرواتب") {
        return { backgroundColor: "#0047BB", color: "white" };
      }
    default:
      return { backgroundColor: "#fff" };
  }
};
const MoneyTable = ({
  isstaff,
  data,
  type,
  onPrintBorders,
  indexesArr,
  isPrint,
  squareInvisibile,
  showRepName,
}) => {
  const screenTableCells =
    type === "client" && !isstaff && showRepName
      ? [
          "تعديل",
          "ملحوظة",
          "حالة",
          "مبلغ",
          "قيمة",
          "الحالة",
          "العدد",
          "الوزن",
          "السعر",
          "الصنف",
          "التاريخ",
          "اليوم",
          "عدة",
          "بياعة",
          "طباعة",
        ]
      : type === "client" && !isstaff && squareInvisibile !== "invisible"
      ? [
          "تعديل",
          "ملحوظة",
          "حالة",
          "مبلغ",
          "قيمة",
          "الحالة",
          "العدد",
          "الوزن",
          "السعر",
          "الصنف",
          "التاريخ",
          "اليوم",
          "عدة",
          "بياعة",
          "نسبة",
          "طباعة",
        ]
      : type === "client" && !isstaff && squareInvisibile === "invisible"
      ? [
          "تعديل",
          "ملحوظة",
          "حالة",
          "مبلغ",
          "قيمة",
          "الحالة",
          "التاريخ",
          "اليوم",
          "طباعة",
        ]
      : isstaff
      ? ["تعديل", "حالة", "مبلغ", "قيمة", "الحالة", "التاريخ", "اليوم", "طباعة"]
      : type === "buyer" && !isstaff && squareInvisibile === "invisible"
      ? [
          "تعديل",
          "ملحوظة",
          "حالة",
          "مبلغ",
          "قيمة",
          "الحالة",
          "التاريخ",
          "اليوم",
          "طباعة",
        ]
      : [
          "تعديل",
          "ملحوظة",
          "حالة",
          "مبلغ",
          "قيمة",
          "الحالة",
          "العدد",
          "الوزن",
          "السعر",
          "الصنف",
          "التاريخ",
          "اليوم",
          "عدة",
          "بياعة",
          "نسبة",
          "طباعة",
        ];

  const printTableTableCells =
    !isstaff && !isstaff && squareInvisibile === "invisible"
      ? ["ملحوظة", "حالة", "مبلغ", "قيمة", "الحالة", "التاريخ"]
      : !isstaff && !isstaff && squareInvisibile !== "invisible"
      ? [
          "ملحوظة",
          "حالة",
          "مبلغ",
          "قيمة",
          "الحالة",
          "العدد",
          "الوزن",
          "السعر",
          "الصنف",
          "التاريخ",
        ]
      : [
          "حالة",
          "مبلغ",
          "قيمة",
          "الحالة",
          "العدد",
          "الوزن",
          "السعر",
          "الصنف",
          "التاريخ",
        ];
  const headCells = isPrint ? printTableTableCells : screenTableCells;
  const classes = useStyles();
  const role = localStorage.getItem("role");
  const eligableRoles = ["إدارة", "مدير", "مشرف", "موظف"];
  const isEligableToEdit = eligableRoles.includes(role);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [selectedDeposit, setSelectedDeposit] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState({});
  const dispatch = useDispatch();
  function handleUpdate(data) {
    const api = getUpdateApi(selectedRow);
    if (!api) return toast.error("لم تتم برمجته");
    let depData = {
      ...selectedDeposit,
      price: data.price,
      note: data.note,
      depositDate: data.depositDate,
      partner: data.partner,
      allow: data.allow,
    };
    let expData = {
      ...selectedDeposit,
      money: data.price,
      note: data.note,
      dateTime: data.depositDate,
      partner: data.partner,
    };
    let isDamageData = {
      money: data.price,
      id: data.id,
      dateTime: data.dateTime,
      isDamage: false,
    };
    let invoiceData = {
      ...data,
      truckTypeId:
        selectedRow.stateId == 18 || selectedRow.stateId == 19 ? 1 : 2,
    };
    let validObj =
      selectedDeposit.stateName == "دفعرهنمشتريات"
        ? isDamageData
        : selectedDeposit.price
        ? depData
        : selectedDeposit.money
        ? expData
        : invoiceData;

    dispatch(api(validObj)).then((res) => {
      if (res.payload?.id || !!res.payload) {
        window.location.reload();
      }
    });
  }
  function deletHandler() {
    const api = getDeleteApi(selectedRow);
    if (!api) return toast.error("لم تتم برمجته بعد");
    if (window.confirm("هل تريد حذف العنصر")) {
      axios
        .delete(api)
        .then(() => {
          toast.error("تم الحذف");
          window.location.reload();
        })
        .catch((e) => Alerto(e));
    }
  }
  function fetchRowData(row) {
    const api = getFetchApi(row);
    if (!api) return toast.error("لم تتم برمجته");
    axios
      .get(api)
      .then((res) =>
        setSelectedDeposit(res.data?.partner ? res.data : res.data?.data)
      )
      .catch((e) => Alerto(e));
  }
  return (
    <TableContainer
      style={isPrint ? { width: "100%" } : { margin: "auto" }}
      component={Paper}
      className="p-1 border-0 bg-transparents overflow-auto mb-0"
    >
      <Table
        className={classes.table}
        style={{ background: "white", height: "100%" }}
      >
        <TableHead className={classes.tableHead} style={{ padding: 2 }}>
          <TableRow>
            {headCells.map((headCell, index) => (
              <TableCell style={{ padding: 4 }} key={index} align="center">
                <Typography
                  style={{
                    color: "#2E3840",
                    fontSize: isPrint ? "1.5vh" : "2vh",
                    lineHeight: isPrint ? "3vmin" : "normal",
                  }}
                >
                  {headCell}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {!isstaff ? (
          <TableBody className={classes.tableBody}>
            {data.map((row, index) => (
              <TableRow key={index}>
                {!isPrint && (
                  <TableCell
                    className="p-0"
                    align="center"
                    style={{
                      background:
                        row.click === null && !isPrint ? "#534d3b" : "black",
                    }}
                  >
                    {isEligableToEdit &&
                      row.click &&
                      (row.stateId == 11 || row.stateId == 9 ? (
                        <div className="w-100 h-100 center">
                          <Link
                            className=" h-100 w-100 text-white center"
                            to={`/invoice/${row.click.id}`}
                          >
                            فاتورة
                          </Link>
                        </div>
                      ) : (
                        <div className="w-100 h-100 center">
                          <Link
                            className=" h-100 w-100 text-white center"
                            disabled={role == "موظف" && isDayPast(row.dateTime)}

                            onClick={() => {
                              row.stateName == "دفعرهنمشتريات"
                                ? setSelectedDeposit(row)
                                : fetchRowData(row);
                              setOpenUpdate(true);
                              setSelectedRow(row);
                            }}
                          >
                            <EditOutlinedIcon
                              style={{ color: "white" }}
                              classname="p-0"
                            />
                          </Link>
                        </div>
                      ))}
                  </TableCell>
                )}

                <TableCell
                  style={{
                    background:
                      !!row.owner?.name || !!row.note || isPrint
                        ? "white"
                        : "#534d3b",
                  }}
                  className={classes.tableCell}
                  align="center"
                >
                  {showRepName ? (
                    <Link to={`/client-profile/${row.owner?.id}`}>
                      {row.owner?.name}
                    </Link>
                  ) : (
                    row.note
                  )}
                </TableCell>

                <TableCell
                  style={{
                    background:
                      row.creditorOrDebtor === null && !isPrint
                        ? "#534d3b"
                        : "white",
                  }}
                  className={classes.tableCell}
                  align="center"
                >
                  {row.creditorOrDebtor}
                </TableCell>

                <TableCell
                  style={{
                    background:
                      row.totalMoney === null && !isPrint ? "#534d3b" : "white",
                  }}
                  className={classes.tableCell}
                  align="center"
                  onClick={() => console.log(row.totalMoney)}
                >
                  {ConvertToArabicNumbers(
                    row.totalMoney % 1 && row.totalMoney !== 0
                      ? row.totalMoney?.toFixed(1)
                      : row.totalMoney
                  )}

                  {row.totalMoney === 0 ? "صفر" : ""}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="center"
                  style={{
                    background:
                      row.value === null && !isPrint ? "#534d3b" : "white",
                  }}
                >
                  {ConvertToArabicNumbers(
                    row.value % 1 ? row.value?.toFixed(1) : row.value
                  )}
                </TableCell>
                <TableCell
                  style={{
                    ...getStateColor(row.stateId, row.rowName, isPrint),
                    fontFamily: "DroidKufi-Regular",
                  }}
                  className={classes.tableCell}
                  align="center"
                >
                  {row.rowName}
                </TableCell>

                {squareInvisibile !== "invisible" ? (
                  <TableCell
                    style={{
                      background:
                        row.productCount === null &&
                        !isPrint &&
                        getBStateColor(row.stateId, row.rowName, isPrint)
                          ?.backgroundColor === "#fff"
                          ? "#534d3b"
                          : getBStateColor(row.stateId, row.rowName, isPrint)
                              ?.backgroundColor,
                      fontFamily: "DroidKufi-Regular",
                    }}
                    className={classes.tableCell}
                    align="center"
                  >
                    {ConvertToArabicNumbers(
                      row.productCount % 1
                        ? row.productCount?.toFixed(1)
                        : row.productCount
                    )}
                  </TableCell>
                ) : (
                  ""
                )}

                {squareInvisibile !== "invisible" ? (
                  <TableCell
                    style={{
                      background:
                        row.weight === null &&
                        !isPrint &&
                        getBStateColor(row.stateId, row.rowName, isPrint)
                          ?.backgroundColor === "#fff"
                          ? "#534d3b"
                          : getBStateColor(row.stateId, row.rowName, isPrint)
                              ?.backgroundColor,
                    }}
                    className={classes.tableCell}
                    align="center"
                  >
                    {ConvertToArabicNumbers(row.weight)}
                  </TableCell>
                ) : (
                  ""
                )}

                {squareInvisibile !== "invisible" ? (
                  <TableCell
                    style={{
                      background:
                        row.priceForUnitWeight === null &&
                        !isPrint &&
                        getBStateColor(row.stateId, row.rowName, isPrint)
                          ?.backgroundColor === "#fff"
                          ? "#534d3b"
                          : getBStateColor(row.stateId, row.rowName, isPrint)
                              ?.backgroundColor,
                    }}
                    className={classes.tableCell}
                    align="center"
                  >
                    {ConvertToArabicNumbers(
                      row.priceForUnitWeight % 1
                        ? row.priceForUnitWeight
                        : row.priceForUnitWeight
                    )}
                  </TableCell>
                ) : (
                  ""
                )}

                {squareInvisibile !== "invisible" ? (
                  <TableCell
                    style={{
                      background:
                        row.productName === null &&
                        !isPrint &&
                        getBStateColor(row.stateId, row.rowName, isPrint)
                          ?.backgroundColor === "#fff"
                          ? "#534d3b"
                          : getBStateColor(row.stateId, row.rowName, isPrint)
                              ?.backgroundColor,
                      fontFamily: "DroidKufi-Regular",
                    }}
                    className={classes.tableCell}
                    align="center"
                  >
                    {row.productName}
                  </TableCell>
                ) : (
                  ""
                )}

                <TableCell
                  style={{
                    background:
                      row.displayingDate === null &&
                      !isPrint &&
                      getBStateColor(row.stateId, row.rowName, isPrint)
                        ?.backgroundColor === "#fff"
                        ? "#534d3b"
                        : getBStateColor(row.stateId, row.rowName, isPrint)
                            ?.backgroundColor,
                    fontFamily: "DroidKufi-Regular",
                  }}
                  className={classes.tableCell}
                  align="center"
                >
                  {row.displayingDate
                    ? ConvertToArabicNumbers(renderDate(row.displayingDate))
                    : ""}
                </TableCell>
                {!isPrint && (
                  <TableCell
                    style={{
                      background:
                        row.displayingDate === null &&
                        !isPrint &&
                        getBStateColor(row.stateId, row.rowName, isPrint)
                          ?.backgroundColor === "#fff"
                          ? "#534d3b"
                          : getBStateColor(row.stateId, row.rowName, isPrint)
                              ?.backgroundColor,
                      fontFamily: "DroidKufi-Regular",
                    }}
                    className={classes.tableCell}
                    align="center"
                  >
                    {row.displayingDate
                      ? getWeekDay(new Date(row.displayingDate))
                      : ""}
                  </TableCell>
                )}
                {!isPrint && squareInvisibile !== "invisible" ? (
                  <TableCell
                    style={{
                      background:
                        row.toolName === null &&
                        !isPrint &&
                        getBStateColor(row.stateId, row.rowName, isPrint)
                          ?.backgroundColor === "#fff"
                          ? "#534d3b"
                          : getBStateColor(row.stateId, row.rowName, isPrint)
                              ?.backgroundColor,
                      fontFamily: "DroidKufi-Regular",
                    }}
                    className={classes.tableCell}
                    align="center"
                  >
                    {row.toolName}
                  </TableCell>
                ) : (
                  ""
                )}
                {!isPrint && squareInvisibile !== "invisible" ? (
                  <TableCell
                    style={{
                      background:
                        row.commisionForClientOrBuyer === null &&
                        !isPrint &&
                        getBStateColor(row.stateId, row.rowName, isPrint)
                          ?.backgroundColor === "#fff"
                          ? "#534d3b"
                          : getBStateColor(row.stateId, row.rowName, isPrint)
                              ?.backgroundColor,
                      fontFamily: "DroidKufi-Regular",
                    }}
                    className={classes.tableCell}
                    align="center"
                  >
                    {ConvertToArabicNumbers(
                      row.commisionForClientOrBuyer % 1
                        ? row.commisionForClientOrBuyer?.toFixed(2)
                        : row.commisionForClientOrBuyer
                    )}
                  </TableCell>
                ) : (
                  ""
                )}
                {!showRepName &&
                  (type === "client" &&
                  !isPrint &&
                  squareInvisibile !== "invisible" ? (
                    <TableCell
                      style={{
                        background:
                          row.commisionPercentage === null &&
                          getBStateColor(row.stateId, row.rowName, isPrint)
                            ?.backgroundColor === "#fff"
                            ? "#534d3b"
                            : getBStateColor(row.stateId, row.rowName, isPrint)
                                ?.backgroundColor,
                        fontFamily: "DroidKufi-Regular",
                      }}
                      className={classes.tableCell}
                      align="center"
                    >
                      {ConvertToArabicNumbers(row.commisionPercentage)}
                      {row.commisionPercentage ? " % " : ""}
                    </TableCell>
                  ) : !isPrint && squareInvisibile !== "invisible" ? (
                    <TableCell
                      style={{
                        background:
                          row.toolMortage === null &&
                          !isPrint &&
                          getBStateColor(row.stateId, row.rowName, isPrint)
                            ?.backgroundColor === "#fff"
                            ? "#534d3b"
                            : getBStateColor(row.stateId, row.rowName, isPrint)
                                ?.backgroundColor,
                        fontFamily: "DroidKufi-Regular",
                      }}
                      className={classes.tableCell}
                      align="center"
                    >
                      {ConvertToArabicNumbers(row.toolMortage)}
                    </TableCell>
                  ) : (
                    ""
                  ))}
                {!isPrint && (
                  <TableCell className={classes.tableCell} align="center">
                    <Checkbox
                      disabled={
                        indexesArr &&
                        indexesArr.length === 2 &&
                        !indexesArr.includes(index)
                      }
                      onClick={() => {
                        onPrintBorders(index);
                      }}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody className={classes.tableBody}>
            {data.map((row, index) => (
              <TableRow key={index}>
                {!isPrint && (
                  <TableCell
                    className="p-0"
                    align="center"
                    style={{
                      background:
                        row.click === null && !isPrint ? "#534d3b" : "black",
                    }}
                  >
                    {isEligableToEdit}
                    {isEligableToEdit &&
                      row.click &&
                      (row.stateId == 11 || row.stateId == 9 ? (
                        <div className="w-100 h-100 center">
                          <Link
                            className=" h-100 w-100 text-white center"
                            to={`/invoice/${row.click.id}`}
                          >
                            فاتورة
                          </Link>
                        </div>
                      ) : (
                        <div className="w-100 h-100 center">
                          <Link
                            className=" h-100 w-100 text-white center"
                            disabled={
                              role == "موظف" && isDayPast(row.dateTime)
                            }
                            onClick={() => {
                              row.stateName == "دفعرهنمشتريات"
                                ? setSelectedDeposit(row)
                                : fetchRowData(row);
                              setOpenUpdate(true);
                              setSelectedRow(row);
                            }}
                          >
                            <EditOutlinedIcon
                              style={{ color: "white" }}
                              classname="p-0"
                            />
                          </Link>
                        </div>
                      ))}
                  </TableCell>
                )}
                {/* <TableCell
                  style={{ fontWeight: "500", fontSize: 18 }}
                  className={classes.tableCell}
                  align="center"
                >
                  {row.note}
                </TableCell> */}
                <TableCell
                  style={{
                    background:
                      row.creditorOrDebtor === null && !isPrint
                        ? "#534d3b"
                        : "white",
                  }}
                  className={classes.tableCell}
                  align="center"
                >
                  {row.creditorOrDebtor}
                </TableCell>

                <TableCell
                  style={{
                    background:
                      row.totalMoney === null && !isPrint ? "#534d3b" : "white",
                  }}
                  className={classes.tableCell}
                  align="center"
                  onClick={() => console.log(row.totalMoney)}
                >
                  {ConvertToArabicNumbers(
                    row.totalMoney % 1 && row.totalMoney !== 0
                      ? row.totalMoney?.toFixed(1)
                      : row.totalMoney
                  )}

                  {row.totalMoney === 0 ? "صفر" : ""}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="center"
                  style={{
                    background:
                      row.value === null && !isPrint ? "#534d3b" : "white",
                  }}
                >
                  {ConvertToArabicNumbers(
                    row.value % 1 ? row.value?.toFixed(1) : row.value
                  )}
                </TableCell>

                <TableCell
                  style={{
                    ...getBStateColor(row.stateId, row.rowName, isPrint),
                    fontFamily: "DroidKufi-Regular",
                  }}
                  className={classes.tableCell}
                  align="center"
                >
                  {row.rowName}
                </TableCell>

                <TableCell
                  style={{
                    ...getBStateColor(row.stateId, row.rowName, isPrint),
                    fontFamily: "DroidKufi-Regular",
                  }}
                  className={classes.tableCell}
                  align="center"
                >
                  {row.displayingDate
                    ? ConvertToArabicNumbers(renderDate(row.displayingDate))
                    : ""}
                </TableCell>

                {!isPrint && (
                  <TableCell
                    style={{
                      ...getBStateColor(row.stateId, row.rowName, isPrint),
                      fontFamily: "DroidKufi-Regular",
                    }}
                    className={classes.tableCell}
                    align="center"
                  >
                    {row.displayingDate
                      ? getWeekDay(new Date(row.displayingDate))
                      : ""}
                  </TableCell>
                )}

                {!isPrint && (
                  <TableCell className={classes.tableCell} align="center">
                    <Checkbox
                      disabled={
                        indexesArr &&
                        indexesArr.length === 2 &&
                        !indexesArr.includes(index)
                      }
                      onClick={() => {
                        onPrintBorders(index);
                      }}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      <UpdateDeposit
        open={openUpdate}
        onClose={() => setOpenUpdate(false)}
        onUpdate={handleUpdate}
        deposit={selectedDeposit}
        onDelete={deletHandler}
        fromAccount={true}
      />
    </TableContainer>
  );
};

export default MoneyTable;

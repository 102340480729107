import authReducer from '../features/auth/authSlice';
import clientReducer from '../features/client-profile/clientSlice';
import collectingReducer from '../features/collecting-address/collectingSlice';
import { combineReducers } from '@reduxjs/toolkit';
import damageReducer from '../features/movement-tools/damageSlice';
import dataReducer from '../features/main-data/dataSlice';
import expensesReducer from '../features/expenses/expensesSlice';
import exportReducer from '../features/movement-tools/exportSlice';
import importRedducer from '../features/movement-tools/importSlice';
import invoiceReducer from '../features/invoice/invoiceSlice';
import loadsReducer from '../features/loads/loadsSlice';
import sellerReducer from '../features/seller-profile/sellerSlice';
import sellersInvoiceReducer from '../features/invoices-sellers/sellersInvoiceSlice';
import toolsReducer from '../features/movement-tools/tools-reports/toolsSlice';
import treasuryReducer from '../features/treasury/treasurySlice';

const rootReducer = combineReducers({
  loads: loadsReducer,
  data: dataReducer,
  invoice: invoiceReducer,
  exporting: exportReducer,
  importing: importRedducer,
  damage: damageReducer,
  tools: toolsReducer,
  sellersInvoice: sellersInvoiceReducer,
  treasury: treasuryReducer,
  expenses: expensesReducer,
  collecting: collectingReducer,
  seller: sellerReducer,
  client: clientReducer,
  auth: authReducer,
});

export default rootReducer;


import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import classy from "./summarize.module.css";
import { ConvertToArabicNumbers } from "../../../utils";
import LogoImage from "../../../components/LogoImage";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import React from "react";
import SoldItemsTable from "./SoldItemsTable";
import SummeryTable from "./SummeryTable";
import { makeStyles } from "@material-ui/styles";
import { useReactToPrint } from "react-to-print";
const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid",
    padding: 10,
  },

  tableBody: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },

  qty: {
    borderRadius: 3,
    display: "flex",
    fontSize: 18,
    border: "2px  solid black",
    width: "100%",
    background: "#f3c637",
    justifyContent: "center",
    padding: 5,
  },
  "@media print": {
    printIcon: {
      display: "none",
    },
    printHidden: {
      display: "none",
    },
    root: {
      margin: "auto",
    },
  },
}));

const Summarize = ({
  onMoveSelected,
  soldItems,
  summaryItems,
  invoiceItems,
  truck,
  fromLoads,
}) => {
  const total = soldItems.reduce((acc, currentValue) => {
    return acc + currentValue.totalMoney;
  }, 0);
  let d = truck.truckDate;
  let b = d.split("T").splice(0, 1);

  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "بيان اسعار واوزان البيع",
  });

  const classes = useStyles();
  return (
    <div ref={componentRef} className={classes.root}>
      <div className={classes.printIcon}>
        <Tooltip title="طباعة البيان">
          <IconButton onClick={handlePrint}>
            <PrintOutlinedIcon />
          </IconButton>
        </Tooltip>
      </div>
      {!fromLoads && (
        <div style={{ border: "1px solid black" }}>
          <LogoImage />
        </div>
      )}
      <div className={classy.holder}>
        <div className={classy.main}>
          اسم صاحب النقلة
          <div className={classy.cell}>
            {truck.farmer ? truck.farmer.name : truck.farmerName}
          </div>
        </div>

        <div className={classy.main}>
          تاريخ النقلة :
          <div className={classy.cell}>
            {ConvertToArabicNumbers(
              new Date(b)
                .toISOString()
                .substring(0, 10)
                .replace(/-/g, "/")
                .replace(/(^|\/)0+/g, "$1")
            )}
          </div>
        </div>
        <div className={classy.main}>
          رقم النقلة :
          <div className={classy.cell}>
            {ConvertToArabicNumbers(truck.truckIdForThisDayAndClient)}
          </div>
        </div>
      </div>
      <Grid direction="column" s alignItems="center">
        <Grid item>
          <div style={{ width: "100%", marginBottom: 13 }}>
            <SoldItemsTable soldItems={soldItems} />
          </div>
        </Grid>
        <Grid
          container
          alignItems="center"
          style={{ justifyContent: "space-around" }}
        >
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.qty}>
              الاجمالي:
              <div className={classy.cell}>
                {ConvertToArabicNumbers(Math.round(total))}
              </div>
            </Typography>
          </Grid>
          <Grid item xs={12} className="mt-2">
            <SummeryTable summaryItems={summaryItems} />
          </Grid>
          {!fromLoads && (
            <Grid item>
              {invoiceItems.length > 0 ? (
                ""
              ) : (
                <Button
                  style={{ marginTop: 12 }}
                  variant="outlined"
                  onClick={onMoveSelected}
                >
                  ترحيل البيان
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Summarize;

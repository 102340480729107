import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  getClientsMoney,
  getEmployeesSalary,
  getGeneralDamage,
  getPurchasesMortgageExpenses,
  getSellerAdvanceExpenses,
  getSellersMortgageExpenses,
  getTrucksDamage,
  getTrucksNolon,
} from "./expensesSlice";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";
import ExpensesReport from "./components/ExpensesReport";
import SearchIcon from "@material-ui/icons/Search";
import Total from "../../components/UI/Total";
import ar from "date-fns/locale/ar-SA";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import MainHeader from "../../components/mainHeader/MainHeader";

registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 12,
  },
  SearchForm: {
    marginTop: 12,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    "& > *": {
      marginRight: 12,
    },
  },
}));

function Expenses() {
  const [dateTime, setDateTime] = useState(new Date());
  const [reportType, setReportType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const purchasesMortgageExpenses = useSelector(
    (state) => state.expenses.purchasesMortgageExpenses
  );
  const sellersMortgageExpenses = useSelector(
    (state) => state.expenses.sellersMortgageExpenses
  );
  const sellerAdvanceExpenses = useSelector(
    (state) => state.expenses.sellerAdvanceExpenses
  );
  const generalDamage = useSelector((state) => state.expenses.GeneralDamage);
  const clientMoney = useSelector((state) => state.expenses.ClientsMoney);
  const truckNolon = useSelector((state) => state.expenses.TrucksNolon);
  const employeesSalary = useSelector(
    (state) => state.expenses.EmployeesSalary
  );
  const truckDamage = useSelector((state) => state.expenses.TruckDamage);

  React.useEffect(() => {
    if (reportType.id === 1) {
      dispatch(getSellersMortgageExpenses(dateTime));
    } else if (reportType.id === 2) {
      dispatch(getPurchasesMortgageExpenses(dateTime));
    } else if (reportType.id === 3) {
      dispatch(getSellerAdvanceExpenses(dateTime));
    } else if (reportType.id === 5) {
      dispatch(getGeneralDamage(dateTime));
    } else if (reportType.id === 6) {
      dispatch(getTrucksDamage(dateTime));
    } else if (reportType.id === 7) {
      dispatch(getEmployeesSalary(dateTime));
    } else if (reportType.id === 8) {
      dispatch(getTrucksNolon(dateTime));
    } else if (reportType.id === 9) {
      dispatch(getClientsMoney(dateTime));
    }
  }, [reportType, dateTime]);

  const reportsTypes = [
    { id: 1, name: "مصروفات رهن بائعين" },
    { id: 2, name: " مصروفات رهن مشتريات" },
    { id: 3, name: "مصروفات سلفة بائع" },
    { id: 5, name: "هوالك عامة" },
    { id: 6, name: "هوالك نقلات" },
    { id: 7, name: "مرتبات موظفين" },
    { id: 8, name: "نولون نقلات" },
    { id: 9, name: "نقديات العملاء" },
  ];

  let expensesReport = [];
  if (reportType.id === 1) {
    expensesReport = sellersMortgageExpenses;
  } else if (reportType.id === 2) {
    expensesReport = purchasesMortgageExpenses;
  } else if (reportType.id === 3) {
    expensesReport = sellerAdvanceExpenses;
  } else if (reportType.id === 5) {
    expensesReport = generalDamage;
  } else if (reportType.id === 6) {
    expensesReport = truckDamage;
  } else if (reportType.id === 7) {
    expensesReport = employeesSalary;
  } else if (reportType.id === 8) {
    expensesReport = truckNolon;
  } else if (reportType.id === 9) {
    expensesReport = clientMoney;
  }
  const totalPrice = expensesReport?.reduce((acc, val) => {
    return acc + val.money;
  }, 0);

  const classes = useStyles();
  return (
    <div
      className=" w-100  p-0 py-4 pb-5 position-relative  "
      style={{
        background: "#534d3b",
        marginTop: " -15px !important",
        minHeight: "107vh",
        outline: " 8px solid #534d3b",
      }}
    >
      <Container>
        <div className="my-4">
          <MainHeader title="تقارير المصروفات" />
        </div>
        <CardContent className="headerSearch m-0">
          <Grid container spacing={2} className="center">
            <Grid item xs={12} md={3}>
              <Autocomplete
                options={reportsTypes}
                getOptionSelected={(option, value) =>
                  option.value === value?.value
                }
                getOptionLabel={(option) => (option.name ? option.name : "")}
                style={{ width: "100%", marginLeft: 16 }}
                autoHighlight
                autoComplete
                noOptionsText="لم يتم العثور على خيارات"
                onChange={(event, value) => {
                  if (value) {
                    setReportType(value);
                  } else {
                    setReportType("");
                  }
                }}
                value={reportType}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="نوع التقرير"
                    variant="outlined"
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
              {/* <TextField
                style={{ width: 260, marginRight: 12 }}
                size="small"
                select
                label="نوع التقرير"
                onChange={(e) => setReportType(e.target.value)}
                value={reportType}
                variant="outlined"
              >
                {reportsTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField> */}
            </Grid>
            <Grid item xs={12} md={3}>
              <DatePicker
                locale="ar"
                selected={dateTime}
                onChange={(d) => setDateTime(d)}
                dateFormat="dd/MM/yyyy"
                customInput={
                  <TextField
                    size="small"
                    variant="outlined"
                    id="dateTime"
                    style={{ width: "100%", backgroundColor: "#FFF" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                placeholder="بحث..."
                className="w-100"
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        {reportType && (
          <div className="center">
            {totalPrice !== 0 && <Total label="الاجمالي" value={totalPrice} />}
          </div>
        )}
        <ExpensesReport
          reportName={reportType.name}
          expenses={expensesReport}
          searchQuery={searchQuery}
          totalPrice={totalPrice}
        />
      </Container>
    </div>
  );
}

export default Expenses;

import { IconButton, TableCell, TableRow, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { ConvertToArabicNumbers } from "../../../utils";
import CustomizedTable from "../../../components/Table";
import DeleteBox from "../../../components/DeleteBox";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditImportItem from "./EditImportItem";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {},
  details: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  delBtn: {
    backgroundColor: theme.palette.error.main,
    width: 80,
    marginLeft: 16,
    height: 40,
  },
  editIcon: {
    color: theme.palette.primary.light,
  },

  deleteIcon: {
    color: theme.palette.error.light,
    fontSize: 80,
    marginBottom: 24,
  },
  label: {
    fontSize: 16,
    marginRight: 32,
  },

  trash: {
    color: theme.palette.error.light,
  },
  edit: {
    color: theme.palette.info.light,
  },

  formContent: {
    display: "flex",
    flexDirection: "row",
    "& > *": {
      marginRight: 12,
    },
  },
}));
function ImportDetailTable({
  items,
  onDeleteItemClicked,
  onUpdateItemClicked,
  disableAll,
}) {
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState({});
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deletedItemId, setDeletedItemId] = React.useState("");

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 16,
      overflow: "auto",
    },
  }))(TableCell);
  const classes = useStyles();
  return (
    <div style={{ width: 380, margin: "12px auto" }}>
      {items && items.length > 0 && (
        <CustomizedTable tableHeader={["العدة", "العدد", "تعديل", "حذف"]}>
          {items.map((item, index) => (
            <TableRow key={index} align="center" className={classes.tableBody}>
              <StyledTableCell align="center">
                <Typography variant="body1" color="textPrimary">
                  {item.movementTool.name}
                </Typography>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Typography variant="body1" color="textPrimary">
                  {ConvertToArabicNumbers(item.count)}
                </Typography>
              </StyledTableCell>

              <StyledTableCell align="center" style={{ padding: 0, width: 32 }}>
                <IconButton
                  className={classes.edit}
                  disabled={disableAll}
                  onClick={() => {
                    setItem(item);
                    setOpen(true);
                  }}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </StyledTableCell>

              <StyledTableCell align="center" style={{ padding: 0, width: 32 }}>
                <IconButton
                  className={classes.trash}
                  disabled={disableAll}
                  onClick={() => {
                    setOpenDelete(true);
                    setDeletedItemId(item.id);
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          ))}
        </CustomizedTable>
      )}
      {item && item.id && (
        <EditImportItem
          open={open}
          item={item}
          onEditItemClicked={onUpdateItemClicked}
          onClose={() => setOpen(false)}
        />
      )}
      <DeleteBox
        action={() => {
          onDeleteItemClicked(deletedItemId);
          setOpenDelete(false);
        }}
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        message="هل تريد حذف العنصر"
      />
    </div>
  );
}

export default ImportDetailTable;

import { addDeductions, updateDeductions } from "../invoiceSlice";
import { useDispatch, useSelector } from "react-redux";

import Deductions from "../../loads/components/Deductions";
import React from "react";

function AddDedeuctions({ closeDialog, deduction, onDisableEdit }) {
  const deduction1 = useSelector((state) => state.invoice.deduction);
  const dispatch = useDispatch();
  const onAddDeductions = ({
    independentCommision,
    commisionPercentage,
    carryingPrice,
    officeTips,
    movementToolRent,
    fridgeRent,
    sharedPartnerId,
  }) => {
    dispatch(
      addDeductions({
        independentCommision,
        commisionPercentage,
        carryingPrice,
        officeTips,
        movementToolRent,
        fridgeRent,
        sharedPartnerId,
      })
    );
    onDisableEdit();
  };
  const onUpdate = (data) => {
    dispatch(updateDeductions(data));
    onDisableEdit();
  };

  return (
    <div>
      <Deductions
        onDisableEdit={onDisableEdit}
        deduction={deduction || deduction1}
        onAddDeductionsClicked={onAddDeductions}
        onUpdateClicked={onUpdate}
      />
    </div>
  );
}

export default AddDedeuctions;

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { fetchBuyers, fetchTools } from "../../main-data/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { toast } from "react-toastify";
import Mydate from "../../../components/Helpers/MyDateParser";

const UpdateSellingForm = ({
  item,
  onClose,
  onUpdateClicked,
  isSellerHidden,
}) => {
  const buyers = useSelector((state) => state.data.buyers);
  const items = useSelector((state) => state.loads.commissionItems.items);
  const tools = useSelector((state) => state.data.tools);
  const [partner, setPartner] = useState({});
  const [partnerName, setPartnerName] = useState("");
  const [truckItem, setTruckItem] = useState({});
  const [productsCount, setProductsCount] = useState("");
  const [movementTool, setMovementTool] = useState("");
  const [weight, setWeight] = useState("");
  const [priceForUnitWeight, setPriceForUnitWeight] = useState("");
  const [commision, setCommision] = useState("");
  const [dateTime, setDateTime] = useState(new Date());
  const role = localStorage.getItem("role");

  const dispatch = useDispatch();
  const getProducts = (arr) => {
    let products = [];
    for (let i = 0; i < arr.length; i++) {
      products.push({
        id: arr[i].id,
        product: arr[i].product,
      });
    }
    return products;
  };

  const loadProducts = getProducts(items);
  const [isSuppling, setIsSuppling] = useState(false);
  useEffect(() => {
    if (item.truckItem) {
      setPriceForUnitWeight(item.priceForUnitWeight);
      setTruckItem(item.truckItem);
      setCommision(item.commision);
      setMovementTool(item.movementTool);
      setProductsCount(item.productsCount);
      setPartner(item.partner);
      setWeight(item.weight);
      setDateTime(new Date(item.dateTime));
      if (item.invoiceTypeId === 3) {
        setIsSuppling(true);
      } else {
        setIsSuppling(false);
      }
    }
  }, [item]);

  useEffect(() => {
    dispatch(fetchBuyers());
    dispatch(fetchTools());
  }, []);
  const onUpdateItemClicked = () => {
    const invoiceType = buyers.find((p) => p.id === partner.id);
    if (!invoiceType) return toast.error("هذا البائع غير مفعل");

    let type = isSuppling
      ? 3
      : partner.payementTypeId === undefined
      ? invoiceType && invoiceType.payementTypeId
      : partner.payementTypeId;
    if (partner && truckItem) {
      onUpdateClicked({
        id: item.id,
        truckItem: truckItem,
        partner,
        movementTool,
        priceForUnitWeight,
        weight,
        productsCount,
        commision,
        invoiceTypeId: type,
        dateTime: Mydate(dateTime),
        oldCount: item.productsCount,
        oldtruckItemId: item.truckItem.id,
      });
    } else {
      toast.warning("الصنف او البائع غير موجود");
    }
    onClose();
  };

  return (
    <Box mt={3} p={2}>
      <Card
        style={{
          boxShadow:
            "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
        }}
      >
        {buyers && (
          <CardContent style={{ overflow: "visible" }}>
            <form style={{ overflow: "visible" }} noValidate autoComplete="off">
              <Grid
                style={{ overflow: "visible" }}
                container
                spacing={1}
                direction="row"
                alignItems="center"
              >
                <Grid item xs={6} md={3}>
                  <Autocomplete
                    options={buyers}
                    getOptionSelected={(option, value) =>
                      option.value === value?.value
                    }
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    autoHighlight
                    size="small"
                    noOptionsText="لم يتم العثور على خيارات"
                    onChange={(e, value) => {
                      if (value) {
                        setPartner(value);
                      } else {
                        setPartner("");
                      }
                    }}
                    value={partner}
                    onInputChange={(e, v) => {
                      setPartnerName(v);
                    }}
                    inputValue={partnerName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="اسم البائع"
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} md={2}>
                  <TextField
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        onUpdateItemClicked();
                      }
                    }}
                    label="العدد"
                    id="items-number"
                    variant="outlined"
                    size="small"
                    className="w-100"
                    type="number"
                    onChange={(e) =>
                      setProductsCount(Number.parseFloat(e.target.value))
                    }
                    value={productsCount >= 0 ? productsCount : ""}
                  />
                </Grid>

                <Grid item xs={6} md={2}>
                  <TextField
                    label="الوزن"
                    id="weight"
                    variant="outlined"
                    size="small"
                    className="w-100"
                    type="number"
                    onChange={(e) =>
                      setWeight(Number.parseFloat(e.target.value))
                    }
                    value={weight >= 0 ? weight : ""}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        onUpdateItemClicked();
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={6} md={2}>
                  <TextField
                    className="w-100"
                    label="السعر"
                    id="price"
                    variant="outlined"
                    size="small"
                    type="number"
                    onChange={(e) =>
                      setPriceForUnitWeight(Number.parseFloat(e.target.value))
                    }
                    value={priceForUnitWeight >= 0 ? priceForUnitWeight : ""}
                  />
                </Grid>

                <Grid item xs={6} md={1}>
                  <TextField
                    className="w-100"
                    label="بياعة"
                    id="commission-value"
                    variant="outlined"
                    size="small"
                    type="number"
                    onChange={(e) =>
                      setCommision(Number.parseFloat(e.target.value))
                    }
                    value={commision >= 0 ? commision : ""}
                  />
                </Grid>

                <Grid item xs={6} md={2}>
                  <Autocomplete
                    options={tools}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    className="w-100"
                    autoHighlight
                    size="small"
                    freeSolo
                    onChange={(e, value) => {
                      if (value) {
                        setMovementTool(value);
                      } else {
                        setMovementTool("");
                      }
                    }}
                    value={movementTool}
                    renderInput={(params) => (
                      <TextField
                        autoComplete="off"
                        {...params}
                        label="العدة"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} md={2}>
                  {loadProducts.length > 0 && !isSellerHidden && (
                    <Autocomplete
                      options={loadProducts}
                      getOptionSelected={(option, value) =>
                        option.value === value?.value
                      }
                      getOptionLabel={(option) => {
                        return option.product
                          ? option.product.name.toString()
                          : "";
                      }}
                      className="w-100"
                      autoHighlight
                      size="small"
                      onChange={(e, value) => {
                        if (value) {
                          setTruckItem(value);
                        } else {
                          setTruckItem("");
                        }
                      }}
                      value={truckItem}
                      renderInput={(params) => (
                        <TextField
                          autoComplete="off"
                          {...params}
                          label="اسم الصنف"
                          variant="outlined"
                          size="small"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                </Grid>

                <Grid item xs={6} md={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Basic example"
                      value={dateTime}
                      onChange={(date) => setDateTime(date)}
                      disabled={role == "موظف"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          label="التاريخ"
                          className="w-100"
                        />
                      )}
                      className="col-xs-6 col-md-2"
                    />
                  </LocalizationProvider>
                </Grid>

                {partner.payementTypeId !== 2 && !isSellerHidden && (
                  <Grid
                    item
                    xs={8}
                    md={1}
                    className="center justify-content-evenly"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isSuppling}
                          onChange={(event) => {
                            setIsSuppling(event.target.checked);
                          }}
                        />
                      }
                      label="توريد"
                    />
                  </Grid>
                )}

                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="btnMore py-1"
                    onClick={onUpdateItemClicked}
                  >
                    تعديل
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        )}
      </Card>
    </Box>
  );
};

UpdateSellingForm.defaultProps = {
  isSellerHidden: false,
};
export default UpdateSellingForm;

import axios from "axios";
import { parseISO } from "date-fns";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import Alerto from "../../../components/UI/toaster";
import { ConvertToArabicNumbers, renderDate } from "../../../utils";
import PaginationArrows from "./PaginationArrows";
import cls from "./TruckHistoryTable.module.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { StyledTableCell } from "../../../components/Table";
const head = [
  " اسم العميل",
  "الهاتف",
  "رصيد افتتاحي",
  "النوع",
  "نوع الدفع",
  "تنبيه الرصيد",
  "خط التحصيل ",
  "موظف ",
  "نشط ",
  " اسم المستخدم",
  " تاريخ الاجراء ",
  " نوع الاجراء ",
];
const options = {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
};
const NameHistoryTable = (props) => {
  const Modal = (props) => {
    return (
      <div onClick={props.close} className={props.class} style={{ zIndex: 3 }}>
        <TableContainer overflowX="auto" style={{ overflowY: "hidden", position: 'relative' }} >

          <Table>
            <TableHead>
              <TableRow style={{ background: "#8c4312" }}>
                <TableCell align="center" colSpan={12} className="text-white">
                  سجل الأسماء
                </TableCell>
              </TableRow>
              <TableRow style={{ background: "#F3C637" }}>
                {head.map((ele, index) => (
                  <TableCell
                    key={index}
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {ele}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.slice(pageNum * 20 - 20, pageNum * 20).map((el) => (
                <TableRow
                  style={{ background: "#fff" }}
                  onClick={() => props.fetch(el.id, el.periodStart)}
                >
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                      border: '1px solid'
                    }}
                  >
                    {el.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                      border:'1px solid'
                    }}
                  >
                    {" "}
                    {ConvertToArabicNumbers(el.phone1)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                      border: '1px solid'

                    }}
                  >
                    {el.openingBalance}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                      border: '1px solid'

                    }}
                  >
                    {el.partnerTypeName}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                      border: '1px solid'

                    }}
                  >
                    {el.payementTypeName}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                      border: '1px solid'

                    }}
                  >
                    {el.balanceAlert}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                      border: '1px solid'

                    }}
                  >
                    {el.collectingAddressName}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                      border: '1px solid'
                    }}
                  >
                    {el.isEmployee ? "نعم" : ""}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                      border: '1px solid'
                    }}
                  >
                    {!el.isActive ? "لا" : ""}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px", border: '1px solid'
                    }}
                  >
                    {el.recorderName}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 170px",
                      textAlign: "center",
                      padding: "10px",
                      border:'1px solid'
                    }}
                  >
                    {ConvertToArabicNumbers(
                      parseISO(el.periodStart).getMonth() +
                      1 +
                      "/" +
                      parseISO(el.periodStart).getDate()
                    )}
                    {"  الساعة  "}
                    {ConvertToArabicNumbers(
                      parseISO(el.periodStart).toLocaleString("en-US", options)
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                      border:'1px solid'
                    }}
                  >
                    {" "}
                    {el.processTypeEnum == 1
                      ? "أضافة"
                      : el.processTypeEnum == 3
                        ? "حذف"
                        : "تعديل"}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow style={{ background: "#ff0" }}>
                <TableCell align="center" colSpan={12} className="p-0">
                  {!props.close && (
                    <PaginationArrows
                      pageNum={pageNum}
                      onPageUp={onPageUp}
                      onPageDown={onPageDown}
                      availabe={data[pageNum * 20 + 1]}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const [data, setData] = React.useState([]);
  const [GetData, setGetData] = React.useState([]);
  React.useEffect(() => {
    setPageNum(1);
    setData(props.data.filter((ele) => ele.name?.includes(props.search)));
  }, [props]);
  const getHandler = async (id, date) => {
    try {
      setGetData([]);
      const res = await axios.post(
        `History/maindata/partners/before?entityId=${id}&before=${date.substring(
          0,
          date.length - 5
        )}`
      );
      if (res.data[0]) {
        setGetData(res.data);
      } else {
        toast.error("لا يوجد بيانات لعرضها");
      }
    } catch (e) {
      Alerto(e);
    }
  };
  const [pageNum, setPageNum] = React.useState(1);
  const onPageUp = () => {
    setPageNum(pageNum + 1);
  };
  const onPageDown = () => {
    setPageNum(pageNum - 1);
  };
  return (
    <Fragment>
      <Modal class={cls.nameTable} fetch={getHandler} data={data} />
      {GetData[0] && (
        <Modal
          close={() => setGetData([])}
          class={cls.modalnameTable}
          data={GetData}
        />
      )}
    </Fragment>
  );
};
export default NameHistoryTable;
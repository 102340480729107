import { useDispatch, useSelector } from "react-redux";
import { Element, ElementWrapper, TwistedArrow, Wrapper } from "./styles";
import { useEffect } from "react";
import { getRemainingDetailsForPartners } from "../../features/movement-tools/tools-reports/toolsSlice";
import { ConvertToArabicNumbers } from "../../utils";
import Arow from "../../assets/icons/twistedArrow.svg";
export default function AccountReamainingTable({ partnerId }) {
  const remainingDetails = useSelector((state) => state.tools.remainingDetails);

  const dispatch = useDispatch();
  useEffect(() => {
    partnerId && dispatch(getRemainingDetailsForPartners(partnerId));
  }, []);
  return (
    <Wrapper>
      <TwistedArrow src={Arow} />
      {remainingDetails.map((ele) => (
        <ElementWrapper>
          <Element head>{ele.tool?.name}</Element>
          <Element>{ConvertToArabicNumbers(ele.count)}</Element>
        </ElementWrapper>
      ))}
    </Wrapper>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { indexOfId } from "../../utils";

import axios from "axios";
import { toast } from "react-toastify";
import Alerto from "../../components/UI/toaster";
import Mydate from "../../components/Helpers/MyDateParser";

export const getTruckFullData = createAsyncThunk(
  "invoice/getTruckFullData",
  async (truckId) => {
    try {
      if (truckId) {
        const response = await axios.get(`/Truck/FullData/${truckId}`);
        return response.data;
      }
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createInvoice = createAsyncThunk(
  "invoice/createInvoice",
  async (truckId, { rejectWithValue, getState }) => {
    try {
      const response = await axios.post(`/TruckInvoice`, {
        truckId,
        creattionDate: Mydate(new Date()),
        freezed: false,
        adderId: getState().auth.user.UserId,
      });
      if (response.status === 200) {
        toast.success("تم انشاء فاتورة");
        return response.data;
      }
    } catch (e) {
      Alerto(e);
      throw new Error(e.response.data);
    }
  }
);

//it's only available for commision truck!
export const getInvoice = createAsyncThunk(
  "invoice/getInvoice",
  async (truckId) => {
    try {
      const response = await axios.get(`/TruckInvoice/${truckId}`);

      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);

export const freezeInvoice = createAsyncThunk(
  "invoice/freezeInvoice",
  async (invoiceId) => {
    try {
      const response = await axios.put(
        `/TruckInvoice/freezeInvoice/${invoiceId}`
      );

      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);

export const activeInvoice = createAsyncThunk(
  "invoice/activeInvoice",
  async (invoiceId) => {
    try {
      const response = await axios.put(
        `TruckInvoice/ActiveInvoice/${invoiceId}`
      );
      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);

export const getActualSelling = createAsyncThunk(
  "invoice/getActualSelling",
  async (truck) => {
    try {
      const response = await axios.get(
        `/Selling/Summerize?truckTypeId=${truck.truckType}&truckId=${truck.id}`
      );
      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);

export const getSummary = createAsyncThunk(
  "invoice/getSummary",
  async (truck) => {
    try {
      const response = await axios.get(
        `/Selling/SummerizeByItem?truckTypeId=${truck.truckType}&truckId=${truck.id}`
      );
      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);

export const addItemsCollectionToInvoice = createAsyncThunk(
  "invoice/addItemsCollectionToInvoice",
  async (items, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/TruckInvoice/Item`, {
        itemsDto: items, //you have to customize the body!!!
      });
      return response.data;
    } catch (error) {
      Alerto(error);
      return rejectWithValue(error.response.data);
    }
  }
);

//get all items of a specific invoice
export const getInvoiceItems = createAsyncThunk(
  "invoice/getInvoiceItems",
  async (invoiceId, { rejectWithValue }) => {
    try {
      if (invoiceId) {
        const response = await axios.get(`TruckInvoice/Item/${invoiceId}`);
        return response.data;
      }
      return [];
    } catch (error) {
      Alerto(error);
      return rejectWithValue([]);
    }
  }
);

export const updateInvoiceItem = createAsyncThunk(
  "invoice/updateInvoiceItem",
  async (item) => {
    try {
      const response = await axios.put(`/TruckInvoice/Item/${item.id}`, {
        id: item.id,
        invoiceId: item.invoiceId,
        itemId: item.item.id,
        itemCount: item.itemCount,
        weight: item.weight,
        priceForUnitWeight: item.priceForUnitWeight,
      });
      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);

export const deleteInvoiceItem = createAsyncThunk(
  "invoice/deleteInvoiceItem",
  async (id) => {
    try {
      const response = await axios.delete(`/TruckInvoice/Item/${id}`);
      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);
export const deleteAllInvoiceItems = createAsyncThunk(
  "invoice/deleteInvoiceItem",
  async (id) => {
    try {
      const response = await axios.delete(`/TruckInvoice/${id}`);
      toast.error("تم الحذف");
      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);

export const getBuyingTruckProfit = createAsyncThunk(
  "invoice/getBuyingTruckProfit",
  async (truckId) => {
    try {
      const response = await axios.get(
        `/TruckInvoice/BuyingTruckProfit/${truckId}`
      );
      return response.data;
    } catch (error) {
      Alerto(error);
      return error.response.data;
    }
  }
);

export const addDeductions = createAsyncThunk(
  "invoice/addDeductions",
  async (data, { getState, dispatch }) => {
    const truckId = getState().invoice.truck.id;
    try {
      const response = await axios.post(`/Discounts/${truckId}`, {
        ...data,
        adderId: getState().auth.user.UserId,
        dateTime: Mydate(new Date()),
      });
      dispatch(getDeductions());

      return response.data;
    } catch (error) {
      Alerto(error);
      return error.response.data;
    }
  }
);

export const getDeductions = createAsyncThunk(
  "invoice/getDeductions",
  async (truckId, { getState }) => {
    try {
      const response = await axios.get(
        `/Discounts/${truckId || getState().invoice.truck.id}`
      );

      if (response.status === 200) {
        return response.data;
      } else if (response.status === 204) {
        return {
          independentCommision: 0,
          commisionPercentage: 0,
          carryingPrice: 0,
          officeTips: 0,
          movementToolRent: 0,
          fridgeRent: 0,
        };
      }
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateDeductions = createAsyncThunk(
  "invoice/updateDeductions",
  async (data, { getState, dispatch }) => {
    const deductionId = getState().invoice.deduction.id;
    const truckId = getState().invoice.truck.id;
    try {
      const response = await axios.put(
        `/Discounts/${deductionId || data?.deductionId}`,
        {
          ...data,
          truckId,
          adderId: getState().auth.user.UserId,
        }
      );
      dispatch(getDeductions());
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

//===================================EXPENSES=================================================
export const addExpenses = createAsyncThunk(
  "invoice/addExpenses",
  async (data, { getState }) => {
    const truckId = getState().invoice.truck.id;
    const owner = getState().invoice.truck.farmer;
    const dateTime = Mydate(new Date());
    try {
      const response = await axios.post("/ExpenseContoller", {
        money: data.money,
        notes: data.notes,
        treasuryTypeId: data.category.id,
        truckId,
        dateTime,
        adderId: getState().auth.user.UserId,
        SharedPartnerId: data.SharedPartnerId || owner?.id,
      });
      toast.success("تمت الاضافة");
      return response.data;
    } catch (error) {
      Alerto(error);
      return error.response.data;
    }
  }
);

export const getExpenses = createAsyncThunk(
  "invoice/getExpenses",
  async (_, { getState }) => {
    const truckId = getState().invoice.truck.id;
    try {
      const response = await axios.get(`/ExpenseContoller/${truckId}`);
      return response.data;
    } catch (error) {
      Alerto(error);

      return error.response.data;
    }
  }
);

export const deleteExpenses = createAsyncThunk(
  "invoice/deleteExpenses",
  async (data, { getState }) => {
    try {
      const response = await axios.delete(
        `ExpenseContoller/RemoveExpenseById?isDamage=${data.isDamage}&id=${data.id}`
      );
      toast.error("تم الحذف");
      return response.data;
    } catch (error) {
      Alerto(error);
      return error.response.data;
    }
  }
);
export const updateInvoiceExpense = createAsyncThunk(
  "invoice/updateExpenses",
  async ({
    isDamage,
    id,
    money,
    dateTime,
    sharedPartnerId,
    SharedPartnerId,
  }) => {
    try {
      await axios.put(
        `ExpenseContoller/UpdateExpenseOrDamageMoneyAndDate?isDmage=${isDamage}&sharedPartnerId=${
          sharedPartnerId || SharedPartnerId
        }&id=${id}&money=${money}${!!dateTime ? `&date=${dateTime}` : ""}`
      );
      toast.success("تم التعديل");
      return true;
    } catch (error) {
      Alerto(error);
      return false;
    }
  }
);

const initialState = {
  truck: {},
  invoice: {},
  invoiceStatus: "idle",
  soldItems: {
    data: [],
    status: "idle",
    error: null,
  },
  summaryItems: [],
  invoiceItems: {
    data: [],
    status: "idle",
    deleteStatus: "idle",
    error: null,
  },
  buyingProfit: "",
  deduction: {},
  loadExpenses: {
    expenses: [],
    invoiceDeductions: [],
    conclusions: [],
  },
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    // omit existing reducers here
  },
  extraReducers: {
    [getTruckFullData.pending]: (state, action) => {
      state.invoice = {};
      state.invoiceItems.data = [];
      state.soldItems.data = [];
      state.summaryItems = [];
      state.deduction = {};
      state.buyingProfit = "";
    },
    [getTruckFullData.fulfilled]: (state, action) => {
      state.truck = action.payload;
      state.invoice = {};
      state.invoiceItems.data = [];
      state.deduction = {};
    },
    [getInvoice.fulfilled]: (state, action) => {
      state.invoice = action.payload;
    },
    [createInvoice.pending]: (state, action) => {
      state.invoiceStatus = "loading";
    },
    [createInvoice.fulfilled]: (state, action) => {
      state.invoiceStatus = "succeeded";
      state.invoice = action.payload;
    },
    [createInvoice.rejected]: (state, action) => {
      state.invoiceStatus = "failed";
    },
    [freezeInvoice.fulfilled]: (state, action) => {
      state.invoice.freezed = true;
    },
    [activeInvoice.fulfilled]: (state, action) => {
      state.invoice.freezed = false;
    },
    [getActualSelling.fulfilled]: (state, action) => {
      state.soldItems.data = action.payload;
    },
    [getSummary.fulfilled]: (state, action) => {
      state.summaryItems = action.payload;
    },

    [getInvoiceItems.pending]: (state, action) => {
      state.invoiceItems.status = "loading";
    },
    [getInvoiceItems.fulfilled]: (state, action) => {
      state.invoiceItems.status = "succeded";
      state.invoiceItems.data = action.payload;
    },
    [getInvoiceItems.rejected]: (state, action) => {
      state.invoiceItems.status = "failed";
      state.invoiceItems.data = action.payload;
    },
    [updateInvoiceItem.fulfilled]: (state, action) => {
      const index = indexOfId(state.invoiceItems.data, action.meta.arg.id);
      state.invoiceItems.data[index] = action.meta.arg;
      state.invoiceItems.data[index].totalMoney = action.payload.totalMoney;
    },

    [deleteInvoiceItem.pending]: (state, action) => {
      state.invoiceItems.deleteStatus = "loading";
    },

    [deleteInvoiceItem.fulfilled]: (state, action) => {
      state.invoiceItems.deleteStatus = "succeeded";
      state.invoiceItems.data = state.invoiceItems.data.filter((item) => {
        return item.id !== action.meta.arg;
      });
    },

    [getBuyingTruckProfit.fulfilled]: (state, action) => {
      state.buyingProfit = action.payload;
    },

    //================================DEDUCTIONS==================================

    [updateDeductions.fulfilled]: (state, action) => {},

    [getDeductions.fulfilled]: (state, action) => {
      state.deduction = action.payload;
    },

    //================================EXPENSES==================================

    [addExpenses.pending]: (state, action) => {
      state.loadExpenses.expenses.push(action.meta.arg);
    },

    [addExpenses.rejected]: (state, action) => {
      state.loadExpenses.expenses.pop();
    },

    [addExpenses.fulfilled]: (state, action) => {
      state.loadExpenses.expenses[state.loadExpenses.expenses.length - 1].id =
        action.payload.id;
    },

    [getExpenses.fulfilled]: (state, action) => {
      state.loadExpenses = action.payload;
    },

    [deleteExpenses.fulfilled]: (state, action) => {
      state.loadExpenses.expenses = state.loadExpenses.expenses.filter(
        (exp) => exp.id !== action.meta.arg.id
      );
    },
    [updateInvoiceExpense.fulfilled]: (state, action) => {},
  },
});

export default invoiceSlice.reducer;

import { Button, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function IterationInputs({
  tools,
  ele,
  rowData,
  onInputChanged,
  removeItem,
  onAddRow,
  disabled,
  isDamage,
  isAnonymous,
}) {
  const isPlusButtonDisabled =
    !rowData.count ||
    !rowData.tool ||
    (!rowData.partner && !isAnonymous) ||
    (!rowData.price && isDamage && !isAnonymous);
  return (
    <>
      <div className="row">
        <div
          className={
            window.location.pathname === "/damage-tools"
              ? "col-12 col-md-6 mb-3"
              : "col-12 col-md-5 mb-3"
          }
        >
          <Autocomplete
            options={tools}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            getOptionSelected={(option, value) => option.id === value.id}
            autoHighlight
            autoComplete
            noOptionsText="لم يتم العثور على خيارات"
            disabled={disabled}
            onChange={(event, value) => {
              onInputChanged({ key: "tool", value });
            }}
            value={
              disabled
                ? tools.find((tool) => tool.id === ele.toolId)
                : rowData.tool
            }
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                label="العدة"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            )}
          />
        </div>
        {/* "col-9 col-md-5 mb-3" */}
        <div
          className={
            window.location.pathname === "/damage-tools"
              ? "col-12 col-md-6 mb-3"
              : "col-9 col-md-5 mb-3"
          }
        >
          <TextField
            className="w-100"
            disabled={disabled}
            value={ele.count || rowData.count}
            onChange={({ target }) =>
              onInputChanged({ key: "count", value: target.value })
            }
            label="عدد"
            size="small"
            variant="outlined"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
              }
            }}
          />
        </div>

        {isDamage && !isAnonymous && (
          <div
            className={
              window.location.pathname === "/damage-tools"
                ? "col-9 col-md-6 mb-3"
                : "col-9 col-md-5 mb-3"
            }
          >
            <TextField
              className="w-100"
              disabled={disabled}
              value={ele.price || rowData.price}
              onChange={({ target }) =>
                onInputChanged({ key: "price", value: target.value })
              }
              label="سعر"
              size="small"
              variant="outlined"
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                }
              }}
            />
          </div>
        )}

        {disabled && (
          <div className="col-3 col-md-2">
            <Button
              variant="contained"
              style={{ height: 40, width: 40, alignSelf: "center" }}
              onClick={removeItem}
            >
              -
            </Button>
          </div>
        )}
        {!disabled && (
          <div className="col-3 col-md-2">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ height: 40, width: 40, alignSelf: "center" }}
              disabled={isPlusButtonDisabled}
              onClick={onAddRow}
            >
              +
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
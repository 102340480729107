import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ConvertToArabicNumbers } from "../../../../utils";
import { infoCardHeader } from "../../../styles";
import useInfoHook from "./modal";

const useStyles = makeStyles((theme) => ({
  qty: {
    display: "flex",
    padding: 8,
    borderRadius: 20,
    paddingRight: 12,
    paddingLeft: 12,
    color: "#FFF",
    ...infoCardHeader,
  },
  para: {
    margin: "0 0 0 10px",
  },
}));

export default function UserInfo({ partnerId }) {
  const classes = useStyles();

  const { money } = useInfoHook({ partnerId: partnerId });

  return (
    <div className={classes.purchasesTruck}>
      {money && (
        <Grid container spacing={1}>
          <Grid item>
            <Typography className={classes.qty}>
              الرهن :{" "}
              {money?.remain == 0
                ? "صفر"
                : ConvertToArabicNumbers(Math.round(Math.abs(money.remain)))}
              <p className={classes.para}>{money.remain > 0 ? "عليه" : "له"}</p>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.qty}>
              عدة:
              {money?.tool == 0 ? "صفر" : ConvertToArabicNumbers(money.tool)}
              <p className={classes.para}>{money.tool > 0 ? "عليه" : "له"}</p>
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

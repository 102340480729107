import { ConvertToArabicNumbers } from "../../../utils";
import React from "react";

const SummeryTable = (props) => {
  const tableHeader = props.isPaper
    ? ["الصنف", "العدد", "الوزن"]
    : ["الصنف", "العدد", "الوزن", "القيمة"];
  return (
    <table style={{width:'100%', border: "3px solid black",  borderCollapse: 'collapse' ,margin:'6px 0' }}>
      <thead>
        <tr style={{background:'#f3c637', lineHeight: "0px" }}>
          {tableHeader.map((item, index) => (
            <td key={index} align="center">
              <p> {item} </p>
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.summaryItems &&
          props.summaryItems.length > 0 &&
          props.summaryItems.map((item, index) => (
            <tr key={index} align="center" style={{ lineHeight: ".1px" }}>
              <td
                style={{ backgroundColor: "#EEF66C", padding: "0 5px 0 5px", border: "3px solid black" }}
                align="center"
              >
                <p variant="body1" color="textPrimary">
                  {item.item.product.name}
                </p>
              </td>

              <td style={{ backgroundColor: "#fff",border: "3px solid black"}}>
                <p variant="body1" color="textPrimary">
                  {ConvertToArabicNumbers(
                    item.itemCount ? item.itemCount : item.itemsCount
                  )}
                </p>
              </td>

              <td
                style={{ backgroundColor: "#fff", border: "3px solid black" }}
                align="center"
              >
                <p>{ConvertToArabicNumbers(item.weight)}</p>
              </td>

              {!props.isPaper && (
                <td
                  style={{ backgroundColor: "#fff", border: "3px solid black" }}
                  align="center"
                >
                  <p>{ConvertToArabicNumbers(Math.round(item.totalMoney))}</p>
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default SummeryTable;
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConvertToArabicNumbers } from '../../../utils';
import { Link } from 'react-router-dom';
import { getInvoiceSummery } from '../sellerSlice';
import { Row } from 'react-bootstrap';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function InvoiceSummery({ partnerId, invoiceId }) {
  const dispatch = useDispatch();
  const invoiceSummery = useSelector((state) => state.seller.invoiceSummery);

  const sellingInvoiceTypeId = useSelector(
    (state) => state.seller.sellingInvoiceTypeId
  );

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (invoiceId) {
        dispatch(getInvoiceSummery(invoiceId));
      }
    }
    return () => {
      mounted = false;
    };
  }, [dispatch, invoiceId]);

  return (
    <div>
      {invoiceSummery && (

        <Row xs={12} className='m-auto mt-3 text-center p-0'>
          <TableContainer className="p-0 m-0" component={Paper} >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: '#FED049', height: '65px' }}>
                <TableRow>
                  <TableCell align="center" className='headerTableFont' >عددالفئات</TableCell>
                  <TableCell align="center" className='headerTableFont' >صافي </TableCell>
                  <TableCell align="center" className='headerTableFont' > بياعه</TableCell>
                  <TableCell align="center" className='headerTableFont' > جمله</TableCell>
                  <TableCell align="center" className='headerTableFont' > عدد العدة</TableCell>
                  <TableCell align="center" className='headerTableFont' >  الرهن</TableCell>
                  <TableCell align="center" className='headerTableFont' >  الاجمالي</TableCell>
                  <TableCell align="center" className='headerTableFont' >  نسخه مطبوعه</TableCell>
                  <TableCell align="center" className='headerTableFont' ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                  <TableCell align="center" className='BodyTableFont'>  {invoiceSummery.uniqueProductCount > 0
                    ? ConvertToArabicNumbers(Math.round(invoiceSummery.uniqueProductCount))
                    : 'غير متوفر'}</TableCell>
                  <TableCell align="center" className='BodyTableFont'> {invoiceSummery.totalPriceOfWeight !== 0 ? ConvertToArabicNumbers(Math.round(invoiceSummery.totalPriceOfWeight.toFixed(2))) : 'غير متوفر'}</TableCell>
                  <TableCell align="center" className='BodyTableFont'> {invoiceSummery.totalPriceOfCommisions !== 0 ? ConvertToArabicNumbers(Math.round(invoiceSummery.totalPriceOfCommisions.toFixed(2))) : 'غير متوفر'}</TableCell>
                  {/* <TableCell align="center">{invoiceSummery.discounts !== 0 ? ConvertToArabicNumbers(invoiceSummery.discounts.toFixed(2)) : 'صفر'}</TableCell> */}
                  <TableCell align="center" className='BodyTableFont'> {invoiceSummery.totalPriceOfWeightAndCommisionsMinusDiscounts !==
                    0
                    ? ConvertToArabicNumbers(
                      Math.round(invoiceSummery.totalPriceOfWeightAndCommisionsMinusDiscounts.toFixed(
                        2
                      ))
                    )
                    : 'غير متوفر'}</TableCell>
                  <TableCell align="center" className='BodyTableFont'> {invoiceSummery.toolsCount !== 0
                    ? ConvertToArabicNumbers(Math.round(invoiceSummery.toolsCount))
                    : 'غير متوفر'}</TableCell>
                  <TableCell align="center" className='BodyTableFont'>  {invoiceSummery.toolsMortage !== 0
                    ? ConvertToArabicNumbers(
                      Math.round(invoiceSummery.toolsMortage.toFixed(2))
                    )
                    : 'غير متوفر'}</TableCell>
                  <TableCell align="center" className='BodyTableFont'>{invoiceSummery.totalValueOfWeightAndCommsisionsAndToolsMortage !==
                    0
                    ? ConvertToArabicNumbers(
                      Math.round( invoiceSummery.totalValueOfWeightAndCommsisionsAndToolsMortage.toFixed(
                        2
                      )
                    ))
                    : 'غير متوفر'}</TableCell>
                  <TableCell align="center" className='BodyTableFont'>  {invoiceSummery.printingCount > 0
                    ? ConvertToArabicNumbers(Math.round(invoiceSummery.printingCount))
                    : 'صفر'}</TableCell>
                  <TableCell className='btnMore BodyTableFont' align="center">
                    <Link to={`/sellers-invoices/${partnerId}/${sellingInvoiceTypeId}/profile`} style={{color:'white'}}>
                      تعديل الفاتورة
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Row>

      )}
    </div>
  );
}

export default InvoiceSummery;

import { updateDeductions, addDeductions, getDeductions } from "../loadsSlice";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, TableRow, Typography } from "@material-ui/core";
import CustomizedTable, { StyledTableCell } from "../../../components/Table";
import { ConvertToArabicNumbers } from "../../../utils";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Deductions from "../components/Deductions";
import React from "react";
import DeleteBox from "../../../components/DeleteBox";
import { useState } from "react";
import axios from "axios";
import Alerto from "../../../components/UI/toaster";
function AddDedeuctions({ closeDialog }) {
  const deduction = useSelector((state) => state.loads.deduction);
  const truck = useSelector((state) => state.loads.load?.data);
  const [deductionToDelete, setDeductionToDelete] = useState(null);
  const [deductionToEdit, setDeductionToEdit] = useState(null);
  const [openDeleteDeduction, setOpendeleteDeduction] = useState(false);
  const tableHeader = [
    "عميل مشترك",
    "عمولة",
    "نسبة العمولة",
    "مشال",
    "مكتب",
    "أيجار عدة",
    "تلاجة",
    "تعديل",
    "حذف",
  ];
  !truck?.sharedTruck && tableHeader.splice(0, 1);
  const dispatch = useDispatch();
  const onAddDeductions = ({
    independentCommision,
    commisionPercentage,
    carryingPrice,
    officeTips,
    movementToolRent,
    fridgeRent,
    sharedPartnerId,
  }) => {
    dispatch(
      addDeductions({
        sharedPartnerId,
        independentCommision,
        commisionPercentage,
        carryingPrice,
        officeTips,
        movementToolRent,
        fridgeRent,
      })
    );
  };
  const onUpdate = (data) => {
    dispatch(updateDeductions(data));
    setDeductionToEdit(null);
  };
  async function onDeleteDeduction() {
    try {
      await axios.delete(`discounts?discountid=${deductionToDelete.id}`);
      setOpendeleteDeduction(false);
      dispatch(getDeductions(truck?.id));
    } catch (error) {
      Alerto(error);
    }
  }
  return (
    <div>
      <Deductions
        deduction={deductionToEdit}
        onAddDeductionsClicked={onAddDeductions}
        onUpdateClicked={onUpdate}
      />
      {deduction?.length > 0 && (
        <CustomizedTable tableHeader={tableHeader}>
          {deduction.map((item, index) => (
            <TableRow key={index} align="center">
              {!!truck?.sharedTruck && (
                <StyledTableCell align="center">
                  <Typography variant="body1" color="textPrimary">
                    {item.sharedPartner?.name}
                  </Typography>
                </StyledTableCell>
              )}
              <StyledTableCell align="center">
                <Typography variant="body1" color="textPrimary">
                  {ConvertToArabicNumbers(item.independentCommision)}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="body1" color="textPrimary">
                  {ConvertToArabicNumbers(item.commisionPercentage)}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="body1" color="textPrimary">
                  {ConvertToArabicNumbers(item.carryingPrice)}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="body1" color="textPrimary">
                  {ConvertToArabicNumbers(item.officeTips)}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="body1" color="textPrimary">
                  {ConvertToArabicNumbers(item.movementToolRent)}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="body1" color="textPrimary">
                  {ConvertToArabicNumbers(item.fridgeRent)}
                </Typography>
              </StyledTableCell>

              <StyledTableCell align="center">
                <IconButton
                  // className={classes.edit}
                  onClick={() => {
                    setDeductionToEdit(item);
                  }}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </StyledTableCell>
              <StyledTableCell align="center">
                <IconButton
                  onClick={() => {
                    setOpendeleteDeduction(true);
                    setDeductionToDelete(item);
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          ))}
        </CustomizedTable>
      )}
      {!!deductionToDelete && (
        <DeleteBox
          action={onDeleteDeduction}
          open={openDeleteDeduction}
          onClose={() => setOpendeleteDeduction(false)}
          message={"حذف خصومات العميل"}
        />
      )}
    </div>
  );
}

export default AddDedeuctions;

import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import DebtItem from "./components/DebtItem";
import classy from "./Buyer.module.css";
import "./clientAccount.css";
import { Helmet } from "react-helmet";
import LogoImage from "../../components/LogoImage";
import MoneyTable from "./components/MoneyTable";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { useReactToPrint } from "react-to-print";
import Alerto from "../../components/UI/toaster";
import { HistoryIcon } from "@mui/icons-material/History";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Col, Row } from "react-bootstrap";
import { ConvertToArabicNumbers } from "../../utils";
import moment from "moment/moment";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  infoBox: {
    width: 120,
    padding: 8,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
  },
  printableTable: {
    overflow: "hidden",
    height: 0,
  },
  profileData: {
    backgroundColor: "#FD8D00",
    width: "100%",
    padding: 4,
  },
  "@media print": {
    printableTable: {
      height: "auto",
      overflowY: "auto",
    },
    printHidden: {
      display: "none",
    },
    profileData: {
      backgroundColor: "#FD8D00",
      width: "auto",
      padding: 4,
    },
  },
}));
function EmployeeAccount() {
  const { partnerId } = useParams();
  const role = localStorage.getItem("role");
  const [partner, setPartner] = useState("");
  const [staffdata, setstaffdata] = useState([]);
  const [staffdebt, setstaffdebt] = useState("");
  const getPartnerData = () => {
    axios.get(`/Partners/${partnerId}`).then((res) => {
      setPartner(res.data);
    });
  };
  const getPartneraccount = async () => {
    const t = moment();
    const tt = t.add(1, "days").format("YYYY-MM-DD");
    try {
      const res = await axios.get(
        `MoneyCurrentAccountReport/employee?partnerId=${partnerId}&from=2020-01-10&to=${tt}`
      );
      setstaffdata(res.data);
    } catch (e) {
      Alerto(e);
    }
  };
  const getPartnerDebt = async () => {
    try {
      const res = await axios.get(
        `MoneyCurrentAccountReport/debtDetails/employee/${partnerId}`
      );

      setstaffdebt(res.data);
    } catch (e) {
      Alerto(e);
    }
  };
  useEffect(() => {
    getPartnerData();
    getPartneraccount();
    getPartnerDebt();
  }, []);

  const [indexesArr, setIndexesArr] = React.useState([]);
  const onPrintBorders = (index) => {
    if (indexesArr.includes(index)) {
      let indexes = [...indexesArr];
      indexes.splice(indexes.indexOf(index), 1);
      setIndexesArr(indexes);
      return;
    }
    if (indexesArr.length < 2) {
      setIndexesArr((oldIdxs) => [...oldIdxs, index]);
    }
  };

  const tartgetIndexes = indexesArr.sort(function (a, b) {
    return a - b;
  });

  const [printable, setPrintable] = useState([]);

  const componentRef = React.useRef();
  useEffect(() => {
    setPrintable(staffdata.slice(tartgetIndexes[0], tartgetIndexes[1] + 1));
  }, [staffdata, tartgetIndexes]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const element = document.getElementById("scrollList");
  function downList_Page() {
    setTimeout(() => {
      window.scrollTo({
        top: element.scrollHeight,
        behavior: "smooth",
      });
    });
  }

  const classes = useStyles();
  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }, 1000);
  }, []);
  return (
    <div className="container">
      <Helmet>
        <title>حساب جاري الموظف</title>
      </Helmet>
      <div id="scrollList">
        <div className=" p-0 container position-relative my-3 ">
          <div
            id="accountsHeader"
            className="nameAndDate center  justify-content-between container top1"
            style={{ zIndex: 3 }}
          >
            <div className=" center arrowUPTo ">
              {indexesArr?.length < 2 ? (
                <ArrowBackIosIcon
                  onClick={downList_Page}
                  className="w-75 h-75 ms-3"
                  style={{ fontSize: "2px !important" }}
                />
              ) : (
                <PrintOutlinedIcon
                  onClick={handlePrint}
                  style={{ color: "white", transform: "rotate(-270deg)" }}
                />
              )}
            </div>
            <Col xs={6} sm={7} md={5} className="m-auto center p-3 ">
              <Typography style={{ fontSize: "23px" }}>
                {partner?.name}
              </Typography>
            </Col>
          </div>
        </div>
        {staffdata.length > 0 && (
          <MoneyTable
            isstaff={true}
            data={staffdata}
            type="client"
            onPrintBorders={(index) => onPrintBorders(index)}
            indexesArr={indexesArr}
          />
        )}{" "}
        <Row className="p-0 my-2   m-auto   ">
          <Col xs={12} className=" text-center w-100 p-1 m-auto">
            <TableContainer className="p-0 m-0" component={Paper}>
              <Table aria-label="simple table">
                <TableHead style={{ background: "#FED049" }}>
                  <TableRow>
                    <TableCell
                      align="center"
                      className=" center justify-content-between p-0 py-4"
                      style={{ fontSize: 20 }}
                    >
                      المرتب
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.printHidden}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className=" py-3">
                    <TableCell
                      align="center"
                      className="w-50 "
                      style={{ fontSize: 20 }}
                    >
                      {ConvertToArabicNumbers(Math.abs(Math.round(staffdebt)))}{" "}
                      {Math.round(staffdebt) > 0 ? "عليه" : ""}
                      {Math.round(staffdebt) < 0 ? "له" : ""}
                      {staffdebt === 0 ? "صفر" : ""}
                    </TableCell>
                    {role != "محصل" && role != "عميلاوبائع" && (
                      <TableCell
                        align="center"
                        component={Link}
                        style={{
                          background: " #343434",
                          color: "white",
                          boxShadow:
                            "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
                          cursor: "pointer",
                          fontSize: 16,
                          padding: "25px 0",
                        }}
                        to={`/add-expenses/partners/${partnerId}/8`}
                        className={classes.printHidden}
                      >
                        سداد راتب موظفين
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
        </Row>
      </div>

      <div ref={componentRef}>
        <div className={classes.printableTable}>
          <div className={classy.imgholder}>
            <LogoImage />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                color: "#2E3840",
                width: "99%",
                padding: 10,
                margin: "10px auto",
                border: "2px dashed black",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  marginTop: ".3vh",
                }}
              >
                {"مطلوب من : "}
              </Typography>
              <Typography
                style={{
                  fontWeight: "bolder",
                }}
              >
                {partner.name}
              </Typography>
            </div>
          </div>
          <MoneyTable
            type="client"
            squareInvisibile="invisible"
            data={printable}
            isPrint={true}
          />
          <Row
            className="p-0 w-100 m-auto overflow-hidden     "
            style={{ minHeight: "10vh" }}
          >
            <Col xs={12} className=" text-center p-0 m-auto w-100 ">
              <TableContainer
                component={Paper}
                className="p-0 "
                style={{
                  border: "1px black solid",
                }}
              >
                <Table>
                  <TableHead
                    style={{
                      backgroundColor: "#FAA829",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        align="center"
                        className=" center justify-content-between py-1"
                        style={{ fontSize: 16 }}
                      >
                        المرتب
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.printHidden}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="w-50 p-0 "
                        style={{ fontSize: 16 }}
                      >
                        {ConvertToArabicNumbers(
                          Math.abs(Math.round(staffdebt))
                        )}{" "}
                        {Math.round(staffdebt) > 0 ? "عليه" : ""}
                        {Math.round(staffdebt) < 0 ? "له" : ""}
                        {staffdebt === 0 ? "صفر" : ""}
                      </TableCell>
                      {role != "محصل" && role != "عميلاوبائع" && (
                        <TableCell
                          align="center"
                          component={Link}
                          style={{
                            background: " #343434",
                            color: "white",
                            boxShadow:
                              "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
                            cursor: "pointer",
                            fontSize: 16,
                            padding: "0",
                          }}
                          to={`/add-expenses/partners/${partnerId}/8`}
                          className={classes.printHidden}
                        >
                          سداد راتب موظفين
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default EmployeeAccount;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { indexOfId } from "../../utils";

import axios from "axios";
import { toast } from "react-toastify";
import Alerto from "../../components/UI/toaster";
import Mydate from "../../components/Helpers/MyDateParser";

export const initializeLoad = createAsyncThunk(
  "loads/initializeLoad",
  async (data, { rejectWithValue, getState }) => {
    try {
      const response = await axios.post("/truck", {
        farmerId: data.farmer.id,
        truckDate: data.truckDate,
        adderId: getState().auth.user.UserId,
        truckTypeId: data.truckTypeId,
      });
      if (response.status === 200) {
        toast.success("تم انشاء النقلة بنجاح");
      }
      return response.data;
    } catch (e) {
      Alerto(e);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateLoad = createAsyncThunk(
  "loads/updateLoad",
  async (changes, { getState, rejectWithValue }) => {
    const truckId = getState().loads.load.data.id;
    try {
      const response = await axios.put(`/Truck/${truckId}`, {
        id: truckId,
        farmerId: changes.farmer.id,
        truckDate: changes.truckDate,
        adderId: getState().auth.user.UserId, //changes.adderId,
        truckTypeId: changes.truckTypeId,
      });
      if (response.status === 200) {
        toast.success("تم تعديل بيانات النقلة");
      }
      return response.data;
    } catch (error) {
      Alerto(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteLoad = createAsyncThunk(
  "loads/deleteLoad",
  async (_, { getState, rejectWithValue }) => {
    const truckId = getState().loads.load.data.id;
    try {
      const response = await axios.delete(`/truck/${truckId}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTruck = createAsyncThunk(
  "loads/getTruck",
  async (truckId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/Truck/${truckId}`);

      return response.data;
    } catch (error) {
      Alerto(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const addCommisionItem = createAsyncThunk(
  "loads/addCommisionItem",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const truck = !!data.truck ? data.truck : getState().loads.load.data;
    try {
      if (truck.truckTypeId === 1) {
        const response = await axios.post(
          `/Truck/CommisionItems/${truck.id || truck.truckId}`,
          {
            SharedPartnerId: data.SharedPartnerId,
            productId: data.product.id,
            mostSellingPrice: data.mostSellingPrice,
            mostCommision: data.mostCommision,
            mostMovementToolId: data.mostMovementTool.id
              ? data.mostMovementTool.id
              : null,
            itemsCount: data.itemsCount,
            adderId: getState().auth.user.UserId,
            dateTime: new Date(),
          }
        );
        toast.success("تم اضافة الصنف");
        dispatch(getCommisionItem());
        return response.data;
      } else {
        const response = await axios.post(
          `/Truck/BuyingItems/${truck.id || truck.truckId}`,
          {
            SharedPartnerId: data.SharedPartnerId,
            productId: data.product.id,
            mostSellingPrice: data.mostSellingPrice,
            mostCommision: data.mostCommision,
            mostMovementToolId: data.mostMovementTool.id
              ? data.mostMovementTool.id
              : null,
            itemsCount: data.itemsCount,
            commisionForClient: data.commisionForClient,
            weight: data.weight,
            price: data.price,
            adderId: getState().auth.user.UserId,
            dateTime: new Date(),
          }
        );
        toast.success("تم اضافة الصنف");
        dispatch(getCommisionItem());
        return response.data;
      }
    } catch (e) {
      Alerto(e);
      return rejectWithValue(e.response.data);
    }
  }
);

//=======================================================

export const getCommisionItem = createAsyncThunk(
  "loads/getCommisionItem",
  async (_, { getState, rejectWithValue }) => {
    const truckId = getState().loads.load.data.id;
    const truck = getState().loads.load.data;
    try {
      if (truck.truckTypeId === 1) {
        const response = await axios.get(`/Truck/CommisionItems/${truckId}`);
        return response.data;
      } else if (truck.truckTypeId === 2) {
        const response = await axios.get(`/Truck/BuyingItems/${truckId}`);
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCommisionItem = createAsyncThunk(
  "loads/updateCommisionItem",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const truck = getState().loads.load.data;
    if (truck.truckTypeId === 1) {
      try {
        const response = await axios.put(`/Truck/CommisionItems/${data.id}`, {
          id: data.id,
          productId: data.product.id,
          mostSellingPrice: data.mostSellingPrice,
          mostCommision: data.mostCommision,
          mostMovementToolId: data.mostMovementTool
            ? data.mostMovementTool.id
            : null,
          itemsCount: data.itemsCount,
          adderId: getState().auth.user.UserId,
          dateTime: new Date(),
          sharedPartnerId: data.sharedPartnerId,
        });
        toast.success("تم التعديل");
        dispatch(getCommisionItem());
        return response.data;
      } catch (error) {
        Alerto(error);
        return rejectWithValue(error.response.data);
      }
    } else {
      try {
        const response = await axios.put(`/Truck/BuyingItems/${data.id}`, {
          id: data.id,
          productId: data.product.id,
          mostSellingPrice: data.mostSellingPrice,
          mostCommision: data.mostCommision,
          mostMovementToolId: data.mostMovementTool
            ? data.mostMovementTool.id
            : null,
          itemsCount: data.itemsCount,
          commisionForClient: data.commisionForClient,
          weight: data.weight,
          price: data.price,
          adderId: getState().auth.user.UserId,
          dateTime: new Date(),
          sharedPartnerId: data.sharedPartnerId,
        });

        dispatch(getCommisionItem());
        return response.data;
      } catch (error) {
        Alerto(error);
        return rejectWithValue(error.response.data);
      }
    }
  }
);

//////////////////////////////////////

export const deleteCommisionItem = createAsyncThunk(
  "loads/deleteCommisionItem",
  async (itemId, { getState, rejectWithValue }) => {
    const truck = getState().loads.load.data;
    try {
      if (truck.truckTypeId === 1) {
        const response = await axios.delete(`/Truck/CommisionItems/${itemId}`);
        return response.data;
      } else {
        const response = await axios.delete(`/Truck/BuyingItems/${itemId}`);
        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

//===================================SELLING=================================================

export const sellCommisionItem = createAsyncThunk(
  "loads/sellCommisionItem",
  async (
    {
      partner,
      truckItem,
      movementTool,
      priceForUnitWeight,
      weight,
      productsCount,
      commision,
      invoiceTypeId,
      dateTime,
    },
    { getState, rejectWithValue }
  ) => {
    const typeId = getState().loads.load.data.truckTypeId;
    const movementToolId =
      movementTool && movementTool.id ? movementTool.id : null;
    try {
      const response = await axios.post(`/Selling/${typeId}`, {
        partnerId: partner.id,
        priceForUnitWeight,
        productsCount,
        commision,
        movementToolId,
        weight,
        truckItemId: truckItem.id,
        invoiceTypeId,
        dateTime: dateTime,
        adderId: getState().auth.user.UserId,
      });
      toast.success("تمت العملية");
      return response.data;
    } catch (error) {
      Alerto(error);

      return rejectWithValue(error.response.data);
    }
  }
);

export const getSoldItems = createAsyncThunk(
  "loads/getSoldItems",
  async (truck, { getState, rejectWithValue }) => {
    const truck2 = getState().loads.load.data;

    try {
      const response = await axios.get(
        `/Selling?truckTypeId=${
          truck.truckTypeId || truck2.truckTypeId
        }&truckId=${truck.id || truck2.id}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSellCommisionItem = createAsyncThunk(
  "loads/updateSellCommisionItem",
  async (data, { getState, rejectWithValue }) => {
    const truck = getState().loads.load.data;
    const {
      id,
      truckItem,
      partner,
      movementTool,
      priceForUnitWeight,
      weight,
      productsCount,
      commision,
      invoiceTypeId,
      dateTime,
      truckTypeId,
    } = data;

    const movementToolId =
      movementTool && movementTool.id ? movementTool.id : null;
    try {
      const response = await axios.put(
        `/Selling?truckTypeId=${
          truck?.truckTypeId || truckTypeId
        }&ItemId=${id}`,
        {
          id,
          partnerId: partner.id,
          priceForUnitWeight,
          productsCount,
          commision,
          movementToolId,
          weight,
          truckItemId: truckItem.id,
          invoiceTypeId,
          adderId: getState().auth.user.UserId,
          dateTime: dateTime ? dateTime : new Date(),
        }
      );

      toast.success("تم التعديل");

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSellCommissionItem = createAsyncThunk(
  "loads/deleteSellCommissionItem",
  async (id, { getState, rejectWithValue }) => {
    const typeId = getState().loads.load.data.truckTypeId;
    try {
      const response = await axios.delete(
        `/Selling?truckTypeId=${typeId}&sellId=${id}`
      );

      if (response.status === 200) {
        toast.error("تم حذف عملية البيع");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//===================================DEDUCTIONS=================================================

export const addDeductions = createAsyncThunk(
  "loads/addDeductions",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const sharedPartnerId =
      data.sharedPartnerId || getState().loads.load.data.farmer.id;
    const truckId = getState().loads.load.data.id;
    try {
      const response = await axios.post(`/Discounts/${truckId}`, {
        ...data,
        sharedPartnerId,
        adderId: getState().auth.user.UserId,
      });
      dispatch(getDeductions());
      return response.data;
    } catch (error) {
      toast.error(error.response.data);

      return rejectWithValue(error.response.data);
    }
  }
);

export const getDeductions = createAsyncThunk(
  "loads/getDeductions",
  async (_, { getState, rejectWithValue }) => {
    const truckId = getState().loads.load.data.id;
    try {
      const response = await axios.get(`/Discounts/${truckId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateDeductions = createAsyncThunk(
  "loads/updateDeductions",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const deductionId = getState().loads.deduction.id;
    const truckId = getState().loads.load.data.id;
    try {
      const response = await axios.put(
        `/Discounts/${deductionId || data.deductionId}`,
        {
          ...data,
          truckId,
        }
      );
      dispatch(getDeductions());
      return response.data;
    } catch (error) {
      toast.error(error.response.data);

      return rejectWithValue(error.response.data);
    }
  }
);

//===================================EXPENSES=================================================
export const addExpenses = createAsyncThunk(
  "loads/addExpenses",
  async (data, { getState, rejectWithValue, dispatch }) => {
    const truckId = getState().loads.load.data.id;
    const SharedPartnerId =
      data.SharedPartnerId || getState().loads.load.data.farmer.id;
    const dateTime = Mydate(new Date());
    try {
      const response = await axios.post("/ExpenseContoller", {
        SharedPartnerId,
        money: data.money,
        notes: data.notes,
        treasuryTypeId: data.category.id,
        truckId,
        dateTime,
        adderId: getState().auth.user.UserId,
      });
      dispatch(getExpenses());
      return response.data;
    } catch (error) {
      toast.error(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getExpenses = createAsyncThunk(
  "loads/getExpenses",
  async (_, { getState, rejectWithValue }) => {
    const truckId = getState().loads.load.data.id;
    try {
      const response = await axios.get(`/ExpenseContoller/${truckId}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteExpenses = createAsyncThunk(
  "loads/RemoveExpenseById",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `ExpenseContoller/RemoveExpenseById?isDamage=${data.isDamage}&id=${data.id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//===================================SUPPLING=================================================

const initialState = {
  load: {
    data: {},
    error: null,
  },
  checkedTransferRows: [],
  isTransferEligable: false,
  chosedTruckForTransfer: {},
  showTransfer: false,
  commissionItems: {
    items: [],
    error: null,
    updateStatus: "idle",
    deleteStatus: "idle",
  },
  selectedProduct: {},
  soldProduct: {},
  soldItems: [],
  deduction: {},
  loadExpenses: {
    expenses: [],
    invoiceDeductions: [],
    conclusions: [],
  },
  deleteSodlItem: false,
  deleteTruckItem: false,

  partnerSellings: [],
};

const loadsSlice = createSlice({
  name: "loads",
  initialState,
  reducers: {
    transferRowsHandler: (state, action) => {
      state.checkedTransferRows = action.payload;
    },
    transferStateHandler: (state, action) => {
      state.isTransferEligable = action.payload.state;
      state.chosedTruckForTransfer = action.payload.truck;
    },
    showTransfer: (state, action) => {
      state.showTransfer = action.payload;
    },
    productSelected: (state, action) => {
      state.selectedProduct = action.payload;
    },
    createNewLoad: (state) => {
      state.load = {};
      state.commissionItems.items = [];
      state.soldItems = [];
      state.selectedProduct = {};
      state.deduction = {};
      state.expenses = {};
    },
    soldProductSelected: (state, action) => {
      state.soldProduct = action.payload;
    },
  },

  extraReducers: {
    // [initializeLoad.pending]: (state, action) => {
    //   state.load.data = action.meta.arg;
    // },

    [initializeLoad.fulfilled]: (state, action) => {
      state.load.data = action.payload;
    },

    [initializeLoad.rejected]: (state, action) => {
      state.load.data = {};
      state.load.error = action.payload;
    },
    [updateLoad.fulfilled]: (state, action) => {
      state.load.data = action.meta.arg;
      state.load.data.id = action.payload.id;
    },

    [deleteLoad.fulfilled]: (state, action) => {
      state.load = {};
      state.commissionItems.items = [];
      state.soldItems = [];
      state.selectedProduct = {};
      state.deduction = {};
      state.loadExpenses = {};
    },

    [deleteLoad.rejected]: (state, action) => {},

    [getTruck.fulfilled]: (state, action) => {
      state.load.data = action.payload;
    },

    [getCommisionItem.fulfilled]: (state, action) => {
      state.commissionItems.items = action.payload;
    },

    [addCommisionItem.pending]: (state, action) => {
      state.commissionItems.items.push(action.meta.arg);
    },
    [addCommisionItem.fulfilled]: (state, action) => {
      state.commissionItems.items[state.commissionItems.items.length - 1].id =
        action.payload[0].id;
      state.commissionItems.items[
        state.commissionItems.items.length - 1
      ].remainingProductCount = action.payload[0].remainingProductCount;
    },
    [addCommisionItem.rejected]: (state, action) => {
      state.commissionItems.items.pop();
    },

    [updateCommisionItem.pending]: (state, action) => {
      const index = indexOfId(state.commissionItems.items, action.meta.arg.id);
      state.commissionItems.items[index] = action.meta.arg;

      state.commissionItems.updateStatus = "loading";
    },

    [updateCommisionItem.fulfilled]: (state, action) => {
      state.commissionItems.updateStatus = "succeeded";
      const index = indexOfId(state.commissionItems.items, action.meta.arg.id);
      state.commissionItems.items[index] = action.meta.arg;

      state.commissionItems.items[index].remainingProductCount =
        action.payload.remainingProductCount;

      state.selectedProduct =
        state.selectedProduct.id === action.meta.arg.id
          ? (state.selectedProduct = state.commissionItems.items[index])
          : state.selectedProduct;
    },

    [updateCommisionItem.rejected]: (state, action) => {
      state.commissionItems.updateStatus = "error";
      state.commissionItems.error = action.payload;
    },

    [deleteCommisionItem.pending]: (state, action) => {
      state.commissionItems.deleteStatus = "loading";
    },

    [deleteCommisionItem.fulfilled]: (state, action) => {
      const itemIdx = indexOfId(state.commissionItems.items, action.meta.arg);
      state.soldItems = state.soldItems.filter((item) => {
        return item.truckItem.id !== state.commissionItems.items[itemIdx].id;
      });
      if (
        state.selectedProduct.id === state.commissionItems.items[itemIdx].id
      ) {
        state.selectedProduct = {};
      }
      state.selectedProduct =
        state.selectedProduct.id === action.meta.arg
          ? (state.selectedProduct = null)
          : state.selectedProduct;
      state.commissionItems.deleteStatus = "succeeded";
      state.commissionItems.items = state.commissionItems.items.filter(
        function (el) {
          return el.id !== action.meta.arg;
        }
      );
    },
    [deleteCommisionItem.rejected]: (state, action) => {
      state.commissionItems.deleteStatus = "failed";
    },

    //================================SELLING==================================
    // [sellCommisionItem.pending]: (state, action) => {
    //   state.soldItems.unshift(action.meta.arg);
    // },
    // [sellCommisionItem.rejected]: (state, action) => {
    //   state.soldItems.shift();
    // },
    [sellCommisionItem.fulfilled]: (state, action) => {
      // state.soldItems.unshift(action.payload.id);

      state.selectedProduct.remainingProductCount -= Math.abs(
        action.payload.productsCount
      );
      const index = indexOfId(
        state.commissionItems.items,
        action.payload.truckItemId
      );
      if (index >= 0) {
        state.commissionItems.items[index].remainingProductCount -= Math.abs(
          action.payload.productsCount
        );
      }
    },

    [getSoldItems.fulfilled]: (state, action) => {
      state.soldItems = action.payload;
    },

    [updateSellCommisionItem.fulfilled]: (state, action) => {
      if (state.soldItems?.[0]) {
        const index = indexOfId(state.soldItems, action.payload.id);
        state.soldItems[index] = action.meta.arg;

        if (state.selectedProduct?.id === state.soldProduct.truckItem.id) {
          state.selectedProduct.remainingProductCount +=
            state.soldProduct.oldCount;
          state.selectedProduct.remainingProductCount -=
            action.meta.arg.productsCount;
        }

        const truckItemIndex = indexOfId(
          state.commissionItems.items,
          state.soldProduct.truckItem.id
        );

        const oldItemIdx = indexOfId(
          state.commissionItems.items,
          action.meta.arg.oldtruckItemId
        );
        if (truckItemIndex === oldItemIdx) {
          state.commissionItems.items[truckItemIndex].remainingProductCount +=
            Math.abs(action.meta.arg.oldCount);
          state.commissionItems.items[truckItemIndex].remainingProductCount -=
            Math.abs(action.payload.productsCount);
        } else {
          state.commissionItems.items[oldItemIdx].remainingProductCount +=
            Math.abs(action.meta.arg.oldCount);
          state.commissionItems.items[truckItemIndex].remainingProductCount -=
            Math.abs(action.payload.productsCount);
        }
      }
      //=====================================
    },

    [deleteSellCommissionItem.fulfilled]: (state, action) => {
      state.selectedProduct = {};
      const index = indexOfId(state.soldItems, action.meta.arg);
      const itemIdx = indexOfId(
        state.commissionItems.items,
        state.soldItems[index].truckItem.id
      );
      state.commissionItems.items[itemIdx].remainingProductCount +=
        state.soldItems[index].productsCount;
      state.soldItems = state.soldItems.filter(
        (item) => item.id !== action.meta.arg
      );
      state.deleteSodlItem = true;
      state.selectedItem = {};
    },

    //================================DEDUCTIONS==================================

    [updateDeductions.fulfilled]: (state, action) => {
      state.deduction = action.payload;
    },
    [updateDeductions.rejected]: (state, action) => {
      state.deduction = "";
    },
    [getDeductions.fulfilled]: (state, action) => {
      state.deduction = action.payload;
    },

    //================================EXPENSES==================================

    [addExpenses.pending]: (state, action) => {
      state.loadExpenses.expenses.push(action.meta.arg);
    },

    [addExpenses.rejected]: (state, action) => {
      state.loadExpenses.expenses.pop();
    },

    [addExpenses.fulfilled]: (state, action) => {
      state.loadExpenses.expenses[state.loadExpenses.expenses.length - 1].id =
        action.payload.id;
    },

    [getExpenses.fulfilled]: (state, action) => {
      state.loadExpenses = action.payload;
    },

    [deleteExpenses.fulfilled]: (state, action) => {
      state.loadExpenses.expenses = state.loadExpenses.expenses.filter(
        (exp) => exp.id !== action.meta.arg.id
      );
    },
  },
});

export const {
  productSelected,
  soldProductSelected,
  createNewLoad,
  transferStateHandler,
  transferRowsHandler,
  showTransfer,
} = loadsSlice.actions;
export default loadsSlice.reducer;

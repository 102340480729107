import { Grid } from "@material-ui/core";
import ToolsTable from "../../features/movement-tools/components/ToolsImportTable";
import ToolsForm from "../ToolsForm";
import ToolsSearch from "../ToolsSearch";
import useToolsModal from "./modal";
import { Container } from "react-bootstrap";

export default function Tools({ baseApi, title, isDamage, haveAction }) {
  const {
    tableData,
    deleteHandler,
    updateHandler,
    refresh,
    searchData,
    setSearchData,
  } = useToolsModal({
    baseApi,
  });

  return (
    <Container>
      <ToolsForm
        haveAction={haveAction}
        isDamage={isDamage}
        title={title}
        targetApi={baseApi + "addOrUpdate"}
        refresh={refresh}
      />
      <ToolsSearch searchData={searchData} setSearchData={setSearchData} />
      <Grid container justifyContent="center">
        <Grid item xs={12} >
          <ToolsTable
            isDamage={isDamage}
            items={tableData}
            onEditItemClicked={updateHandler}
            onDeleteItemClicked={deleteHandler}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

import { Box, Button, Container, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import NotFoundImg from "../../assets/images/not_found.svg";

const NotFound = () => {
  const history = useHistory();
  useEffect(() => {
    let block = localStorage.getItem("block");
    if (block) {
      history.replace("./block");
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>404 الصفحة غير موجودة</title>
      </Helmet>
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography align="center" color="textPrimary" variant="h1">
              404: الصفحة التي تبحث عنها ليست هنا
            </Typography>
            <Typography align="center" color="textPrimary" variant="subtitle2">
              إما أنك جربت طريقًا مشبوهًا أو أتيت إلى هنا عن طريق الخطأ. أيهما ،
              حاول استخدام الملاحة
            </Typography>
            <Box sx={{ textAlign: "center" }}>
              <img
                alt="Under development"
                src={NotFoundImg}
                style={{
                  marginTop: 50,
                  display: "inline-block",
                  maxWidth: "100%",
                  width: 560,
                }}
              />
            </Box>

            <Button
              component={Link}
              to="/"
              startIcon={<ArrowBackIcon fontSize="small" />}
              sx={{ mt: 3 }}
              variant="contained"
              style={{
                backgroundColor: "#6C63FF",
                color: "#fff",
                fontWeight: 600,
              }}
            >
              عودة الي الرئيسية
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default NotFound;

import { Fragment, useState } from "react";
import "./Navitem.css";
import { BsCircle } from "react-icons/bs";
import { TiChevronLeft } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";
import { Link } from "react-router-dom";
const Collapse = (props) => {
  const [openedindex, setopenedindex] = useState("");
  let stylo = "";
  const handlecollapse = (index) => {
    if (index === openedindex) {
      setopenedindex("");
    } else {
      setopenedindex(index);
    }
  };
  const handlecollapse2 = (index) => {
    if (!props.isopen) {
      if (index === openedindex) {
        setopenedindex("");
      } else {
        setopenedindex(index);
      }
    }
  };
  if (props.isopen) {
    stylo = "open";
  } else {
    stylo = "nestedopen";
  }
  return (
    <Fragment>
      <div className={props.isopen ? "navholderexpand" : "navholder"}>
        {props.lists.map((item, index) => (
          <div key={index}>
            <div
              className="btn"
              style={{ borderBottom: "1px solid #F3C637" }}
              onClick={() => {
                handlecollapse(index);
              }}
            >
              <div
                className={props.isopen ? "iconHandler" : "iconHandleractive"}
              >
                <item.icon />
              </div>
              {props.isopen && <p key={index}>{item.list}</p>}

              {openedindex !== index
                ? props.isopen && (
                    <TiChevronLeft
                      style={{ color: "#FFDA00", fontSize: "1.5vw" }}
                    />
                  )
                : props.isopen && (
                    <TiArrowSortedDown
                      style={{ color: "#FFDA00", fontSize: "1.5vw" }}
                    />
                  )}
            </div>
            {openedindex === index && !props.isopen && (
              <div
                onClick={() => handlecollapse(index)}
                className="closemodal"
              />
            )}
            <div className="collapseholder ">
              <div
                className={`${stylo} ${
                  openedindex !== index ? "collapse" : ""
                }`}
              >
                {item.items.map((nested, idx) =>
                  !!nested.href ? (
                    <Link
                      onClick={() => handlecollapse2(index)}
                      to={nested.href}
                   
                    >
                      <div key={idx} className="nested">
                        <div>
                          <BsCircle className="icon" />
                        </div>
                        <p
                          style={{
                            color: "#181301",
                            paddingRight: 0,
                            marginRight: 0,
                            fontSize: 14,
                          }}
                        >
                          {nested.title}
                        </p>
                      </div>
                    </Link>
                  ) : (
                    <Link
                      onClick={() => handlecollapse2(index)}
                      to={{ pathname: nested.blank }}
                      target="_blank"
                    >
                      <div key={idx} className="nested">
                        <div>
                          <BsCircle className="icon" />
                        </div>
                        <p
                          style={{
                            color: "#181301",
                            paddingRight: 0,
                            marginRight: 0,
                            fontSize: 14,
                          }}
                        >
                          {nested.title}
                        </p>
                      </div>
                    </Link>
                  )
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};
export default Collapse;

import { Button, Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { addItemsCollectionToInvoice, getInvoiceItems } from "../invoiceSlice";

import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
    border: ".1px solid gray",
    padding: "2vh 0vw 2vh 1vw",
  },
}));

function AddInvoiceItem({ truck, invoice }) {
  const [item, setItem] = useState("");
  const [itemsCount, setItemsCount] = useState("");
  const [weight, setWeight] = useState("");
  const [price, setPrice] = useState("");
  const [owner, setOwner] = useState({});
  const [ownerName, setOwnerName] = useState("");
  const [sharedPartners, setSharedPartners] = useState([]);

  async function fetchSharedpartners() {
    try {
      const res = await axios.get(`Truck/GetSharedPartners/${truck?.id}`);
      setSharedPartners(res.data);
    } catch (e) {
      console.log(e);
    }
  }
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const getProducts = () => {
    axios.get(`/Truck/products/${truck.id}`).then((res) => {
      setProducts(res.data);
    });
  };
  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      getProducts();
      fetchSharedpartners();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const handleSubmit = () => {
    setItem("");
    setItemsCount("");
    setPrice("");
    setWeight("");
    dispatch(
      addItemsCollectionToInvoice([
        {
          invoiceId: invoice.id,
          itemId: item.truckItemId,
          itemCount: itemsCount,
          priceForUnitWeight: price,
          weight: weight,
          totalMoney: price * weight,
          sharedPartnerId: owner?.id,
        },
      ])
    ).then(({ payload }) => {
      dispatch(getInvoiceItems(invoice.id));
    });
  };

  const classes = useStyles();
  return (
    <form
      className="headerSearch py-3 rounded-3 center my-2 m-auto"
      noValidate
      autoComplete="off"
    >
      <Grid container className=" center" spacing={1}>
        {!!truck.sharedTruck && (
          <Grid item md={3} sm={5} xs={8} className="my-2 p-2">
            <Autocomplete
              options={sharedPartners}
              getOptionSelected={(option, value) =>
                option.value === value?.value
              }
              getOptionLabel={(option) => (option.name ? option.name : "")}
              autoHighlight
              value={owner}
              onChange={(e, newValue) => {
                if (newValue) {
                  setOwner(newValue);
                } else {
                  setOwner("");
                }
              }}
              onInputChange={(e, v) => {
                setOwnerName(v);
              }}
              inputValue={ownerName}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  {...params}
                  label="عميل مشترك"
                  variant="outlined"
                  size="small"
                  className="w-100"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item md={3} sm={5} xs={8}>
          <Autocomplete
            options={products}
            getOptionSelected={(option, value) => option.value === value?.value}
            getOptionLabel={(option) => {
              return option.productName ? option.productName : "";
            }}
            style={{ backgroundColor: "#fff" }}
            onChange={(e, value) => {
              if (value) {
                setItem(value);
              }
            }}
            value={item}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                {...params}
                label="اسم الصنف"
                variant="outlined"
                size="small"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={2} sm={5} xs={8}>
          <TextField
            label="العدد"
            variant="outlined"
            size="small"
            style={{ backgroundColor: "#fff", width: "100%" }}
            onChange={(e) => setItemsCount(Number.parseFloat(e.target.value))}
            value={itemsCount > 0 ? itemsCount : ""}
            type="number"
          />
        </Grid>
        <Grid item md={2} sm={5} xs={8}>
          <TextField
            label="الوزن"
            variant="outlined"
            size="small"
            style={{ backgroundColor: "#fff", width: "100%" }}
            onChange={(e) => setWeight(Number.parseFloat(e.target.value))}
            value={weight > 0 ? weight : ""}
            type="number"
          />
        </Grid>
        <Grid item md={2} sm={5} xs={8}>
          <TextField
            label="السعر"
            variant="outlined"
            size="small"
            style={{ backgroundColor: "#fff", width: "100%" }}
            onChange={(e) => setPrice(Number.parseFloat(e.target.value))}
            value={price > 0 ? price : ""}
            type="number"
          />
        </Grid>
        <Grid item md={3} sm={5} xs={8}>
          <Button
            onClick={handleSubmit}
            style={{
              height: 45,
              backgroundColor: "rgb(20 20 20 / 85%)",
              fontWeight: 600,
              color: "#FFF",
              width: "100%",
            }}
            variant="contained"
            color="primary"
          >
            اضف
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default AddInvoiceItem;

import { ConvertToArabicNumbers, getWeekDay, renderDate } from "../../../utils";
import { Paper, Typography } from "@material-ui/core";

import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { parseISO } from "date-fns";

const useStyles = makeStyles({
  table: {
    width: "100%",
  },

  tableCell: {},
});
var options = {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
};
export default function SessionTable({ data }) {
  const classes = useStyles();

  return (
    <TableContainer
      style={{ width: '95%', margin: "auto", boxShadow: "#fff 0px 3px 8px" }}
      component={Paper}
    >
      <Table className={classes.table}>
        <TableHead style={{ backgroundColor: "#F3C637" }}>
          <TableRow>
            <TableCell align="center">
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                المستخدم
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                من
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                الي
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tableCell} align="center">
                <Typography variant="body1">{row.name}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <Typography
                  variant="body1"
                  style={{ color: "#666", fontFamily: "monospace" }}
                >
                  {ConvertToArabicNumbers(
                    parseISO(row.from).getMonth() +
                      1 +
                      "/" +
                      parseISO(row.from).getDate()
                  )}
                  {"  الساعة  "}
                  {ConvertToArabicNumbers(
                    parseISO(row.from).toLocaleString("en-US", options)
                  )}
                </Typography>
              </TableCell>

              {row.to ? (
                <TableCell className={classes.tableCell} align="center">
                  <Typography
                    variant="body1"
                    style={{ color: "#666", fontFamily: "monospace" }}
                  >
                    {/* {getWeekDay(parseISO(row.to))} {'  '}( */}
                    {ConvertToArabicNumbers(
                      parseISO(row.to).getMonth() +
                        1 +
                        "/" +
                        parseISO(row.to).getDate()
                    )}
                    {"  الساعة  "}
                    {ConvertToArabicNumbers(
                      parseISO(row.to).toLocaleString("en-US", options)
                    )}
                  </Typography>
                </TableCell>
              ) : (
                <TableCell align="center">
                  <Typography variant="body1">حتي الان</Typography>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
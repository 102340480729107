import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Mydate from "../../components/Helpers/MyDateParser";

const useStyles = makeStyles({
  root: {
    marginTop: 24,
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
  },
  formContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    "& > *": {
      marginRight: 12,
    },
  },
});
function Addresse() {
  const [gov, setGov] = React.useState({});
  const [city, setCity] = React.useState({});
  const user = useSelector((state) => state.auth.user);
  const [govs, setGovs] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const fetchGovs = () => {
    axios
      .get("/Governorates")
      .then((res) => {
        setGovs(res.data);
      })
      .catch((err) => {});
  };

  const fetchCities = () => {
    axios
      .get("/Cities")
      .then((res) => {
        setCities(res.data);
      })
      .catch((err) => {});
  };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchGovs();
      fetchCities();
    }
    return () => {
      mounted = false;
    };
  }, []);

  let citiesList = [];

  const [govCities, setGovCities] = React.useState([]);

  const handleGovChange = (value) => {
    setGov(value);
    citiesList = cities.filter((city) => city.governoratesId === value.id);
    setGovCities(citiesList);
  };

  const submitGov = (govName) => {
    axios
      .post("/Governorates", {
        name: govName,
        userId: user.UserId,
        creationDate: Mydate(new Date()),
      })
      .then((res) => {
        toast.success("تم اضافة المحافظة بنجاح");
        setGovs((govs) => [res.data, ...govs]);
        setGov(res.data);
      });
  };

  const submitCity = (cityName) => {
    axios
      .post("/Cities", {
        name: cityName,
        governoratesId: gov.id,
        userId: user.UserId,
        creationDate: Mydate(new Date()),
      })
      .then((res) => {
        toast.success("تم اضافة المدينة بنجاح");
        setCity("");
      })
      .catch((err) => {
        toast.error("فشل اضافة المدينة");
      });
  };

  const classes = useStyles();

  const [govName, setGovName] = React.useState("");
  const [cityName, setCityName] = React.useState("");

  const submitNewGov = () => {
    let isExist = false;
    govs.filter((gov) => {
      if (gov.name === govName) {
        return (isExist = true);
      }
    });
    if (!isExist) {
      const name = prompt("المحافظة غير موجودة, هل تنوي اضافتها؟", govName);
      if (name) {
        submitGov(name);
      }
    }
  };

  const submitNewCity = () => {
    let isExist = false;
    cities.filter((city) => {
      if (city.name === cityName) {
        return (isExist = true);
      }
    });
    if (!isExist) {
      const name = prompt(" المدينة غير موجودة, هل تنوي اضافتها؟", cityName);
      if (name) {
        submitCity(name);
      }
    }
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography className={classes.title} gutterBottom>
            اضافة المدن والمحافظات
          </Typography>
          <Divider style={{ marginBottom: 24 }} />

          <Box className={classes.formContent}>
            <Autocomplete
              options={govs}
              getOptionSelected={(option, value) =>
                option.value === value?.value
              }
              getOptionLabel={(option) => (option.name ? option.name : "")}
              size="small"
              noOptionsText="لم يتم العثور على خيارات"
              style={{ marginBottom: 16, width: 220 }}
              onChange={(event, value) => {
                if (value) {
                  setGov(value);
                  handleGovChange(value);
                } else {
                  setGov("");
                }
              }}
              value={gov}
              onInputChange={(e, v) => {
                setGovName(v);
              }}
              freeSolo
              inputValue={govName}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  submitNewGov();
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="المحافظة"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
            {gov.id && (
              <>
                <Autocomplete
                  options={govCities}
                  getOptionSelected={(option, value) =>
                    option.value === value?.value
                  }
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  size="small"
                  style={{ width: 220, marginBottom: 16 }}
                  autoComplete
                  noOptionsText="لم يتم العثور على خيارات"
                  onChange={(event, value) => {
                    if (value) {
                      setCity(value);
                    } else {
                      setCity("");
                    }
                  }}
                  value={city}
                  onInputChange={(e, v) => {
                    setCityName(v);
                  }}
                  freeSolo
                  inputValue={cityName}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      submitNewCity();
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="المدينة"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />
                  )}
                />
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 8, height: 40 }}
                  size="small"
                  onClick={submitNewCity}
                >
                  {" "}
                  اضف
                </Button>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default Addresse;

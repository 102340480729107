import { useSelector } from "react-redux";
import useToolsInfo from "./modal";
import InfoIcon from "@mui/icons-material/Info";
import NewRemainingDetails from "../../../../features/movement-tools/components/RemainingDetails/index";

export default function ToolsInfo({ partnerId, haveAction }) {
  const remainingDetails = useSelector((state) => state.tools.remainingDetails);
  const { setIsOpen, isOpen, onDetailsClicked, isAuthorizedToAdd } =
    useToolsInfo();
  return (
    <>
      <InfoIcon
        style={{ margin: "0.4rem 20px 1px", cursor: "pointer", color: "#000" }}
        onClick={() => onDetailsClicked(partnerId)}
      />
      <NewRemainingDetails
        showAddUi={isAuthorizedToAdd && haveAction}
        partnerId={partnerId}
        rows={remainingDetails}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
}

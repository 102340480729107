import { ConvertToArabicNumbers, renderDate } from "../../../utils";
import { Paper, Typography } from "@material-ui/core";

import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  table: {
    width: "100%",
    boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
  },
  inc: {
    backgroundColor: "#C8C8C8",
    fontWeight: "bold",
  },
  exp: {
    backgroundColor: "#DB232D",
    fontWeight: "bold",
    color: "white",
  },
});

export default function ProfitsTable({ headCells, profits, type, dailyDate }) {
  const months = [
    "يناير",
    "فبراير",
    "مارس",
    "ابريل",
    "مايو",
    "يونيو",
    "يوليو",
    "اغسطس",
    "سبتمبر",
    "اكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];

  const classes = useStyles();

  return (
    <TableContainer component={Paper} style={{boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px'}}>
      <Table className={classes.table} >
        <TableHead style={{ backgroundColor: "#F3C637" }}>
          <TableRow>
            {headCells.map((headCell, index) => (
              <TableCell align="center" key={index}>
                {" "}
                <Typography style={{ fontWeight: 600, color: "black" }}>
                  {headCell}{" "}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {profits.map((row, index) => (
            <TableRow key={index}>
              {type !== "general" && (
                <TableCell style={{ fontSize: 12 }} align="center">
                  {type === "annual" && ConvertToArabicNumbers(row.key)}
                  {type === "daily" &&
                    ConvertToArabicNumbers(
                      renderDate(
                        new Date(
                          dailyDate.getFullYear(),
                          dailyDate.getMonth(),
                          row.key
                        )
                      )
                    )}
                  {type === "monthly" && months[row.key - 1]}
                </TableCell>
              )}
              <TableCell align="center">
                {row.buyersCommisions == 0
                  ? "ﻻ يوجد"
                  : ConvertToArabicNumbers(Math.round(row.buyersCommisions))}
              </TableCell>
              <TableCell align="center">
                {row.trucksCommisions == 0
                  ? "ﻻ يوجد"
                  : ConvertToArabicNumbers(Math.round(row.trucksCommisions))}
              </TableCell>
              <TableCell align="center">
                {row.varianceBetweenActualSellingsAndInvoiceItemsInCommisionTrucks ==
                0
                  ? "ﻻ يوجد"
                  : ConvertToArabicNumbers(
                      Math.round(
                        row.varianceBetweenActualSellingsAndInvoiceItemsInCommisionTrucks
                      )
                    )}
              </TableCell>
              <TableCell align="center">
                {row.profitsOfBuyingTrucks == 0
                  ? "ﻻ يوجد"
                  : ConvertToArabicNumbers(
                      Math.round(row.profitsOfBuyingTrucks)
                    )}
              </TableCell>
              <TableCell align="center">
                {row.carryingPrice == 0
                  ? "ﻻ يوجد"
                  : ConvertToArabicNumbers(Math.round(row.carryingPrice))}
              </TableCell>
              <TableCell align="center">
                {row.officeTips == 0
                  ? "ﻻ يوجد"
                  : ConvertToArabicNumbers(Math.round(row.officeTips))}
              </TableCell>
              <TableCell align="center">
                {row.movementToolRent == 0
                  ? "ﻻ يوجد"
                  : ConvertToArabicNumbers(Math.round(row.movementToolRent))}
              </TableCell>
              <TableCell align="center">
                {row.fridgeRent == 0
                  ? "ﻻ يوجد"
                  : ConvertToArabicNumbers(Math.round(row.fridgeRent))}
              </TableCell>
              <TableCell className={classes.inc} align="center">
                {row.sumOfIncomeProfits == 0
                  ? "ﻻ يوجد"
                  : ConvertToArabicNumbers(Math.round(row.sumOfIncomeProfits))}
              </TableCell>
              <TableCell align="center">
                {row.depositsAllows == 0
                  ? "ﻻ يوجد"
                  : ConvertToArabicNumbers(Math.round(row.depositsAllows))}
              </TableCell>
              <TableCell align="center">
                {row.damages > 0
                  ? ConvertToArabicNumbers(Math.round(row.damages))
                  : "ﻻ يوجد"}
              </TableCell>
              <TableCell className={classes.inc} align="center">
                {row.sumOfOutProfits == 0
                  ? "ﻻ يوجد"
                  : ConvertToArabicNumbers(Math.round(row.sumOfOutProfits))}
              </TableCell>
              <TableCell className={classes.exp} align="center">
                {row.profit == 0
                  ? "ﻻ يوجد"
                  : ConvertToArabicNumbers(Math.round(row.profit))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

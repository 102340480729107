import { Card, CardContent, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import ProfitsTable from "./components/ProfitsTable";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
}));

function AnnualProfits() {
  const classes = useStyles();

  const [annualProfits, setAnnualProfits] = useState([]);

  const getAnnualProfits = () => {
    axios
      .get("/profits/Parametrized")
      .then((res) => {
        setAnnualProfits(res.data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getAnnualProfits();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const headCells = [
    "السنة",
    "بياعة البائعين",
    "عمولات النقلات",
    "فرق فواتير العمولة",
    "ربح المشتريات",
    "مشال",
    "مكتب",
    "ايجار عدة",
    "ثلاجة",
    "وارد الربح",
    "سماح",
    "هوالك",
    "خارج الربح",
    "اجمالي الربح",
  ];
  return (
    <div className={classes.root}>
      <CardContent className="headerSearch w-50 m-auto mt-3" style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6"> الارباح السنوية</Typography>
        </CardContent>
      <div style={{ marginTop: 8 }}>
        <ProfitsTable
          headCells={headCells}
          profits={annualProfits}
          type="annual"
        />
      </div>
    </div>
  );
}

export default AnnualProfits;

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  getDamagingsFromBuyers,
  getExportingFromBuyers,
  getImportingFromBuyers,
  getRemainingForBuyers,
} from "./toolsSlice";
import { useDispatch, useSelector } from "react-redux";

import DatePicker from "react-datepicker";
import Report from "../components/Report";
import SearchIcon from "@material-ui/icons/Search";
import Total from "../../../components/UI/Total";
import ar from "date-fns/locale/ar-SA";
import { indexOfId } from "../../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import MainHeader from "../../../components/mainHeader/MainHeader";
import { Container } from "react-bootstrap";

registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 12,
  },
  SearchForm: {
    marginTop: 12,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    "& > *": {
      marginRight: 12,
    },
  },
}));

function SellersReports() {
  const [dateTime, setDateTime] = useState(new Date());
  const [reportType, setReportType] = useState(1);

  const dispatch = useDispatch();
  const remainingForBuyers = useSelector(
    (state) => state.tools.remainingForBuyers
  );

  const exportingFromBuyers = useSelector(
    (state) => state.tools.exportingFromBuyers
  );

  const importingFromBuyers = useSelector(
    (state) => state.tools.importingFromBuyers
  );

  const damagingsForBuyers = useSelector(
    (state) => state.tools.damagingsForBuyers
  );

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (reportType === 1) {
        dispatch(getRemainingForBuyers());
      } else if (reportType === 2 && dateTime) {
        dispatch(getExportingFromBuyers(dateTime));
      } else if (reportType === 3 && dateTime) {
        dispatch(getImportingFromBuyers(dateTime));
      } else if (reportType === 4 && dateTime) {
        dispatch(getDamagingsFromBuyers(dateTime));
      }
    }
    return () => {
      mounted = false;
    };
  }, [reportType, dateTime]);

  const reportsTypes = [
    { id: 1, name: "العدة المتبقية" },
    { id: 2, name: "العدة الخارجة" },
    { id: 3, name: "العدة الواردة" },
    { id: 4, name: "هالك العدة" },
  ];
  const [searchQuery, setSearchQuery] = useState("");
  const getReportName = () => {
    let r = indexOfId(reportsTypes, reportType);
    return reportsTypes[r].name + " للبائعين";
  };

  let report = [];

  report =
    reportType === 1
      ? remainingForBuyers
      : reportType === 2
      ? exportingFromBuyers
      : reportType === 3
      ? importingFromBuyers
      : reportType === 4
      ? damagingsForBuyers
      : [];
  const total = report.reduce((acc, val) => {
    return acc + val.count;
  }, 0);
  const filteredReports = report.filter((item) =>
    item.partner.name.includes(searchQuery)
  );
  const classes = useStyles();
  return (
    <Container className="container">
      <div className="my-4">
        <MainHeader title="تقارير العدة للبائعين" />
      </div>
      <Card>
        {/* <Divider /> */}
        <CardContent className="headerSearch m-0 p-1 ">
          <Box className=" w-100 row center ">
            <div className=" col-10 col-md-4 col-lg-3 m-3 text-center">
              <TextField
                size="small"
                select
                label="نوع التقرير"
                onChange={(e) => setReportType(e.target.value)}
                value={reportType}
                className="text-center w-100 m-0 p-0"
                variant="outlined"
              >
                {reportsTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            {reportType !== 1 && (
              <div className=" col-10 col-md-4 col-lg-3 m-3 text-center">
                <DatePicker
                  locale="ar"
                  className="w-100"
                  selected={dateTime}
                  onChange={(d) => setDateTime(d)}
                  dateFormat="dd/MM/yyyy"
                  customInput={
                    <TextField
                      size="small"
                      variant="outlined"
                      className="text-center w-100 m-0 p-0"
                      id="dateTime"
                    />
                  }
                />
              </div>
            )}
            {report && report.length > 0 && (
              <div className="col-10 col-md-5 col-lg-4 text-center  m-3 ">
                <TextField
                  placeholder="بحث..."
                  size="small"
                  className="w-100"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className={classes.searchIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          </Box>
        </CardContent>
      </Card>
      {total !== 0 ? (
        <div className="w-50 m-auto">
          <Total value={total} />
        </div>
      ) : (
        "ﻻ يوجد"
      )}
      {filteredReports.length > 0 ? (
        <Report
          items={filteredReports}
          reportName={getReportName()}
          hasDetails={reportType === 1}
          total={total}
          searchQuery={searchQuery}
        />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ marginTop: 180 }}
          >
            غير موجود
          </Typography>
        </div>
      )}
    </Container>
  );
}

export default SellersReports;

import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Alerto from "../UI/toaster";
import "./BackUp.css";

const BackUp = () => {
  const [loading, setLoading] = useState(false); // حالة التحميل
  const [completed, setCompleted] = useState(false); // حالة اكتمال التحميل

  useEffect(() => {
    let timeout;
    if (completed) {
      // إعادة تعيين حالة اكتمال التحميل بعد 5 ثوانٍ من اكتمال التحميل
      timeout = setTimeout(() => {
        setCompleted(false);
      }, 10000);
    }

    return () => clearTimeout(timeout);
  }, [completed]);

  const saveFile = async () => {
    setLoading(true); // قم بتعيين حالة التحميل إلى true عند البدء بالتحميل

    try {
      toast.success("انتظر جاري التحميل...");

      // الحصول على الوقت الحالي من السيرفر
      const timeResponse = await axios.get("owlstime/datetime");
      const serverTime = new Date(timeResponse.data);

      const year = serverTime.getFullYear();
      const month = String(serverTime.getMonth() + 1).padStart(2, "0");
      const day = String(serverTime.getDate()).padStart(2, "0");

      const hours = String(serverTime.getHours()).padStart(2, "0");
      const minutes = String(serverTime.getMinutes()).padStart(2, "0");
      const seconds = String(serverTime.getSeconds()).padStart(2, "0");

      // إنشاء اسم الملف باستخدام التاريخ والوقت الحاليين
      const fileName = `نسخة-احتياطية-OWLS${year}-${month}-${day}-${hours}-${minutes}-${seconds}.zip`;

      const backupResponse = await axios.get("BackUp/DownloadBackUp", {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(
        new Blob([backupResponse.data], { type: "application/zip" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success("تم التحميل بنجاح");
      setCompleted(true); // تعيين حالة اكتمال التحميل بعد اكتمال التحميل
    } catch (e) {
      Alerto(e);
    } finally {
      setLoading(false); // بعد الانتهاء من التحميل، قم بتعيين حالة التحميل إلى false
    }
  };

  return (
    <div className="cntr h-88">
      <div className="instruction-container">
        <p className="instruction-text">
          اضغط على الزر في الأسفل للبدء في التحميل:
          <br />
          قم بالنقر على الزر الأحمر المكتوب عليه "نسخة احتياطية". عند النقر على
          هذا الزر، ستبدأ عملية تحميل الملف الاحتياطي.
          <br />
          <br />
          انتظر حتى يتم تحميل الملف:
          <br />
          بمجرد النقر على الزر، ستبدأ عملية التحميل وسيظهر الزر عبارة "جاري
          التحميل...". هذه العبارة تعني أن عملية التحميل جارية وأنه يجب عليك
          الانتظار حتى تكتمل.
          <br />
          <br />
          احفظ الملف:
          <br />
          عند اكتمال التحميل، قم بتحديد مكان حفظ الملف واضغط حفظ. إذا ضغطت على
          حفظ فقط، سيتم حفظ الملف تلقائيًا في مجلد التنزيلات ستتلقى إشعارًا في
          المتصفح يخبرك بأن التحميل قد اكتمل وأن الملف قد تم حفظه بنجاح.
          <br />
        </p>
        <div className="button-container">
          <button
            onClick={saveFile}
            className={`backUp-button ${loading ? "loading" : ""} ${
              completed ? "completed" : ""
            }`}
            disabled={loading}
          >
            {loading
              ? "جاري التحميل..."
              : completed
              ? "تم التحميل"
              : "نسخة احتياطية"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BackUp;

import { Button, IconButton, Typography } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import Alerto from "../../components/UI/toaster";
import { toast } from "react-toastify";
import "./seller_search.css";
import { ConvertToArabicNumbers } from "../../utils";
import ProfilesTable from "./../../components/Tables/ProfilesTable";
import MainHeader from "../../components/mainHeader/MainHeader";
const clientsHeads = [
  {
    id: "name",
    label: "الاسم",
  },
  {
    id: "truckInvoices",
    label: "فواتير",
    render: (value) => (
      <>
        <Typography
          variant="body1"
          className="p-0 border-1"
          style={{ fontSize: "14px" }}
        >
          {value != 0
            ? ConvertToArabicNumbers(Math.ceil(Math.abs(value)))
            : "صفر"}{" "}
          {value > 0 ? "عليه" : value === 0 ? "" : " له  "}
        </Typography>
      </>
    ),
  },

  {
    id: "loans",
    label: "عملة مقدمة",
    render: (value) => (
      <>
        <Typography
          variant="body1"
          className="p-0 border-1"
          style={{ fontSize: "14px" }}
        >
          {value !== 0
            ? ConvertToArabicNumbers(Math.ceil(Math.abs(value)))
            : "صفر"}{" "}
          {value > 0 ? "عليه" : value === 0 ? "" : " له  "}
        </Typography>
      </>
    ),
  },

  {
    id: "toolsMoney",
    label: "الرهن",
    render: (value) => (
      <>
        <Typography
          onClick={() => {}}
          variant="body1"
          className="p-0 border-1"
          style={{ fontSize: "14px" }}
        >
          {value !== 0
            ? ConvertToArabicNumbers(Math.ceil(Math.abs(value)))
            : "صفر"}{" "}
          {value > 0 ? "عليه" : value === 0 ? "" : " له  "}
        </Typography>
      </>
    ),
  },
  {
    id: "toolsCount",
    label: "عدد العدة",
    render: (value) => (
      <>
        <Typography
          variant="body1"
          className="p-0 border-1"
          style={{ fontSize: "14px" }}
        >
          {value != 0
            ? ConvertToArabicNumbers(Math.ceil(Math.abs(value)))
            : "صفر"}{" "}
          {value > 0 ? "عليه" : value === 0 ? "" : " له  "}
        </Typography>
      </>
    ),
  },
];
const sellerHeads = [
  {
    id: "name",
    label: "الاسم",
  },
  {
    id: "sellingInvoices",
    label: "فواتير",
    render: (value) => (
      <>
        <Typography
          variant="body1"
          className="p-0 border-1"
          style={{ fontSize: "14px" }}
        >
          {value != 0
            ? ConvertToArabicNumbers(Math.ceil(Math.abs(value)))
            : "صفر"}{" "}
          {value > 0 ? "عليه" : value === 0 ? "" : " له  "}
        </Typography>
      </>
    ),
  },

  {
    id: "loans",
    label: "سلف",
    render: (value) => (
      <>
        <Typography
          variant="body1"
          className="p-0 border-1"
          style={{ fontSize: "14px" }}
        >
          {value != 0
            ? ConvertToArabicNumbers(Math.ceil(Math.abs(value)))
            : "صفر"}{" "}
          {value > 0 ? "عليه" : value === 0 ? "" : " له  "}
        </Typography>
      </>
    ),
  },

  {
    id: "toolsMoney",
    label: "الرهن",
    render: (value) => (
      <>
        <Typography
          variant="body1"
          className="p-0 border-1"
          style={{ fontSize: "14px" }}
        >
          {value != 0
            ? ConvertToArabicNumbers(Math.ceil(Math.abs(value)))
            : "صفر"}{" "}
          {value > 0 ? "عليه" : value === 0 ? "" : " له  "}
        </Typography>
      </>
    ),
  },
  {
    id: "toolsCount",
    label: "عدد العدة",
    render: (value) => (
      <>
        <Typography
          variant="body1"
          className="p-0 border-1"
          style={{ fontSize: "14px" }}
        >
          {value != 0
            ? ConvertToArabicNumbers(Math.ceil(Math.abs(value)))
            : "صفر"}{" "}
          {value > 0 ? "عليه" : value === 0 ? "" : " له  "}
        </Typography>
      </>
    ),
  },
  {
    id: "totalBuyerDebt",
    label: "اجمالي",
    render: (value) => (
      <>
        <Typography
          variant="body1"
          className="p-0 border-1"
          style={{ fontSize: "14px" }}
        >
          {value != 0
            ? ConvertToArabicNumbers(Math.ceil(Math.abs(value)))
            : "صفر"}{" "}
          {value > 0 ? "عليه" : value === 0 ? "" : " له  "}
        </Typography>
      </>
    ),
  },
];
const staffHeads = (props) => [
  {
    id: "partner",
    label: "الاسم",
    render: (value) => (
      <Typography
        variant="body1"
        className="p-0 border-1"
        style={{ fontSize: "14px" }}
      >
        {value?.name}
      </Typography>
    ),
  },
  {
    id: "",
    label: "النوع",
    render: () => (
      <Typography
        variant="body1"
        className="p-0 border-1"
        style={{ fontSize: "14px" }}
      >
        موظف
      </Typography>
    ),
  },

  {
    id: "salary",
    label: "المرتب",
    render: (value) => (
      <>
        <Typography
          variant="body1"
          className="p-0 border-1"
          style={{ fontSize: "14px" }}
        >
          {value != 0
            ? ConvertToArabicNumbers(Math.ceil(Math.abs(value)))
            : "صفر"}
        </Typography>
      </>
    ),
  },

  {
    id: "",
    label: "عرض",
    render: (value) => (
      <Button
        style={{
          backgroundColor: "wheat",
          color: "black",
          borderRadius: "10px",
          padding: "0vh 1vw 0vh 1vw",
        }}
        component={Link}
        to={`/client-profile/${value?.partner?.id}`}
        className="btnMore globalFont"
      >
        حسابات
      </Button>
    ),
  },
  {
    id: "",
    label: "تعديل ",
    render: (value) => (
      <IconButton
        size="small"
        component={Link}
        to={`../add-employee/${value?.partner?.id}`}
        className=" globalFont"
      >
        <EditOutlinedIcon size="small" />
      </IconButton>
    ),
  },
  {
    id: "",
    label: "حذف",
    render: (value) => (
      <IconButton
        size="small"
        className="btnMore "
        onClick={() => {
          props.onDelete(value);
        }}
      >
        <HighlightOffIcon />
      </IconButton>
    ),
  },
];
function Profile() {
  const { type } = useParams();
  const isSeller = type === "sellers";
  const isClient = type === "clients";
  const isStaff = type === "staff";

  const delethandler = async (ele) => {
    try {
      const state = prompt(`تأكيد حذف ${ele.partner.name}`);
      if (state !== null) {
        const res = await axios.delete(`Employees/${ele.partner.id}`);
        if (res.status === 200) {
          toast.error("تم الحذف");
        }
      }
    } catch (e) {
      Alerto(e);
    }
  };
  const api = isStaff
    ? "Employees"
    : isClient
    ? "debt/clients"
    : "debt/debt/buyers";
  const targetUrl = isSeller ? "seller" : "client";
  const headCells = isStaff
    ? staffHeads({ onDelete: delethandler })
    : isClient
    ? clientsHeads
    : sellerHeads;

  return (
    <>
      <div className="my-4">
        <MainHeader
          title={
            isClient
              ? "حسابات العملاء "
              : isStaff
              ? "حسابات الموظفين"
              : "حسابات البائعين"
          }
        />
      </div>
      <ProfilesTable
        targetUrl={targetUrl}
        headCells={headCells}
        api={api}
        isFiltering={isSeller}
        isPrinting={isSeller}
        isLinkDisabled={isStaff}
      />
    </>
  );
}

export default Profile;

import {
  Button,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { Paper, TableSortLabel } from "@material-ui/core";

import { ConvertToArabicNumbers } from "../../../utils";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  table: {
    border: "1px solid #222",
  },
  tableRow: {
    marginTop: "8px",
  },
  tableCell: {
    border: "1px solid #222",
    fontSize: 14,
    padding: 5,
    width: 130

  },
  searchBar: {
    backgroundColor: "#fff",
  },
  "@media print": {
    printableTable: {
      display: 'none !important'
    },
  }

}));

export default function BuyersReport({ data, handlePrint, selected, sub }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("partnerName");
  const [searchQuery, setSearchQuery] = React.useState("");
  const classes = useStyles();
  const role = localStorage.getItem("role");
  const [print, isPrint] = useState(false)
  const filteredReports = sub.map((report) => {
    const repo = report.filter((item) => {
      return (
        item.toolsCount !== 0 ||
        item.toolsMoney !== 0 ||
        item.sellingsInvoices !== 0 ||
        item.buyerLoans !== 0
      );
    });

    return repo.filter((item) => {
      return item.buyerLoans + item.toolsMoney + item.sellingsInvoices > 10;
    });
  });
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    { label: "عدد", id: "toolsCount" },
    { label: "رهن", id: "toolsMoney" },
    { label: "الاسم", id: "partnerName" },
    { label: "فواتير بيع", id: "sellingsInvoices" },
    { label: "سلف", id: "buyerLoans" },
    { label: "اجمالي" },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow className="p-0">
          {headCells.map((headCell, index) => (
            <TableCell
              key={index}
              align="center"
              style={{ fontWeight: "bold", fontSize: 14, padding: '10px' }}
              sortDirection={orderBy === headCell.id ? order : false}
              className=' m-0 '
            >
              {headCell.id ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function SecondaryTableHead({ address }) {
    return (
      <TableHead  >
        <TableRow >
          <TableCell style={{
            background: '#4b4b4b',
            color: " white"
          }} className='p-2' align="center" colSpan={6}>
            {address}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return filteredReports[0] ? (
    <>

      {
        <Toolbar
          style={{
            width: 'inherit',
            display: 'flex',
            justifyContent: "space-between",
            backgroundColor: "#f3c637",
          }}
          className={classes.printableTable}
        >
          <TextField
            placeholder="بحث..."
            className={classes.searchBar}
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title="طباعة التقرير">
            <IconButton onClick={() => { handlePrint() }}>
              <PrintOutlinedIcon className="text-black" />
            </IconButton>
          </Tooltip>
        </Toolbar>
      }


      <TableContainer style={{

        padding: '5px',
        boxShadow: ' rgb(0 0 0 / 56%) 0px 22px 70px 4px',
      }} component={Paper}>

        {filteredReports &&
          filteredReports.length > 0 &&
          filteredReports.map((report, index) => (

            <Table key={index} >
              {index === 0 ? <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={15}
              /> : null}
              {selected && selected.length > 0 && (
                <SecondaryTableHead
                  address={selected[index] ? selected[index].name : ""}
                />
              )}
              {
                stableSort(report, getComparator(order, orderBy))
                  .filter(
                    (item) =>
                      item.partnerName && item.partnerName.includes(searchQuery)
                  )
                  .map((item, index) => (
                    <TableBody key={index} >
                      <TableRow key={item.partnerId} className='hover-row' component={Link}
                        to={
                          role != "محصل"
                            ? `/seller-profile/${item.partnerId}`
                            : `/seller-user-profile/${item.partnerId}`
                        }>
                        <TableCell className={classes.tableCell} align="center">
                          {!!item.toolsCount
                            ? ConvertToArabicNumbers(Math.abs(item.toolsCount))
                            : ""}{' '}
                          {Math.round(item.toolsCount) < 0 ? "له" : ""}
                          {Math.round(item.toolsCount) > 0 ? "عليه" : ""}
                          {Math.round(item.toolsCount) === 0 ? "صفر" : ""}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {!!item.toolsMoney
                            ? ConvertToArabicNumbers(Math.abs(item.toolsMoney))
                            : ""}
                          {' '}
                          {Math.round(item.toolsMoney) < 0 ? "له" : ""}
                          {Math.round(item.toolsMoney) > 0 ? "عليه" : ""}
                          {Math.round(item.toolsMoney) === 0 ? "صفر" : ""}
                        </TableCell>
                        <TableCell
                          style={{ minWidth: '240px' }}
                          className={classes.tableCell}
                          align="center"
                        >
                          {ConvertToArabicNumbers(item.partnerName)}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {!!item.sellingsInvoices
                            ? ConvertToArabicNumbers(
                              Math.round(Math.abs(item.sellingsInvoices))
                            )
                            : ""}{' '}
                          {Math.round(item.sellingsInvoices) < 0 ? "له" : ""}
                          {Math.round(item.sellingsInvoices) > 0 ? "عليه" : ""}
                          {Math.round(item.sellingsInvoices) === 0 ? "صفر" : ""}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {!!item.buyerLoans
                            ? ConvertToArabicNumbers(Math.round(Math.abs(item.buyerLoans)))
                            : ""}{' '}
                          {Math.round(item.buyerLoans) < 0 ? "له" : ""}
                          {Math.round(item.buyerLoans) > 0 ? "عليه" : ""}
                          {Math.round(item.buyerLoans) === 0 ? "صفر" : ""}

                        </TableCell>
                        <TableCell onClick={() => {
                          console.log(Math.round(
                            item.buyerLoans +
                            item.toolsMoney +
                            item.sellingsInvoices
                          ))
                        }} className={classes.tableCell} align="center">
                          {item.buyerLoans || item.sellingsInvoices
                            ? ConvertToArabicNumbers(
                              Math.abs(Math.round(
                                item.buyerLoans +
                                item.toolsMoney +
                                item.sellingsInvoices
                              ))
                            )
                            : ""}{' '}
                          {Math.round(item.buyerLoans + item.toolsMoney + item.sellingsInvoices) < 0 ? "له" : ""}
                          {Math.round(item.buyerLoans + item.toolsMoney + item.sellingsInvoices) > 0 ? "عليه" : ""}
                          {Math.round(item.buyerLoans + item.toolsMoney + item.sellingsInvoices) === 0 ? "صفر" : ""}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
            </Table>
          ))}

      </TableContainer>
    </>
  ) : (
    "لا يوجد بيانات لعرضها"
  );



}

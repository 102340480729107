import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { ConvertToArabicNumbers } from '../../../utils';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import Alerto from '../../../components/UI/toaster';
import { Row } from 'react-bootstrap';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const useStyles = makeStyles((theme) => ({
  invoiceSummery: {
    padding: 8,
    border: '1px solid #ddd',
    borderRadius: 4,
    backgroundColor: '#fff',
    margin: '32px auto',
  },

  cell: {
    padding: 12,
    backgroundColor: '#FF9900',
    textAlign: 'center',
    borderRadius: 8,
  },
  cellInfo: {},
  label: {
    fontWeight: 600,
  },
}));
function BuyingTruck({ partnerId, invoiceId }) {
  const [invoiceSummery, setInvoiceSummery] = useState('');

  const getBuyingInvoice = () => {
    axios
      .get(`/Truck/Summerize/buying?truckId=${invoiceId}`)
      .then((res) => {
        setInvoiceSummery(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (invoiceId) {
        getBuyingInvoice();
      }
    }
    return () => {
      mounted = false;
    };
  }, [invoiceId]);

  const classes = useStyles();
  return (
    <div>
      {invoiceSummery && (
        <Row xs={12} className='m-auto mt-3 text-center p-0'>
          <TableContainer className="p-0 m-0" component={Paper} >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: '#FED049', height: '65px' }}>
                <TableRow>
                  <TableCell align="center" className='headerTableFont' >عدد  الاصناف</TableCell>
                  <TableCell align="center" className='headerTableFont' >  وارد النقلة  </TableCell>
                  <TableCell align="center" className='headerTableFont' > رهن العدة</TableCell>
                  <TableCell align="center" className='headerTableFont' > مصروفات النقلة</TableCell>
                  <TableCell align="center" className='headerTableFont' > مبيع النقلة</TableCell>
                  <TableCell align="center" className='headerTableFont' >  صافي الفاتورة</TableCell>
                  <TableCell align="center" className='headerTableFont' ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                  <TableCell align="center" className='BodyTableFont'> {invoiceSummery.uniqueProductCount > 0
                    ? ConvertToArabicNumbers(invoiceSummery.uniqueProductCount)
                    : "غير متوفر"}</TableCell>
                  <TableCell align="center" className='BodyTableFont'>   {invoiceSummery.purchasingPrice > 0
                    ? ConvertToArabicNumbers(
                      Math.round(invoiceSummery.purchasingPrice)
                      )
                    : "غير متوفر"}</TableCell>
                  <TableCell align="center" className='BodyTableFont'>   {invoiceSummery.clientToolsMortage !== 0
                    ? ConvertToArabicNumbers(
                      Math.round(invoiceSummery.clientToolsMortage)
                      )
                    : "غير متوفر"}</TableCell>
                  <TableCell align="center" className='BodyTableFont'>  {invoiceSummery.totalExpensesTheClientResieved !== 0
                    ? ConvertToArabicNumbers(
                      Math.round(invoiceSummery.totalExpensesTheClientResieved)
                      )
                    : "غير متوفر"}</TableCell>
                  <TableCell align="center" className='BodyTableFont'>  {invoiceSummery.totalMoneyOfSellings !== 0
                    ? ConvertToArabicNumbers(
                      Math.round(invoiceSummery.totalMoneyOfSellings)
                      )
                    : "غير متوفر"}</TableCell>
                  <TableCell align="center" className='BodyTableFont'>  {invoiceSummery.invoiceValue !== 0
                    ? ConvertToArabicNumbers(
                      Math.round(invoiceSummery.invoiceValue)
                      )
                    : "غير متوفر"}</TableCell >
                  <TableCell className='btnMore BodyTableFont' align="center">
                    <Link
                      to={`/invoice/${invoiceId}`} style={{ color: 'white' }}>
                      تعديل الفاتورة
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Row>)
      }

    </div>
  );
}

export default BuyingTruck;

import VideoComp from "./VideoComp";
import cls from "./Brief.module.scss";
import MainHeader from "../../mainHeader/MainHeader";
const videos = [
  { url: "https://www.youtube.com/embed/VVB_DtgbZnw", description: "" },
  { url: "https://www.youtube.com/embed/4W7jyB_BcwY", description: "" },
  { url: "https://www.youtube.com/embed/JL7Z3FlJnCk", description: "" },
  { url: "https://www.youtube.com/embed/yzovdxz8gq4", description: "" },
  { url: "https://www.youtube.com/embed/omAbq-_pMuU", description: "" },
  { url: "https://www.youtube.com/embed/FzXp61JyjZE", description: "" },
  { url: "https://www.youtube.com/embed/HPrNoZi8Wvs", description: "" },
  { url: "https://www.youtube.com/embed/fWzXjKrNcuA", description: "" },
  { url: "https://www.youtube.com/embed/T3DO8K4tOzA", description: "" },
  { url: "https://www.youtube.com/embed/s6tySefZI4s", description: "" },
  { url: "https://www.youtube.com/embed/-rRJ0LcTbHU", description: "" },
  { url: "https://www.youtube.com/embed/vvhuyr8MOdE", description: "" },
  { url: "https://www.youtube.com/embed/LXSTRdf8KU8", description: "" },
];
const Brief = () => {
  return (
    <div className={cls.container}>
      <div className="my-2">
        <MainHeader title="فيديوهات الشرح  " />
      </div>
      <div className={cls.videoHolder}>
        {videos.map((ele) => (
          <VideoComp ele={ele} />
        ))}
      </div>
    </div>
  );
};
export default Brief;

import { Container } from "@material-ui/core";
// layouts
import LoginForm from "./components/LoginForm";
import Page from "./components/Page";
// material
import { styled } from "@material-ui/styles";
import "./Register.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import MobileStep from "./components/MobileStep";
import axios from "axios";
import SiteStep from "./components/SiteStep";
import PasswordStep from "./components/PasswordStep";
import Alerto from "../../components/UI/toaster";
import Logo from "../../assets/images/logo-yellow2.gif";
import { getAppState, login, logOut } from "./authSlice";

// components

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  height: "84%",

  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  //padding: theme.spacing(6, 0),
}));

// ----------------------------------------------------------------------
const portalBase = "https://portal.owltecheg.com/api";
const isPortal = false;
// ----------------------------------------------------------------------

export default function Login() {
  const subState = useSelector((state) => state.auth.subs);
  const isLocal = useSelector((state) => state.auth.isLocal);
  const [isChecked, setIsChecked] = useState(true);
  //const token = localStorage.getItem("token");
  const [owls, setOwls] = useState([]);
  const [step, setStep] = useState(0);
  const [mobV, setMobV] = useState("");
  const [pass, setPass] = useState("");
  const [chosedSite, setChosedSite] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    //!!token && dispatch(logOut());
    dispatch(getAppState());
    setMobV(localStorage.getItem("phone"));
    setPass(localStorage.getItem("password"));
  }, []);
  function submitMobile(e) {
    e.preventDefault();
    axios
      .get(`${portalBase}/Router/OWLSClients?phoneNumber=${mobV}`)
      .then((res) => {
        setOwls(res.data);
        setStep((v) => v + 1);
      })
      .catch((e) => Alerto(e));
  }
  function handleSite(chosedSite) {
    setChosedSite(chosedSite);
    setStep((v) => v + 1);
  }
  function submitOwl(e) {
    e.preventDefault();
    axios
      .get(
        `${portalBase}/Router/URL?OWLsClientId=${chosedSite.owlsClientId}&phoneNumber=${mobV}&password=${pass}`
      )
      .then((res) => {
        let base = res.data;
        dispatch(login({ phone: mobV, password: pass, base })).then(
          ({ payload }) => {
            if (payload) {
              localStorage.setItem("chosedOwl", chosedSite.owlsClientId);
              localStorage.setItem("baseUrl", base);
              if (isChecked) {
                localStorage.setItem("phone", mobV);
                localStorage.setItem("password", pass);
              } else {
                localStorage.removeItem("phone");
                localStorage.removeItem("password");
              }
              localStorage.setItem("license", true);
            }
          }
        );
      })
      .catch((e) => Alerto(e));
  }
  return (
    <div style={{ backgroundColor: "#FBBF05", height: "100%" }}>
      <RootStyle title="تسجيل الدخول">
        <Container maxWidth="sm">
          <ContentStyle>
            <div>
              {subState.bar && <div className="SubsMargin"></div>}

              <img
                src={Logo}
                alt="logo"
                style={{
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              {!isPortal ? (
                <LoginForm disableLogin={!subState.state} />
              ) : (
                <>
                  {step === 0 && (
                    <MobileStep
                      mobV={mobV}
                      setMobV={setMobV}
                      onSub={submitMobile}
                    />
                  )}
                  {step === 1 && <SiteStep sites={owls} onSub={handleSite} />}
                  {step === 2 && (
                    <PasswordStep
                      setPass={setPass}
                      onSub={submitOwl}
                      pass={pass}
                      label={chosedSite.owLsClientName}
                      isChecked={isChecked}
                      setIsChecked={setIsChecked}
                    />
                  )}
                </>
              )}
            </div>
          </ContentStyle>
        </Container>
      </RootStyle>
    </div>
  );
}

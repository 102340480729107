import * as React from "react";
import { ConvertToArabicNumbers, renderDate } from "../../../utils";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  deleteExpenses,
  deleteFromTruckExpenses,
  updateExpenses,
} from "../expensesSlice";
import DeleteBox from "../../../components/DeleteBox";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import UpdateExpenses from "./UpdateExpenses";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import axios from "axios";
import Alerto from "../../../components/UI/toaster";
import { toast } from "react-toastify";
import { Container } from "react-bootstrap";
import { isDayPast } from "../../../components/Helpers/InsputSorting";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  trash: {
    color: theme.palette.error.light,
  },
  edit: {
    color: theme.palette.info.light,
  },
}));

export default function ExpensesTable({ expenses, searchQuery, refresh }) {
  const headCells = [
    "الاسم",
    "الفئة",
    "المبلغ",
    "التاريخ",
    "ملحوظات",
    "تعديل",
    "حذف",
  ];

  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const [selectedExpense, setSelectedExpense] = React.useState("");
  const [deletedItem, setDeletedItem] = React.useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);

  const handleUpdate = (data) => {
    dispatch(updateExpenses(data));
  };
  const onDeleteDeposit = async () => {
    try {
      if (deletedItem.isFromTruck) {
        dispatch(deleteFromTruckExpenses(deletedItem));
      } else {
        dispatch(deleteExpenses(deletedItem.expenseId));
      }
      setOpenDelete(false);
      refresh();
    } catch (e) {
      Alerto(e);
    }
  };

  const classes = useStyles();
  let expens = [];
  return (
    <div style={{ marginTop: 24 }}>
      <Container fluid>
        <TableContainer
          style={{ width: "100%", margin: "auto" }}
          component={Paper}
        >
          <Table>
            <TableHead
              style={{
                backgroundColor: "#f3c637",
              }}
            >
              <TableRow>
                {headCells.map((item, index) => (
                  <TableCell key={index} align="center">
                    <Typography
                      variant="body2"
                      style={{ fontWeight: 600, color: "#000" }}
                    >
                      {item}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {expenses
                .filter((item) =>
                  item.partner
                    ? item.partner.name.includes(searchQuery)
                    : item.partner === null
                    ? item
                    : ""
                )
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center" style={{ minWidth: 200 }}>
                      {row.partner ? row.partner.name : "هالك"}
                    </TableCell>
                    <TableCell align="center">{row.categoryName}</TableCell>
                    <TableCell align="center">
                      {ConvertToArabicNumbers(row.money)}
                    </TableCell>

                    <TableCell align="center" style={{ minWidth: 120 }}>
                      {ConvertToArabicNumbers(renderDate(row.dateTime))}
                    </TableCell>
                    <TableCell align="center">{row.note}</TableCell>
                    <TableCell style={{ width: "32px" }} align="center">
                      <IconButton
                        onClick={() => {
                          setSelectedExpense(row);
                          setOpenUpdate(true);
                        }}
                        disabled={role == "موظف" && isDayPast(row.dateTime)}
                        className={classes.edit}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center" style={{ width: "32px" }}>
                      <IconButton
                        disabled={role == "موظف" && isDayPast(row.dateTime)}
                        className={classes.trash}
                        onClick={() => {
                          setOpenDelete(true);
                          setDeletedItem(row);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <UpdateExpenses
          open={openUpdate}
          onClose={() => setOpenUpdate(false)}
          onUpdate={handleUpdate}
          refresh={() => refresh()}
          expense={selectedExpense}
        />
        {deletedItem && (
          <DeleteBox
            action={onDeleteDeposit}
            open={openDelete}
            onClose={() => setOpenDelete(false)}
            message={`حذف مصروفات ${
              deletedItem.partner ? deletedItem.partner.name : "هالك"
            }`}
          />
        )}
      </Container>
    </div>
  );
}

ExpensesTable.defaultProps = {
  searchQuery: "",
};

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { ConvertToArabicNumbers } from "../../../utils";
import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  table: {
    borderSpacing: "8px 4px",
    border: "1px solid #222",
  },
  tableRow: {
    marginTop: "3px",
  },
  tableCell: {
    marginTop: "8px",
    minWidth: 64,
    border: "1px solid #222",
    padding: 2,
    fontSize:'14px',


  },
}));

export default function BuyersAddresses({ reports, addresses }) {
  const classes = useStyles();

  const mainHeadCells = [
    { label: "عدد", id: "toolsCount" },
    { label: "رهن", id: "toolsMoney" },
    { label: "الاسم", id: "partnerName" },
    { label: "فواتير بيع", id: "sellingsInvoices" },
    { label: "سلف", id: "buyerLoans" },
    { label: "اجمالي" },
  ];

  const filteredReports = reports.map((report) => {
    const repo = report.filter((item) => {
      return (
        item.toolsCount !== 0 ||
        item.toolsMoney !== 0 ||
        item.sellingsInvoices !== 0 ||
        item.buyerLoans !== 0
      );
    });

    return repo.filter((item) => {
      return item.buyerLoans + item.toolsMoney + item.sellingsInvoices > 10;
    });
  });

  function SecondaryTableHead({ address }) {
    return (
      <TableHead className="bg-dark" >
        <TableRow className="bg-dark">
          <TableCell style={{ fontWeight: "bold" }} className='p-1' align="center" colSpan={6}>
            {address}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function MainTableHead() {
    return (
      <TableHead>
        <TableRow>
          {mainHeadCells.map((headCell, index) => (
            <TableCell
              style={{ fontWeight: "bold" }}
              key={index}
              align="center"
              className="p-2"
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  return (
    <TableContainer component={Paper}>
      {filteredReports &&
        filteredReports.length > 0 &&
        filteredReports.map((report, index) => (
          <Table key={index} className={classes.table}>
            {index === 0 ? <MainTableHead /> : null}
            {addresses && addresses.length > 0 && (
              <SecondaryTableHead className='bg-dark'
                address={addresses[index] ? addresses[index].name : ""}
              />
            )}
            {report.map((item, index) => (
              <TableBody key={index} className={classes.tableBody}>
                <TableRow key={item.partnerId} className={classes.tableBody}>
                  <TableCell className={classes.tableCell} align="center">
                    {!!item.toolsCount
                      ? ConvertToArabicNumbers(item.toolsCount)
                      : ""}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {!!item.toolsMoney
                      ? ConvertToArabicNumbers(item.toolsMoney)
                      : ""}
                  </TableCell>
                  <TableCell
                    style={{ minWidth: 240 }}
                    className={classes.tableCell}
                    align="center"
                  >
                    {ConvertToArabicNumbers(item.partnerName)}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {!!item.sellingsInvoices
                      ? ConvertToArabicNumbers(
                          Math.round(item.sellingsInvoices)
                        )
                      : ""}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {!!item.buyerLoans
                      ? ConvertToArabicNumbers(Math.round(item.buyerLoans))
                      : "صفر"}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {item.buyerLoans || item.sellingsInvoices
                      ? ConvertToArabicNumbers(
                          Math.round(
                            item.buyerLoans +
                              item.toolsMoney +
                              item.sellingsInvoices
                          )
                        )
                      : ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        ))}
    </TableContainer>
  );
}

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import {
  addItemsCollectionToInvoice,
  createInvoice,
  deleteAllInvoiceItems,
  deleteInvoiceItem,
  getActualSelling,
  getDeductions,
  getInvoice,
  getInvoiceItems,
  getSummary,
  getTruckFullData,
} from "./invoiceSlice";
import { useDispatch, useSelector } from "react-redux";
import AddDedeuctions from "./containers/AddDedeuctions";
import AddExpenses from "./containers/AddExpenses";
import AddInvoiceItem from "./components/AddInvoiceItem";
import { ConvertToArabicNumbers, renderDate } from "../../utils";
import CustomDialog from "../../components/CustomDialog";
import DeleteBox from "../../components/DeleteBox";
import { Helmet } from "react-helmet";
import InvoiceDeductions from "./components/InvoiceDeduction";
import InvoiceDetails from "./components/InvoiceDetails";
import InvoiceExpenses from "./components/InvoiceExpenses";
import InvoiceItems from "./components/InvoiceItems";
import InvoicePaper from "./components/InvoicePaper";
import SoldItemsTable from "./components/SoldItemsTable";
import Summarize from "./components/Summarize";
import TruckDetails from "./components/TruckDetails";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import LogoImage from "../../components/LogoImage";
import CustomizedTable, { StyledTableCell } from "../../components/Table";
import Alerto from "../../components/UI/toaster";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  total: {
    padding: 8,
    marginTop: 10,
    borderRadius: 3,
    display: "inline-block",
    fontSize: 18,
    color: "#000066",
    backgroundColor: "#FFDE00",
  },
}));

function InvoicePage() {
  let { truckId } = useParams();
  const invoice = useSelector((state) => state.invoice.invoice);
  const invoiceStatus = useSelector((state) => state.invoice.invoiceStatus);
  const truck = useSelector((state) => state.invoice.truck);
  const soldItems = useSelector((state) => state.invoice.soldItems.data);
  const summaryItems = useSelector((state) => state.invoice.summaryItems);
  const invoiceItems = useSelector((state) => state.invoice.invoiceItems.data);
  const buyingProfit = useSelector((state) => state.invoice.buyingProfit);
  const deductions = useSelector((state) => state.invoice.deduction);
  const [deductionToDelete, setDeductionToDelete] = useState(null);
  const [deductionToEdit, setDeductionToEdit] = useState(null);
  const [openSummarize, setOpenSummarize] = useState(false);
  const [openExpenses, setOpenExpenses] = useState(false);
  const [openDeductions, setOpenDeductions] = useState(false);
  const [sortedInvoice, setSortedInvoice] = useState(false);
  const [sortedSold, setSortedSold] = useState(false);
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  async function onDeleteDeduction() {
    try {
      await axios.delete(`discounts?discountid=${deductionToDelete.id}`);
      setOpendeleteDeduction(false);
      dispatch(getDeductions(truckId));
    } catch (error) {
      Alerto(error);
    }
  }
  useEffect(() => {
    let arr = [...invoiceItems];
    let arr2 = [...soldItems];

    arr.sort((a, b) =>
      a.item?.product.name > b.item?.product.name
        ? 1
        : b.item?.product.name > a.item?.product.name
        ? -1
        : b.priceForUnitWeight - a.priceForUnitWeight
    );
    arr2.sort((a, b) =>
      a.item?.product.name > b.item?.product.name
        ? 1
        : b.item?.product.name > a.item?.product.name
        ? -1
        : b.price - a.price
    );
    setSortedInvoice(arr);
    setSortedSold(arr2);
  }, [invoiceItems, soldItems]);
  useEffect(() => {
    let mounted = true;
    if (mounted && truckId) {
      dispatch(getTruckFullData(truckId))
        .then(({ payload }) => {
          if (payload && payload.truckType === 2) {
            setLoading(false);
          }
          dispatch(getActualSelling(payload)).then(() => {
            dispatch(getSummary(payload));
            dispatch(getDeductions(truckId));
          });
          if (payload && payload.truckType === 1) {
            dispatch(getInvoice(truckId))
              .then(({ payload }) => {
                setLoading(false);
                if (payload && payload.id) {
                  dispatch(getInvoiceItems(payload.id));
                }
              })
              .catch(() => {
                setLoading(false);
              });
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    dispatch(getInvoiceItems(invoice?.id));
  }, [invoice, truckId]);

  const deleteAllInvoiceItemsHandler = async () => {
    dispatch(deleteAllInvoiceItems(invoice.truckId)).then(() => {
      dispatch(getInvoiceItems(invoice?.id));
    });

    setOpenDelete(false);
  };
  const startInvoice = async () => {
    setOpenSummarize(false);
    if (!invoice?.id) {
      dispatch(createInvoice(truckId)).then(({ payload }) => {
        if (payload?.id) {
          const items = soldItems.map((item) => {
            return (item = {
              invoiceId: payload.id,
              itemId: item.item.id,
              itemCount: item.itemsCount,
              priceForUnitWeight: item.price,
              weight: item.weight,
              totalMoney: item.totalMoney,
            });
          });
          dispatch(addItemsCollectionToInvoice(items)).then(() => {
            dispatch(getInvoiceItems(payload.id)).then(() => {
              window.location.reload();
            });
          });
        }
      });
    } else {
      const items = soldItems.map((item) => {
        return (item = {
          invoiceId: invoice?.id,
          itemId: item.item.id,
          itemCount: item.itemsCount,
          priceForUnitWeight: item.price,
          weight: item.weight,
          totalMoney: item.totalMoney,
        });
      });
      dispatch(addItemsCollectionToInvoice(items)).then(() => {
        dispatch(getInvoiceItems(invoice?.id)).then(() => {
          window.location.reload();
        });
      });
    }
  };

  const totalSold = soldItems.reduce((acc, val) => {
    return acc + val.totalMoney;
  }, 0);

  const totalItemsCount = soldItems.reduce((acc, val) => {
    return acc + val.itemsCount;
  }, 0);

  const totalWeight = soldItems.reduce((acc, val) => {
    return acc + val.weight;
  }, 0);

  const totalFakeWeight = invoiceItems.reduce((acc, val) => {
    return acc + val.weight;
  }, 0);

  const weightDifference = totalWeight - totalFakeWeight;

  const subTotal = invoiceItems.reduce((acc, val) => {
    return acc + val.totalMoney;
  }, 0);

  const invoiceDifference = subTotal > 0 && totalSold - subTotal;

  const classes = useStyles();

  const [openInvoice, setOpenInvoice] = useState(false);
  const componentRef = useRef();
  const printLog = () => {
    axios.put(`/TruckInvoice/LogPrintCount/${invoice?.id}`);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle:
      truck && truck.farmer ? `فاتورة العميل ${truck.farmer.name}` : "",
    onAfterPrint: printLog,
  });
  const actualTotal = soldItems.reduce((acc, currentValue) => {
    return acc + currentValue.totalMoney;
  }, 0);
  const [openDeleteDeduction, setOpendeleteDeduction] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const tableHeader = [
    "عميل مشترك",
    "عمولة",
    "نسبة العمولة",
    "مشال",
    "مكتب",
    "أيجار عدة",
    "تلاجة",
    "تعديل",
    "حذف",
  ];
  !truck.sharedTruck && tableHeader.splice(0, 1);
  return (
    <div className={classes.root}>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <CircularProgress style={{ color: "#6297D1" }} size={80} />{" "}
        </div>
      ) : (
        <div>
          <Helmet>
            <title> فواتير العملاء </title>
          </Helmet>
          {truck && truck.id && (
            <TruckDetails truck={truck} invoice={invoice} />
          )}

          <Grid
            container
            justifyContent="center"
            spacing={1}
            style={{ marginTop: 12 }}
          >
            <Grid
              container
              item
              xs={12}
              md={10}
              className="d-flex justify-content-evenly"
            >
              <Grid item xs={12} md={5} className="mb-5">
                <div
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  {truck && truck.truckType === 1 && (
                    <>
                      {invoice && invoice.id && (
                        <div className="center justify-content-between">
                          <Button
                            style={{
                              color: "#fff",
                              backgroundColor: "rgb(20 20 20 / 66%)",
                              width: "30%",
                            }}
                            variant="outlined"
                            size="small"
                            onClick={handlePrint}
                          >
                            طباعة الفاتورة
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            style={{
                              color: "#fff",
                              backgroundColor: "rgb(20 20 20 / 66%)",
                              width: "30%",
                            }}
                            onClick={() => setOpenInvoice(true)}
                          >
                            معاينة الفاتورة
                          </Button>
                        </div>
                      )}
                    </>
                  )}

                  <InvoiceExpenses truck={truck} />
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "rgb(20 20 20 / 66%)",
                      color: "#FFF",
                      width: "50%",
                      marginRight: "25%",
                    }}
                    size="small"
                    onClick={() => setOpenExpenses(true)}
                  >
                    اضافة او حذف المصروفات
                  </Button>
                </div>
              </Grid>
              {/* start here */}

              <Grid item xs={12} md={6} className="mb-5">
                <div
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  {soldItems && soldItems.length > 0 && (
                    <InvoiceDetails
                      truck={truck}
                      soldItems={sortedSold}
                      weightDifference={weightDifference}
                      subTotal={subTotal}
                      buyingProfit={buyingProfit}
                      totalItemsCount={totalItemsCount}
                      totalWeight={totalWeight}
                      totalSold={totalSold}
                      invoiceItems={sortedInvoice}
                      invoiceDifference={invoiceDifference}
                    />
                  )}
                  {truck &&
                    truck.truckType === 1 &&
                    !invoice?.id &&
                    invoiceStatus === "loading" && (
                      <div
                        style={{
                          width: "100%",
                          height: 360,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress size={40} color="secondary" />
                      </div>
                    )}
                  {invoiceItems.length > 0 && truck.truckType === 1 && (
                    <>
                      <InvoiceItems
                        soldItems={sortedSold}
                        invoiceItems={sortedInvoice}
                        truck={truck}
                      />
                    </>
                  )}
                  {invoiceItems.length > 0 && truck?.truckType !== 2 && (
                    <AddInvoiceItem truck={truck} invoice={invoice} />
                  )}
                  {invoiceItems.length > 0 && truck.truckType === 1 && (
                    <>
                      <Typography
                        className={classes.total}
                        style={{
                          background: "#f3c637",
                          textAlign: "center",
                          fontWeight: "600",
                          fontSize: "20px",
                          padding: "15px",
                          borderRadius: "10px",
                          marginBottom: "15px",
                          display: "block",
                        }}
                      >
                        الاجمالي:
                        {ConvertToArabicNumbers(subTotal.toFixed(2))}
                      </Typography>

                      <div className="text-center">
                        <Button
                          onClick={() => setOpenDelete(true)}
                          style={{
                            backgroundColor: "#0682FB",
                            margin: "auto",
                            color: "#fff",
                            padding: "10px 15px",
                            width: "150px",
                          }}
                          className="btnDelete"
                        >
                          حذف البيان
                        </Button>
                      </div>
                    </>
                  )}

                  {truck &&
                    truck.truckType === 2 &&
                    soldItems &&
                    soldItems.length > 0 && (
                      <Box
                        style={
                          truck && truck.truckType === 2
                            ? { width: "100%" }
                            : {}
                        }
                      >
                        <SoldItemsTable soldItems={sortedSold} />
                        <Typography className={classes.total}>
                          الاجمالي:
                          {ConvertToArabicNumbers(actualTotal)}
                        </Typography>
                      </Box>
                    )}
                </div>
              </Grid>

              {/* end here */}
            </Grid>

            {truck && truck.truckType === 1 && (
              <Grid item xs={12} md={2} className="mb-5">
                <div
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <div>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#FFB020" }}
                      onClick={() => {
                        setOpenSummarize(true);
                      }}
                      className="w-100 mb-1"
                    >
                      بيان اسعار واوزان البيع
                    </Button>
                    <Button
                      style={{ backgroundColor: "#2FC96D" }}
                      variant="contained"
                      onClick={() => {
                        setOpenDeductions(true);
                      }}
                      className="w-100 mb-1"
                    >
                      خصومات الفاتورة
                    </Button>
                  </div>
                  <InvoiceDeductions
                    invoiceDifference={invoiceDifference}
                    subTotal={subTotal}
                    truck={truck}
                    invoice={invoice}
                    sortedSold={sortedSold}
                    deductions={deductions}
                  />
                </div>
              </Grid>
            )}
          </Grid>
          <DeleteBox
            action={deleteAllInvoiceItemsHandler}
            message="هل تريد حذف البيان؟"
            open={openDelete}
            onClose={() => setOpenDelete(false)}
          />
          <AddExpenses
            truck={truck}
            open={openExpenses}
            onClose={() => setOpenExpenses(false)}
          />
          <CustomDialog
            open={openDeductions}
            title="اضف خصومات النقلة"
            textAlign="center"
            onClose={() => setOpenDeductions(false)}
          >
            {truck && truck.id && (
              <AddDedeuctions
                deduction={deductionToEdit}
                closeDialog={() => setOpenDeductions(false)}
                onDisableEdit={() => {
                  setDeductionToEdit(null);
                  dispatch(getDeductions(truckId));
                }}
              />
            )}
            {!!deductions?.[0] && (
              <CustomizedTable tableHeader={tableHeader}>
                {deductions.map((item, index) => (
                  <TableRow
                    key={index}
                    align="center"
                    className={classes.tableBody}
                  >
                    {!!truck.sharedTruck && (
                      <StyledTableCell align="center">
                        <Typography variant="body1" color="textPrimary">
                          {item.sharedPartner?.name}
                        </Typography>
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="center">
                      <Typography variant="body1" color="textPrimary">
                        {ConvertToArabicNumbers(item.independentCommision)}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography variant="body1" color="textPrimary">
                        {ConvertToArabicNumbers(item.commisionPercentage)}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography variant="body1" color="textPrimary">
                        {ConvertToArabicNumbers(item.carryingPrice)}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography variant="body1" color="textPrimary">
                        {ConvertToArabicNumbers(item.officeTips)}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography variant="body1" color="textPrimary">
                        {ConvertToArabicNumbers(item.movementToolRent)}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography variant="body1" color="textPrimary">
                        {ConvertToArabicNumbers(item.fridgeRent)}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <IconButton
                        className={classes.edit}
                        onClick={() => {
                          setDeductionToEdit(item);
                        }}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IconButton
                        onClick={() => {
                          setOpendeleteDeduction(true);
                          setDeductionToDelete(item);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </CustomizedTable>
            )}
          </CustomDialog>

          <CustomDialog
            open={openSummarize}
            title={
              soldItems.length > 0
                ? "بيان اسعار واوزان البيع"
                : "لم يتم بيع اي صنف من النقلة"
            }
            textAlign="center"
            onClose={() => setOpenSummarize(false)}
          >
            {soldItems && soldItems.length > 0 && (
              <Summarize
                soldItems={sortedSold}
                openSummarize={openSummarize}
                onMoveSelected={startInvoice}
                summaryItems={summaryItems}
                invoiceItems={sortedInvoice}
                truck={truck}
                invoice={invoice}
              />
            )}
          </CustomDialog>
          <div style={{ width: "21cm", height: "20.7cm" }}>
            {truck && truck.id && invoice && invoice.id && (
              <InvoicePaper
                invoice={invoice}
                ref={componentRef}
                truck={truck}
                openInvoice={openInvoice}
                soldItems={sortedSold}
                invoiceItems={sortedInvoice}
                subTotal={subTotal}
                invoiceDifference={invoiceDifference}
                onClose={() => setOpenInvoice(false)}
                summaryItems={summaryItems}
              />
            )}
          </div>
        </div>
      )}
      {!!deductionToDelete && (
        <DeleteBox
          action={onDeleteDeduction}
          open={openDeleteDeduction}
          onClose={() => setOpendeleteDeduction(false)}
          message={"حذف خصومات العميل"}
        />
      )}
    </div>
  );
}

export default InvoicePage;

function AddForm() {
  return <div></div>;
}

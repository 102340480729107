import { ConvertToArabicNumbers } from "../../../utils";
import React from "react";
import { Typography } from "@material-ui/core";
function DebtItem({ label, value }) {
  return (
    <div
      style={{
        width: 150,
        padding: 8,
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="body1"
        style={{ fontFamily: "bold", textAlign: "center", marginBottom: 8 }}
      >
        {label}
      </Typography>
      <Typography
        style={{
          border: "1px solid #666",
          padding: 4,
          textAlign: "center",
        }}
      >
        {value && value !== 0
          ? ConvertToArabicNumbers(Math.abs(value))
          : "ﻻ يوجد"}
      </Typography>
    </div>
  );
}

export default DebtItem;

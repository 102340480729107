import {
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";

import { ConvertToArabicNumbers } from "../../../utils";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditInvoiceItem from "./EditInvoiceItem";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { deleteInvoiceItem } from "../invoiceSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tableBody: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  moveBtn: {
    height: 40,
  },
  trash: {
    color: theme.palette.error.light,
  },
  edit: {
    color: theme.palette.info.light,
  },
}));

export default function InvoiceItems({ soldItems, invoiceItems, truck }) {
  const [selectedItem, setSelectedItem] = useState({});
  const [open, setOpen] = React.useState(false);
  const [deletedItemId, setDeletedItemId] = useState("");
  const dispatch = useDispatch();
  const deleteStatus = useSelector(
    (state) => state.invoice.invoiceItems.deleteStatus
  );

  const onDeleteItemClicked = (item) => {
    setDeletedItemId(item.id);
    dispatch(deleteInvoiceItem(item.id));
  };

  const classes = useStyles();

  const tableHeader = [
    "عميل مشترك",
    "الصنف",
    "العدد",
    "الوزن",
    "السعر",
    "القيمة",
    "تعديل",
    "حذف",
  ];
  !truck.sharedTruck && tableHeader.splice(0, 1);
  const getProducts = () => {
    let arr = [];
    if (soldItems && soldItems.length > 0) {
      soldItems.forEach((item) => {
        arr.push({ id: item.item.id, product: item.item.product });
      });
    }

    return arr;
  };
  const products = getProducts();

  const seen = new Set();
  const invoiceProducts = products.filter((el) => {
    const duplicate = seen.has(el.id);
    seen.add(el.id);
    return !duplicate;
  });
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead style={{ backgroundColor: "#f3c637" }}>
            <TableRow>
              {tableHeader.map((item, index) => (
                <TableCell key={index} align="center">
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 600, color: "#000" }}
                  >
                    {item}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceItems.length > 0 &&
              invoiceItems.map((item, index) => {
                return (
                  <TableRow key={index} className={classes.tableBody}>
                    {!!truck.sharedTruck && (
                      <TableCell align="center">
                        <Typography variant="body1" color="textPrimary">
                          {item.partner?.name}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <Typography variant="body1" color="textPrimary">
                        {item.item.product.name}
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography variant="body1" color="textPrimary">
                        {ConvertToArabicNumbers(item.itemCount)}
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography variant="body1" color="textPrimary">
                        {ConvertToArabicNumbers(item.weight)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" color="textPrimary">
                        {item.priceForUnitWeight > 0
                          ? ConvertToArabicNumbers(item.priceForUnitWeight)
                          : "صفر"}
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography variant="body1" color="textPrimary">
                        {item.totalMoney > 0
                          ? ConvertToArabicNumbers(item.totalMoney?.toFixed(2))
                          : "صفر"}
                      </Typography>
                    </TableCell>

                    <TableCell
                      padding="none"
                      style={{ width: 32, padding: 0, margin: 0 }}
                      align="center"
                    >
                      <IconButton
                        className={classes.edit}
                        onClick={() => {
                          setSelectedItem(item);
                          setOpen(true);
                        }}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      padding="none"
                      style={{ width: 32, padding: 0, margin: 0 }}
                      align="center"
                    >
                      {deleteStatus === "loading" &&
                      deletedItemId === item.id ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <IconButton
                          className={classes.trash}
                          onClick={() => onDeleteItemClicked(item)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {products && products.length > 0 && (
        <EditInvoiceItem
          products={invoiceProducts}
          open={open}
          onClose={() => setOpen(false)}
          truck={truck}
          item={selectedItem}
        />
      )}
    </>
  );
}

import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useField } from 'formik';

function AppTextField(props) {
  const [field, meta] = useField(props);

  return (
    <TextField
    className='w-100'
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.touched && meta.error ? meta.error : null}
      size="small"
      {...field}
      {...props}
    >
      {' '}
      {props.children}{' '}
    </TextField>
  );
}

export default AppTextField;

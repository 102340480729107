import * as Yup from "yup";

import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";

import AppTextField from "../../components/form/AppTextField";
import ProductsTable from "./components/ProductsTable";
import React from "react";
import axios from "axios";
import { indexOfId } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Alert from "../../components/UI/toaster";
import Alerto from "../../components/UI/toaster";
import MainHeader from "../../components/mainHeader/MainHeader";
import { Container } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "relative",
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
  },
});

function AddProduct() {
  const [crops, setCrops] = React.useState([]);
  const addCrop = (data) => {
    let cropsArr = [...crops];
    axios
      .post("/Products", {
        adderId: data.adderId,
        description: data.description,
        name: data.name.trim(),
      })
      .then((res) => {
        cropsArr.unshift(res.data);
        setCrops(cropsArr);
        if (res.status === 200) {
          toast.success("تم الحفظ");
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.error("اسم الصنف مستخدم من قبل");
        }
        cropsArr.shift();
      });
  };

  const updateCrop = (data) => {
    axios
      .put(`/Products/${data.id}`, {
        code_Product: data.code_Product,
        description: data.description,
        id: data.id,
        name: data.name.trim(),
        userId: data.userId,
      })
      .then(() => {
        const index = indexOfId(crops, data.id);
        let updatedCrops = [...crops];
        updatedCrops[index] = data;
        setCrops(updatedCrops);
        toast.success("تم التعديل  ");
      })
      .catch((error) => {
        Alerto(error);
      });
  };

  const deleteCrop = (id) => {
    axios
      .delete(`/Products/${id}`)
      .then((res) => {
        setCrops(crops.filter((crop) => crop.id !== id));
        toast.error("تم الحذف");
      })
      .catch((e) => {
        Alert(e);
      });
  };

  const getCrops = () => {
    axios
      .get("/Products/GetDataAll")
      .then((res) => {
        setCrops(res.data);
      })
      .catch((err) => {});
  };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      getCrops();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const user = useSelector((state) => state.auth.user);
  const classes = useStyles();
  const checkhandler = (name) => {
    if (name.length > 0) {
      axios
        .get(`/Products/isExist/${name.trim()}`)
        .then((res) => {
          if (res.data === true) {
            alert("الصنف موجود مسبقا");
          }
        })
        .catch((e) => {
          Alert(e);
        });
    }
  };
  return (
    <Container>
      <div className="my-4 ">
        <MainHeader title="الاصناف" />
      </div>
      <div className="headerSearch p-3 center  ">
        <Formik
          initialValues={{
            name: "",
            description: "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("اجباري"),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            addCrop({
              ...values,
              adderId: user.UserId,
            });
            resetForm({});
          }}
          enableReinitialize={true}>
          {({ values, setFieldValue }) => (
            <Form>
              <Box>
                <Grid
                  container
                  spacing={3}
                  style={{
                    
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <Grid item xs={12} md={4}>
                    <AppTextField
                      onBlur={() => checkhandler(values.name)}
                      label="اسم الصنف"
                      name="name"
                      type="text"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <AppTextField
                      label="وصف الصنف"
                      name="description"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={2}  className="center">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                    
                      className="btnMore"
                      >
                      حفظ
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
      <div >
        <ProductsTable
          deleteCrop={deleteCrop}
          updateCrop={updateCrop}
          checkhandler={checkhandler}
          crops={crops}
        />
      </div>
    </Container>
  );
}

export default AddProduct;

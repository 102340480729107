import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTargetPartner } from "../../../../features/main-data/dataSlice";
import Mydate from "../../../Helpers/MyDateParser";
import { useParams } from "react-router-dom";
const initialRowState = {
  partner: null,
  tool: null,
  price: "",
  count: "",
};
export default function useToolsForm({ onSubmit, isAnonymous }) {
  const [rowData, setRowData] = useState(initialRowState);
  const { partnerId } = useParams();
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();
  const inpRef = useRef();
  const tools = useSelector((state) => state.data.tools);
  const partners = useSelector((state) => state.data.partners);
  function clearState() {
    setItems([]);
    setRowData(initialRowState);
    dispatch(setTargetPartner(null));
  }
  function handleTargetpartner(partner) {
    dispatch(setTargetPartner(partner));
    onInputChanged({ key: "partner", value: partner });
  }
  function onAddRow() {
    items.push({
      id: 0,
      dateTime: Mydate(new Date()),
      partnerId: rowData.partner?.id,
      toolId: rowData.tool?.id,
      count: rowData.count,
      price: rowData.price || null,
    });
    setItems([...items]);
    setRowData({ ...initialRowState, partner: rowData.partner });
  }
  const onInputChanged = ({ value, key }) => {
    setRowData((prev) => ({ ...prev, [key]: value }));
  };
  function removeItem(indx) {
    setItems(items.filter((ele, inx) => inx !== indx));
  }
  const handleSubmit = () => {
    rowData.count &&
      rowData.tool?.id &&
      items.push({
        id: 0,
        dateTime: Mydate(new Date()),
        partnerId: rowData.partner?.id,
        toolId: rowData.tool?.id,
        count: rowData.count,
        price: rowData.price || null,
      });
    onSubmit({ operations: items });
    setItems([]);
    dispatch(setTargetPartner({}));
    setRowData(initialRowState);
    inpRef.current.focus();
  };
  useEffect(() => {
    clearState();
  }, [isAnonymous]);
  useEffect(() => {
    !!partnerId &&
      partners?.[0] &&
      handleTargetpartner(partners.find((ele) => ele.id === +partnerId));
  }, [partnerId, partners]);
  return {
    partners,
    handleSubmit,
    tools,
    handleTargetpartner,
    inpRef,
    onAddRow,
    items,
    removeItem,
    onInputChanged,
    rowData,
  };
}

import {
  Button,
  CardContent,
  Grid,
  Card as MUICard,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CircleIcon from '@mui/icons-material/Circle';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import KitchenOutlinedIcon from '@mui/icons-material/KitchenOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    padding: 0,
  },
  actionholder: {
    display: "grid",
    gridTemplateColumns: `auto auto auto auto`,
  },
  cardBtn: { color: "#FFF", width: 56, textAlign: "center" },
  title: {
    color: "#FFF",
    fontSize: 18,
    cursor: "default",
    transition: ".3s",
  },
}));

function Card({ id, title, actions }) {
  const renderCardStyle = () => {
    switch (id) {
      case 1:
        return { 
          background: "linear-gradient(to top, #fd3c3c, #fd6767, #FFADAD, #fd6767, #fd3c3c)",
          transition: '.4s' 
        };
        

      case 2:
        return { background: "linear-gradient(to top, #f59206, #fbad3d, #f7d1a4, #fbad3d, #f59206)",
          transition: '.4s' 
        }; 

      case 3:
        return { background:"linear-gradient(to top,#FFD400, #FFE000,#FDFFB6,   #FFE000, #FFD400)",
          transition: '.4s' 
        }; 

      case 4:
        return {background: "linear-gradient(to top,#57f736, #84ff6b,#c4f7ba, #84ff6b, #57f736 )",
          transition: '.4s' 
        }; 

      default:
        return { backgroundColor: 'transparent', transition: '.4s' };
    }
  };

  const classes = useStyles();

  return (
    <div>
      <MUICard className='p-0 m-3 hoverCard rounded-3' style={{
        ...renderCardStyle(),
        boxShadow: 'rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset',
        minHeight:'175px'
      }}>
        <CardContent className='p-0' >
          <Grid className='p-0'>
            <Grid item className='p-0 '>
              <Typography className='center w-100 bg-global globalFont' style={{height:'60px' }} >
                {title === "الفواتير" ? <AssignmentOutlinedIcon className='m-2' /> : ''}
                {title === "النقلات" ? <LocalShippingOutlinedIcon className='m-2' /> : ''}
                {title === "العده و الفوارغ" ? <Inventory2OutlinedIcon className='m-2' /> : ''}
                {title === "الخزينة" ? <KitchenOutlinedIcon className='m-2' /> : ''}
                {title}
              </Typography>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              className='py-3 '
            >
              {actions.map((action) => (
                <Grid key={action.name} item className='w-50  '>
                  <Link
                    to={action.link}
                    variant="text"
                    className='center text-black justify-content-start pe-3 w-100 linkHover'
                  >
                    <div className='p-2 ' >
                      <CircleIcon style={{fontSize:'10px'}}/>
                    </div>
                    {action.name}
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </MUICard>
    </div>
  );
}

export default Card;

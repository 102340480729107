import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  dangerCardHeader,
  successCardHeader,
} from "../../../components/styles";

import { ConvertToArabicNumbers } from "../../../utils";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginTop: 0,
    "& > *": {
      margin: 4,
    },
  },
  profit: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: 6,
    color: "#fff",
    ...successCardHeader,
  },
  tableBody: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

function InvoiceDeductions({
  truck,
  deductions,
  sortedSold,
}) {
  const [details, setDetails] = useState(null);

  const classes = useStyles();

  async function fetchDetails(truckId) {
    try {
      const response = await axios.get(
        `truckInvoice/invoiceDetails?truckid=${truckId}`
      );
      setDetails(response.data);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    !!truck?.id && fetchDetails(truck.id);
  }, [truck, sortedSold, deductions]);
  return (
    <div style={{ marginTop: 12 }}>
      {!isNaN(details?.profit) && details?.profit >= 0 ? (
        <Box className={classes.profit}>
          <Typography variant="body1"> الربح </Typography>
          <Typography variant="h5">
            {details?.profit > 0
              ? ConvertToArabicNumbers(Math.abs(Math.round(details?.profit)))
              : "صفر"}
          </Typography>
        </Box>
      ) : (
        !isNaN(details?.profit) && (
          <Box style={dangerCardHeader} className={classes.profit}>
            <Typography variant="body1"> خسارة </Typography>
            <Typography variant="h5">
              {ConvertToArabicNumbers(Math.abs(Math.round(details?.profit)))}
            </Typography>
          </Box>
        )
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableBody>
            {details?.discounts?.map((ele) => (
              <TableRow className={classes.tableBody}>
                <TableCell align="center">{ele.name}</TableCell>
                <TableCell align="center">
                  {ConvertToArabicNumbers(Math.round(ele.value))}
                </TableCell>
              </TableRow>
            ))}

            <TableRow style={{ backgroundColor: "#f3c637", color: "#FFF" }}>
              <TableCell align="center">
                <Typography variant="body1" color="textPrimary">
                  قيمة الخصومات
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h6" color="textPrimary">
                  {ConvertToArabicNumbers(Math.round(details?.totalDiscount))}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow style={{ backgroundColor: "#E9D001" }}></TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {details?.invoiceValue >= 0 && (
        <div
          style={{
            backgroundColor: "#FEF65B",
            color: "#FFF",
            boxShadow:
              "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
            margin: "10px 0",
          }}
        >
          <div
            className="btnMore center p-3 w-100"
            align="center"
            style={{
              fontSize: 18,
              height: 50,
            }}
          >
            صافي الفاتورة{" "}
          </div>

          <div
            style={{
              backgroundColor: "#FEF65B",
              color: "#222",
              fontSize: 30,
            }}
            align="center"
          >
            <div variant="h6" className="center" color="textPrimary">
              {ConvertToArabicNumbers(Math.round(details?.invoiceValue))}{" "}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InvoiceDeductions;

import { RiArrowDropRightLine } from "react-icons/ri";
import { RiArrowDropLeftLine } from "react-icons/ri";
import cls from "./TruckHistoryTable.module.scss";

const PaginationArrows = (props) => {
  const disable = { color: "#dddddd", cursor: "default" };

  return (
    <div className={cls.arrowHolder}>
      <RiArrowDropRightLine
        style={!props.availabe ? disable : ""}
        onClick={props.availabe ? props.onPageUp : null}
      />
      {props.pageNum}
      <RiArrowDropLeftLine
        style={props.pageNum == 1 ? disable : ""}
        onClick={props.pageNum > 1 ? props.onPageDown : null}
      />
    </div>
  );
};
export default PaginationArrows;

import {
  Button,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DetailsIcon from "@material-ui/icons/Details";
import { ConvertToArabicNumbers, getDateDashed, renderDate } from "../../utils";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import classy from "./Buyer.module.css";
import Alert from "@material-ui/lab/Alert";
import DebtItem from "./components/DebtItem";
import { Helmet } from "react-helmet";
import LogoImage from "../../components/LogoImage";
import MoneyTable from "./components/MoneyTable";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import { getRemainingDetailsForPartners } from "../movement-tools/tools-reports/toolsSlice";
import { useDispatch, useSelector } from "react-redux";
import RemainingDetails from "../movement-tools/components/RemainingDetails";
import Alerto from "../../components/UI/toaster";
import "./clientAccount.css";
import { Col, Container, Row } from "react-bootstrap";
import HistoryIcon from "@mui/icons-material/History";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import AccountHeader from "./components/accountHeader";
import ToolsInfo from "../../components/ToolsForm/partials/ToolsInfo";
import AccountReamainingTable from "../../components/accountRemainingTable";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  infoBox: {
    width: 120,
    padding: 8,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
  },
  profileData: {
    backgroundColor: "#FD8D00",
    width: "100%",
    padding: 4,
    position: "relative",
  },
  printableTable: {
    overflow: "hidden",
    height: 0,
  },
  "@media print": {
    printableTable: {
      height: "auto",
      overflowY: "auto",
    },
    printHidden: {
      display: "none",
    },
    profileData: {
      backgroundColor: "#FD8D00",
      width: "auto",
      padding: 4,
    },
  },
}));
function BuyerAccount() {
  const { type, partnerId, factor } = useParams();
  const remainingDetails = useSelector((state) => state.tools.remainingDetails);
  const [pagePrintStyle, setPagePrintStyle] = useState("size: A5 landscape;");
  const [showDate, setShowDate] = useState(false);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [buyerMoney, setBuyerMoney] = useState([]);
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();
  const getBuyersMoney = () => {
    axios
      .get(
        `/MoneyCurrentAccountReport/buyer${
          factor ? "/" + factor : ""
        }?partnerId=${partnerId}&from=${getDateDashed(
          fromDate
        )}&to=${getDateDashed(toDate)}`
      )
      .then((res) => {
        setBuyerMoney((oldReport) => [...res.data]);
      })
      .catch((err) => {});
  };
  const getBuyerLastTreatment = () => {
    axios
      .get(
        `/MoneyCurrentAccountReport/LastOperation/buyer${
          !factor ? "" : factor === "selling" ? "/selling" : "/loans"
        }/${partnerId}`
      )
      .then((res) => {
        if (res.status === 204) {
          toast.info("ﻻ يوجد تعامل مع هذا المستخدم");
          return;
        }
        setShowDate(true);

        let lastDate = new Date(res.data);
        lastDate.setDate(lastDate.getDate() + 1);

        setToDate(lastDate);
        let myFrom = new Date(res.data);
        myFrom.setMonth(myFrom.getMonth() - 1);
        setFromDate(myFrom);
      })
      .catch((err) => {});
  };
  const [buyerDebt, setBuyerDebt] = useState("");
  const getBuyerDebtDetails = () => {
    axios
      .get(`/MoneyCurrentAccountReport/debtDetails/buyer/${partnerId}`)
      .then((res) => {
        setBuyerDebt(res.data);
      })
      .catch((err) => {});
  };
  const [allTools, setAllTools] = useState([]);

  const getAllTools = () => {
    axios
      .get(`/MovementTools/reports/Remaining/${partnerId}`)
      .then((res) => {
        setAllTools(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  const totalToolsCount = allTools.reduce((acc, val) => {
    return acc + val.count;
  }, 0);
  const [clientDebt, setClientDebt] = useState("");
  const getClientDebtDetails = () => {
    axios
      .get(`/MoneyCurrentAccountReport/debtDetails/client/${partnerId}`)
      .then((res) => {
        setClientDebt(res.data);
      })
      .catch((err) => {});
  };

  const [partner, setPartner] = useState("");
  const getPartnerData = () => {
    axios.get(`/Partners/${partnerId}`).then((res) => {
      setPartner(res.data);
    });
  };

  const [totalMortage, setTotalMortage] = useState("");

  const getPriceOfRemainingTools = () => {
    axios
      .get(`/MovementTools/reports/Remaining/price/${partnerId}`)
      .then((res) => {
        setTotalMortage(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getAllTools();
      getPartnerData();
      getBuyerLastTreatment();
      getBuyerDebtDetails();
      getClientDebtDetails();
      getPriceOfRemainingTools();
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (fromDate && toDate) {
        getBuyersMoney();
      }
    }
    return () => {
      mounted = false;
    };
  }, [fromDate]);
  const [last, setLast] = useState(true);
  const reloadhandler = () => {
    let partnerDate = new Date(partner.creationDate);
    const y = fromDate && fromDate.getFullYear() >= partnerDate.getFullYear();
    if (y || last) {
      const newFromDate = new Date(fromDate.setMonth(fromDate.getMonth() - 1));
      setFromDate(newFromDate);
      !y && setLast(false);
    }
  };
  const classes = useStyles();

  const totalBuyerMoney =
    buyerDebt.totalInvoices + buyerDebt.totalLoans + totalMortage;

  const [NetDebt, setNetDabt] = useState("");
  const getNetDebt = () => {
    let data = {
      trucksInvoices: clientDebt.totalInvoices,
      sellingsInvoices: buyerDebt.totalInvoices,
      totalClientLoans: clientDebt.totalLoans,
      totalBuyerLoans: buyerDebt.totalLoans,
      totalToolMortage: totalMortage != "" ? totalMortage : 0,
      //  totalSalaryDebt: employeeDebt != "" ? employeeDebt : 0,
    };
    axios
      .post(`MoneyCurrentAccountReport/calculator/debt/total`, data)
      .then((res) => {
        setNetDabt(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  useEffect(() => {
    getNetDebt();
  }, [clientDebt, buyerDebt, totalMortage]);
  const [indexesArr, setIndexesArr] = React.useState([]);
  const onPrintBorders = (index) => {
    let arr = [];
    if (indexesArr.includes(index)) {
      let indexes = [...indexesArr];
      indexes.splice(indexes.indexOf(index), 1);
      arr = indexes;
      setIndexesArr(indexes);
      return;
    }
    if (indexesArr.length < 2) {
      arr = [...indexesArr, index];
      setIndexesArr((oldIdxs) => [...oldIdxs, index]);
    }
    const tartgetIndexes = arr.sort(function (a, b) {
      return a - b;
    });
    setPrintable(buyerMoney.slice(tartgetIndexes[0], tartgetIndexes[1] + 1));
  };

  const [printable, setPrintable] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    onBeforePrint: () => setIsPrint(true),
    content: () => componentRef.current,
    onAfterPrint: () => setIsPrint(false),
  });

  useEffect(() => {
    isPrint && handlePrint();
  }, [isPrint]);

  const element = document.getElementById("scrollList");
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: element.scrollHeight,
        behavior: "smooth",
      });
    }, 1000);
  }, [partner]);

  function downList_Page() {
    setTimeout(() => {
      window.scrollTo({
        top: element.scrollHeight,
        behavior: "smooth",
      });
    });
  }
  const [openDetails, setOpenDetails] = useState(false);

  const onDetailsClicked = (partnerId) => {
    setOpenDetails(true);
    dispatch(getRemainingDetailsForPartners(partnerId));
  };

  const [hiddenHeader, setHiddenHeader] = useState("none");
  window.addEventListener("scroll", (x) => {
    if (window.scrollY >= 180) setHiddenHeader("flex");
    else setHiddenHeader("none");
  });

  return (
    <div
      className=" w-100 marginHolder  p-0 py-4 pb-5 position-relative  "
      style={{
        background: "#534d3b",
        minHeight: "107vh",
        outline: " 8px solid #534d3b",
      }}
    >
      <Helmet>
        <title>حساب جاري البائع</title>
      </Helmet>

      <div className=" p-0 container position-relative " id="scrollList">
        <AccountHeader
          onScrollBottom={downList_Page}
          indexesArr={indexesArr}
          handlePrint={handlePrint}
          fromDate={fromDate}
          toDate={toDate}
          partner={partner}
          reloadhandler={reloadhandler}
        />

        <MoneyTable
          data={buyerMoney}
          type="buyer"
          squareInvisibile={
            factor &&
            factor !== "selling" &&
            !isPrint &&
            role != "محصل" &&
            role != "عميل_او_بائع"
              ? "invisible"
              : "visible"
          }
          onPrintBorders={(index) => onPrintBorders(index)}
          indexesArr={indexesArr}
        />
        <Row className="p-0   m-auto   ">
          <Col xs={12} className=" text-center w-100 p-1 m-auto">
            <TableContainer className="p-0 m-0 w-100" component={Paper}>
              <Table aria-label="simple table">
                <TableHead style={{ background: "#FED049", height: "65px" }}>
                  <TableRow>
                    <TableCell
                      align="center"
                      className="headerTableFont center justify-content-between"
                    >
                      <div className="center">
                        <p>الرهن</p>{" "}
                        {!isPrint && (
                          <ToolsInfo partnerId={partner.id} haveAction />
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="center" className="headerTableFont">
                      العدة
                    </TableCell>
                    <TableCell align="center" className="headerTableFont">
                      {" "}
                      سُلف
                    </TableCell>
                    <TableCell align="center" className="headerTableFont">
                      فواتير المبيع
                    </TableCell>
                    {partner.partnerTypeId === 3 && (
                      <TableCell align="center" className="headerTableFont">
                        عملات مقدمة
                      </TableCell>
                    )}
                    {partner.partnerTypeId === 3 && (
                      <TableCell align="center" className="headerTableFont">
                        فواتير نقلات
                      </TableCell>
                    )}
                    {partner && partner.partnerTypeId === 3 && (
                      <TableCell align="center" className="headerTableFont">
                        الاجمالي{" "}
                      </TableCell>
                    )}
                    {partner && partner.partnerTypeId === 2 && (
                      <TableCell align="center" className="headerTableFont">
                        الاجمالي{" "}
                      </TableCell>
                    )}

                    <TableCell align="center" className="headerTableFont">
                      {" "}
                      رهن البائع
                    </TableCell>
                    {role != "محصل" && role != "عميل_او_بائع" && (
                      <TableCell align="center" className="headerTableFont">
                        {" "}
                      </TableCell>
                    )}
                    {factor &&
                      factor !== "selling" &&
                      role != "محصل" &&
                      role != "عميل_او_بائع" && (
                        <TableCell align="center" className="headerTableFont">
                          {" "}
                        </TableCell>
                      )}
                    {partner.partnerTypeId === 3 && factor == "selling" && (
                      <TableCell align="center" className="headerTableFont">
                        {" "}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" className="BodyTableFont">
                      {Math.round(totalMortage) > 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(Math.round(totalMortage))
                          )} عليه `
                        : ""}
                      {Math.round(totalMortage) < 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(Math.round(totalMortage))
                          )} له `
                        : ""}
                      {Math.round(totalMortage) === 0 ? "لايوجد" : ""}
                    </TableCell>
                    <TableCell align="center" className="BodyTableFont">
                      {totalToolsCount > 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(Math.round(totalToolsCount))
                          )} عليه `
                        : ""}
                      {totalToolsCount < 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(Math.round(totalToolsCount))
                          )} له `
                        : ""}
                      {totalToolsCount === 0 ? "لايوجد" : ""}
                    </TableCell>
                    <TableCell align="center" className="BodyTableFont">
                      {buyerDebt.totalLoans > 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(Math.round(buyerDebt.totalLoans))
                          )} عليه `
                        : ""}
                      {buyerDebt.totalLoans < 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(Math.round(buyerDebt.totalLoans))
                          )} له `
                        : ""}
                      {buyerDebt.totalLoans === 0 ? "لايوجد" : ""}
                    </TableCell>
                    <TableCell align="center" className="BodyTableFont">
                      {buyerDebt.totalInvoices > 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(Math.round(buyerDebt.totalInvoices))
                          )} عليه `
                        : ""}
                      {buyerDebt.totalInvoices < 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(Math.round(buyerDebt.totalInvoices))
                          )} له `
                        : ""}
                      {buyerDebt.totalInvoices === 0 ? "لايوجد" : ""}
                    </TableCell>
                    {partner.partnerTypeId === 3 && (
                      <TableCell align="center" className="BodyTableFont">
                        {clientDebt.totalLoans > 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(clientDebt.totalLoans))
                            )} عليه `
                          : ""}
                        {clientDebt.totalLoans < 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(clientDebt.totalLoans))
                            )} له `
                          : ""}
                        {clientDebt.totalLoans === 0 ? "لايوجد" : ""}
                      </TableCell>
                    )}

                    {partner.partnerTypeId === 3 && (
                      <TableCell align="center" className="BodyTableFont">
                        {clientDebt.totalInvoices > 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(clientDebt.totalInvoices))
                            )} عليه `
                          : ""}
                        {clientDebt.totalInvoices < 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(clientDebt.totalInvoices))
                            )} له `
                          : ""}
                        {clientDebt.totalInvoices === 0 ? "لايوجد" : ""}
                      </TableCell>
                    )}

                    {partner && partner.partnerTypeId === 2 && (
                      <TableCell align="center" className="BodyTableFont">
                        {totalBuyerMoney > 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(totalBuyerMoney))
                            )} عليه `
                          : ""}
                        {totalBuyerMoney < 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(totalBuyerMoney))
                            )} له `
                          : ""}
                        {totalBuyerMoney === 0 ? "لايوجد" : ""}
                      </TableCell>
                    )}
                    {partner.partnerTypeId === 3 && (
                      <TableCell align="center" className="BodyTableFont">
                        {NetDebt > 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(NetDebt))
                            )} عليه `
                          : ""}
                        {NetDebt < 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(NetDebt))
                            )} له `
                          : ""}
                        {NetDebt === 0 ? "لايوجد" : ""}
                      </TableCell>
                    )}

                    <TableCell align="center" className="BodyTableFont">
                      {buyerDebt.buyerMortage !== 0
                        ? ConvertToArabicNumbers(
                            Math.round(buyerDebt.buyerMortage)
                          )
                        : "ﻻ يوجد"}
                    </TableCell>
                    {role != "محصل" && role != "عميل_او_بائع" && !isPrint && (
                      <TableCell
                        align="center"
                        component={Link}
                        to={`/add-deposit/${partnerId}/${
                          factor == "mortage" ? 5 : factor == "loans" ? 2 : 4
                        }`}
                        className="headerTableFont btnMore m-3 m-0 h-100"
                      >
                        {factor == "mortage"
                          ? "اضف رهن البائع"
                          : factor == "loans"
                          ? "سداد  سلفة"
                          : "اضافة دفع"}
                      </TableCell>
                    )}
                    {factor &&
                      factor !== "selling" &&
                      !isPrint &&
                      role != "محصل" &&
                      role != "عميل_او_بائع" && (
                        <TableCell
                          align="center"
                          component={Link}
                          to={`/add-expenses/seller/${partnerId}/
                            ${factor == "mortage" ? 11 : 10}`}
                          className="headerTableFont btnMore m-3 m-0 h-100"
                        >
                          {" "}
                          {factor == "mortage"
                            ? "   سداد رهن البائع"
                            : "اضف  سلفة"}
                        </TableCell>
                      )}
                    {partner.partnerTypeId === 3 &&
                      !isPrint &&
                      factor == "selling" && (
                        <TableCell
                          align="center"
                          component={Link}
                          to={`/MoneyCurrentAccountReport/client/${partnerId}`}
                          className="headerTableFont btnMore m-3 m-0 h-100"
                        >
                          {" "}
                          حساب العميل
                        </TableCell>
                      )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
        </Row>
      </div>

      <div ref={componentRef}>
        <div className={classes.printableTable}>
          <div>
            <LogoImage />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                backgroundColor: "#ECECEC",
                color: "#2E3840",
                width: "90%",
                padding: 4,
                height: "3vh",
                border: "1px solid black",
                margin: 6,
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  margin: 2,
                }}
              >
                {"مطلوب من"}
              </Typography>
              <Typography
                style={{
                  fontWeight: "bolder",
                  fontSize: "14px",
                }}
              >
                {partner.name}
              </Typography>
            </div>
          </div>
          <MoneyTable
            type="buyer"
            data={printable}
            isPrint={true}
            onPrintBorders={(index) => onPrintBorders(index)}
            indexesArr={indexesArr}
            squareInvisibile={
              factor &&
              factor !== "selling" &&
              isPrint &&
              role != "محصل" &&
              role != "عميل_او_بائع"
                ? "invisible"
                : "visible"
            }
          />
          <Row
            className="p-0 w-100 m-auto overflow-hidden     "
            style={{ minHeight: "3vh" }}
          >
            <Col xs={12} className=" text-center p-0 m-auto w-100 ">
              <TableContainer
                component={Paper}
                style={isPrint ? { width: "100%" } : { margin: "auto" }}
                className="p-1   bg-transparents printTable"
              >
                <Table
                  style={{
                    border: "1px black solid",
                  }}
                >
                  <TableHead style={{ background: "#FAA829" }}>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{ fontSize: "14px" }}
                        className=" center justify-content-between"
                      >
                        الرهن
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: "14px" }}
                        className=""
                      >
                        العدة
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: "14px" }}
                        className=""
                      >
                        {" "}
                        سُلف
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: "14px" }}
                        className=""
                      >
                        فواتير المبيع
                      </TableCell>
                      {partner.partnerTypeId === 3 && (
                        <TableCell align="center" className="">
                          عملات مقدمة
                        </TableCell>
                      )}
                      <TableCell
                        align="center"
                        style={{ fontSize: "14px" }}
                        className=""
                      >
                        فواتير نقلات
                      </TableCell>
                      {partner && partner.partnerTypeId === 3 && (
                        <TableCell
                          align="center"
                          style={{ fontSize: "14px" }}
                          className=""
                        >
                          الاجمالي{" "}
                        </TableCell>
                      )}
                      {partner && partner.partnerTypeId === 2 && (
                        <TableCell
                          align="center"
                          style={{ fontSize: "14px" }}
                          className=""
                        >
                          الاجمالي{" "}
                        </TableCell>
                      )}

                      <TableCell
                        align="center"
                        style={{ fontSize: "14px" }}
                        className=""
                      >
                        {" "}
                        رهن البائع
                      </TableCell>
                      {role != "محصل" && role != "عميل_او_بائع" && (
                        <TableCell
                          align="center"
                          style={{ fontSize: "14px" }}
                          className=""
                        >
                          {" "}
                        </TableCell>
                      )}
                      {factor &&
                        factor !== "selling" &&
                        role != "محصل" &&
                        role != "عميل_او_بائع" && (
                          <TableCell
                            align="center"
                            style={{ fontSize: "14px" }}
                            className=""
                          >
                            {" "}
                          </TableCell>
                        )}
                      {partner.partnerTypeId === 3 && factor == "selling" && (
                        <TableCell
                          align="center"
                          style={{ fontSize: "14px" }}
                          className=""
                        >
                          {" "}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="center"
                        style={{
                          borderRight: "1px solid #919191",
                          borderBottom: "1px solid #919191",
                          padding: 6,
                          fontSize: "14px",
                        }}
                      >
                        {Math.round(totalMortage) > 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(totalMortage))
                            )} عليه `
                          : ""}
                        {Math.round(totalMortage) < 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(totalMortage))
                            )} له `
                          : ""}
                        {Math.round(totalMortage) === 0 ? "لايوجد" : ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderRight: "1px solid #919191",
                          borderBottom: "1px solid #919191",
                          padding: 6,
                          fontSize: "14px",
                        }}
                      >
                        {totalToolsCount > 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(totalToolsCount))
                            )} عليه `
                          : ""}
                        {totalToolsCount < 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(totalToolsCount))
                            )} له `
                          : ""}
                        {totalToolsCount === 0 ? "لايوجد" : ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderRight: "1px solid #919191",
                          borderBottom: "1px solid #919191",
                          padding: 6,
                          fontSize: "14px",
                        }}
                      >
                        {buyerDebt.totalLoans > 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(buyerDebt.totalLoans))
                            )} عليه `
                          : ""}
                        {buyerDebt.totalLoans < 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(buyerDebt.totalLoans))
                            )} له `
                          : ""}
                        {buyerDebt.totalLoans === 0 ? "لايوجد" : ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderRight: "1px solid #919191",
                          borderBottom: "1px solid #919191",
                          padding: 6,
                          fontSize: "14px",
                        }}
                      >
                        {buyerDebt.totalInvoices > 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(buyerDebt.totalInvoices))
                            )} عليه `
                          : ""}
                        {buyerDebt.totalInvoices < 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(buyerDebt.totalInvoices))
                            )} له `
                          : ""}
                        {buyerDebt.totalInvoices === 0 ? "لايوجد" : ""}
                      </TableCell>
                      {partner.partnerTypeId === 3 && (
                        <TableCell
                          align="center"
                          style={{
                            borderRight: "1px solid #919191",
                            borderBottom: "1px solid #919191",
                            padding: 6,
                            fontSize: "14px",
                          }}
                        >
                          {clientDebt.totalLoans > 0
                            ? `${ConvertToArabicNumbers(
                                Math.abs(Math.round(clientDebt.totalLoans))
                              )} عليه `
                            : ""}
                          {clientDebt.totalLoans < 0
                            ? `${ConvertToArabicNumbers(
                                Math.abs(Math.round(clientDebt.totalLoans))
                              )} له `
                            : ""}
                          {clientDebt.totalLoans === 0 ? "لايوجد" : ""}
                        </TableCell>
                      )}

                      <TableCell
                        align="center"
                        style={{
                          borderRight: "1px solid #919191",
                          borderBottom: "1px solid #919191",
                          padding: 6,
                          fontSize: "14px",
                        }}
                      >
                        {clientDebt.totalInvoices > 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(clientDebt.totalInvoices))
                            )} عليه `
                          : ""}
                        {clientDebt.totalInvoices < 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(clientDebt.totalInvoices))
                            )} له `
                          : ""}
                        {clientDebt.totalInvoices === 0 ? "لايوجد" : ""}
                      </TableCell>

                      {partner && partner.partnerTypeId === 2 && (
                        <TableCell
                          align="center"
                          style={{
                            borderRight: "1px solid #919191",
                            borderBottom: "1px solid #919191",
                            padding: 6,
                            fontSize: "14px",
                          }}
                        >
                          {totalBuyerMoney > 0
                            ? `${ConvertToArabicNumbers(
                                Math.abs(Math.round(totalBuyerMoney))
                              )} عليه `
                            : ""}
                          {totalBuyerMoney < 0
                            ? `${ConvertToArabicNumbers(
                                Math.abs(Math.round(totalBuyerMoney))
                              )} له `
                            : ""}
                          {totalBuyerMoney === 0 ? "لايوجد" : ""}
                        </TableCell>
                      )}
                      {partner.partnerTypeId === 3 && (
                        <TableCell
                          align="center"
                          style={{
                            borderRight: "1px solid #919191",
                            borderBottom: "1px solid #919191",
                            padding: 6,
                            fontSize: "14px",
                          }}
                        >
                          {NetDebt > 0
                            ? `${ConvertToArabicNumbers(
                                Math.abs(Math.round(NetDebt))
                              )} عليه `
                            : ""}
                          {NetDebt < 0
                            ? `${ConvertToArabicNumbers(
                                Math.abs(Math.round(NetDebt))
                              )} له `
                            : ""}
                          {NetDebt === 0 ? "لايوجد" : ""}
                        </TableCell>
                      )}

                      <TableCell
                        align="center"
                        style={{
                          borderRight: "1px solid #919191",
                          borderBottom: "1px solid #919191",
                          padding: 6,
                          fontSize: "14px",
                        }}
                      >
                        {buyerDebt.buyerMortage !== 0
                          ? ConvertToArabicNumbers(
                              Math.round(buyerDebt.buyerMortage)
                            )
                          : "ﻻ يوجد"}
                      </TableCell>
                      {role != "محصل" && role != "عميل_او_بائع" && !isPrint && (
                        <TableCell
                          align="center"
                          component={Link}
                          to={`/add-deposit/${partnerId}/${
                            factor == "mortage" ? 5 : factor == "loans" ? 2 : 4
                          }`}
                          className="headerTableFont btnMore m-3 m-0 h-100"
                        >
                          {factor == "mortage"
                            ? "اضف رهن البائع"
                            : factor == "loans"
                            ? "سداد  سلفة"
                            : "اضافة دفع"}
                        </TableCell>
                      )}
                      {factor &&
                        factor !== "selling" &&
                        role != "محصل" &&
                        !isPrint &&
                        role != "عميل_او_بائع" && (
                          <TableCell
                            align="center"
                            component={Link}
                            to={`/add-expenses/seller/${partnerId}/
${factor == "mortage" ? 11 : 10}`}
                            className="headerTableFont btnMore m-3 m-0 h-100"
                          >
                            {" "}
                            {factor == "mortage"
                              ? "   سداد رهن البائع"
                              : "اضف  سلفة"}
                          </TableCell>
                        )}
                      {partner.partnerTypeId === 3 &&
                        !isPrint &&
                        factor == "selling" && (
                          <TableCell
                            align="center"
                            component={Link}
                            to={`/MoneyCurrentAccountReport/client/${partnerId}`}
                            className="headerTableFont btnMore m-3 m-0 h-100"
                          >
                            {" "}
                            حساب العميل
                          </TableCell>
                        )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Col>
          </Row>
          {factor === "selling" && (
            <AccountReamainingTable partnerId={partnerId} />
          )}
        </div>
      </div>

      <RemainingDetails
        rows={remainingDetails}
        open={openDetails}
        onClose={() => setOpenDetails(false)}
      />
    </div>
  );
}

export default BuyerAccount;

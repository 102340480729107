import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  getCashSellers,
  getClientsAdvancePayment,
  getClientsBills,
  getClientsPurchasesMortage,
  getEmployeesBills,
  getEmployeesCredits,
  getForwardSellers,
  getSellersCredits,
  getSellersToolsMortage,
  getSupplingSellers,
} from "./treasurySlice";
import { useDispatch, useSelector } from "react-redux";

import DatePicker from "react-datepicker";
import DepositsReport from "./components/DepositsReport";
import { Helmet } from "react-helmet";
import SearchIcon from "@material-ui/icons/Search";
import Total from "../../components/UI/Total";
import ar from "date-fns/locale/ar-SA";
import { indexOfId } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import { Container } from "react-bootstrap";
import MainHeader from "../../components/mainHeader/MainHeader";
registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 12,
  },
  SearchForm: {
    marginTop: 12,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    "& > *": {
      marginRight: 12,
    },
  },
}));

function DepositsReports() {
  const [dateTime, setDateTime] = useState(new Date());
  const [reportType, setReportType] = useState("");

  const dispatch = useDispatch();
  const forwardSellers = useSelector((state) => state.treasury.forwardSellers);
  const cashSellers = useSelector((state) => state.treasury.cashSellers);
  const supplingSellers = useSelector(
    (state) => state.treasury.supplingSellers
  );
  const sellersCredits = useSelector((state) => state.treasury.sellersCredits);
  const sellersToolsMortage = useSelector(
    (state) => state.treasury.sellersToolsMortage
  );

  const clientsBills = useSelector((state) => state.treasury.clientsBills);
  const clientsAdvancePayment = useSelector(
    (state) => state.treasury.clientsAdvancePayment
  );
  const clientsPurchasesMortage = useSelector(
    (state) => state.treasury.clientsPurchasesMortage
  );

  const employeesBills = useSelector((state) => state.treasury.employeesBills);
  const employeesCredits = useSelector(
    (state) => state.treasury.employeesCredits
  );

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (reportType === 1) {
        dispatch(getForwardSellers(dateTime));
      } else if (reportType === 2) {
        dispatch(getCashSellers(dateTime));
      } else if (reportType === 3) {
        dispatch(getSupplingSellers(dateTime));
      } else if (reportType === 4) {
        dispatch(getSellersCredits(dateTime));
      } else if (reportType === 5) {
        dispatch(getSellersToolsMortage(dateTime));
      } else if (reportType === 7) {
        dispatch(getClientsBills(dateTime));
      } else if (reportType === 8) {
        dispatch(getClientsAdvancePayment(dateTime));
      } else if (reportType === 9) {
        dispatch(getClientsPurchasesMortage(dateTime));
      } else if (reportType === 11) {
        dispatch(getEmployeesCredits(dateTime));
      } else if (reportType === 12) {
        dispatch(getEmployeesBills(dateTime));
      }
    }
    return () => {
      mounted = false;
    };
  }, [reportType, dateTime]);

  const reportsTypes = [
    { id: 1, name: "تحصيل من بائعين الاجل" },
    { id: 2, name: "تحصيل من بائعين النقدية" },
    { id: 3, name: "تحصيل من التوريد" },
    { id: 4, name: "تحصيل سلف البائعين" },
    { id: 5, name: "تحصيل رهن بائعين" },
    { id: 7, name: "تحصيل من العملاء فواتير زراعة" },
    { id: 8, name: "تحصيل عملة مقدمة" },
    { id: 9, name: "تحصيل  رهن مشتريات" },
  ];

  let depositsReport = [];
  if (reportType === 1) {
    depositsReport = forwardSellers;
  } else if (reportType === 2) {
    depositsReport = cashSellers;
  } else if (reportType === 3) {
    depositsReport = supplingSellers;
  } else if (reportType === 4) {
    depositsReport = sellersCredits;
  } else if (reportType === 5) {
    depositsReport = sellersToolsMortage;
  } else if (reportType === 7) {
    depositsReport = clientsBills;
  } else if (reportType === 8) {
    depositsReport = clientsAdvancePayment;
  } else if (reportType === 9) {
    depositsReport = clientsPurchasesMortage;
  } else if (reportType === 11) {
    depositsReport = employeesCredits;
  } else if (reportType === 12) {
    depositsReport = employeesBills;
  }

  const getReportName = () => {
    if (reportType) {
      let r = indexOfId(reportsTypes, reportType);
      return reportsTypes[r].name;
    }
  };

  const totalPrice =
    depositsReport &&
    depositsReport.length > 0 &&
    depositsReport.reduce((acc, val) => {
      return acc + val.price;
    }, 0);

  const totalAllow =
    depositsReport &&
    depositsReport.length > 0 &&
    depositsReport.reduce((acc, val) => {
      return acc + val.allow;
    }, 0);

  const [searchQuery, setSearchQuery] = useState("");

  const classes = useStyles();
  return (
    <div
      className=" w-100  p-0 py-4 pb-5 position-relative  "
      style={{
        background: "#534d3b",
        marginTop: " -15px !important",
        minHeight: "107vh",
        outline: " 8px solid #534d3b",
      }}
    >
      <Container>
        <Helmet>
          <title> تقارير التحصيل </title>
        </Helmet>
        <div className="my-4">
          <MainHeader title="تقارير التحصيل" />
        </div>
        <CardContent className="headerSearch m-0">
          <Grid container spacing={2} className="center">
            <Grid item xs={12} md={3}>
              <TextField
                style={{ width: "100%" }}
                size="small"
                select
                label="نوع التقرير"
                onChange={(e) => setReportType(e.target.value)}
                value={reportType}
                variant="outlined"
              >
                {reportsTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <DatePicker
                locale="ar"
                selected={dateTime}
                onChange={(d) => setDateTime(d)}
                dateFormat="dd/MM/yyyy"
                customInput={
                  <TextField
                    size="small"
                    variant="outlined"
                    id="dateTime"
                    style={{ width: "100%", backgroundColor: "#FFF" }}
                  />
                }
              />
            </Grid>
            {depositsReport && depositsReport.length > 0 && (
              <Grid item xs={12} md={3}>
                <TextField
                  placeholder="بحث بالاسم"
                  className="w-100"
                  size="small"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className={classes.searchIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
        {reportType && (
          <div className="center">
            {totalPrice > 0 && (
              <Total label="اجمالي التحصيل" value={totalPrice} />
            )}
            {totalAllow > 0 && (
              <Total label="اجمالي السماح" value={totalAllow} />
            )}
          </div>
        )}
        {depositsReport && depositsReport.length > 0 ? (
          <DepositsReport
            deposits={depositsReport}
            reportName={getReportName()}
            totalPrice={totalPrice}
            totalAllow={totalAllow}
            searchQuery={searchQuery}
          />
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginTop: 180 }}
            >
              {" "}
              ﻻ يوجد تحصيل{" "}
            </Typography>
          </div>
        )}
      </Container>
    </div>
  );
}

export default DepositsReports;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import Alert from "../../components/UI/toaster";

export const getPartnersData = createAsyncThunk(
  "seller/getPartnersData",
  async (partnerId) => {
    try {
      const response = await axios.get(`/Partners/${partnerId}`);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      Alert(error);
    }
  }
);

export const getLastDepositDate = createAsyncThunk(
  "seller/getLastDepositDate",
  async (partnerId) => {
    try {
      const response = await axios.get(`/Deposits/last?partnerId=${partnerId}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {}
  }
);

export const getInvoiceSummery = createAsyncThunk(
  "seller/getInvoiceSummery",
  async (invoiceId, { dispatch }) => {
    try {
      const response = await axios.get(
        `/SellingsInvoices/Summerize/${invoiceId}`
      );
      dispatch(getTrucksIdsForInvoice(invoiceId));
      return response.data;
    } catch (error) {}
  }
);

export const getTrucksIdsForInvoice = createAsyncThunk(
  "seller/getTrucksIdsForInvoice",
  async (invoiceId) => {
    try {
      const response = await axios.get(
        `/SellingsInvoices/trucks?invoiceId=${invoiceId}`
      );

      return response.data;
    } catch (error) {
      Alert(error);
    }
  }
);

const initialState = {
  partner: "",
  lastDepositDate: "",
  trucksIds: [],
  invoiceSummery: "",
  sellerInvoiceDate: "",
  sellingInvoiceTypeId: "",
};

const sellerSlice = createSlice({
  name: "seller",
  initialState,

  reducers: {
    getSellerInvoiceDate: (state, action) => {
      state.sellerInvoiceDate = action.payload;
    },
    getSellerInvoiceType: (state, action) => {
      state.sellingInvoiceTypeId = action.payload;
    },
  },

  extraReducers: {
    [getLastDepositDate.fulfilled]: (state, action) => {
      if (action.payload) {
        state.lastDepositDate = action.payload;
      }
    },

    [getPartnersData.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {

        state.partner = action.payload;
      }
    },
    [getInvoiceSummery.fulfilled]: (state, action) => {
      if (action.payload) {
        state.invoiceSummery = action.payload[0];
      }
    },
    [getTrucksIdsForInvoice.fulfilled]: (state, action) => {
      if (action.payload) {
        state.invoiceSummery = {
          ...state.invoiceSummery,
          invoiceId: action.meta.arg,
          trucksIds: action.payload,
        };
      }
    },
  },
});

export const { getSellerInvoiceDate, getSellerInvoiceType } =
  sellerSlice.actions;
export default sellerSlice.reducer;

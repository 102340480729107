import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { getFilledTools, getTreasuryTools } from "./toolsSlice";
import { useDispatch, useSelector } from "react-redux";

import GeneralTable from "../components/GeneralTable";
import Total from "../../../components/UI/Total";
import ar from "date-fns/locale/ar-SA";
import { indexOfId } from "../../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import MainHeader from "../../../components/mainHeader/MainHeader";
import { Container } from "react-bootstrap";

registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 12,
  },
  SearchForm: {
    marginTop: 12,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    "& > *": {
      marginRight: 12,
    },
  },
}));

function GeneralReports() {
  const [reportType, setReportType] = useState(1);

  const dispatch = useDispatch();
  const filledTools = useSelector((state) => state.tools.filledTools);
  const treasuryTools = useSelector((state) => state.tools.treasuryTools);

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (reportType === 1) {
        dispatch(getFilledTools());
      } else if (reportType === 2) {
        dispatch(getTreasuryTools());
      }
    }
    return () => {
      mounted = false;
    };
  }, [reportType]);

  const reportsTypes = [
    { id: 1, name: "المبيع" },
    { id: 2, name: "المخزن" },
  ];
  const getReportName = () => {
    let r = indexOfId(reportsTypes, reportType);
    return reportsTypes[r].name;
  };

  let report = [];

  report =
    reportType === 1 ? filledTools : reportType === 2 ? treasuryTools : [];

  const total = report.reduce((acc, val) => {
    return acc + val.count;
  }, 0);

  const classes = useStyles();
  return (
    <Container>
      <div className="my-4">
        <MainHeader title="تقارير العدة المتبقية في المخزن و المبيع" />
      </div>
      <Card>
        {/* <Divider /> */}
        <CardContent className="headerSearch m-0 p-1 ">
          <Box className=' w-100 row center '>
            <div className=" col-10 col-md-5 col-lg-4 m-3 text-center"              >
              <TextField
                size="small"
                select
                label="نوع التقرير"
                onChange={(e) => setReportType(e.target.value)}
                value={reportType}
                variant="outlined"
                className="w-100"
              >
                {reportsTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Box>
        </CardContent>
      </Card>
      {total !== 0 ? <div className="w-50 m-auto">
        <Total value={total} />
      </div> : "ﻻ يوجد"}
      {report.length > 0 ? (
        <GeneralTable
          items={report}
          reportName={getReportName()}
          total={total}
        />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ marginTop: 180 }}
          >
            غير موجود
          </Typography>
        </div>
      )}
    </Container>
  );
}

export default GeneralReports;

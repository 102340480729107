import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getDateDashed } from '../../utils';
import { toast } from 'react-toastify';
import Alerto from '../../components/UI/toaster';

export const getSellingReport = createAsyncThunk(
  'sellersInvoice/getSellingReport',
  async (date) => {
    try {
      const response = await axios.get(
        `/SellingsInvoices/${getDateDashed(date)}`
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const addInvoice = createAsyncThunk(
  'sellersInvoice/addInvoice',
  async (data, { rejectWithValue, getState }) => {
    const { sellingDate, partnerId, invoiceTypeId } = data;

    try {
      const response = await axios.post('/SellingsInvoices', {
        sellingDate,
        partnerId,
        invoiceTypeId,
        adderId: getState().auth.user.UserId,
        creationDate: getDateDashed(new Date()),
      });
      if (response.status === 200) {
        toast.success('تم انشاء فاتورة');
      }
      return response.data;
    } catch (error) {
      Alerto(error); 
      rejectWithValue(error.response.data);
      return error.response.data;
    }
  }
);

export const getSellerInvoiceItems = createAsyncThunk(
  'sellersInvoice/getSellerInvoiceItems',
  async (partnerReport, { rejectWithValue }) => {

    try {
      const response = await axios.get(
        `/SellingsInvoices/items?invoiceid=${partnerReport.invoice.invoiceId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  sellingReport: [],
  invoice: '',
  trucks: [],
  invoiceItems: [],
  sellerInvoiceDate: '',
};

const sellersInvoiceSlice = createSlice({
  name: 'sellersInvoice',
  initialState,

  extraReducers: {
    [getSellingReport.fulfilled]: (state, action) => {
      if (action.payload) {
        state.sellingReport = action.payload;
      }
    },

    [addInvoice.fulfilled]: (state, action) => {
      state.invoice = action.payload;
    },

    [getSellerInvoiceItems.fulfilled]: (state, action) => {
      state.invoiceItems = action.payload;
    },
  },
});

export default sellersInvoiceSlice.reducer;

import {
  Button,
  DialogContent,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import { ConvertToArabicNumbers } from '../../../utils';
import CustomDialog from '../../../components/CustomDialog';
import ErrorSharpIcon from '@material-ui/icons/ErrorSharp';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

function BalanceNotification({
  open,
  onClose,
  onUpdateBalance,
  debt,
  balanceAlert,
  total,
}) {
  const classes = useStyles();

  const [openBalanceForm, setopenBalanceForm] = useState(false);
  const [addedBalance, setAddedBalance] = useState('');

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!isNaN(balanceAlert)) {
        setAddedBalance(balanceAlert);
      }
    }
    return () => {
      mounted = false;
      setAddedBalance('');
    };
  }, []);

  const handleUpdate = () => {
    onUpdateBalance(addedBalance);
    setAddedBalance('');
    onClose();
  };

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      action1={
        <Button
          onClick={onClose}
          color="secondary"
          size="small"
          style={{ height: 40 }}
        >
          الغاء العملية
        </Button>
      }
      action2={
        <Button
          variant="contained"
          color="primary"
          style={{ height: 40, marginLeft: 16 }}
          size="small"
          disabled={!(addedBalance > 0)}
          onClick={handleUpdate}
        >
          تغيير تنبيه الرصيد
        </Button>
      }
    >
      <DialogContent style={{ textAlign: 'center' }}>
        {total + debt > balanceAlert ? (
          <div className={classes.root}>
            <ErrorSharpIcon style={{ color: '#dd2c00' }} />
            <Typography style={{ color: '#dd2c00' }} variant="body1">
              تحذير! البائع تخطي الحد الاقصي لقيمة تنبيه الرصيد بمقدار
            </Typography>
            <Typography variant="h6">
              {ConvertToArabicNumbers(total + debt - balanceAlert)}
            </Typography>
          </div>
        ) : (
          <div className={classes.root}>
            <Typography style={{ color: '#00c853' }} variant="body1">
              اجمالي البيع و الدين اقل من تنبيه الرصيد بمقدار
            </Typography>
            <Typography variant="h6">
              {balanceAlert - total - debt > 0
                ? ConvertToArabicNumbers(balanceAlert - total - debt)
                : 'صفر'}
            </Typography>
          </div>
        )}
        {
          <Button
            variant="text"
            style={{ color: '#01579B' }}
            size="small"
            onClick={() => {
              setopenBalanceForm(true);
            }}
          >
            رفع الحد الاقصي لتنبيه الرصيد؟
          </Button>
        }
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            margin: '12px auto',
          }}
        >
          <Typography color="textSecondary">
            الدين: {debt > 0 ? ConvertToArabicNumbers(debt) : 'صفر'}
          </Typography>
          <Typography color="textSecondary">
            الاجمالي: {ConvertToArabicNumbers(total)}
          </Typography>
          <Typography color="textSecondary">
            تنبيه الرصيد:{' '}
            {balanceAlert > 0 ? ConvertToArabicNumbers(balanceAlert) : 'صفر'}
          </Typography>
        </div>
        {
          <TextField
            onChange={(e) => setAddedBalance(Number.parseFloat(e.target.value))}
            value={addedBalance >= 0 ? addedBalance : ''}
            label="تنبيه الرصيد"
            size="small"
            variant="outlined"
            style={{ width: 220, marginTop: 8, marginBottom: 8 }}
            autoFocus
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleUpdate();
              }
            }}
          />
        }
        <Typography variant="body2" color="textSecondary">
          يجب الا يقل تنبيه الرصيد عن {ConvertToArabicNumbers(total + debt)}
        </Typography>
      </DialogContent>
    </CustomDialog>
  );
}

export default BalanceNotification;

import { Box, Button } from "@material-ui/core";
import React, { useState } from "react";
import { fetchCrops, fetchTools } from "../main-data/dataSlice";
import {
  deleteSellCommissionItem,
  getDeductions,
  getExpenses,
  getSoldItems,
  getTruck,
  productSelected,
  soldProductSelected,
  updateSellCommisionItem,
} from "./loadsSlice";
import { useDispatch, useSelector } from "react-redux";

import AddItems from "./components/AddItems";
import { Helmet } from "react-helmet";
import LoadsToolbar from "./components/LoadsToolbar";
import SalesTable from "./container/SalesTable";
import SelectedItemInfo from "./components/SelectedItemInfo";
import SellCommissionForm from "./components/SellCommissionForm";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Loads = () => {
  const { truckId } = useParams();
  const loadItems = useSelector((state) => state.loads.commissionItems.items);
  const load = useSelector((state) => state.loads.load.data);
  const soldItems = useSelector((state) => state.loads.soldItems);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const onUpdate = (item) => {
    dispatch(soldProductSelected(item));
    dispatch(
      updateSellCommisionItem({
        id: item.id,
        truckItem: item.truckItem,
        partner: item.partner,
        movementTool: item.movementTool,
        priceForUnitWeight: item.priceForUnitWeight,
        weight: item.weight,
        productsCount: item.productsCount,
        commision: item.commision,
        dateTime: item.dateTime,

        oldCount: item.oldCount,
        oldtruckItemId: item.oldtruckItemId,
        invoiceTypeId: item.invoiceTypeId,
      })
    ).then(() => {
      if (item.oldtruckItemId !== item.truckItem.id) {
        dispatch(productSelected({}));
      }
    });
  };
  const onDelete = (itemId) => {
    dispatch(deleteSellCommissionItem(itemId));
  };
  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(fetchCrops());
      dispatch(fetchTools());
    }
    return () => {
      mounted = false;
    };
  }, []);
  function refresh() {
    dispatch(getTruck(truckId)).then(({ payload }) => {
      dispatch(productSelected({}));
      dispatch(getSoldItems(payload));
      dispatch(getDeductions());
      dispatch(getExpenses());
    });
  }
  React.useEffect(() => {
    if (truckId) {
      refresh();
      setShowModal(true);
    }
  }, []);

  return (
    <div
      className=" w-100 h-100  p-0 py-1 pb-5 margin15px "
      style={{
        background: "#534d3b",
        marginTop: " -15px  !important",
        outline: " 8px solid #534d3b",
      }}
    >
      <Helmet>
        <title> النقلات </title>
      </Helmet>
      <Box
        sx={{
          minHeight: "100%",
        }}
      >
        <LoadsToolbar
          truckId={truckId}
          onClick={() => {
            setShowModal(true);
          }}
        />

        <SelectedItemInfo
          onChangeItem={() => {
            setShowModal(true);
          }}
        />

        {load && load.id ? (
          <SellCommissionForm focustBuyer={!showModal} />
        ) : null}

        {soldItems.length > 0 && (
          <SalesTable
            refresh={refresh}
            onUpdate={onUpdate}
            onDelete={onDelete}
            soldItems={soldItems}
          />
        )}
        {load && load.id && (
          <AddItems
            show={showModal}
            onDepart={() => setShowModal(false)}
            onClose={() => {
              if (loadItems.length === 0) {
                setShowModal(false);
              } else {
                toast.error("يرجي ترحيل صنف");
              }
            }}
          />
        )}
      </Box>
    </div>
  );
};

export default Loads;

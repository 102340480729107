import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDateDashed, indexOfId } from "../../utils";

import axios from "axios";
import { parseISO } from "date-fns";
import { toast } from "react-toastify";
import Alerto from "../../components/UI/toaster";
import Mydate from "../../components/Helpers/MyDateParser";

export const addNewImporting = createAsyncThunk(
  "importing/addNewImporting",
  async (
    { partner, movementTool, count, importingDate },
    { rejectWithValue, getState }
  ) => {
    try {
      const response = await axios.post(`/MovementTools/importing/routing`, {
        toolId: movementTool.id,
        count,
        partnerId: partner.id,
        adderId: getState().auth.user.UserId,
        importingDate: importingDate,
      });
      return response.data;
    } catch (error) {
      Alerto(error);
      return rejectWithValue(error.response.data);
    }
  }
);

// export const addImporting = createAsyncThunk(
//   'importing/addImporting',
//   async ({ partner, dateTime, partnerType }) => {
//     try {
//       if (partnerType === '1') {
//         const response = await axios.post('/MovementTools/importing/client', {
//           partnerId: partner.id,
//           adderId: getState().auth.user.UserId,
//           dateTime: dateTime,
//         });
//         return response.data;
//       } else if (partnerType === '2') {
//         const response = await axios.post('MovementTools/importing/buyer', {
//           partnerId: partner.id,
//           adderId: getState().auth.user.UserId,
//           dateTime: dateTime,
//         });
//         return response.data;
//       }
//     } catch (error) {
//       return error.response.data;
//     }
//   }
// );

export const getImporting = createAsyncThunk(
  "importing/getImporting",
  async (importingId) => {
    try {
      const response = await axios.get(
        `/MovementTools/importing/${importingId}`
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateImporting = createAsyncThunk(
  "importing/updateImporting",
  async (
    { itemId, partner, importingDate, movementTool, count },
    { rejectWithValue, getState }
  ) => {
    try {
      const response = await axios.put(
        `/MovementTools/importing/routing/${itemId}`,
        {
          itemId,
          toolId: movementTool.id,
          count,
          importingDate,
          partnerId: partner.id,
          adderId: getState().auth.user.UserId,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteImporting = createAsyncThunk(
  "importing/deleteImporting",
  async (_, { getState }) => {
    const id = getState().importing.importing.data.id;
    try {
      const response = await axios.delete(`/MovementTools/importing/${id}`);
      if (response.status === 200) {
        toast.success("تم الحذف بنجاح");
        window.location = "/tools-in";
        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data);
      return error.response.data;
    }
  }
);

export const addImportingItem = createAsyncThunk(
  "importing/addImportingItem",
  async (data, { getState, rejectWithValue }) => {
    const importingId = getState().importing.importing.data.id;
    try {
      const response = await axios.post("/MovementTools/importing/item", {
        importingId,
        movementToolId: data.movementTool.id,
        count: data.count,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateImportingItem = createAsyncThunk(
  "importing/updateImportingItem",
  async (data, { getState }) => {
    const importingId = getState().importing.importing.data.id;
    try {
      const response = await axios.put(
        `/MovementTools/importing/item/${data.id}`,
        {
          id: data.id,
          importingId,
          movementToolId: data.movementTool.id,
          count: data.count,
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateImportingDetails = createAsyncThunk(
  "importing/updateImportingDetails",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.put(`/MovementTools/importing/${data.id}`, {
        id: data.id,
        partnerId: data.partner.id,
        dateTime: data.dateTime,
        adderId: getState().auth.user.UserId,
      });
      return response.data;
    } catch (error) {
      Alerto(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteImportingItem = createAsyncThunk(
  "importing/deleteImportingItem",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `/MovementTools/importing/item/${id}`
      );

      return response.data;
    } catch (error) {
      toast.error(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteImportingDetailsItem = createAsyncThunk(
  "importing/deleteImportingDetailsItem",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `/MovementTools/importing/item/${id}`
      );

      return response.data;
    } catch (error) {
      toast.error(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRemainingDetails = createAsyncThunk(
  "importing/getRemainingDetails",
  async (id) => {
    try {
      const response = await axios.get(
        `/MovementTools/reports/Remaining/${id}`
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const searchImportingByDate = createAsyncThunk(
  "importing/searchImportingByDate",
  async (dateTime) => {
    try {
      const response = await axios.get(
        `/MovementTools/importing/search?dateTime=${getDateDashed(dateTime)}`
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const searchImportingByName = createAsyncThunk(
  "importing/searchImportingByName",
  async (partnerId) => {
    try {
      const response = await axios.get(
        `/MovementTools/importing/search?partnerId=${partnerId}`
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  importing: {
    data: {},
    items: [],
  },
  importingItems: [],
  imports: [],
  remainingTools: [],
};

const importSlice = createSlice({
  name: "importing",
  initialState,

  extraReducers: {
    // [addImporting.pending]: (state, action) => {
    //   state.importing.data = action.meta.arg;
    // },
    // [addImporting.fulfilled]: (state, action) => {
    //   state.importing.data.id = action.payload.id;
    // },
    // [addImporting.rejected]: (state) => {
    //   state.importing.data = {};
    // },

    [addNewImporting.pending]: (state, action) => {
      state.importingItems.unshift(action.meta.arg);
    },
    [addNewImporting.fulfilled]: (state, action) => {
      if (action.payload && action.payload.itemId) {
        state.importingItems[0].itemId = action.payload.itemId;
      }
    },
    [addNewImporting.rejected]: (state) => {
      if (state.importingItems.length > 0) {
        state.importingItems.pop();
      }
    },
    [getImporting.fulfilled]: (state, action) => {
      state.importing.data = action.payload;
      state.importing.items = action.payload.items;
    },
    [updateImporting.fulfilled]: (state, action) => {
      const indexOfItem = () => {
        for (let i = 0; i < state.importingItems.length; i++) {
          if (state.importingItems[i].itemId === action.meta.arg.itemId)
            return i;
        }
      };

      const index = indexOfItem();
      state.importingItems[index] = action.meta.arg;
    },
    [deleteImporting.fulfilled]: (state) => {
      state.importing.data = {};
      state.importing.items = [];
    },

    [updateImportingDetails.fulfilled]: (state, action) => {
      state.importing.data = action.meta.arg;
    },

    [addImportingItem.pending]: (state, action) => {
      state.importing.items.push(action.meta.arg);
    },
    [addImportingItem.fulfilled]: (state, action) => {
      state.importing.items[state.importing.items.length - 1].id =
        action.payload.id;
    },
    [addImportingItem.rejected]: (state) => {
      state.importing.items.pop();
    },
    [updateImportingItem.fulfilled]: (state, action) => {
      const index = indexOfId(state.importing.items, action.meta.arg.id);
      state.importing.items[index] = action.meta.arg;
    },
    [deleteImportingItem.fulfilled]: (state, action) => {
      state.importingItems = state.importingItems.filter((item) => {
        return item.itemId !== action.meta.arg;
      });
    },
    [deleteImportingDetailsItem.fulfilled]: (state, action) => {
      state.importing.items = state.importing.items.filter((item) => {
        return item.id !== action.meta.arg;
      });
    },
    [getRemainingDetails.fulfilled]: (state, action) => {
      state.remainingTools = action.payload;
    },
    [searchImportingByDate.fulfilled]: (state, action) => {
      state.imports = action.payload;
    },
    [searchImportingByName.fulfilled]: (state, action) => {
      state.imports = action.payload;
    },
  },
});

export default importSlice.reducer;

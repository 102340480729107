import { ConvertToArabicNumbers, renderDate } from '../../../utils';

import { Dialog } from '@material-ui/core';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  table: {
    width: 400,
  },
  dialog: {
    position: 'absolute',
    top: 32,
    right: 16,
    backgroundColor: '#DDD',
    zIndex: 100,
    minHeight: 140,
  },
  tableCell: {
    borderBottom: '1px solid #222',
  },
});

export default function NotifyArrears({ open, onClose, error }) {
  const buyersArrears = useSelector((state) => state.data.buyersArrears);
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      hideBackdrop={true}
      classes={{
        paper: classes.dialog,
      }}
    >
      {buyersArrears.length > 0 ? (
        <Table className={classes.table}>
          <TableHead style={{ backgroundColor: '' }}>
            <TableRow>
              <TableCell align="center">
                <Typography variant="body2" style={{ fontWeight: 600 }}>
                  الاسم
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" style={{ fontWeight: 600 }}>
                  فواتير بيع
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" style={{ fontWeight: 600 }}>
                  اخر تحصيل
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {buyersArrears.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tableCell} align="center">
                  {row.partnerName}
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  {row.totalInvoices}
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  {' '}
                  {ConvertToArabicNumbers(
                    renderDate(row.lastDepositDate)
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div>
          {error ? (
            <Typography style={{ padding: 16, color: '#e00' }}>
              {error}
            </Typography>
          ) : (
            <Typography> لا يوجد اعدادات متأخر </Typography>
          )}
        </div>
      )}
    </Dialog>
  );
}

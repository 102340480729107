import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomDialog from "../../../components/CustomDialog";
import Alerto from "../../../components/UI/toaster";
import { fetchPartners } from "../dataSlice";
const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1rem",
  },
  field: {
    width: "20rem",
    margin: "5px 0",
  },
  pKnower: {
    margin: 0,
    fontWeight: "bold",
    color: "gray",
    "& span": {
      color: "black",
      fontWeight: "bolder",
    },
  },

  btn: {
    margin: "1rem 0",
    backgroundColor: "#F3C637",
    color: "black",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "1rem",
    border: 0,
    fontWeight: "bold",
    padding: "5px 10px",
  },
  subBtn: {
    width: "5rem",
    margin: "5px 0",
    backgroundColor: "black",
    color: "white",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "1rem",
    "&:hover": {
      backgroundColor: "#F3C637",
      color: "black",
    },
    "&:disabled": {
      cursor: "initial",
      color: "white",
      backgroundColor: "gray",
    },
  },
}));
const TransferProfile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fromPartner, setFromPartner] = useState({});
  const [toPartner, setToPartner] = useState({});
  const partners = useSelector((state) => state.data.partners);
  const [partnerState, setPartnersState] = useState(partners);
  function clearState() {
    setFromPartner({});
    setToPartner({});
  }
  const onTransfer = () => {
    const q = window.confirm(
      `تأكيد تحويل ${fromPartner.name} الي ${toPartner.name}`
    );
    if (q) {
      setLoading(true);
      axios
        .put(
          `Partners/transferToAnotherAccount?from=${fromPartner.id}&to=${toPartner.id}`
        )
        .then((res) => {
          toast.success("تم التحويل");
          clearState();
          setIsModalOpen(false);
        })
        .catch((e) => Alerto(e))
        .finally(() => setLoading(false));
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPartners());
    return () => clearState();
  }, [dispatch]);
  useEffect(() => {
    fromPartner?.partnerTypeId
      ? setPartnersState(
          partners.filter(
            (ele) =>
              ele.partnerTypeId === fromPartner.partnerTypeId &&
              ele.id !== fromPartner.id
          )
        )
      : setPartnersState(partners);
  }, [fromPartner]);
  useEffect(() => {
    setPartnersState(partners);
  }, [partners]);
  const classes = useStyles();

  return (
    <>
      <button className={classes.btn} onClick={() => setIsModalOpen(true)}>
        تحويل حساب
      </button>
      <CustomDialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className={classes.container}>
          {!!fromPartner?.partnerTypeId && (
            <p className={classes.pKnower}>
              لا يمكن التحويل الا ل{" "}
              <span>
                {fromPartner.partnerTypeId == 1
                  ? "عميل"
                  : fromPartner.partnerTypeId == 2
                  ? "بائع"
                  : "عميل بائع"}
              </span>
            </p>
          )}
          <Autocomplete
            options={partnerState}
            getOptionSelected={(option, value) => {
              return option.value === value?.value;
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            autoHighlight
            autoComplete
            noOptionsText="لم يتم العثور على خيارات"
            onChange={(event, value) => {
              if (value) {
                setFromPartner(value);
              } else {
                setFromPartner("");
              }
            }}
            value={fromPartner}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                //onSubmit();
              }
            }}
            renderInput={(params) => (
              <TextField
                className={classes.field}
                {...params}
                label="من"
                variant="outlined"
                autoComplete="off"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            )}
          />
          <Autocomplete
            options={partnerState}
            getOptionSelected={(option, value) => {
              return option.value === value?.value;
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            autoHighlight
            autoComplete
            noOptionsText="لم يتم العثور على خيارات"
            onChange={(event, value) => {
              if (value) {
                setToPartner(value);
              } else {
                setToPartner("");
              }
            }}
            value={toPartner}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                //onSubmit();
              }
            }}
            renderInput={(params) => (
              <TextField
                className={classes.field}
                {...params}
                label="الي"
                variant="outlined"
                autoComplete="off"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            )}
          />
          <button
            className={classes.subBtn}
            disabled={!toPartner?.id || !fromPartner?.id || loading}
            onClick={onTransfer}
          >
            تحويل
          </button>
          {loading && <p>يرجي الانتظار ....</p>}
        </div>
      </CustomDialog>
    </>
  );
};
export default TransferProfile;

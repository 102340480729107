import { Card, CardContent, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ProfitsTable from "./components/ProfitsTable";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { ConvertToArabicNumbers } from "../../utils";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  totHead: {
    backgroundColor: "#F3C637",
    borderRadius: "10px 10px 0 0",
    padding: '10px 0'
  },

}));

function Reports() {
  const classes = useStyles();
  const [total, setTotal] = useState(0);
  const [generalProfits, setGeneralProfits] = useState([]);
  const getGeneralProfits = async () => {
    let obj = {};
    const res = await axios.get("TotalDebts/sellings");
    obj.sellingsCount = res.data;

    const res1 = await axios.get("TotalDebts/buyersLoans");
    obj.totalSellingsMoney = res1.data;
    const res6 = await axios.get("TotalDebts/buyersMortages");
    obj.totalBuyingsMoney = res6.data;
    const res4 = await axios.get("TotalDebts/clientsMortages");
    obj.totalDepositsMoney = res4.data;
    const res3 = await axios.get("TotalDebts/trucksInvoices");
    obj.totalExpensesMoney = res3.data;

    const res2 = await axios.get("TotalDebts/clientsLoans");
    obj.totalDamagesMoney = res2.data;

    setGeneralProfits([obj]);

    setTotal(
      obj.sellingsCount +
      obj.totalDamagesMoney +
      obj.totalSellingsMoney -
      obj.totalExpensesMoney
    );
  };
  useEffect(() => {
    getGeneralProfits();
  }, []);
  const headCells = [
    "فواتير مبيع",
    "سلف بائع",
    "رهن بائعين",
    "رهن المشتريات",
    "فواتير نقلات",
    "عملات مقدمة",
  ];
  return (
    <div className={classes.root}>
      <CardContent className="headerSearch w-50 m-auto mt-3" style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h6"> تقارير مديونية</Typography>
      </CardContent>
      <div style={{ marginTop: 8 }}>
        <ProfitsTable
          removeNegative={true}
          headCells={headCells}
          profits={generalProfits}
          type="general"
        />
      </div>
      <div style={{
          marginTop: "10rem",
          backgroundColor: "white",
          borderRadius: "10px",
          height: "10rem",
          margin: "auto",
          textAlign: "center",
          fontSize: "2rem"
        }} className='col-8 col-md-6'>
        <p className={classes.totHead}>تصفية مديونية</p>
        <div >
          {total !== 0 ? ConvertToArabicNumbers(Math.round(Math.abs(total))) : "صفر"}{'    '}
          {total != 0 && (total > 0 ? 'دائن' : "مدين")}
        </div>
      </div>
    </div>
  );
}

export default Reports;

import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { ConvertToArabicNumbers, renderDate } from "../../../utils";
import { activeInvoice, freezeInvoice } from "../invoiceSlice";

import { Link } from "react-router-dom";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  loadInfo: {
    background: "#f3c637 !important",
    color: "black ",
    boxShadow:
      "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
    height: "38px",
    padding: "15px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
}));

function TruckDetails({ truck, invoice }) {
  console.log("🚀 ~ TruckDetails ~ invoice:", truck);
  const [checked, setChecked] = React.useState(false);

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (invoice?.freezed) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [invoice]);

  const handleChange = () => {
    if (invoice?.freezed) {
      dispatch(activeInvoice(invoice.id)).then(() => {
        setChecked(false);
      });
    } else {
      dispatch(freezeInvoice(invoice.id)).then((res) => {
        setChecked(true);
      });
    }
  };

  const classes = useStyles();
  if (!(truck && truck.farmer && truck.farmer.name)) {
    return null;
  } else
    return (
      <Card className="headerSearch my-2 p-0">
        <CardContent style={{ overflowX: "auto" }} className="py-3">
          <Grid container spacing={1} className="center">
            <Grid item>
              <Typography className={classes.loadInfo}>
                {" "}
                اسم العميل: {truck.farmer.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.loadInfo}>
                نوع النقلة:{" "}
                {Boolean(truck.truckType) && truck.truckType === 1
                  ? "عمولة"
                  : "مشتريات"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.loadInfo}>
                تاريخ النقلة:{" "}
                {ConvertToArabicNumbers(renderDate(truck.truckDate))}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.loadInfo}>
                رقم النقلة:{" "}
                {ConvertToArabicNumbers(truck.truckIdForThisDayAndClient)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.loadInfo}>
                عدد الاصناف:{" "}
                {truck.itemsCount > 0
                  ? ConvertToArabicNumbers(truck.itemsCount)
                  : "صفر"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.loadInfo}>
                العدد:{" "}
                {truck.innerItemsCount > 0
                  ? ConvertToArabicNumbers(truck.innerItemsCount)
                  : "صفر"}
              </Typography>
            </Grid>
            {truck.truckType !== 2 && (
              <Grid item>
                <Typography className={classes.loadInfo}>
                  نسخ مطبوعة:{" "}
                  {invoice.printingNumber > 0
                    ? ConvertToArabicNumbers(invoice.printingNumber)
                    : "صفر"}
                </Typography>
              </Grid>
            )}
            <Grid item>
              {truck && truck.truckType === 2 && (
                <Typography className={classes.loadInfo}>
                  الوزن:{" "}
                  {truck.totalWeight
                    ? ConvertToArabicNumbers(truck.totalWeight)
                    : "صفر"}
                </Typography>
              )}
            </Grid>
            <Grid item>
              {truck && truck.truckType === 2 && (
                <Typography className={classes.loadInfo}>
                  القيمة:{" "}
                  {truck.totalCostPrice
                    ? ConvertToArabicNumbers(truck.totalCostPrice)
                    : "صفر"}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <Button
                component={Link}
                color="secondary"
                to={`/loads/${truck.id}`}
                size="small"
                variant="contained"
                className="btnMore px-2 center"
                style={{
                  height: 42,
                }}
              >
                ترحيل النقلة
              </Button>
            </Grid>
            <Grid item xs={6} md={2}>
              {invoice && invoice.id && (
                <FormControlLabel
                  style={{ marginRight: 2 }}
                  control={
                    <Checkbox checked={checked} onChange={handleChange} />
                  }
                  label={`${invoice?.freezed ? "مجمدة" : "غير مجمدة"} `}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
}

export default TruckDetails;

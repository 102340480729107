export const renderDate = (date) => {
  if (!!date && !!date.length & (date[date.length - 1] === "Z"))
    date = date.substring(0, date.length - 1);
  let current_datetime = new Date(date);
  return (
    current_datetime.getDate() +
    " / " +
    (current_datetime.getMonth() + 1) +
    " / " +
    current_datetime.getFullYear()
  );
};

export const getWeekDay = (d) => {
  var weekday = new Array(7);
  weekday[0] = "الاحد";
  weekday[1] = "الاثنين";
  weekday[2] = "الثلاثاء";
  weekday[3] = "الاربعاء";
  weekday[4] = "الخميس";
  weekday[5] = "الجمعة";
  weekday[6] = "السبت";

  if (d) {
    return weekday[new Date(d).getDay()];
  } else {
    return null;
  }
};

export const indexOfId = (array, id) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === id) return i;
  }
  return -1;
};

export const getDateDashed = (date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${
    date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
  }`;
};

export const ConvertToArabicNumbers = (num) => {
  const arabicNumbers =
   // "0123456789";
    "\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669";

  if (num) {
    return num.toString().replace(/[0123456789]/g, (d) => {
      return arabicNumbers[d];
    });
  } else {
    return "";
  }
};
export const sortAlphabetically = (items) => {
  if (items && items.length > 0) {
    return items.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }
};
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import jwtDecode from "jwt-decode";
import Alerto from "../../components/UI/toaster";

const tokenKey = "token";

export const login = createAsyncThunk(
  "auth/login",
  async ({ phone, password, base }, thunkAPI) => {
    try {
      const response = await axios.post(`${base ? base : ""}Security/login`, {
        phone,
        password,
      });
      if (response.status === 200) {
        localStorage.setItem(tokenKey, response.data);
        window.location = "/";
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      Alerto(error);
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  }
);

export const logOut = createAsyncThunk(
  "auth/logOut",
  async (_, { getState, rejectWithValue }) => {
    try {
      const userId = getState().auth.user.UserId;
      localStorage.removeItem(tokenKey);
      localStorage.removeItem("chosedOwl");
      localStorage.removeItem("baseUrl");
      window.location = "/login";
      await axios.post(`/Security/logOut?userId=${userId}`);
    } catch (error) {
      Alerto(error);
      if (error.response) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
export const GetWebDate = createAsyncThunk(
  "auth/GetWebDate",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get("owlstime/datetime");

      let d = new Date(res.data);
      d.setHours(0, 0, 0, 0);
      return d;
    } catch (error) {
      Alerto(error);
      if (error.response) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  "auth/getCurrentUser",
  async () => {
    if (localStorage.getItem(tokenKey)) {
      const jwt = localStorage.getItem(tokenKey);
      return jwtDecode(jwt);
    }
  }
);
export const getAppState = createAsyncThunk(
  "AppSetting/mode",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get("AppSetting/mode");
      return res.data;
    } catch (error) {
      Alerto(error);
    }
  }
);
export const getClaims = createAsyncThunk(
  "AppSetting/mode",
  async (_, { getState }) => {
    try {
      const userId = getState().auth.user.UserId;
      const res = await axios.get(`/Security/claims?userId=${userId}`);
      return res.data;
    } catch (error) {
      Alerto(error);
    }
  }
);

const initialState = {
  isLocal: false,
  user: "",
  claims: [],
  loginStatus: "idle",
  subs: { state: true, bar: false, text: "" },
  webDate: null,
  loginError: "",
  token: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    handleSub: (state, { payload }) => {
      state.subs = payload;
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loginStatus = "loading";
    },
    [login.fulfilled]: (state) => {
      state.loginStatus = "succeeded";
    },
    [login.rejected]: (state, action) => {
      state.loginStatus = "failed";
      state.loginError = action.payload;
    },
    [getClaims.fulfilled]: (state, action) => {
      state.claims = action.payload;
    },
    [logOut.fulfilled]: (state) => {
      state.user = null;
    },
    [getCurrentUser.fulfilled]: (state, action) => {
      if (action.payload) {
        state.user = action.payload;
      }
    },
    [getAppState.fulfilled]: (state, action) => {
      if (action.payload) {
        state.isLocal = action.payload.mode == 1 ? false : true;
      }
    },
    [GetWebDate.fulfilled]: (state, action) => {
      if (action.payload) {
        state.webDate = action.payload;
      }
    },
  },
});
export const { handleSub } = authSlice.actions;
export default authSlice.reducer;

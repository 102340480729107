import { useState } from "react";
import { dynamicReq } from "../../../../components/Helpers/ajax";
import Mydate from "../../../../components/Helpers/MyDateParser";
const api = "MovementTools/importing/addOrUpdate";
export default function useRemainingDetails({ onClose, partnerId }) {
  const [inpVs, setInpVs] = useState({});
  function clearState() {
    setInpVs({});
  }
  function submitChanges() {
    const payload = [];
    Object.keys(inpVs).forEach((key) => {
      const ele = inpVs[key];
      !!ele?.count &&
        payload.push({
          partnerId: partnerId,
          dateTime: Mydate(new Date()),
          id: 0,
          count: ele.count,
          toolId: ele.toolId,
        });
    });
    dynamicReq({
      data: { operations: payload },
      type: "post",
      msg: "تمت الاضافة",
      api,
    });
    onClose();
    clearState();
  }
  return {
    inpVs,
    setInpVs,
    submitChanges,
  };
}

import { Button, DialogContentText } from '@material-ui/core';

import CustomDialog from './CustomDialog';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteOutline';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  delBtn: {
    backgroundColor: theme.palette.error.main,
    width: 80,
    marginLeft: 16,
    height: 40,
  },

  deleteIcon: {
    color: theme.palette.error.light,
    fontSize: 80,
    marginBottom: 24,
  },
}));

function DeleteBox({ open, onClose, message, action }) {
  const classes = useStyles();
  return (
    <CustomDialog
      open={open}
      title="هل انت متأكد؟"
      textAlign="center"
      onClose={onClose}
      action1={
        <Button
          onClick={onClose}
          variant="text"
          size="small"
          style={{ height: 40 }}
          className='globalFont'
        >
          الغاء
        </Button>
      }
      action2={
        <Button
          variant="contained"
          className='globalFont btnDelete'

          onClick={action}
          size="small"
        >
          حذف
        </Button>
      }
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <DeleteForeverOutlinedIcon className={classes.deleteIcon} />
      </div>
      <DialogContentText>{message}</DialogContentText>
    </CustomDialog>
  );
}

export default DeleteBox;

import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { ConvertToArabicNumbers } from '../../../utils';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import Alerto from '../../../components/UI/toaster';
import { Row } from 'react-bootstrap';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function CommissionTruck({ invoiceId }) {
  const [invoiceSummery, setInvoiceSummery] = useState('');

  const getCommisionInvoice = () => {
    axios
      .get(`/Truck/Summerize/commision?truckId=${invoiceId}`)
      .then((res) => {
        setInvoiceSummery(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (invoiceId) {
        getCommisionInvoice();
      }
    }
    return () => {
      mounted = false;
    };
  }, [invoiceId]);

  return (
    <div>
      {invoiceSummery && (
        <Row xs={12} className='m-auto mt-3 text-center p-0'>
          <TableContainer className="p-0 m-0" component={Paper} >
            <Table  aria-label="simple table">
              <TableHead style={{ background: '#FED049', height: '65px' }}>
                <TableRow>
                  <TableCell align="center" className='headerTableFont'>عدد  الاصناف</TableCell>
                  <TableCell align="center" className='headerTableFont'> مصروفات النقلة  </TableCell>
                  <TableCell align="center" className='headerTableFont'> قائم الفاتورة</TableCell>
                  <TableCell align="center" className='headerTableFont'> خصومات الفاتورة</TableCell>
                  <TableCell align="center" className='headerTableFont'> عمولة الفاتورة</TableCell>
                  <TableCell align="center" className='headerTableFont'>  صافي الفاتورة</TableCell>
                  <TableCell align="center" className='headerTableFont'>  نسخه مطبوعه</TableCell>
                  <TableCell align="center" className='headerTableFont'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                  <TableCell align="center" className='BodyTableFont'> {invoiceSummery.uniqueProductCount > 0 ? ConvertToArabicNumbers(invoiceSummery.uniqueProductCount) : "غير متوفر"}</TableCell>
                  <TableCell align="center" className='BodyTableFont'> {invoiceSummery.totalExpenses !== 0 ? ConvertToArabicNumbers(Math.round(invoiceSummery.totalExpenses)) : "غير متوفر"}</TableCell>
                  <TableCell align="center" className='BodyTableFont'> {invoiceSummery.totalMoneyOfIncoiceItems !== 0 ? ConvertToArabicNumbers(Math.round(invoiceSummery.totalMoneyOfIncoiceItems)) : "غير متوفر"}</TableCell>
                  <TableCell align="center" className='BodyTableFont'>   {invoiceSummery.totalDiscountsExcludedCommisions !== 0 ? ConvertToArabicNumbers(Math.round(invoiceSummery.totalDiscountsExcludedCommisions)) : "صفر"}</TableCell>
                  <TableCell align="center" className='BodyTableFont'> {invoiceSummery.totalCommisions !== 0 ? ConvertToArabicNumbers(invoiceSummery.totalCommisions.toFixed(2)) : "غير متوفر"}</TableCell>
                  <TableCell align="center" className='BodyTableFont'> {invoiceSummery.invoiceValue !== 0 ? ConvertToArabicNumbers(invoiceSummery.invoiceValue.toFixed(2)) : "غير متوفر"}</TableCell>
                  <TableCell align="center" className='BodyTableFont'>  {invoiceSummery.invoice && invoiceSummery.invoice.printingCount > 0 ? ConvertToArabicNumbers(invoiceSummery.invoice.printingCount) : "صفر"}</TableCell>

                  <TableCell align="center" className=' btnMore headerTableFont'>
                    <Link
                      to={`/invoice/${invoiceId}`} style={{ color: 'white' }}>
                      تعديل الفاتورة
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Row>)
      }
    </div>
  );
}

export default CommissionTruck;

import { parseISO } from "date-fns";
import React, { Fragment, useEffect } from "react";
import { ConvertToArabicNumbers } from "../../../utils";
import PaginationArrows from "./PaginationArrows";
import cls from "./TruckHistoryTable.module.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
const options = {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
};
const ToolsTable = (props) => {
  const Modal = (props) => {
    return (
      <div onClick={props.close} className={props.class} style={{ zIndex: 3 }}>
        <TableContainer overflowX="auto" style={{ overflowY: "hidden" }}>
          <Table>
            <TableHead>
              <TableRow style={{ background: "#8c4312" }}>
                <TableCell align="center" colSpan={12} className="text-white">
                  {props.title}
                </TableCell>
              </TableRow>
              <TableRow style={{ background: "#F3C637" }}>
                {props.head.map((ele) => (
                  <TableCell
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {ele}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.slice(pageNum * 20 - 20, pageNum * 20).map((el) => (
                <TableRow
                  style={{ background: "#fff" }}
                  onClick={() => props.fetch(el.id, el.periodStart)}
                >
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {el.partnerName}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 180px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {ConvertToArabicNumbers(
                      parseISO(el.dateTime).getMonth() +
                        1 +
                        "/" +
                        parseISO(el.dateTime).getDate()
                    )}
                    {"  الساعة  "}
                    {ConvertToArabicNumbers(
                      parseISO(el.dateTime).toLocaleString("en-US", options)
                    )}
                  </TableCell>
                  {el.toolName && (
                    <TableCell
                      align="center"
                      style={{
                        minWidth: " 100px",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      {el.toolName}
                    </TableCell>
                  )}

                  {el.toolCount && (
                    <TableCell
                      align="center"
                      style={{
                        minWidth: " 100px",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      {ConvertToArabicNumbers(el.toolCount)}
                    </TableCell>
                  )}
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {el.recorderName}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {ConvertToArabicNumbers(
                      parseISO(el.periodStart).getMonth() +
                        1 +
                        "/" +
                        parseISO(el.periodStart).getDate()
                    )}
                    {"  الساعة  "}
                    {ConvertToArabicNumbers(
                      parseISO(el.periodStart).toLocaleString("en-US", options)
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {" "}
                    {el.processTypeEnum == 1
                      ? "أضافة"
                      : el.processTypeEnum == 3
                      ? "حذف"
                      : "تعديل"}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow style={{ background: "#ff0" }}>
                <TableCell align="center" colSpan={12} className="p-0">
                  {!props.close && (
                    <PaginationArrows
                      pageNum={pageNum}
                      onPageUp={onPageUp}
                      onPageDown={onPageDown}
                      availabe={data[pageNum * 20 + 1]}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };
  const [pageNum, setPageNum] = React.useState(1);
  const [data, setData] = React.useState([]);
  const onPageUp = () => {
    setPageNum(pageNum + 1);
  };
  const onPageDown = () => {
    setPageNum(pageNum - 1);
  };
  useEffect(() => {
    setPageNum(1);
    setData(props.data.filter((ele) => ele.partnerName.includes(props.search)));
  }, [props]);
  return (
    <Fragment>
      <Modal
        title={props.title}
        head={props.head}
        data={data}
        class={props.class}
        fetch={props.getHandler}
      />
      {props.GetData[0] && (
        <Modal
          title={props.title}
          search={props.search}
          head={props.head}
          data={props.GetData}
          class={cls.modalnameTable}
          close={props.close}
        />
      )}
    </Fragment>
  );
};
export default ToolsTable;
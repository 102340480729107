import React from "react";
import axios from "axios";
import Alerto from "../../components/UI/toaster";
import { Avatar } from "@material-ui/core";

function BannerImg(props) {
  const [banner, setBanner] = React.useState("");

  const getBanner = () => {
    axios
      .get("/Setting/indexImage")
      .then((res) => {
        setBanner(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      getBanner();
    }
    return () => {
      mounted = false;
    };
  }, []);

  return !!banner ? (
    <img
      style={{ width: "100%", height: "auto", boxShadow: " white 0px 0px 7px" }}
      src={`data:image/jpeg;base64,${banner}`}
      className="rounded-3"
      alt="صورة الشركة"
      {...props}
    />
  ) : (
    <Avatar className="avatar" />
  );
}

export default BannerImg;

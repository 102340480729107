import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Alerto from "../../components/UI/toaster";
import cls from "./Security.module.scss";
const Security = () => {
  const [key, setkey] = useState("");
  const [actvcode, setactvcode] = useState("");
  const history = useHistory();
  useEffect(async () => {
    try {
      const res = await axios.get("License/GetClientActivationKey");
      if (res.status === 200) {
        setkey(res.data);
      }
    } catch (e) {
      Alerto(e);
    }
  });
  const pastehandler = async () => {
    const code = await navigator.clipboard.readText();
    setactvcode(code);
  };
  const activhandler = async () => {
    try {
      const res = await axios.post("/License/Activate", {
        secretKey: actvcode,
      });
      if (res.status === 200) {
        localStorage.setItem("license", true);
        localStorage.removeItem("block");
        localStorage.removeItem("blockcount");
        history.replace("/login");
        toast.success("تم التفعيل");
      }
    } catch (e) {
      localStorage.removeItem("license");
      localStorage.removeItem("token");
      let block = localStorage.getItem("blockcount");
      if (block && block >= 1) {
        localStorage.setItem("block", true);
        history.replace("./block");
        return;
      }
      if (block) {
        block++;
        localStorage.setItem("blockcount", block);
      } else {
        localStorage.setItem("blockcount", 0);
      }
      Alerto(e);
    }
  };
  
  return (
    <div className={cls.main}>
      <h2>Security</h2>
      <div className={cls.key}>
        <p>{key}</p>
        <button
          onClick={() => {
            navigator.clipboard.writeText(key);
            toast.success("تم النسخ ");
          }}
        >
          أنسخ
        </button>
      </div>
      <div className={cls.actvholder}>
        <input value={actvcode} placeholder="ضع كود التفعيل هنا" />
        <button onClick={pastehandler}>لصق</button>
        <button onClick={activhandler} className={cls.actvbtn}>
          تفعيل
        </button>
      </div>
    </div>
  );
};
export default Security;

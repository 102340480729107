import { IconButton, Paper, TableSortLabel, Toolbar } from '@material-ui/core';
import {
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';

import DeleteBox from '../../../components/DeleteBox';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SearchIcon from '@material-ui/icons/Search';
import UpdateAddress from './UpdateAddress';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '8px 4px',
    border: '1px solid #222',
  },
  tableRow: {
    marginTop: '8px',
  },
  tableCell: {
    marginTop: '8px',
    border: '1px solid #222',
    padding: 4,
  },
  searchBar: {
    backgroundColor: '#fff',
    borderRadius: 5,
  },
  trash: {
    color: theme.palette.error.light,
  },
  edit: {
    color: theme.palette.info.light,
  },
  '@media print': {
    total: {
      display: 'flex',
      width: 400,
      justifyContent: 'space-between',
      margin: 8,
    },
    printIcon: {
      display: 'none',
    },
    printHidden: {
      display: 'none',
    },
  },
}));

export default function CollectingTable({ data, handleUpdate, handleDelete }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selectedItem, setSelectedItem] = useState({});
  const [deletedItem, setDeletedItem] = useState('');
  const classes = useStyles();

  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    { label: 'خط التحصيل', id: 'name' },
    { label: 'تعديل' },
    { label: 'حذف' },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead style={{ backgroundColor: '#F3C637' }} >
        <TableRow>
          {headCells.map((headCell, index) => (
            <TableCell
              style={{ fontWeight: 'bold' }}
              key={index}
              align="center"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.id ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [searchQuery, setSearchQuery] = React.useState('');
  return (
    <>
        <Toolbar
          style={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#f8f8f8',
          }}
        >
          <TextField
            placeholder="بحث..."
            className={classes.searchBar + ' ' + 'w-50'}
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Toolbar>
      <TableContainer style={{ width: '100%', marginTop: 12 }} component={Paper}>
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={15}
          />
          <TableBody className={classes.tableBody}>
            {stableSort(data, getComparator(order, orderBy))
              .filter((item) => item.name && item.name.includes(searchQuery))
              .map((item, index) => (
                <TableRow key={item.id} className={classes.tableBody}>
                  <TableCell align="center">{item.name}</TableCell>
                  <TableCell align="center" style={{ width: '32px' }}>
                    <IconButton
                      className={classes.edit}
                      onClick={() => {
                        setOpenUpdate(true);
                        setSelectedItem(item);
                      }}
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center" style={{ width: '32px' }}>
                    <IconButton
                      className={classes.trash}
                      onClick={() => {
                        setDeletedItem(item);
                        setOpenDelete(true);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <UpdateAddress
          open={openUpdate}
          onClose={() => setOpenUpdate(false)}
          address={selectedItem}
          handleUpdate={handleUpdate}
        />
        <DeleteBox
          action={() => {
            handleDelete(deletedItem);
            setOpenDelete(false);
          }}
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          message={`هل تريد حذف (${deletedItem.name})`}
        />
      </TableContainer>
    </>
  );
}

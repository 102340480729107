import cls from "./Brief.module.scss";
const VideoComp = ({ ele }) => {
  return (
    <div className={cls.videoComp}>
      <iframe
        width="400"
        height="225"
        src={ele.url}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    </div>
  );
};
export default VideoComp;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDateDashed, indexOfId } from "../../utils";

import axios from "axios";
import { toast } from "react-toastify";
import Alert from "../../components/UI/toaster";
import Alerto from "../../components/UI/toaster";

export const addDeposit = createAsyncThunk(
  "treasury/addDeposit",
  async (data, { rejectWithValue, getState }) => {
    try {
      const response = await axios.post(`MoneyAndToolImporting`, data);
      if (response.status === 200) {
        toast.success("تم اضافة التحصيل بنجاح");
        return response.data;
      }
    } catch (error) {
      Alerto(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateDeposits = createAsyncThunk(
  "treasury/updateDeposits",
  async (
    { id, partner, depositDate, price, allow, note, depostCategoryId },
    { rejectWithValue, getState }
  ) => {
    try {
      const response = await axios.put(
        `/Deposits/${id}`,
        {
          treasuryTypeId:depostCategoryId,
          partnerId: partner.id,
          depositDate: depositDate,
          price,
          allow,
          note,
          adderId: getState().auth.user.UserId,
        }
      );
    
        toast.success("تم التعديل");

        return response.data;
      
    } catch (error) {
      Alerto(error);
      rejectWithValue(error.response.data);
    }
  }
);

export const deleteDeposits = createAsyncThunk(
  "treasury/deleteDeposits",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/Deposits?id=${id}`);
      if (response.status === 200) {
        toast.error("تم حذف التحصيل ");
      }
      return response.data;
    } catch (error) {
      Alerto(error);
      return error.response.data;
    }
  }
);

export const getDepositCategories = createAsyncThunk(
  "treasury/getDepositCategories",
  async () => {
    try {
      const response = await axios.get("/Deposits/categories");
      return response.data;
    } catch (error) {
      Alert(error);
      toast.error(error.response.data);
      return error.response.data;
    }
  }
);

export const getAllDeposits = createAsyncThunk(
  "treasury/getAllDeposits",
  async (date) => {
    try {
      const response = await axios.get(`/Deposits?date=${date}`);
      return response.data;
    } catch (error) {}
  }
);

//=========================== SELLERS =========================

export const getForwardSellers = createAsyncThunk(
  "treasury/getForwardSellers",
  async (date) => {
    try {
      const response = await axios.get(
        `/Deposits/GetReportSellerLong?date=${getDateDashed(date)}`
      );
      return response.data;
    } catch (error) {}
  }
);

export const getCashSellers = createAsyncThunk(
  "treasury/getCashSellers",
  async (date) => {
    try {
      const response = await axios.get(
        `/Deposits/GetReportSellerCash?date=${getDateDashed(date)}`
      );
      return response.data;
    } catch (error) {}
  }
);

export const getSupplingSellers = createAsyncThunk(
  "treasury/getSupplingSellers",
  async (date) => {
    try {
      const response = await axios.get(
        `/Deposits/GetReportSellerSupply?date=${getDateDashed(date)}`
      );
      return response.data;
    } catch (error) {}
  }
);

export const getSellersCredits = createAsyncThunk(
  "treasury/getSellersCredits",
  async (date) => {
    try {
      const response = await axios.get(
        `/Deposits/GetReportSellerCreditory?date=${getDateDashed(date)}`
      );
      return response.data;
    } catch (error) {}
  }
);

export const getSellersToolsMortage = createAsyncThunk(
  "treasury/getSellersToolsMortage",
  async (date) => {
    try {
      const response = await axios.get(
        `/Deposits/GetReportSellerMortgage?date=${getDateDashed(date)}`
      );
      return response.data;
    } catch (error) {}
  }
);

//=========================== CLIENTS =========================

export const getClientsBills = createAsyncThunk(
  "treasury/getClientsBills",
  async (date) => {
    try {
      const response = await axios.get(
        `/Deposits/GetReportCustomerBills?date=${getDateDashed(date)}`
      );
      return response.data;
    } catch (error) {}
  }
);

export const getClientsAdvancePayment = createAsyncThunk(
  "treasury/getClientsAdvancePayment",
  async (date) => {
    try {
      const response = await axios.get(
        `/Deposits/GetReportCustomerCurrency?date=${getDateDashed(date)}`
      );
      return response.data;
    } catch (error) {}
  }
);

export const getClientsPurchasesMortage = createAsyncThunk(
  "treasury/getClientsPurchasesMortage",
  async (date) => {
    try {
      const response = await axios.get(
        `/Deposits/GetReportCustomerpurchase?date=${getDateDashed(date)}`
      );
      return response.data;
    } catch (error) {}
  }
);

//=========================== EMPLOYEES =========================

export const getEmployeesCredits = createAsyncThunk(
  "treasury/getEmployeesCredits",
  async (date) => {
    try {
      const response = await axios.get(
        `/Deposits/GetReportEmployeeCreditory?date=${getDateDashed(date)}`
      );
      return response.data;
    } catch (error) {}
  }
);

export const getEmployeesBills = createAsyncThunk(
  "treasury/getEmployeesBills",
  async (date) => {
    try {
      const response = await axios.get(
        `/Deposits/GetReportEmployeeBills?date=${getDateDashed(date)}`
      );
      return response.data;
    } catch (error) {}
  }
);

const initialState = {
  deposits: [],
  depositCategories: [],
  allDeposits: [],
  forwardSellers: [],
  cashSellers: [],
  supplingSellers: [],
  sellersCredits: [],
  sellersToolsMortage: [],

  clientsBills: [],
  clientsAdvancePayment: [],
  clientsPurchasesMortage: [],

  employeesCredits: [],
  employeesBills: [],
};

const treasurySlice = createSlice({
  name: "treasury",
  initialState,

  extraReducers: {
    [addDeposit.pending]: (state, action) => {
      state.deposits.unshift(action.meta.arg);
    },

    [addDeposit.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        state.deposits[0].id = action.payload.id;
      }
    },
    [addDeposit.rejected]: (state) => {
      state.deposits.shift();
    },

    [updateDeposits.fulfilled]: (state, action) => {
      if (action.payload) {
        const index = indexOfId(state.deposits, action.meta.arg.id);
        state.deposits[index] = action.meta.arg;
      }
    },

    [deleteDeposits.fulfilled]: (state, action) => {
      state.deposits = state.deposits.filter((deposit) => {
        return deposit.id !== action.meta.arg;
      });
    },

    [getDepositCategories.fulfilled]: (state, action) => {
      state.depositCategories = action.payload;
    },

    [getAllDeposits.fulfilled]: (state, action) => {
      state.allDeposits = action.payload;
    },

    [getForwardSellers.fulfilled]: (state, action) => {
      state.forwardSellers = action.payload;
    },

    [getCashSellers.fulfilled]: (state, action) => {
      state.cashSellers = action.payload;
    },

    [getSupplingSellers.fulfilled]: (state, action) => {
      state.supplingSellers = action.payload;
    },

    [getSellersCredits.fulfilled]: (state, action) => {
      state.sellersCredits = action.payload;
    },

    [getSellersToolsMortage.fulfilled]: (state, action) => {
      state.sellersToolsMortage = action.payload;
    },

    [getClientsBills.fulfilled]: (state, action) => {
      state.clientsBills = action.payload;
    },

    [getClientsAdvancePayment.fulfilled]: (state, action) => {
      state.clientsAdvancePayment = action.payload;
    },

    [getClientsPurchasesMortage.fulfilled]: (state, action) => {
      state.clientsPurchasesMortage = action.payload;
    },

    [getEmployeesCredits.fulfilled]: (state, action) => {
      state.employeesCredits = action.payload;
    },

    [getEmployeesBills.fulfilled]: (state, action) => {
      state.employeesBills = action.payload;
    },
  },
});

export default treasurySlice.reducer;

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ar from "date-fns/locale/ar-SA";
import { makeStyles } from "@material-ui/core/styles";
import { parseISO } from "date-fns/esm";
import { registerLocale } from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Mydate from "../../../components/Helpers/MyDateParser";
import Alerto from "../../../components/UI/toaster";
import axios from "axios";
import { infoCardHeader } from "../../../components/styles";
import { ConvertToArabicNumbers } from "../../../utils";
import { useParams } from "react-router-dom";
import { InputSorting } from "../../../components/Helpers/InsputSorting";
import { fetchPartners } from "../../main-data/dataSlice";
import { getDepositCategories } from "../treasurySlice";
import { Container } from "react-bootstrap";
import InfoIcon from "@mui/icons-material/Info";

import ToolsInfo from "../../../components/ToolsForm/partials/ToolsInfo";
import { useHistory } from "react-router-dom";
registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
    backgroundColor: "white",
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
  },
  detailArrow: {
    position: "absolute",
    top: 0,
  },
  formContent: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "& > *": {
      marginRight: 12,
    },
  },

  loadInfo: {
    padding: "8px 12px",
    borderRadius: 20,
    backgroundColor: "#EEF66C",
    boxShadow:
      "0 4px 20px  rgba(238, 246, 108, 0.3), 0 -7px -10px -5px rgba(238, 246, 108, 0.09)",
    "&:hover": {
      boxShadow: "0 0 0",
    },
  },
  qty: {
    background: "#f3c637 !important",
    color: "black !important",
    boxShadow:
      "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
    height: "50px",
    padding: "0 20px",
    width: "content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  para: {
    margin: "0 0 0 10px",
  },
  close: {
    position: "absolute",
    top: 16,
    right: 16,
    color: theme.palette.grey[700],
    height: 36,
    width: 36,
    backgroundColor: "rgba(216, 218, 223, 0.5)",
    "&:hover": {
      backgroundColor: "#D8DADF",
    },
  },
}));
const handleMoney = async (partner) => {
  try {
    const remain = await axios.get(
      `MovementTools/reports/Remaining/price/${partner.id}`
    );
    const buyer = await axios.get(
      `MoneyCurrentAccountReport/debtDetails/buyer/${partner.id}`
    );
    const client = await axios.get(
      `MoneyCurrentAccountReport/debtDetails/client/${partner.id}`
    );
    const tool = await axios.get(
      `MovementTools/reports/Remaining/${partner.id}`
    );
    const totalToolsCount =
      tool.data?.length > 0 &&
      tool.data.reduce((acc, val) => {
        return acc + val.count;
      }, 0);
    let obj = {
      remain: remain.data,
      tool: totalToolsCount,
      inAdvance: partner.partnerTypeId == 2 ? null : client.data.totalLoans,
      truckInvoice:
        partner.partnerTypeId == 2 ? null : client.data.totalInvoices,
      totalLoans: partner.partnerTypeId == 1 ? null : buyer.data.totalLoans,
      totalInvoices:
        partner.partnerTypeId == 1 ? null : buyer.data.totalInvoices,
    };

    return obj;
  } catch (e) {
    Alerto(e);
  }
};

function DepositForm({ onSubmit, deposit, isUpdate, onUpdateClicked }) {
  const userId = useSelector((state) => state.auth.user.UserId);
  const [partner, setPartner] = useState("");
  console.log(
    "🚀 ~ file: DepositForm.js:138 ~ DepositForm ~ partner:",
    partner
  );
  const [depositDate, setDepositDate] = useState(new Date());
  const [category, setCategory] = useState("");
  const role = localStorage.getItem("role");
  const [categoryes, setCategoryes] = useState([]);
  const [price, setPrice] = useState("");
  const [allow, setAllow] = useState("");
  const [note, setNote] = useState("");
  const [tool, setTool] = useState({});
  const [tools, setTools] = useState({});
  const [checkDisplayTool, setCheckDisplayTool] = useState(false);
  const [toolCount, setToolCount] = useState("");
  const { push } = useHistory();
  const depositCategories = useSelector(
    (state) => state.treasury.depositCategories
  );

  const { partnerId, factor } = useParams();
  const fetchTools = async () => {
    try {
      const res = await axios.get("MovementTools");
      setTools(res.data);
    } catch (e) {
      Alerto(e);
    }
  };
  useEffect(() => {
    dispatch(fetchPartners());
    dispatch(getDepositCategories());
    fetchTools();
  }, []);
  useEffect(() => {
    let cat = [];
    if (partner.partnerTypeId) {
      if (partner.partnerTypeId === 1 && depositCategories.client) {
        cat = depositCategories.client;
      }
      if (partner.partnerTypeId === 2 && depositCategories.buyer) {
        cat = depositCategories.buyer;
        let target = cat.filter((ele) => ele.name == "نقدية");
        setCategory(target[0]);
      }
      if (partner.partnerTypeId === 3 && depositCategories.clientBuyer) {
        cat = depositCategories.clientBuyer;
        let target = cat.filter((ele) => ele.name == "نقدية");
        setCategory(target[0]);
      }
      if (partner.isEmployee && depositCategories.employee) {
        cat = [...cat, ...depositCategories.employee];
      }
    }
    setCategoryes(cat);
  }, [deposit, partner, depositCategories]);
  const partners = useSelector((state) => state.data.partners);
  React.useEffect(() => {
    if (partnerId && partners.length > 0) {
      const targetPartner = partners.find((p) => {
        return p.id == partnerId;
      });
      if (targetPartner && targetPartner.id) {
        handleMoney(targetPartner).then((res) => {
          setMoney(res);
        });
        setPartner(targetPartner);
        if (categoryes[0]) {
          const target = categoryes.find((ele) => ele.id == factor);
          if (target) {
            setCategory(target);
          }
        }
      }
    }
  }, [partnerId, partners, categoryes]);
  const inpRef = useRef();
  const handleSubmit = () => {
    let data = { moneyDepositDto: null, toolDto: null };
    if (partner) {
      if (category?.id) {
        data.moneyDepositDto = {
          partnerId: partner.id,
          depositDate: Mydate(depositDate),
          price,
          allow: allow > 0 ? allow : 0,
          note,
          treasuryTypeId: category.id,
          depositCategoryName: category.name,
          adderId: userId,
        };
      }
      if (tool?.id) {
        data.toolDto = {
          partnerId: partner.id,
          importingDate: Mydate(depositDate),
          count: toolCount,
          toolId: tool.id,

          adderId: userId,
        };
      }
    }
    onSubmit(data);
    data = { moneyDepositDto: null, toolDto: null };
    setPartner("");
    setDepositDate(new Date());
    setPrice("");
    setAllow("");
    setCategory("");
    setNote("");
    setTool({});
    setToolCount("");
    inpRef.current?.focus();
  };
  React.useEffect(() => {
    if (deposit) {
      setPartner(deposit.partner);
      setDepositDate(parseISO(deposit.depositDate));
      setPrice(deposit.price);
      setAllow(deposit.allow);
      setCategory({
        id: deposit.depostCategoryId,
        name: deposit.depostCategoryName,
      });
      setNote(deposit.note);
    }
  }, [deposit]);
  const [money, setMoney] = useState({});
  const handlPartner = async (value) => {
    try {
      if (value) {
        setPartner(value);
        handleMoney(value).then((res) => {
          setMoney(res);
        });
      } else {
        setPartner("");
        setMoney({});
      }
    } catch (e) {
      Alerto(e);
    }
  };
  const [valid, setValid] = useState(false);
  useEffect(() => {
    let valid =
      (category?.id && price && tool?.id && toolCount) ||
      (category?.id && price && !tool?.id && !toolCount) ||
      (!category?.id && !price && tool?.id && toolCount);
    let v2 = valid && partner?.id;
    setValid(v2);
  }, [partner, category, price, tool, toolCount]);
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div>
      <Container fluid>
        {!isUpdate && (
          <>
            <div style={{ display: "flex" }}>
              <CardHeader className="text-white" title="التحصيل" />
              <FormControlLabel
                style={{
                  color: "#FFF",
                }}
                control={
                  <Checkbox
                    onChange={() => setCheckDisplayTool(!checkDisplayTool)}
                    style={{
                      color: "#FFF",
                    }}
                  />
                }
                label="بدون عدة"
              />

              {partner?.id && <ToolsInfo haveAction partnerId={partner.id} />}
            </div>
            <div
              className={
                // load && load.truckTypeId === 1
                //   ? classes.commissionTruck
                classes.purchasesTruck
              }
            >
              {money && partner?.id && (
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography className={classes.qty}>
                      الرهن :{" "}
                      {ConvertToArabicNumbers(
                        Math.abs(Math.round(money.remain))
                      )}{" "}
                      {Math.round(money.remain) > 0 ? "عليه" : ""}
                      {Math.round(money.remain) < 0 ? "له" : ""}
                      {money.remain === 0 ? "صفر" : ""}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.qty}>
                      عدة :{" "}
                      {ConvertToArabicNumbers(Math.abs(Math.round(money.tool)))}{" "}
                      {Math.round(money.tool) > 0 ? "عليه" : ""}
                      {Math.round(money.tool) < 0 ? "له" : ""}
                      {money.tool === 0 ? "صفر" : ""}
                    </Typography>
                  </Grid>
                  {money.totalLoans != null && (
                    <Grid item>
                      <Typography className={classes.qty}>
                        سلف :{" "}
                        {ConvertToArabicNumbers(
                          Math.abs(Math.round(money.totalLoans))
                        )}{" "}
                        {Math.round(money.totalLoans) > 0 ? "عليه" : ""}
                        {Math.round(money.totalLoans) < 0 ? "له" : ""}
                        {money.totalLoans === 0 ? "صفر" : ""}
                      </Typography>
                    </Grid>
                  )}
                  {money.totalInvoices != null && (
                    <Grid item>
                      <Typography className={classes.qty}>
                        فواتير مبيع:
                        {ConvertToArabicNumbers(
                          Math.abs(Math.round(money.totalInvoices))
                        )}{" "}
                        {Math.round(money.totalInvoices) > 0 ? "عليه" : ""}
                        {Math.round(money.totalInvoices) < 0 ? "له" : ""}
                        {money.totalInvoices === 0 ? "صفر" : ""}
                      </Typography>
                    </Grid>
                  )}
                  {money.inAdvance != null && (
                    <Grid item>
                      <Typography className={classes.qty}>
                        عملة مقدمة:
                        {ConvertToArabicNumbers(
                          Math.abs(Math.round(money.inAdvance))
                        )}{" "}
                        {Math.round(money.inAdvance) > 0 ? "عليه" : ""}
                        {Math.round(money.inAdvance) < 0 ? "له" : ""}
                        {money.inAdvance === 0 ? "صفر" : ""}
                      </Typography>
                    </Grid>
                  )}
                  {money.truckInvoice != null && (
                    <Grid item>
                      <Typography className={classes.qty}>
                        فواتير نقلات:
                        {ConvertToArabicNumbers(
                          Math.abs(Math.round(money.truckInvoice))
                        )}{" "}
                        {Math.round(money.truckInvoice) > 0 ? "عليه" : ""}
                        {Math.round(money.truckInvoice) < 0 ? "له" : ""}
                        {money.truckInvoice === 0 ? "صفر" : ""}
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    onClick={() =>
                      push(
                        partner.partnerTypeId === 1
                          ? `client-profile/${partner.id}`
                          : `seller-profile/${partner.id}`
                      )
                    }
                    item
                  >
                    <Typography className={classes.qty}>حسابات</Typography>
                  </Grid>
                </Grid>
              )}
            </div>
            <Divider />
          </>
        )}
        <CardContent className="headerSearch pt-4 mt-2">
          <Grid item container spacing={1}>
            <Grid item xs={12} md={3}>
              <Autocomplete
                options={partners}
                getOptionSelected={(option, value) => {
                  return option.value === value?.value;
                }}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                filterOptions={(options, state) =>
                  InputSorting(options, state.inputValue)
                }
                style={{ width: "100%" }}
                autoHighlight
                autoComplete
                noOptionsText="لم يتم العثور على خيارات"
                onChange={(event, value) => {
                  handlPartner(value);
                }}
                value={partner}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    onSubmit();
                  }
                }}
                size="small"
                renderInput={(params) => (
                  <TextField
                    autoFocus={!isUpdate && !partnerId}
                    {...params}
                    inputRef={inpRef}
                    label="الاسم"
                    variant="outlined"
                    // onKeyDown={() => alert("a")}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
              {/* <CustomInput
              value={partner}
              setClient={handlPartner}
              options={partners}
              width="15rem"
            /> */}
            </Grid>
            <Grid item className="col-6 col-lg">
              <Autocomplete
                options={categoryes}
                getOptionSelected={(option, value) => {
                  return option?.id === value?.id;
                }}
                getOptionLabel={(option) =>
                  option.name ? option.name.replace(/_/g, " ") : ""
                }
                style={{ width: "100%" }}
                autoHighlight
                autoComplete
                noOptionsText="لم يتم العثور على خيارات"
                onChange={(event, value) => {
                  if (value) {
                    setCategory(value);
                  } else {
                    setCategory("");
                  }
                }}
                value={category}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="الفئة "
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item className="col-6 col-lg">
              <TextField
                value={price >= 0 ? price : ""}
                onChange={(e) => setPrice(Number.parseFloat(e.target.value))}
                style={{ width: "100%" }}
                label="المبلغ"
                variant="outlined"
                size="small"
                type="number"
                autoFocus={!!partnerId && true}
              />
            </Grid>
            {category?.id == 4 && (
              <Grid item className="col-6 col-lg">
                <TextField
                  onChange={(e) => setAllow(Number.parseFloat(e.target.value))}
                  value={allow >= 0 ? allow : ""}
                  style={{ width: "100%" }}
                  label="السماح"
                  variant="outlined"
                  size="small"
                  type="number"
                />
              </Grid>
            )}
            {!checkDisplayTool
              ? !isUpdate && (
                  <>
                    <Grid item className="col-6 col-lg">
                      <Autocomplete
                        options={tools}
                        getOptionSelected={(option, value) => {
                          return option.id === value.id;
                        }}
                        getOptionLabel={(option) =>
                          option.name ? option.name.replace(/_/g, " ") : ""
                        }
                        style={{ width: "100%" }}
                        autoHighlight
                        autoComplete
                        noOptionsText="لم يتم العثور على خيارات"
                        onChange={(event, value) => {
                          if (value) {
                            setTool(value);
                          } else {
                            setTool("");
                          }
                        }}
                        value={tool}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="العدة "
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                              form: {
                                autoComplete: "off",
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item className="col-6 col-lg">
                      <TextField
                        value={toolCount}
                        onChange={(e) =>
                          setToolCount(Number.parseFloat(e.target.value))
                        }
                        style={{ width: "100%" }}
                        label="العدد"
                        variant="outlined"
                        size="small"
                        type="number"
                      />
                    </Grid>
                  </>
                )
              : ""}

            {!isUpdate && (
              <Grid item className="col-6 col-lg-1">
                <Button
                  className="btnMore"
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    transform: "translateY(-4px)",
                  }}
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!valid}
                >
                  اضف
                </Button>
              </Grid>
            )}

            {isUpdate && (
              <Grid item xs={6} md={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableFuture
                    disabled={role == "موظف"}
                    label="التاريخ"
                    openTo="day"
                    views={["year", "month", "day"]}
                    value={depositDate}
                    onChange={(d) => setDepositDate(d)}
                    renderInput={(params) => (
                      <TextField style={{ width: "100%" }} {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            <Grid item className="col-6 col-lg">
              <TextField
                value={note}
                onChange={(e) => setNote(e.target.value)}
                style={{ width: "100%" }}
                label="ملحوظة"
                variant="outlined"
                size="small"
              />
            </Grid>
            {isUpdate && (
              <Grid item className="col-6 col-lg-1">
                <Button
                  className="btnMore"
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    transform: "translateY(-4px)",
                  }}
                  onClick={() => {
                    onUpdateClicked({
                      id: deposit.id,
                      partner,
                      depositDate: Mydate(depositDate),
                      price,
                      allow,
                      note,
                      depostCategoryId: category.id,
                      depostCategoryName: category.name,
                    });
                  }}
                >
                  تعديل
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Container>
    </div>
  );
}

DepositForm.defaultProps = {
  isUpdate: false,
};

export default DepositForm;

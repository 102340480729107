import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import LogoImage from "../../../components/LogoImage";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import Total from "../../../components/UI/Total";
import { useReactToPrint } from "react-to-print";
import ToolsInfo from "../../../components/ToolsForm/partials/ToolsInfo";
import { ConvertToArabicNumbers } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  info: {
    color: theme.palette.grey[400],
  },
  total: {
    display: "none",
    width: '100%'
  },
  logo: { display: "none" },
  "@media print": {
    total: {
      display: "block",
      margin: '8 auto',
      width: '100%'
    },
    printIcon: {
      display: "none",
    },
    printHidden: {
      display: "none",
    },
    logo: { display: "block" },
  },
}));
function GeneralTable({
  items,
  reportName,
  onDetailsClicked,
  hasDetails,
  total,
}) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: reportName,
  });

  const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.textPrimary,
      fontSize: 18,
    },
    body: {
      fontSize: 16,
      overflow: "auto",
    },
  }))(TableCell);

  const classes = useStyles();
  const headCells = ["العدة", "العدد"];
  return (
    <div
      className="w-100"

      ref={componentRef}
    >
      {items && items.length > 0 && (
        <TableContainer component={Paper} style={{ width: '100%' }}>
          <div className={classes.logo}>
            <LogoImage />
          </div>
          <Toolbar
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "rgb(243 198 55)",
            }}
          >
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {reportName}
            </Typography>

            <div className={classes.printIcon}>
              <Tooltip title="طباعة التقرير">
                <IconButton onClick={handlePrint}>
                  <PrintOutlinedIcon className="text-black" />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
          <Table>
            <TableHead>
              <TableRow>

                {headCells.map((item, index) => (
                  <StyledTableCell key={index} align="center" style={{ border: '2px solid' }}
                  >
                    {item}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow
                  key={index}
                  align="center"
                  className="hover-row"
                  style={{ border: '2px solid' }}


                >
                  <StyledTableCell align="center" className="p-1" style={{ border: '2px solid' }}
                  >
                    {item.tool.name}
                  </StyledTableCell>

                  <StyledTableCell align="center" className="p-1" style={{ border: '2px solid' }}
                  >
                    {ConvertToArabicNumbers(item.count)}{' '}
                  </StyledTableCell>
                  {hasDetails && (
                    <StyledTableCell align="center" className="p-1" style={{ border: '2px solid' }}
                    >
                      <ToolsInfo partnerId={item.partner.id} />
                    </StyledTableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className={classes.total + ' ' + 'w-100'}>
            <Total value={total} />
          </div>
        </TableContainer>
      )}
    </div>
  );
}

export default GeneralTable;

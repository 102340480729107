import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDialog from "../../../components/CustomDialog";
import Alerto from "../../../components/UI/toaster";
import Summarize from "../../invoice/components/Summarize";
import {
  getActualSelling,
  getDeductions,
  getInvoice,
  getInvoiceItems,
  getSummary,
  getTruckFullData,
} from "../../invoice/invoiceSlice";
function SummarizeModal({ truck, open, setOpen }) {
  const summaryItems = useSelector((state) => state.invoice.summaryItems);
  const soldItems = useSelector((state) => state.invoice.soldItems.data);
  const invoice = useSelector((state) => state.invoice.invoice);
  const invoiceItems = useSelector((state) => state.invoice.invoiceItems.data);
  const [sortedInvoice, setSortedInvoice] = useState(false);
  const [sortedSold, setSortedSold] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    truck &&
      dispatch(getTruckFullData(truck?.truckId))
        .then(({ payload }) => {
          if (payload && payload.truckType === 2) {
          }
          dispatch(getActualSelling(payload)).then(() => {
            dispatch(getSummary(payload));
            dispatch(getDeductions(truck?.truckId));
          });
          if (payload && payload.truckType === 1) {
            dispatch(getInvoice(truck?.truckId))
              .then(({ payload }) => {
                if (payload && payload.id) {
                  dispatch(getInvoiceItems(payload.id));
                }
              })
              .catch((e) => Alerto(e));
          }
        })
        .catch((e) => Alerto(e));
  }, [truck]);
  useEffect(() => {
    if ((invoiceItems, soldItems)) {
      let arr = [...invoiceItems];
      let arr2 = [...soldItems];

      arr.sort((a, b) =>
        a.item?.product.name > b.item?.product.name
          ? 1
          : b.item?.product.name > a.item?.product.name
          ? -1
          : b.priceForUnitWeight - a.priceForUnitWeight
      );
      arr2.sort((a, b) =>
        a.item?.product.name > b.item?.product.name
          ? 1
          : b.item?.product.name > a.item?.product.name
          ? -1
          : b.price - a.price
      );
      setSortedInvoice(arr);
      setSortedSold(arr2);
    }
  }, [invoiceItems, soldItems]);
  return (
    <CustomDialog
      open={open}
      title={
        soldItems?.length > 0
          ? "بيان اسعار واوزان البيع"
          : "لم يتم بيع اي صنف من النقلة"
      }
      textAlign="center"
      onClose={() => setOpen(false)}
    >
      {soldItems && soldItems.length > 0 && (
        <Summarize
          soldItems={sortedSold}
          openSummarize={open}
          summaryItems={summaryItems}
          invoiceItems={sortedInvoice}
          truck={truck}
          invoice={invoice}
          fromLoads={true}
        />
      )}
    </CustomDialog>
  );
}
export default SummarizeModal
import * as Yup from "yup";

import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchBuyers, fetchClients } from "../main-data/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import "./Register.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Register = () => {
  const user = useSelector((state) => state.auth.user);
  const clients = useSelector((state) => state.data.clients);
  const buyers = useSelector((state) => state.data.buyers);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(fetchClients());
      dispatch(fetchBuyers());
    }
  }, []);
  const history = useHistory();
  const [partner, setPartner] = useState("");
  const [options, setoptions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const registerUser = (values) => {
    axios
      .post("/Security/Register", {
        partnerId: partner.id,
        ...values,
      })
      .then((res) => {
        toast.success(res.data);
        history.push("/login");
      })
      .catch((err) => {
        toast.error(err.response && err.response.data);
      });
  };

  const registerSuperAdmin = (values) => {
    axios
      .post("/Security/superAdmin/Register", { ...values })
      .then((res) => {
        toast.success(res.data);
        history.push("/login");
      })
      .catch((err) => {
        toast.error(err.response);
      });
  };

  const register = (values) => {
    if (partner && partner.id) {
      registerUser(values);
    } else if (!partner.id) {
      registerSuperAdmin(values);
    }
  };

  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(5, "يجب الا تقل كلمة المرور عن 5 ارقام")
        .max(255)
        .required("كلمة المرور اجباري"),
    }),
    onSubmit: (values) => {
      register(values);
    },
  });
  useEffect(() => {
    let arr = [];
    clients[0] ? (arr = [...clients]) : (arr = arr);
    buyers[0]
      ? (arr = [
          ...arr,
          ...buyers.filter((buyer) => {
            return buyer.partnerTypeId !== 3;
          }),
        ])
      : (arr = arr);
    setoptions(arr);
  }, [clients, buyers]);
  return (
    <>
      <Box
        component="main"
        sx={{
          minHeight: "80%",
        }}
        className="mt-5"
      >
        <Container maxWidth="sm">
          <form
            className="regholder"
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
            }}
            onSubmit={formik.handleSubmit}
          >
            <Box sx={{ my: 3 }}>
              <Typography
                color="textPrimary"
                className="my-3 text-center"
                variant="h4"
              >
                انشاء حساب جديد
              </Typography>
            </Box>
            {user && (
              <Autocomplete
                options={options}
                getOptionSelected={(option, value) => {
                  return option.value === value?.value;
                }}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                autoHighlight
                autoComplete
                noOptionsText="لم يتم العثور على خيارات"
                onChange={(event, value) => {
                  if (value) {
                    setPartner(value);
                  } else {
                    setPartner("");
                  }
                }}
                value={partner}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    //onSubmit();
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="الاسم"
                    variant="outlined"
                    helperText="الاسم اجباري"
                    autoComplete="off"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            )}
            {!user && (
              <TextField
                error={Boolean(formik.touched.phone && formik.errors.phone)}
                fullWidth
                helperText={formik.touched.phone && formik.errors.phone}
                label="رقم الهاتف"
                margin="normal"
                name="phone"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.phone}
                variant="outlined"
                autoComplete="off"
              />
            )}
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="كلمة المرور"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.password}
              variant="outlined"
              autoComplete="off"
              style={{ backgroundColor: "#fff" }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ py: 2 }}>
              <Button
                style={{ backgroundColor: "#0C9BBD", color: "#fff" }}
                fullWidth
                size="large"
                variant="contained"
                //disabled={formik.isSubmitting}
                type="submit"
              >
                تسجيل الحساب
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </>
  );
};
export default Register;

import axios from "axios";
import { parseISO } from "date-fns";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import Alerto from "../../../components/UI/toaster";
import { ConvertToArabicNumbers } from "../../../utils";
import PaginationArrows from "./PaginationArrows";
import cls from "./TruckHistoryTable.module.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
const head = [
  "العميل",
  "تاريخ النقلة",
  "رقم النقلة",
  "صنف",
  "عدد",
  "عدة",
  "سعر البيع ",
  "بياعة البيع",
  "المستخدم",
  "تاريخ الاجراء",
  "الاجراء",
];
const options = {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
};
const CommisonTable = (props) => {
  const Modal = (props) => {
    return (
      // <div className={cls.title}> أصناف نقلات عمولة </div>
      <div onClick={props.close} className={props.class} style={{ zIndex: 3 }}>
        <TableContainer overflowX="auto" style={{ overflowY: "hidden" }}>
          <Table className="w-100">
            <TableHead>
              <TableRow style={{ background: "#8c4312" }}>
                <TableCell align="center" colSpan={12} className="text-white">
                  أصناف نقلات عمولة
                </TableCell>
              </TableRow>
              <TableRow style={{ background: "#F3C637" }}>
                {head.map((ele, index) => (
                  <TableCell
                    key={index}
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {ele}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.slice(pageNum * 20 - 20, pageNum * 20).map((el) => (
                <TableRow
                  style={{ background: "#fff" }}
                  onClick={() => props.fetch(el.id, el.periodStart)}
                >
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {el.farmerName}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {ConvertToArabicNumbers(
                      parseISO(el.truckDate).getMonth() +
                        1 +
                        "/" +
                        parseISO(el.truckDate).getDate()
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {el.truckNo}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {el.productName}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {el.itemsCount}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {el.toolName}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {el.mostSellingPrice}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {el.mostCommision}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {el.recorderName}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 170px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {ConvertToArabicNumbers(
                      parseISO(el.periodStart).getMonth() +
                        1 +
                        "/" +
                        parseISO(el.periodStart).getDate()
                    )}
                    {"  الساعة  "}
                    {ConvertToArabicNumbers(
                      parseISO(el.periodStart).toLocaleString("en-US", options)
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {el.processTypeEnum == 1
                      ? "أضافة"
                      : el.processTypeEnum == 3
                      ? "حذف"
                      : "تعديل"}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow style={{ background: "#ff0" }}>
                <TableCell align="center" colSpan={12} className="p-0">
                  {!props.close && (
                    <PaginationArrows
                      pageNum={pageNum}
                      onPageUp={onPageUp}
                      onPageDown={onPageDown}
                      availabe={data[pageNum * 20 + 1]}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };
  const [data, setData] = React.useState([]);
  const [GetData, setGetData] = React.useState([]);

  React.useEffect(() => {
    setPageNum(1);
    setData(
      props.data
        .filter((ele) => ele.farmerName?.includes(props.search))
        .filter((ele) =>
          props.buyer?.length > 0 ? ele.buyerName?.includes(props.buyer) : ele
        )
    );
  }, [props]);
  const getHandler = async (id, date) => {
    try {
      setGetData([]);
      const res = await axios.get(
        `/History/Trucks/CommisionItems/before?entityId=${id}&before=${date.substring(
          0,
          date.length - 5
        )}`
      );
      if (res.data[0]) {
        setGetData(res.data);
      } else {
        toast.error("لا يوجد بيانات لعرضها");
      }
    } catch (e) {
      Alerto(e);
    }
  };
  const [pageNum, setPageNum] = React.useState(1);
  const onPageUp = () => {
    setPageNum(pageNum + 1);
  };
  const onPageDown = () => {
    setPageNum(pageNum - 1);
  };
  return (
    <Fragment>
      <Modal data={data} class={cls.table1} fetch={getHandler} />
      {GetData[0] && (
        <Modal
          data={GetData}
          class={cls.modalnameTable}
          close={() => setGetData([])}
        />
      )}
    </Fragment>
  );
};
export default CommisonTable;
import { Card, CardContent, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import ProfitsTable from "./components/ProfitsTable";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { Container } from "react-bootstrap";
import MainHeader from "../../components/mainHeader/MainHeader";

const months = [
  "يناير",
  "فبراير",
  "مارس",
  "ابريل",
  "مايو",
  "يونيو",
  "يوليو",
  "اغسطس",
  "سبتمبر",
  "اكتوبر",
  "نوفمبر",
  "ديسمبر",
];
const days = [
  "السبت",
  "الاحد",
  "الاثنين",
  "الثلاثاء",
  "الاربعاء",
  "الخميس",
  "الجمعة",
];

const locale = {
  localize: {
    month: (n) => months[n],
    day: (n) => days[n],
  },
  formatLong: {},
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
}));

function DailyProfits() {
  const [dailyDate, setDailyDate] = useState(new Date());
  const [dailyProfits, setDailyProfits] = useState([]);
  const classes = useStyles();

  const getDailyProfits = () => {
    axios
      .get(
        `/profits/Parametrized?year=${dailyDate.getFullYear()}&month=${
          dailyDate.getMonth() + 1
        }`
      )
      .then((res) => {
        setDailyProfits(res.data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getDailyProfits();
    }
    return () => {
      mounted = false;
    };
  }, [dailyDate]);

  const headCells = [
    "اليوم",
    "بياعة البائعين",
    "عمولات النقلات",
    "فرق فواتير العمولة",
    "ربح المشتريات",
    "مشال",
    "مكتب",
    "ايجار عدة",
    "ثلاجة",
    "وارد الربح",
    "سماح",
    "هوالك",
    "خارج الربح",
    "اجمالي الربح",
  ];
  return (
    <div className={classes.root}>
      <div className="my-4">
        <MainHeader title=" الارباح اليومية" />
      </div>
      <CardContent
        className="headerSearch center  col-12 col-md-4 m-auto mt-3"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <DatePicker
          locale={locale}
          selected={dailyDate}
          style={{ with: "50%" }}
          onChange={(date) => {
            setDailyDate(date);
          }}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          showFourColumnMonthYearPicker
          customInput={
            <TextField
              className="m-auto w-100 "
              variant="outlined"
              size="small"
              label="الشهر والسنة"
            />
          }
        />
      </CardContent>
      <div style={{ marginTop: 14, display: "flex", justifyContent: "center" }}>
        <ProfitsTable
          headCells={headCells}
          profits={dailyProfits}
          type="daily"
          dailyDate={dailyDate}
        />
      </div>
    </div>
  );
}

export default DailyProfits;

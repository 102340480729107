import { Paper, Typography } from "@material-ui/core";
import React, { useRef } from "react";

import { ConvertToArabicNumbers } from "../../../utils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  table: {
    borderSpacing: "4px 2px",
    border: "2px solid #000",
    width: "100%",
  },
  tableRow: {
    marginTop: "8px",
  },
  tableCell: {
    border: "2px solid #000",
    padding: 1,
    fontSize: 14
  },
});

export default function TableInvoiceItems({ invoiceItems, isCashInvoice }) {
  const classes = useStyles();

  const tableCells = isCashInvoice
    ? ["الصنف", "العدد", "الوزن", "سعر الوحدة", "اجمالي"]
    : ["الصنف", "العدد", "الوزن", "سعر الوحدة", "اجمالي", "ملحوظات"];
  return (
    <TableContainer
      style={{ marginBottom: 12, width: "100%" }}
      component={Paper}
    >
      <Table className={classes.table}>
        <TableHead>
          <TableRow style={{background:'#f3c637'}} >
            {tableCells.map((item, index) => (
              <TableCell
                className={classes.tableCell}
                key={index}
                align="center"
              >
                <Typography className="center p-1" variant="body2" style={{ fontSize: 14 }}>
                  {" "}
                  {item}{" "}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{ border: "1px solid #777" }}>
          {invoiceItems.map((item) => (
            <TableRow key={item.sellingId} className={classes.tableRow}>
              <TableCell className={classes.tableCell} align="center">
                {item.productName}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {ConvertToArabicNumbers(item.productCount)}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {ConvertToArabicNumbers(item.weight)}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {ConvertToArabicNumbers(item.priceForUnitWeight)}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {ConvertToArabicNumbers(Math.round(item.totalSellingPrice))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

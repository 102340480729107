import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Alerto from '../../components/UI/toaster'
import axios from 'axios';

export const getClientsCollectingMoney = createAsyncThunk(
  'collecting/getClientsCollectingMoney',
  async (address) => {
    try {
      const response = await axios.get(
        `/CollectingMoneyReport/clients?addressId=${address.id}`
      );
      return response.data;
    } catch (error) {
  Alerto(error);    }
  }
);

export const getBuyersCollectingMoney = createAsyncThunk(
  'collecting/getBuyersCollectingMoney',
  async (address) => {
    try {
      const response = await axios.get(
        `/CollectingMoneyReport/buyers?addressId=${address.id}`
      );
      return response.data;
    } catch (error) {
       Alerto(error); 
    }
  }
);

const initialState = {
  printReport: [],
  clientsData: [],
  clientAddresses: [],

  buyersReport: [],
  buyersData: [],
  buyersAddresses: [],
};

const collectingSlice = createSlice({
  name: 'collecting',
  initialState,

  reducers: {
    addClientAddress: (state, action) => {
      state.clientAddresses.push(action.payload);
    },
    addBuyersAddress: (state, action) => {
      state.buyersAddresses.push(action.payload);
    },
  },

  extraReducers: {
    [getClientsCollectingMoney.fulfilled]: (state, action) => {
      if (action.payload && action.payload.length > 0) {
        state.printReport.push(action.payload);
        state.clientsData.push(...action.payload);
      }
    },

    [getBuyersCollectingMoney.fulfilled]: (state, action) => {
      if (action.payload && action.payload.length > 0) {
        state.buyersReport.push(action.payload);
        state.buyersData.push(...action.payload);
      }
    },
  },
});

export const { addClientAddress, addBuyersAddress } = collectingSlice.actions;

export default collectingSlice.reducer;

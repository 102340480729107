import { Tooltip, Typography } from "@material-ui/core";

import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";

import "../../clientAccount.css";
import { Col } from "react-bootstrap";

import HistoryIcon from "@mui/icons-material/History";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ConvertToArabicNumbers, renderDate } from "../../../../utils";

export default function AccountHeader({
  onScrollBottom,
  indexesArr,
  handlePrint,
  partner,
  fromDate,
  toDate,
  reloadhandler,
}) {
  return (
    <div
      id="accountsHeader"
      className="nameAndDate center justify-content-between container fixed top1"
    >
      <div className=" center arrowUPTo ">
        {indexesArr?.length < 2 ? (
          <ArrowBackIosIcon
            onClick={onScrollBottom}
            className="w-75 h-75 ms-3"
            style={{ fontSize: "2px !important" }}
          />
        ) : (
          <PrintOutlinedIcon
            onClick={handlePrint}
            style={{ color: "white", transform: "rotate(-270deg)" }}
          />
        )}
      </div>

      <Col
        xs={6}
        sm={7}
        md={5}
        className="m-auto center justify-content-start p-3 "
      >
        <Typography style={{ fontSize: "23px" }}>{partner?.name}</Typography>
      </Col>

      <Col
        xs={6}
        sm={5}
        md={5}
        className="m-auto center justify-content-start globalFont p-2 "
      >
        من: {ConvertToArabicNumbers(renderDate(fromDate))}
        <br /> الي: {"  "}
        {ConvertToArabicNumbers(renderDate(toDate))}
        <Tooltip title="عرض المزيد">
          <HistoryIcon
            className="mx-3"
            onClick={reloadhandler}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
      </Col>
    </div>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { getDateDashed } from "../../../utils";

export const getRemainingForClients = createAsyncThunk(
  "tools/getRemainingForClients",
  async () => {
    try {
      const response = await axios.get(
        "/MovementTools/reports/Remaining/client"
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getExportingFromClients = createAsyncThunk(
  "tools/getExportingFromClients",
  async (date) => {
    try {
      const response = await axios.get(
        `/MovementTools/reports/Exporting/client/${getDateDashed(date)}`
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getImportingFromClients = createAsyncThunk(
  "tools/getImportingFromClients",
  async (date) => {
    try {
      const response = await axios.get(
        `/MovementTools/reports/importing/client/${getDateDashed(date)}`
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getDamagingsFromClients = createAsyncThunk(
  "tools/getDamagingsFromClients",
  async (date) => {
    try {
      const response = await axios.get(
        `/MovementTools/reports/Damages/client/${getDateDashed(date)}`
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getRemainingForBuyers = createAsyncThunk(
  "tools/getRemainingForBuyers",
  async () => {
    try {
      const response = await axios.get(
        "/MovementTools/reports/Remaining/buyer"
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getExportingFromBuyers = createAsyncThunk(
  "tools/getExportingFromBuyers",
  async (date) => {
    try {
      const response = await axios.get(
        `/MovementTools/reports/Exporting/buyer/${getDateDashed(date)}`
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getImportingFromBuyers = createAsyncThunk(
  "tools/getImportingFromBuyers",
  async (date) => {
    try {
      const response = await axios.get(
        `/MovementTools/reports/importing/buyer/${getDateDashed(date)}`
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getDamagingsFromBuyers = createAsyncThunk(
  "tools/getDamagingsFromBuyers",
  async (date) => {
    try {
      const response = await axios.get(
        `/MovementTools/reports/Damages/buyer/${getDateDashed(date)}`
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getRemainingDetailsForPartners = createAsyncThunk(
  "tools/getRemainingDetailsForPartners",
  async (partnerId) => {
    try {
      const response = await axios.get(
        `/MovementTools/reports/Remaining/${partnerId}`
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getFilledTools = createAsyncThunk(
  "tools/getFilledTools",
  async (date) => {
    try {
      const response = await axios.get("/MovementTools/reports/filled");
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getTreasuryTools = createAsyncThunk(
  "tools/getTreasuryTools",
  async (date) => {
    try {
      const response = await axios.get("/MovementTools/reports/treasury");
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  remainingForClients: [],
  exportingFromClients: [],
  importingFromClients: [],
  damagingsForClients: [],
  remainingForBuyers: [],
  exportingFromBuyers: [],
  importingFromBuyers: [],
  damagingsForBuyers: [],
  remainingDetails: [],
  filledTools: [],
  treasuryTools: [],
};

const toolsSlice = createSlice({
  name: "tools",
  initialState,

  extraReducers: {
    [getRemainingForClients.fulfilled]: (state, action) => {
      state.remainingForClients = action.payload;
    },
    [getExportingFromClients.fulfilled]: (state, action) => {
      state.exportingFromClients = action.payload;
    },

    [getImportingFromClients.fulfilled]: (state, action) => {
      state.importingFromClients = action.payload;
    },

    [getDamagingsFromClients.fulfilled]: (state, action) => {
      state.damagingsForClients = action.payload;
    },
    [getRemainingForBuyers.fulfilled]: (state, action) => {
      state.remainingForBuyers = action.payload;
    },

    [getExportingFromBuyers.fulfilled]: (state, action) => {
      state.exportingFromBuyers = action.payload;
    },

    [getImportingFromBuyers.fulfilled]: (state, action) => {
      state.importingFromBuyers = action.payload;
    },

    [getDamagingsFromBuyers.fulfilled]: (state, action) => {
      state.damagingsForBuyers = action.payload;
    },

    [getRemainingDetailsForPartners.fulfilled]: (state, action) => {
      state.remainingDetails = action.payload?.filter((ele) => ele.count !== 0);
    },

    [getFilledTools.fulfilled]: (state, action) => {
      state.filledTools = action.payload;
    },

    [getTreasuryTools.fulfilled]: (state, action) => {
      state.treasuryTools = action.payload;
    },
  },
});

//export const { addItem } = toolsSlice.actions;

export default toolsSlice.reducer;

import {
  Button,
  CircularProgress,
  Container,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import BannerImg from '../../Home/BannerImg';
import LogoImage from '../../../components/LogoImage';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import Alerto from '../../../components/UI/toaster';

const useStyles = makeStyles((theme) => ({
  fileInput: {
    borderBottom: '4px solid lightgray',
    borderRight: '4px solid lightgray',
    borderTop: '1px solid black',
    borderLeft: '1px solid black',
    padding: 10,
    margin: 15,
    cursor: 'pointer',
  },

  imgPreview: {
    textAlign: 'center',
    margin: '5px 15px',
    height: 'auto',
    maxWidth: '100%',
    width: 300,
    border: '1px dashed #333',
    marginTop: 12,
    overflow: 'none',
  },

  img: {
    width: '100%',
    height: '100%',
  },

  previewText: {
    width: '100%',
    marginTop: 20,
  },

  submitButton: {
    marginLeft: 10,
    height: 40,
    backgroundColor: '#2F9DDD',
    cursor: 'pointer',
    opacity: 0.8,
    '&:hover': {
      opacity: 1,
      backgroundColor: '#2F9DDD',
    },
  },

  centerText: {
    textAlign: 'center',
    width: 500,
  },
}));

function ProfileImg() {
  const [profileImg, setProfileImg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleProfileImageUpload = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const fd = new FormData();
    fd.append("image", profileImg);
    if (profileImg) {
      axios
        .put("/Setting/indexImage", fd)
        .then((res) => {
          toast.success("تم تغيير الصورة بنجاح");
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          Alerto(err)
        });
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.previewComponent}>
      <input
        accept="image/*"
        type="file"
        onChange={(e) => {
          setProfileImg(e.target.files[0]);
        }}
        size="small"
        style={{ margin: 8 }}
      />

      {profileImg ? (
        <Container maxWidth="sm">
          <div className={classes.imgPreview}>
            {profileImg ? (
              <img
                style={{ width: '100%', height: '100%' }}
                alt="preview"
                src={profileImg ? URL.createObjectURL(profileImg) : ''}
              />
            ) : (
              <Typography
                color="textSecondary"
                style={{
                  marginTop: 72,
                }}
              >
                {' '}
                اختار صورة للمعاينة{' '}
              </Typography>
            )}
          </div>
        </Container>
      ) : (
        <BannerImg />
      )}
      <Container maxWidth="xs">
        {profileImg && (
          <Button
            className={classes.submitButton}
            color="inherit"
            type="submit"
            disabled={isLoading}
            onClick={handleProfileImageUpload}
            endIcon={
              isLoading && (
                <CircularProgress size={24} style={{ color: '#DDD' }} />
              )
            }
          >
            تحميل الصورة
          </Button>
        )}
      </Container>
    </div>
  );
}

export default ProfileImg;

import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { ConvertToArabicNumbers, renderDate } from '../../../utils';

import React from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import { parseISO } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },

  label: {
    fontSize: 16,
    display: 'inline',
    color: theme.palette.grey[600],
  },
  loadData: {
    fontSize: 16,
    marginLeft: 8,
    display: 'inline',
  },
}));
function TruckDetails({ truckId }) {
  const [truck, setTruck] = React.useState('');

  const getTruck = () => {
    axios
      .get(`/Truck/${truckId}`)
      .then((res) => {
        if (res.status === 200) {
          setTruck(res.data);
        }
      })
      .catch((err) => {
      
      });
  };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      getTruck();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const classes = useStyles();
  return (
    <div>
      {truck && truck.id && (
        <Card style={{ width: 'auto' }} className='mb-1 '>
          <CardContent className='p-3' >
            <Grid container className='center'>
              <Grid spacing={5} alignItems="center" className='center' container>
                <Grid item>
                  <Typography className={classes.label}>
                    المبيع من نقلة
                  </Typography>
                  <Typography className={classes.loadData}>
                    {truck.farmer.name}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className={classes.label}>رقم النقلة:</Typography>
                  <Typography variant="body1" className={classes.loadData}>
                    {ConvertToArabicNumbers(truck.truckIdForThisDayAndClient)}
                  </Typography>
                </Grid>

                {/* <Grid item>
                  <Typography className={classes.label}>نوع النقلة:</Typography>
                  <Typography className={classes.loadData}>
                    {Boolean(truck.truckTypeId) && truck.truckTypeId === 1
                      ? 'عمولة'
                      : 'مشتروات'}
                  </Typography>
                </Grid> */}

                <Grid item>
                  <Typography className={classes.label}>
                    تاريخ النقلة:
                  </Typography>
                  <Typography className={classes.loadData}>
                    {ConvertToArabicNumbers(
                      renderDate(truck.truckDate)
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </div>
  );
}

export default TruckDetails;

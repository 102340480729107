import styled from "@emotion/styled";

export const SubmitBtn = styled.button`
  padding: 1rem 2rem;
  width: 10.5rem;
  border-radius: 10px;
  background-color: black;
  margin: 1rem auto;
  color: white;
  cursor: pointer;
  display: block;
`;
export const ShowBtn = styled.button`
  padding: 5px 1rem;
  border-radius: 10px;
  background-color: black;
  cursor: pointer;
  color: white;
`;
export const TableHeader = styled.div`
  height: 2rem;
  background-color: #f3c637;
  display: flex;
  justify-content: center;
  alignitems: center;
  font-size: 1.2rem;
  font-weight: 600;
`;

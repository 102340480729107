import { Button, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { InputSorting } from "../../../Helpers/InsputSorting";
import IterationInputs from "../iterationInputs";
import useToolsForm from "./modal";

export default function Form({ onSubmit, isAnonymous, isDamage }) {
  const {
    partners,
    handleTargetpartner,
    handleSubmit,
    tools,
    items,
    inpRef,
    onAddRow,
    removeItem,
    onInputChanged,
    rowData,
  } = useToolsForm({ onSubmit, isAnonymous });
  const disableSubmit =
    (!rowData.count ||
      !rowData.tool?.id ||
      (!rowData.partner?.id && !isAnonymous) ||
      (!rowData.price && !isAnonymous && isDamage)) &&
    !items[0];
  return (
    <div className="row justify-content-between">
      {!isAnonymous && (
        <div className="col-12 col-md-3 mb-3">
          <Autocomplete
            options={partners}
            getOptionSelected={(option, value) => option?.id === value?.id}
            filterOptions={(options, state) =>
              InputSorting(options, state.inputValue)
            }
            getOptionLabel={(option) => (option.name ? option.name : "")}
            className="w-100"
            autoHighlight
            autoComplete
            noOptionsText="لم يتم العثور على خيارات"
            onChange={(event, value) => {
              handleTargetpartner(value);
            }}
            value={rowData.partner}
            size="small"
            disabled={items[0]}
            renderInput={(params) => (
              <TextField
                autoFocus
                {...params}
                label="الاسم "
                className="w-100"
                inputRef={inpRef}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            )}
          />
        </div>
      )}

      <div className="col-12 col-md-7 mb-3">
        {[...items, {}].map((ele, indx) => (
          <IterationInputs
            tools={tools}
            ele={ele}
            rowData={rowData}
            removeItem={() => removeItem(indx)}
            onInputChanged={onInputChanged}
            onAddRow={onAddRow}
            isDamage={isDamage}
            disabled={!!ele.count}
            isAnonymous={isAnonymous}
          />
        ))}
      </div>
      <div className="col-12 col-md-2">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          style={{ height: 40, width: 80 }}
          onClick={handleSubmit}
          disabled={disableSubmit}
        >
          اضف
        </Button>
      </div>
    </div>
  );
}
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@material-ui/core";

import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Draggable from "react-draggable";
import { Paper } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  close: {
    position: "absolute",
    top: 16,
    right: 16,
    color: theme.palette.grey[700],
    height: 36,
    width: 36,

    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#D8DADF",
    },
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomDialog({
  open,
  onClose,
  children,
  title,
  textAlign,
  action1,
  action2,
  maxWidth,
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
      maxWidth={maxWidth ? maxWidth : "lg"}
      aria-labelledby="draggable-dialog-title"
      TransitionComponent={Transition}
      keepMounted
    >
      <div>
        <DialogTitle
          style={{
            cursor: "move",
            textAlign: !!textAlign ? textAlign : "right",
          }}
          id="draggable-dialog-title"
        >
          {title}
        </DialogTitle>
        <DialogContent
          style={{
            minWidth: 300,
            textAlign: !!textAlign ? textAlign : "right",
          }}
        >
          <IconButton
            className={classes.close}
            onClick={onClose}
            style={{ top: "1px", left: "2px" }}
          >
            <CloseRoundedIcon />
          </IconButton>
          {children}
        </DialogContent>
        <DialogActions>
          {action1}
          {action2}
        </DialogActions>
      </div>
    </Dialog>
  );
}
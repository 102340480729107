import { Grid, Typography } from "@material-ui/core";
import {
  dangerCardHeader,
  infoCardHeader,
  successCardHeader,
  warningCardHeader,
} from "../../../components/styles";

import { ConvertToArabicNumbers } from "../../../utils";
import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 16,
    paddingTop: 20,
    width: 1000,
  },
  buyingTruckInfo: {
    color: "#FFF",
    padding: 6,
    borderRadius: 20,
  },
  qty: {
    padding: 6,
    borderRadius: 20,
    paddingRight: 12,
    paddingLeft: 12,
    display: "inline-block",
    fontSize: 16,
    color: "#FFF",
    ...infoCardHeader,
  },
  total: {
    padding: 8,
    marginTop: 10,
    borderRadius: 3,
    display: "inline-block",
    fontSize: 18,
    color: "#FFF",
    ...warningCardHeader,
  },
}));

function InvoiceDetails({
  truck,
  soldItems,
  weightDifference,
  subTotal,
  buyingProfit,
  totalItemsCount,
  totalWeight,
  totalSold,
  invoiceItems,
  invoiceDifference,
}) {
  const classes = useStyles();
  return (
    <div className="text-center center me-auto" style={{ width: "100%", marginBottom: 8 }}>
      <Grid container className="m-auto center" spacing={1}>
        <Grid item>
          <Typography className={classes.qty}>
            العدد المباع:
            {ConvertToArabicNumbers(totalItemsCount.toFixed(1))}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.qty}>
            الوزن المباع:
            {ConvertToArabicNumbers(totalWeight.toFixed(1))}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.qty}>
            قيمة البيع:
            {ConvertToArabicNumbers(totalSold.toFixed(1))}
          </Typography>
        </Grid>
        <Grid item>
          {soldItems && soldItems.length > 0 && (
            <Typography
              className={classes.qty}
              style={
                truck.innerItemsCount - totalItemsCount < 0
                  ? dangerCardHeader
                  : {}
              }
            >
              العدد المتبقي:
              {truck.innerItemsCount === totalItemsCount
                ? "صفر"
                : ConvertToArabicNumbers(
                    Math.abs(truck.innerItemsCount - totalItemsCount)
                  )}
            </Typography>
          )}
        </Grid>
        <Grid item>
          {invoiceItems && invoiceItems.length > 0 && (
            <Typography
              style={weightDifference < 0 ? dangerCardHeader : {}}
              className={classes.qty}
            >
              {weightDifference >= 0 ? "  فرق الوزن : " : " عجز الوزن "}
              {weightDifference === 0
                ? "صفر"
                : ConvertToArabicNumbers(Math.abs(weightDifference.toFixed(1)))}
            </Typography>
          )}
        </Grid>
        <Grid item>
          {totalSold > 0 && subTotal > 0 && (
            <Typography
              style={
                invoiceDifference >= 0 ? successCardHeader : dangerCardHeader
              }
              className={classes.qty}
            >
              فرق الفاتورة:
              {invoiceDifference === 0
                ? "صفر"
                : ConvertToArabicNumbers(
                    Math.abs(invoiceDifference.toFixed(1))
                  )}
            </Typography>
          )}
        </Grid>
        {truck &&
          truck.truckType === 2 &&
          !isNaN(totalItemsCount) &&
          !isNaN(truck.innerItemsCount) && (
            <>
              {totalWeight < truck.totalWeight ? (
                <Grid item>
                  <Typography
                    style={dangerCardHeader}
                    className={classes.buyingTruckInfo}
                  >
                    عجز الوزن:
                    {truck.totalWeight === totalWeight
                      ? "صفر"
                      : ConvertToArabicNumbers(
                          Math.abs(truck.totalWeight - totalWeight).toFixed(1)
                        )}
                  </Typography>
                </Grid>
              ) : (
                <Grid item>
                  <Typography
                    style={successCardHeader}
                    className={classes.buyingTruckInfo}
                  >
                    زائد الوزن :
                    {truck.totalWeight === totalWeight
                      ? "صفر"
                      : ConvertToArabicNumbers(
                          Math.abs(truck.totalWeight - totalWeight).toFixed(1)
                        )}
                  </Typography>
                </Grid>
              )}
              {!isNaN(buyingProfit) && (
                <Grid item>
                  <Typography
                    style={
                      buyingProfit < 0 ? dangerCardHeader : successCardHeader
                    }
                    className={classes.buyingTruckInfo}
                  >
                    قيمة فرق المشتريات:
                    {buyingProfit === 0
                      ? "صفر"
                      : ConvertToArabicNumbers(
                          Math.abs(buyingProfit).toFixed(1)
                        )}
                  </Typography>
                </Grid>
              )}
            </>
          )}
      </Grid>
    </div>
  );
}

export default InvoiceDetails;
import ClaimsForm from './components/ClaimsForm';
import React from 'react';
import RoleForm from './components/RoleForm';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: '100%',
  },
}));

function Permissions() {
  const { userId, partnerId, partnerName } = useParams();

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <RoleForm
        userId={userId}
        partnerId={partnerId}
        partnerName={partnerName}
      />
      <ClaimsForm partnerId={partnerId} userId={userId} />
    </div>
  );
}

export default Permissions;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { ConvertToArabicNumbers } from '../../../utils';
import CustomDialog from '../../../components/CustomDialog';
import React from 'react';

function RemainingDetails({ open, onClose, rows }) {
  return (
    <CustomDialog open={open} textAlign="center" onClose={onClose}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography style={{ fontWeight: 600, fontSize: 16 }}>
                  العدة
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography style={{ fontWeight: 600, fontSize: 16 }}>
                  العدد
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.tool.id}>
                <TableCell align="center">{row.tool.name}</TableCell>
                <TableCell align="center">
                  {ConvertToArabicNumbers(row.count)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomDialog>
  );
}

export default RemainingDetails;

/* eslint-disable react-hooks/exhaustive-deps */

import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { ConvertToArabicNumbers, getDateDashed, renderDate } from "../../utils";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  getLastDepositDate,
  getPartnersData,
  getSellerInvoiceDate,
  getSellerInvoiceType,
} from "./sellerSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../auth/authSlice";
import DatePicker from "react-datepicker";
import InvoiceSummery from "./components/InvoiceSummery";
import TrucksList from "./components/TrucksList";
import axios from "axios";
import { fetchBuyers } from "../main-data/dataSlice";
import { makeStyles } from "@material-ui/styles";
import { parseISO } from "date-fns";
import Alerto from "../../components/UI/toaster";
import { getRemainingDetailsForPartners } from "../movement-tools/tools-reports/toolsSlice";
import RemainingDetails from "../movement-tools/components/RemainingDetails";
import { Col, Container, Row } from "react-bootstrap";
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import "./seller-profile.css";
import { withStyles } from "@material-ui/styles";

const ProfileItem = (num) =>
  num !== 0 && num ? ConvertToArabicNumbers(Math.abs(Math.round(num))) : "صفر";
function SellerProfile() {
  // const fixedvalue = 1;
  const { partnerId } = useParams();
  // const user = useSelector((state) => state.auth.user);
  const lastDepositDate = useSelector((state) => state.seller.lastDepositDate);
  const [noMore, setNoMore] = useState(false);
  const [orderType, setOrderType] = useState('تنازلي');
  const [pageNumber, setPageNumber] = useState(1);
  const [invoiceDate, setInvoiceDate] = React.useState(new Date());
  const [searchType, setSearchType] = useState(1);
  const [invoicesByDate, setInvoicesByDate] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const remainingDetails = useSelector((state) => state.tools.remainingDetails);
  const [openDetails, setOpenDetails] = useState(false);
  const onDetailsClicked = (partnerId) => {
    setOpenDetails(true);
    dispatch(getRemainingDetailsForPartners(partnerId));
  };

  function usePrevious(value) {
    const ref = React.useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevPageNumber = usePrevious(pageNumber);

  const getInvoicesByPagination = () => {
    axios
      .get(
        `/SellingsInvoices/partner/pagination?partnerId=${partnerId}&pageNumber=${pageNumber}&pageSize=100`
      )
      .then((res) => {

        setInvoices((prevInvoices) => [...prevInvoices, ...res.data]);
        if (res.data.length < 30) {
          setNoMore(true);
        }
      });
  };
  const searchForInvoicesByDate = (date) => {
    axios
      .get(
        `/SellingsInvoices/partner/bydate?partnerId=${partnerId}&date=${getDateDashed(
          date
        )}`
      )
      .then((res) => {
        setInvoicesByDate(res.data);

      });
  };

  const [invoiceId, setInvoiceId] = useState("");
  const onInvoiceSelected = (id, sellingDate, invoiceTypeId) => {
    setInvoiceId(id);
    dispatch(getSellerInvoiceDate(sellingDate));
    dispatch(getSellerInvoiceType(invoiceTypeId));
  };
  useEffect(() => {
    return () => setInvoiceId("");
  }, []);
  const [lastTreatment, setLastTreatment] = useState("");
  const getBuyerLastTreatment = () => {
    axios
      .get(`/MoneyCurrentAccountReport/LastOperation/buyer/${partnerId}`)
      .then((res) => {
        if (res.status === 200) {
          setLastTreatment(res.data);
        }
      })
      .catch((err) => { });
  };

  const [damagedToolsCount, setDamagedToolsCount] = useState("");
  const getDamagedTools = () => {
    axios
      .get(`/MovementTools/damage?partnerId=${partnerId}`)
      .then((res) => {
        setDamagedToolsCount(res.data);
      })
      .catch((err) => { });
  };

  const [invoiceCount, setInvoiceCount] = useState("");
  const getInvoicesCounts = () => {
    axios
      .get(`/SellingsInvoices/invoiceTypePairCount?partnerId=${partnerId}`)
      .then((res) => {
        setInvoiceCount(res.data);
      })
      .catch((err) => { });
  };

  const [buyerDebt, setBuyerDebt] = useState("");
  const getBuyerDebtDetails = () => {
    axios
      .get(`/MoneyCurrentAccountReport/debtDetails/buyer/${partnerId}`)
      .then((res) => {
        setBuyerDebt(res.data);
      })
      .catch((err) => { });
  };

  const [clientDebt, setClientDebt] = useState("");
  const getClientDebtDetails = () => {
    axios
      .get(`/MoneyCurrentAccountReport/debtDetails/client/${partnerId}`)
      .then((res) => {
        setClientDebt(res.data);
      })
      .catch((err) => { });
  };

  const [totalMortage, setTotalMortage] = useState("");

  const getPriceOfRemainingTools = () => {
    axios
      .get(`/MovementTools/reports/Remaining/price/${partnerId}`)
      .then((res) => {
        setTotalMortage(res.data);
      })
      .catch((err) => { });
  };
  const logSearch = (id) => {
    axios
      .post(`/Tracking/LogSearching`, {
        userId: id,
        partnerSearchedForId: partnerId,
      })
      .then(() => { })
      .catch((err) => {
        Alerto(err);
      });
  };
  const [allTools, setAllTools] = useState([]);
  const getAllTools = () => {
    axios
      .get(`/MovementTools/reports/Remaining/${partnerId}`)
      .then((res) => {
        setAllTools(res.data);
      })
      .catch((err) => { });
  };

  const totalToolsCount =
    allTools.length > 0 &&
    allTools.reduce((acc, val) => {
      return acc + val.count;
    }, 0);
  const [partner, setPartner] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBuyers());
    if (partnerId) {
      getInvoicesCounts();
      dispatch(getPartnersData(partnerId)).then((res) => {
        setPartner(res.payload);
      });
      dispatch(getLastDepositDate(partnerId));
      getDamagedTools();
      getBuyerLastTreatment();
      getBuyerDebtDetails();
      getemployeeDebtDetails();
      getPriceOfRemainingTools();
      getAllTools();
      dispatch(getCurrentUser()).then((res) => {
        logSearch(res.payload.UserId);
      });
    }

    getClientDebtDetails();
  }, [partnerId]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (partnerId) {
        if (pageNumber !== prevPageNumber) {
          getInvoicesByPagination();
        }
      }
    }
    return () => {
      mounted = false;
    };
  }, [pageNumber]);

  const searchInvoices = searchType === 1 ? invoices : invoicesByDate;

  const totalInvoicesCount =
    invoiceCount.postDatedInvoicesCount +
    invoiceCount.cashedInvoicesCount +
    invoiceCount.supplingInvoicesCount;
  // const loansSummery1 = buyerDebt.totalLoans + clientDebt.totalLoans;
  // const loansSummery =
  //   partner.partnerTypeId === 3
  //     ? buyerDebt.totalLoans + clientDebt.totalLoans
  //     : buyerDebt.totalLoans;

  // const netDebt =
  //   partner.partnerTypeId === 3
  //     ? clientDebt.totalInvoices -
  //     buyerDebt.totalInvoices -
  //     loansSummery -
  //     totalMortage
  //     : buyerDebt.totalInvoices + totalMortage + loansSummery;

  ////////////////////////////////////////////////////////////////////////////
  const [employeeDebt, setemployeeDebt] = useState(0);
  const [NetDebt, setNetDabt] = useState(0);
  const getemployeeDebtDetails = () => {
    axios
      .get(`MoneyCurrentAccountReport/debtDetails/employee/${partnerId}`)
      .then((res) => {
        setemployeeDebt(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  const getNetDebt = () => {
    let data = {
      trucksInvoices: clientDebt.totalInvoices,
      sellingsInvoices: buyerDebt.totalInvoices,
      totalClientLoans: clientDebt.totalLoans,
      totalBuyerLoans: buyerDebt.totalLoans,
      totalToolMortage: totalMortage,
      totalSalaryDebt: employeeDebt,
    };
    axios
      .post(`MoneyCurrentAccountReport/calculator/debt/total`, data)
      .then((res) => {
        setNetDabt(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  useEffect(() => {
    if (employeeDebt !== "" && totalMortage !== "") {
      getNetDebt();
    }
  }, [clientDebt, buyerDebt, employeeDebt, totalMortage]);

  const changeOrderType = () => {
    if (orderType === 'تنازلي') {
      setOrderType('تصاعدي')
    }
    if (orderType === 'تصاعدي') {
      setOrderType('تنازلي')
    }

  }

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#161101",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 16,
      overflow: "auto",
    },
  }))(TableCell);

  return (
    <div className=" w-100  p-0 py-4 pb-5 margin15px" style={{ background: '#534d3b', marginTop: ' -15px  !important', outline: ' 8px solid #534d3b',minHeight:'hv-100 ' }}>
      <Container className="sellerpage" >

        {/* ............. header start ..................... */}

        <Row xs={12} className='mt-3'>

          {/* ............. اسم البائع ............. */}
          <Col className=" m-auto mb-3" xs={9} lg={4}>
            <Card
              style={{
                height: 88,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'
              }}
            >
              <CardContent>
                <Typography variant="h6">{partner.name}</Typography>
              </CardContent>
            </Card>
          </Col>
          {/* .......................... */}

          {/* ............. تفاصيل الحساب............. */}

          <Col className=" mb-3 text-center" lg={8}>
            <Card>
              <CardContent
                style={{ boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset' }}>
                <Grid
                  container
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Link style={{ color: 'red !important' }}
                      to={`/add-partner/seller/${partnerId}`} >
                      <Tooltip title="بيانات البائع">
                        <IconButton>
                          <AccountCircleRoundedIcon style={{ fontSize: '35px' }} />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Button
                      component={Link}
                      style={{ backgroundColor: "#90DCD0", color: "#333" }}
                      to={`/MoneyCurrentAccountReport/seller/${partnerId}`}
                      className='btnMore'
                    >
                      تفاصيل الحساب
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="textSecondary" className="headerTableFont">
                      {" "}
                      تاريخ اخر دفع:{" "}
                      <Typography
                        variant="body2"
                        component="span"
                        color="textPrimary"
                        className="headerTableFont"
                      >
                        {lastDepositDate
                          ? ConvertToArabicNumbers(
                            renderDate(lastDepositDate)
                          )
                          : "ﻻ يوجد"}{" "}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="textSecondary" className="headerTableFont">
                      {" "}
                      تاريخ اخر تعامل:{" "}
                      <Typography
                        variant="body2"
                        component="span"
                        color="textPrimary"
                        className="headerTableFont"
                      >
                        {lastTreatment
                          ? ConvertToArabicNumbers(
                            renderDate(lastTreatment)
                          )
                          : "ﻻ يوجد"}{" "}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Col>
          {/* .......................... */}

        </Row>
        {/* ............. header end ..................... */}



        <Row sm={12} className='pb-5 mb-4'>
          {/* .............  الجدول (الجانب الايمن) ............. */}

          <Col lg={4} >
            <Row xs={12}>
              <Col xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className='px-2'
                >
                  <DatePicker className="Calendar-container"
                    locale="ar"
                    selected={invoiceDate}
                    onChange={(date) => {
                      setSearchType(2)
                      searchForInvoicesByDate(date);
                      setInvoiceDate(date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    customInput={
                      <div className="Calendar-container btnMore ">
                        <CalendarMonthIcon className="ms-2 " />
                        بحث بتاريخ الفاتورة
                      </div>
                    }
                  />
                  {searchType === 1 ? <div className="filterIcons btnMore " onClick={() => { setSearchType(1); changeOrderType(); searchInvoices.reverse() }}> <span>ترتيب {orderType}</span>  <SortOutlinedIcon style={{ cursor: 'pointer' }} /></div> :
                    <span className="Calendar-container showAll btnMore" onClick={() => { setSearchType(1) }} style={{ cursor: 'pointer' }}>
                      عرض الكل
                    </span>
                  }
                </div>

              </Col>

              <Col xs={12}>
                <TrucksList
                  invoices={searchInvoices}
                  onInvoiceSelected={onInvoiceSelected}
                />
                {noMore || searchType === 2 ? (
                  ""
                ) : (
                  <Button onClick={() => setPageNumber((num) => num + 1)}>
                    تحميل المزيد من الفواتير....
                  </Button>
                )}
              </Col>

            </Row>


          </Col>
          {/* .......................... */}

          {/* .............  بعض التفاصيل (الجانب الايسر) ............. */}
          <Col lg={8} >
            {invoiceId && (
              <InvoiceSummery partnerId={partnerId} invoiceId={invoiceId} />
            )}


            <Row xs={12} className='m-auto mt-3 text-center p-0'>
              <TableContainer className="p-0 m-0" component={Paper} >
                <Table aria-label="simple table">
                  <TableHead style={{ background: '#FED049', height: '65px' }}>
                    <TableRow>
                      <TableCell className=' headerTableFont' align="center">عدد هالك العدة</TableCell>
                      <TableCell className=' headerTableFont' align="center">عدد الفواتير</TableCell>
                      <TableCell className=' headerTableFont' align="center">الفواتير المؤجلة</TableCell>
                      <TableCell className=' headerTableFont' align="center">فواتير النقدية</TableCell>
                      <TableCell className=' headerTableFont' align="center"> فواتير التوريد</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell className=' BodyTableFont' align="center"> {damagedToolsCount > 0 ? ConvertToArabicNumbers(damagedToolsCount) : "غير متوفر"}</TableCell>
                      <TableCell className=' BodyTableFont' align="center">{totalInvoicesCount > 0 ? ConvertToArabicNumbers(totalInvoicesCount) : "غير متوفر"}</TableCell>
                      <TableCell className=' BodyTableFont' align="center">{invoiceCount.postDatedInvoicesCount > 0 ? ConvertToArabicNumbers(invoiceCount.postDatedInvoicesCount) : "غير متوفر"}</TableCell>
                      <TableCell className=' BodyTableFont' align="center">{invoiceCount.cashedInvoicesCount > 0 ? ConvertToArabicNumbers(invoiceCount.cashedInvoicesCount) : "غير متوفر"}</TableCell>
                      <TableCell className=' BodyTableFont' align="center">{invoiceCount.supplingInvoicesCount > 0 ? ConvertToArabicNumbers(invoiceCount.supplingInvoicesCount) : "غير متوفر"}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Row>



            <Row xs={12} className='gx-5'>
              <Col xs={12}>
                <div className="w-100 bg-white my-3 rounded-3" style={{ height: '3px' }}>
                </div>
              </Col>
              < Col lg={6} className='mx-auto'>
                <TableContainer className="p-0  mb-3  hover-link" component={Paper} >
                  <Link to={`/MoneyCurrentAccountReport/seller/${partnerId}/selling`}>
                    <Table aria-label="simple table">
                      <TableHead style={{ background: '#FED049', height: '65px' }}>
                        <TableRow>
                          <TableCell className=' headerTableFont' align="center">فواتير مبيع</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell className=' BodyTableFont' align="center">
                            <span  style={{fontSize:'26px'}}  >
                              {ProfileItem(buyerDebt.totalInvoices)}
                              {" "}
                              {buyerDebt.totalInvoices > 0 ? "عليه" : ""}
                              {buyerDebt.totalInvoices < 0 ? "له" : ""}
                              {buyerDebt.totalInvoices === '' ? "" : ""}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Link>
                </TableContainer>
              </Col>

              <Col lg={6} className='mx-auto'>
                <TableContainer className="p-0 hover-link " component={Paper} >
                  <Link to={`/MoneyCurrentAccountReport/seller/${partnerId}/loans`}>
                    <Table aria-label="simple table">
                      <TableHead style={{ background: '#FED049', height: '65px' }}>
                        <TableRow>
                          <TableCell className=' headerTableFont' align="center">السلف</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell className=' BodyTableFont' align="center">
                            {ProfileItem(buyerDebt.totalLoans)}
                            <span variant="body1" >
                              {" "}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Link>
                </TableContainer>
              </Col>
              {partner.partnerTypeId === 3 && (
                <Col lg={6} className='mx-auto'>
                  <TableContainer className="p-0 mt-3" component={Paper} >
                    <Table aria-label="simple table">
                      <TableHead style={{ background: '#FED049', height: '65px' }}>
                        <TableRow>
                          <TableCell className=' tableFontSize' align="center">  </TableCell>
                          <TableCell className=' tableFontSize' align="center">  </TableCell>
                          <TableCell className=' headerTableFont' align="center">فواتير نقلات </TableCell>
                          <TableCell className=' tableFontSize' align="center">  </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                          <TableCell className=' tableFontSize' align="center">  </TableCell>
                          <TableCell className=' tableFontSize' align="center">  </TableCell>

                          <TableCell className=' BodyTableFont' align="center" >
                            <span  style={{fontSize:'26px'}}  >
                              {ProfileItem(clientDebt.totalInvoices)}
                              {" "}
                              {clientDebt.totalInvoices > 0 ? "عليه" : ""}
                              {clientDebt.totalInvoices < 0 ? "له" : ""}
                              {clientDebt.totalInvoices === '' ? "" : ""}
                            </span>
                          </TableCell>
                          <TableCell className=' BodyTableFont btnMore' align="center" >
                            <Link
                              to={`/client-profile/${partner.id}`}
                              style={{ color: 'white' }}     >
                              حساب العميل
                            </Link>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Col>
              )}

              {partner.partnerTypeId === 3 && (

                <Col lg={6} className='m-auto'>
                  <TableContainer className="p-0 mt-3" component={Paper} >
                    <Table aria-label="simple table">
                      <TableHead style={{ background: '#FED049', height: '65px' }}>
                        <TableRow>
                          <TableCell className=' headerTableFont' align="center">عملة مقدمة </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell className=' BodyTableFont' align="center">
                            <span variant="body1" >
                              {" "}
                              {ProfileItem(clientDebt.totalLoans)}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Col>
              )
              }

              <Col xs={12}>
                <div className="w-100 bg-white my-3 rounded-3" style={{ height: '3px' }}>
                </div>
              </Col>

              <Col lg={6} className='mx-auto'>
                <TableContainer className="p-0 mb-3 hover-link" component={Paper} >
                  <Link to={`/tools-current-account/${partnerId}`}>
                    <Table aria-label="simple table">
                      <TableHead style={{ background: '#FED049', height: '65px' }}>
                        <TableRow>
                          <TableCell className=' headerTableFont' align="center">عدد العدة</TableCell>
                          <TableCell className=' headerTableFont' align="center">  الرهن</TableCell>
                          <TableCell className=' tableFontSize' align="center"> </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell className=' tableFontSize' align="center">
                            {ProfileItem(totalToolsCount)}
                            <span variant="body1" >
                              {" "}
                            </span>
                          </TableCell>
                          <TableCell className=' BodyTableFont' align="center">
                            {ProfileItem(totalMortage)}
                            <span variant="body1" >
                              {" "}
                              {totalToolsCount > 0 ? "عليه" : ""}
                              {totalToolsCount < 0 ? "له" : ""}
                              {totalToolsCount === '' ? "" : ""}
                              {" "}
                            </span>
                          </TableCell>
                          <TableCell className=' BodyTableFont' align="center">
                            <Link style={{ color: 'red !important' }}
                              onClick={() => onDetailsClicked(partner.id)}>
                              <Tooltip title="العدد">
                                <IconButton>
                                  <InfoIcon style={{ fontSize: '35px' }} />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Link>
                </TableContainer>
              </Col>

              {partner.isEmployee ? (

                <Col lg={6} className='mx-auto'>
                  <TableContainer className="p-0 hover-link " component={Paper} >
                    <Link to={`/MoneyCurrentAccountReport/employee/${partnerId}`}>
                      <Table aria-label="simple table">
                        <TableHead style={{ background: '#FED049', height: '65px' }}>
                          <TableRow>
                            <TableCell className=' headerTableFont' align="center"> راتب</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell className=' BodyTableFont' align="center">
                              {ProfileItem(employeeDebt)}
                              {" "}
                              {employeeDebt > 0 ? "عليه" : ""}
                              {employeeDebt < 0 ? "له" : ""}
                              {employeeDebt === '' ? "" : ""}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Link>
                  </TableContainer>
                </Col>) :
                <div></div>

              }


              <Col xs={12}>
                <TableContainer className="p-0 mt-3" component={Paper} >
                  <Table aria-label="simple table">
                    <TableHead style={{ background: '#f3c637', height: '65px' }}>
                      <TableRow  >
                        <TableCell className=' headerTableFont btnMore font23px ' align="center">اجمالي المبلغ</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell className=' BodyTableFont' align="center">
                          <span  style={{fontSize:'26px'}} >{ProfileItem(NetDebt)}</span>
                          <span  style={{fontSize:'26px'}}  >
                            {" "}
                            {NetDebt > 0 ? "عليه" : ""}
                            {NetDebt < 0 ? "له" : ""}
                            {NetDebt === '' ? "" : ""}
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Col>

              <Col lg={6} className='mx-auto'>
                <TableContainer className="p-0 mt-3 hover-link" component={Paper} >
                  <Link to={`/MoneyCurrentAccountReport/seller/${partnerId}/mortage`}>
                    <Table aria-label="simple table">
                      <TableHead style={{ background: '#FED049', height: '65px' }}>
                        <TableRow>
                          <TableCell className=' headerTableFont' align="center">رهن البائع</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell className=' BodyTableFont' align="center">
                            {ProfileItem(buyerDebt.buyerMortage)}
                            <span variant="body1" >
                              {" "}
                              {buyerDebt.buyerMortage > 0 ? "عليه" : ""}
                              {buyerDebt.buyerMortage < 0 ? "له" : ""}
                              {buyerDebt.buyerMortage === '' ? "" : ""}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Link>
                </TableContainer>
              </Col>

            </Row>
          </Col>
          {/* .......................... */}

        </Row >

        {/* .............  popup (بتاعت تفاصيل الرهن) ............. */}

        <RemainingDetails
          rows={remainingDetails}
          open={openDetails}
          onClose={() => setOpenDetails(false)}
        />
        {/* .......................... */}


      </Container >
    </div>

  );
}

export default SellerProfile;

import MainHeader from "../../components/mainHeader/MainHeader";
import Tools from "../../components/tools";

export default function DamageTools() {
  return (
    <div>
      <div className="my-4">
        <MainHeader title="اضف هالك عدة" />
      </div>
      {/* <Tools isDamage title="اضف هالك عدة" baseApi="MovementTools/Damage/" /> */}
      <Tools isDamage baseApi="MovementTools/Damage/" />
    </div>
  );
}

import { Avatar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./Layout.css";
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ConvertToArabicNumbers } from "../utils";
import CssBaseline from "@material-ui/core/CssBaseline";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import Body from "../assets/images/Logo/Body.png";
import Eyes from "../assets/images/Logo/Eyes.png";
import Bottom from "../assets/images/Logo/Bottom.png";
import LeftEar from "../assets/images/Logo/Left.png";
import RightEar from "../assets/images/Logo/Right.png";
import Top from "../assets/images/Logo/Top.png";
import RedLogout from "../assets/images/redLogout.png";
import BlackLogout from "../assets/images/blackLogout.png";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import NavItems from "./NavItems copy";
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import { logOut } from "../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { style } from "@mui/system";
import BannerImg from "../features/Home/BannerImg";
import UserImg from "../features/Home/userImg";
import ConnectionStatus from './ConnectionStatus';


const drawerWidth = 232;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    minHeight: "100vh",
    
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "linear-gradient(to top, #f57542, #FFA500, #F3C637 70%, #FFD700, #FFD700,#FFD700, #FFA500)",
    boxShadow: ' rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px,rgb(0 0 0 / 20%) 0px -3px 0px inset',
    color: "#261000",
    transition: ".8s",
   
  },
  appBarShift: {
    width: "85%", 
    
    [theme.breakpoints.down('xs')]: {
      width: '75%',
    },
    
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    transition: ".8s",
    
  },

  menuButton: {
    marginRight: "3vw",
    color: " #483804",
    
  },
  hide: {
    display: "none",
   
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    
    
  },
  drawerPaper: {
    position: "relative",
    width: 400,
    overflowX: "hidden",
    overflowY: "hidden",
    "&:hover": {
      overflowY: "auto",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
   
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "auto",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    paddingTop: 8,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    minHeight: `calc(100vh - 64px)`,
    overflow: "auto",
    width: "100%",
    position: "relative",
    padding: theme.spacing(1),
    "&::-webkit-scrollbar": {
      display: "none",
    } /* Chrome */,
    
  },

  grow: {
    flexGrow: 1,
  },
  homeLink: {
    textDecoration: "none",
    color: "#261000",
    fontSize: "2vw",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3vw",
    },
  },

  customBadge: {
    backgroundColor: "#F6412D",
    color: "#fff",
  },
}));

export default function MiniDrawer(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [logoState, setLogoState] = React.useState(false);
  const [profileImg, setProfileImg] = React.useState("");
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      {localStorage.getItem("token") ? (
        <>
          <AppBar
            position="absolute"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuOpenIcon />
              </IconButton>
              <div style={{ cursor: "pointer" }} onClick={handleDrawerClose}>
                {open && <ChevronRightIcon className="rightIcon" />}
              </div>
              <Link className={classes.homeLink} to="/">
                الرئيسية
              </Link>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                {props.user && (
                  <>
                    <IconButton
                      disableFocusRipple
                      disableRipple
                      style={{
                        backgroundColor: "transparent",
                        borderRadius: 0,
                      }}
                      className={open ? "disable" : ""}
                      component={Link}
                      underline="none"
                      to={
                        role == "عميل_او_بائع" || role == "محصل"
                          ? `/client-user-profile/${props.user.PartnerId}`
                          : props.partner.partnerTypeId === 1
                          ? props.partner &&
                            `/client-profile/${props.user.PartnerId}`
                          : props.partner &&
                            `/seller-profile/${props.user.PartnerId}`
                      }
                    >
                      <Typography className={open ? "disable" : "user"}>
                        {props.user &&
                          props.user[
                            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
                          ]}
                      </Typography>
                      <UserImg style={{ width: "35px" }} />
                    </IconButton>

                    <IconButton
                      onMouseEnter={() => setLogoState(true)}
                      onMouseLeave={() => setLogoState(false)}
                      onClick={() => {
                        dispatch(logOut());
                      }}
                    >
                      <div className="exit">
                        {/* <ExitToAppRoundedIcon /> */}
                        <img src={logoState ? RedLogout : BlackLogout} />
                      </div>
                    </IconButton>
                  </>
                )}
              </div>
            </Toolbar>
          </AppBar>
          <div className="mainholder">
            <div className={open ? "iconholder" : "iconholderclosed"}>
              <Link className={classes.homeLink} to="/">
                <div className="owlimg">
                  <div className="logo">
                    <div className="owlLOGO">
                      <img className="owlBody" src={Body} alt="Body" />
                      <img className="owlEyes" src={Eyes} alt="Eyes" />
                      <img className="owlLeftEar" src={LeftEar} alt="Left Ear" />
                      <img className="owlRightEar" src={RightEar} alt="Right Ear" />
                      <img className="owlTop" src={Top} alt="Top" />
                      <img className="owlBottom" src={Bottom} alt="Bottom" />
                    </div>
                  </div>
                </div>
                <div ><ConnectionStatus /></div>
                    
              </Link>
               
            </div>
                <NavItems isDrawerOpen={open} />
          </div>

          <main
            className={classes.content}
            style={{ position: "relative !important" }}
          >
            <div className={classes.toolbar} />
            {props.children}
          </main>
        </>
      ) : (
        <div style={{ height: "100%", width: "100%" }}>{props.children}</div>
      )}
    </div>
  );
}

import {
  addExpenses,
  getAllExpenses,
  getExpensesCategories,
} from "./expensesSlice";
import { fetchPartners } from "../main-data/dataSlice";
import { useDispatch, useSelector } from "react-redux";

import ExpensesForm from "./components/ExpensesForm";
import ExpensesTable from "./components/ExpensesTable";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import BalanceNotification from "../loads/components/BalanceNotification";
import Mydate from "../../components/Helpers/MyDateParser";
import MainHeader from "../../components/mainHeader/MainHeader";

function AddExpenses() {
  const [show, setshow] = useState(false);
  const { type, partnerId, factor } = useParams();
  const expenses = useSelector((state) => state.expenses.expenses);
  const dispatch = useDispatch();
  const fetchdata = () => {
    setTimeout(() => {
      dispatch(getAllExpenses(Mydate(new Date())));
    }, 100);
  };

  React.useEffect(() => {
    dispatch(fetchPartners());
    dispatch(getExpensesCategories());
    fetchdata();
  }, []);

  const handleSubmit = (data) => {
    if (data) {
      if (data.category.id === 4 || data.category.id === 10) {
        ////////Logic/////
      }
      dispatch(addExpenses(data)).then((res) => {
        fetchdata();
      });
    }
  };
  return (
    <div
      className=" w-100  p-0 py-4 pb-5 position-relative text-white  "
      style={{
        background: "#534d3b",
        marginTop: " -15px !important",
        minHeight: "107vh",
        outline: " 8px solid #534d3b",
      }}
    >
      <div className="my-4">
        <MainHeader title="اضافة مصروفات" />
      </div>

      {show && <BalanceNotification />}
      <ExpensesForm
        onSubmit={handleSubmit}
        type={type}
        partnerId={partnerId}
        factor={factor}
      />
      {expenses && expenses.length > 0 && (
        <ExpensesTable refresh={fetchdata} expenses={expenses} />
      )}
    </div>
  );
}

export default AddExpenses;

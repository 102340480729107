import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { getBuyingTruckProfit, getExpenses } from '../invoiceSlice';
import { useDispatch, useSelector } from 'react-redux';

import { ConvertToArabicNumbers } from '../../../utils';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  tableBody: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  tableCell: {},
  trash: {
    color: theme.palette.error.light,
  },
  edit: {
    color: theme.palette.info.light,
  },
}));

function InvoiceExpenses({ truck }) {
  const loadExpenses = useSelector((state) => state.invoice.loadExpenses);
  const totalExpenses = loadExpenses.expenses.reduce((acc, val) => {
    return acc + val.money;
  }, 0);

  const dispatch = useDispatch();
  React.useEffect(() => {
    let mounted = true;
    if (mounted && truck && truck.id)
      dispatch(getExpenses()).then(() => {
        if (truck.truckType === 2) {
          dispatch(getBuyingTruckProfit(truck.id));
        }
      });
    return () => {
      mounted = false;
    };
  }, [truck]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      {loadExpenses && loadExpenses.expenses.length > 0 ? (
        <TableContainer style={{ marginBottom: 8 }} component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: '#F3C637' }}>
              <TableRow>
                <TableCell align="center">
                  <Typography variant="body2" style={{ fontWeight: 500 }}>
                    المبلغ
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2" style={{ fontWeight: 500 }}>
                    نوع المصروف
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2" style={{ fontWeight: 500 }}>
                    ملحوظة
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadExpenses &&
                loadExpenses.expenses.length > 0 &&
                loadExpenses.expenses.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell className={classes.tableCell} align="center">
                      {ConvertToArabicNumbers(row.money)}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {row.name.replace(/_/g, ' ')}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {row.notes}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div
          style={{ textAlign: 'center',fontSize:18 }}
          className='headerSearch mb-4 rounded-2 mt-0 w-100 py-4 '
        >
          لم يتم اضافة نفقات
        </div>
      )}
      {totalExpenses > 0 && (
        <div
          variant="body1"
          color="textPrimary"
          style={{ textAlign: 'center', fontWeight: 600,
          background:'#F3C637',
          fontSize:20
          ,padding:15
          ,borderRadius:'10px',
          marginBottom: 15
        }}
        >
          {' '}
          اجمالي المصروفات: {ConvertToArabicNumbers(totalExpenses)}{' '}
        </div>
      )}
    </div>
  );
}

export default InvoiceExpenses;

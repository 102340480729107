import * as React from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  DialogContentText,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { ConvertToArabicNumbers } from "../../../utils";
import CustomDialog from "../../../components/CustomDialog";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import SearchIcon from "@material-ui/icons/Search";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import TransferProfile from "./TransferProfile";
import MainHeader from "../../../components/mainHeader/MainHeader";
import { Container } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
  },
  tableBody: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  tableCell: {
    paddingRight: 0,
    paddingLeft: 0,
    fontSize: 14,
  },
  toolBar: {
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 12,
    },
    "&>*": {
      margin: "auto",
    },
  },
  moveBtn: {
    height: 40,
  },
  searchBar: {
    backgroundColor: "#FFF",
    width: "100%",
    margin: "0vh 0vw 0vh 0vw",
  },
  searchIcon: {
    color: theme.palette.grey[400],
  },
  delBtn: {
    backgroundColor: theme.palette.error.main,
    marginLeft: 16,
    height: 40,
  },
  deleteIcon: {
    color: "#F8D486",
    fontSize: 100,
    fontWeight: "100",
    marginBottom: 24,
  },
  blockBtn: {
    backgroundColor: theme.palette.info.light,
    color: "#fff",
  },
  trash: {
    color: theme.palette.error.light,
  },
  edit: {
    color: theme.palette.info.light,
  },
}));

function descendingComparator(a, b, orderBy) {
  let h = a["collectingAddress"] ? a["collectingAddress"].name : null;
  let h2 = b["collectingAddress"] ? b["collectingAddress"].name : null;
  if (orderBy === "collectingAddress") {
    if (h2 < h) {
      return -1;
    }
    if (h2 > h) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

const headCells = [
  {
    id: "name",
    label: "الاسم",
  },
  {
    label: "هاتف1",
  },

  {
    id: "collectingAddress",
    label: "خط التحصيل",
  },

  {
    label: "النوع",
  },
  {
    label: "الدفع",
  },
  {
    label: "الحالة",
  },
  {
    // disablePadding: true,
    label: "اجراءات",
  },
];

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: "#F3C637" }}>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography
                  style={{ fontSize: 12, fontWeight: 600, color: "black" }}
                >
                  {headCell.label}
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography
                style={{ fontSize: 12, fontWeight: 600, color: "black" }}
              >
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function PartnersTable({ data, onStatusChanged, onDelete }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const role = localStorage.getItem("role");
  const [searchQuery, setSearchQuery] = useState("");
  const [openAddress, setOpenAddress] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState("");
  const [statusItem, setStatusItem] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [address, setAddress] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const [collectingAddresses, setCollectingAddresses] = React.useState([]);
  const fetchCollectingAddresses = () => {
    axios
      .get("/CollectingAddress")
      .then((res) => {
        setCollectingAddresses(res.data);
      })
      .catch((err) => {});
  };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchCollectingAddresses();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const [paymentType, setPaymentType] = useState("");
  const [partnerType, setPartnerType] = useState("");
  const [collectingAddress, setcollectingAddress] = useState("");
  const [status, setStatus] = useState("");
  const paymentTypes = [
    { id: 1, label: "اجل" },
    { id: 2, label: "نقدية" },
  ];

  const types = [
    { id: 1, label: "عميل" },
    { id: 2, label: "بائع" },
    { id: 3, label: "عميل بائع" },
  ];

  const statusTypes = [
    { id: 1, label: "نشط" },
    { id: 2, label: "معطل" },
  ];

  const filteredByType = partnerType
    ? data.filter((r) => {
        return r.partners_TypeId === partnerType;
      })
    : data;

  const filteredByِAddress = collectingAddress
    ? filteredByType.filter((r) => {
        if (r.collectingAddress) {
          return r.collectingAddress.id === collectingAddress.id;
        }
      })
    : filteredByType;

  const filteredByPaymentType = paymentType
    ? filteredByِAddress.filter((r) => {
        return r.payementTypeId === paymentType;
      })
    : filteredByِAddress;

  const filteredByStatus = status
    ? filteredByPaymentType.filter((r) => {
        if (status === 1) {
          return r.isDeleted === false;
        } else if (status === 2) {
          return r.isDeleted === true;
        }
      })
    : filteredByPaymentType;
  const classes = useStyles();
  return (
    <div style={{ width: "100%" }}>
      <div className="my-4">
        <MainHeader title="قائم الاسماء" />
      </div>
      <Container>
        <div className="headerSearch p-3 mb-2">
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} lg>
              <TextField
                autoFocus
                placeholder="بحث بالاسم"
                className={classes.searchBar + " " + "w-100"}
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg>
              <TextField
                style={{ backgroundColor: "#fff" }}
                size="small"
                select
                label="النوع"
                className="w-100"
                onChange={(e) => setPartnerType(e.target.value)}
                value={partnerType}
                variant="outlined"
              >
                {types.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6} lg>
              <Autocomplete
                options={collectingAddresses}
                getOptionSelected={(option, value) =>
                  option.value === value?.value
                }
                getOptionLabel={(option) => (option.name ? option.name : "")}
                style={{ backgroundColor: "#fff" }}
                autoHighlight
                autoComplete
                value={collectingAddress}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setcollectingAddress(newValue);
                  } else {
                    setcollectingAddress("");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="خط التحصيل"
                    variant="outlined"
                    size="small"
                    className="w-100"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg>
              <TextField
                style={{ backgroundColor: "#fff", width: 180 }}
                size="small"
                select
                label="الدفع"
                className="w-100"
                onChange={(e) => setPaymentType(e.target.value)}
                value={paymentType}
                variant="outlined"
              >
                {paymentTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6} lg>
              <TextField
                style={{ backgroundColor: "#fff", width: 180 }}
                size="small"
                select
                className="w-100"
                label="الحالة"
                onChange={(e) => setStatus(e.target.value)}
                value={status}
                variant="outlined"
              >
                {statusTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </div>
        {(role === "إدارة" || role === "مدير") && <TransferProfile />}
        <Box sx={{ maxWidth: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer className={classes.T} component={Paper}>
              <Table size={dense ? "small" : "medium"}>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={data.length}
                />
                <TableBody>
                  {stableSort(filteredByStatus, getComparator(order, orderBy))
                    .filter((row) => row.name && row.name.includes(searchQuery))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow hover key={row.id}>
                          <TableCell
                            className={classes.tableCell}
                            size="small"
                            align="center"
                            style={{ minWidth: 180 }}
                          >
                            {row.name}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            size="small"
                            align="center"
                            style={{ minWidth: 180 }}
                          >
                            {ConvertToArabicNumbers(row.phone1)}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            size="small"
                            align="center"
                            style={{ minWidth: 180 }}
                          >
                            {row.collectingAddress
                              ? row.collectingAddress.name
                              : "صفر"}
                          </TableCell>

                          <TableCell
                            className={classes.tableCell}
                            size="small"
                            align="center"
                            style={{ minWidth: 180 }}
                          >
                            {row.partners_TypeId === 1
                              ? "عميل"
                              : row.partners_TypeId === 2
                              ? "بائع"
                              : "عميل بائع"}
                          </TableCell>

                          <TableCell
                            className={classes.tableCell}
                            size="small"
                            align="center"
                            style={{ minWidth: 180 }}
                          >
                            {row.payementTypeId === 1
                              ? "أجل"
                              : row.payementTypeId === 2
                              ? "نقدية"
                              : ""}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            size="small"
                            align="center"
                            style={{ minWidth: 180 }}
                          >
                            <Switch
                              onClick={() => {
                                setStatusItem(row);
                                setOpenStatus(true);
                              }}
                              color="primary"
                              checked={!row.isDeleted}
                            />
                          </TableCell>

                          <TableCell
                            className={classes.Tcell}
                            size="small"
                            align="center"
                            style={{ minWidth: 180 }}
                          >
                            <IconButton
                              size="small"
                              component={Link}
                              to={`add-partner/${
                                row.partners_TypeId === 1 ? "client" : "seller"
                              }/${row.id}`}
                              className={classes.edit}
                            >
                              <EditOutlinedIcon size="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              className={classes.trash}
                              onClick={() => {
                                setDeletedItemId(row.id);
                                setOpenDelete(true);
                              }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage=""
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="عرض بكثافة"
          />
        </Box>
      </Container>

      <CustomDialog
        open={openAddress}
        title="العنوان"
        textAlign="center"
        onClose={() => setOpenAddress(false)}
      >
        <DialogContentText> {address} </DialogContentText>
      </CustomDialog>

      <CustomDialog
        open={openStatus}
        title={statusItem.isDeleted ? "تنشيط المستخدم" : "تعطيل المستخدم"}
        textAlign="center"
        onClose={() => setOpenStatus(false)}
        action1={
          <Button
            style={{ height: 40 }}
            onClick={() => setOpenStatus(false)}
            variant="contained"
            size="small"
          >
            ﻻ
          </Button>
        }
        action2={
          <Button
            variant="contained"
            className={classes.blockBtn}
            size="small"
            onClick={() => {
              onStatusChanged(statusItem.id, statusItem.isDeleted);
              setOpenStatus(false);
            }}
          >
            نعم
          </Button>
        }
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ErrorOutlineOutlinedIcon className={classes.deleteIcon} />
        </div>
        <DialogContentText>
          {statusItem.isDeleted
            ? "إذا اضغط على نعم ثم قم بتنشيط المستخدم!"
            : "إذا اضغط على نعم ثم قم بتعطيل المستخدم!"}
        </DialogContentText>
      </CustomDialog>

      <CustomDialog
        open={openDelete}
        title="هل انت متأكد؟"
        textAlign="center"
        onClose={() => setOpenDelete(false)}
        action1={
          <Button
            style={{ height: 40 }}
            onClick={() => setOpenDelete(false)}
            variant="contained"
            size="small"
          >
            ﻻ, الغاء
          </Button>
        }
        action2={
          <Button
            variant="contained"
            className={classes.delBtn}
            size="small"
            onClick={() => {
              onDelete(deletedItemId);
              setOpenDelete(false);
            }}
          >
            نعم ، احذف
          </Button>
        }
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ErrorOutlineOutlinedIcon className={classes.deleteIcon} />
        </div>
        <DialogContentText>
          {" "}
          لن تتمكن من استعادة هذه البيانات!{" "}
        </DialogContentText>
      </CustomDialog>
    </div>
  );
}

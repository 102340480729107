import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Alerto from "../../components/UI/toaster";

export const getPartnersData = createAsyncThunk(
  "seller/getPartnersData",
  async (partnerId) => {
    try {

      const response = await axios.get(`/Partners/${partnerId}`);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      Alerto(error);
    }
  }
);

export const getLastDepositDate = createAsyncThunk(
  "seller/getLastDepositDate",
  async (partnerId) => {
    try {
      const response = await axios.get(`/Deposits/last?partnerId=${partnerId}`);

      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);

const initialState = {
  partner: "",
  lastDepositDate: "",
};

const clientSlice = createSlice({
  name: "client",
  initialState,

  extraReducers: {
    [getLastDepositDate.fulfilled]: (state, action) => {
      if (action.payload) {
        state.lastDepositDate = action.payload;
      }
    },

    [getPartnersData.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
    
        state.partner = action.payload;
      }
    },
  },
});

export default clientSlice.reducer;

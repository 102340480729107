import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: 1fr;
  margin-top: 1rem;
  position: relative;
`;
export const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: gray;
  gap: 1px;
`;
export const Element = styled.div`
  padding: 10px 0;
  background: white;
  text-align: center;
  border-width: 0.5px;
  border-style: solid;
  border-color: gray;
  ${({ head }) =>
    !!head &&
    css`
      color: black;
      background: #faa829;
      border: none;
    `};
`;
export const TwistedArrow = styled.img`
  position: absolute;
  top: -1.1rem;
  right: 9rem;
  width: 1rem;
  transform: scaleX(-1);
`;

import { IconButton, Paper, TableSortLabel, Toolbar } from "@material-ui/core";
import {
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";

import DeleteBox from "../../../components/DeleteBox";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SearchIcon from "@material-ui/icons/Search";
import UpdateCrop from "./UpdateCrop";
import { fetchCrops } from "../dataSlice";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "separate",
    borderSpacing: "8px 4px",
    border: "1px solid #222",
  },
  tableRow: {
    marginTop: "8px",

  },
  tableCell: {
    marginTop: "8px",
    border: "1px solid #222",
    padding: 4,
  },
  searchBar: {
    backgroundColor: "#fff",
    borderRadius: 5,
  },

  trash: {
    color: theme.palette.error.light,
  },
  edit: {
    color: theme.palette.info.light,
  },

  "@media print": {
    total: {
      display: "flex",
      width: 400,
      justifyContent: "space-between",
      margin: 8,
    },
    printIcon: {
      display: "none",
    },
    printHidden: {
      display: "none",
    },
  },
}));

export default function ProductsTable({ checkhandler, crops, updateCrop, deleteCrop }) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchCrops());
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const classes = useStyles();
  const [openDelete, setOpenDelete] = useState(false);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    { label: "اسم الصنف", id: "name" },
    { label: "وصف الصنف" },
    { label: "تعديل" },
    { label: "حذف" },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead style={{ backgroundColor: "#F3C637" }}>
        <TableRow>
          {headCells.map((headCell, index) => (
            <TableCell
              style={{ fontWeight: "bold", minWidth: 200 }}
              key={index}
              align="center"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.id ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <Toolbar
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#f5f5f5",
        }}
      >
        <TextField
          placeholder="بحث..."
          className={classes.searchBar +' '+'w-50'}
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      <TableContainer style={{ width: '100%' }} component={Paper}>

        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={15}
          />
          <TableBody className={classes.tableBody}>
            {stableSort(crops, getComparator(order, orderBy))
              .filter((item) => item.name && item.name.includes(searchQuery))
              .map((item, index) => (
                <TableRow key={item.id} className={classes.tableBody}>
                  <TableCell align="center" component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell align="center">{item.description}</TableCell>

                  <TableCell align="center" style={{ width: "32px" }}>
                    <IconButton
                      className={classes.edit}
                      onClick={() => {
                        setOpen(true);
                        setSelectedProduct(item);
                      }}
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center" style={{ width: "32px" }}>
                    <IconButton
                      className={classes.trash}
                      onClick={() => {
                        setSelectedProduct(item);
                        setOpenDelete(true);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteBox
        action={() => {
          deleteCrop(selectedProduct.id);
          setOpenDelete(false);
        }}
        message="هل تريد حذف الصنف؟"
        open={openDelete}
        onClose={() => setOpenDelete(false)}
      />

      <UpdateCrop
        open={open}
        onClose={() => setOpen(false)}
        product={selectedProduct}
        updateCrop={updateCrop}
        checkhandler={checkhandler}
      />
    </>
  );
}

import * as React from "react";

import { Button, Paper } from "@material-ui/core";
import { ConvertToArabicNumbers, renderDate } from "../../../utils";
import DetailsIcon from "@material-ui/icons/Details";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/styles";
import LoadDetails from "../../movement-tools/components/LoadDetails";
import Alerto from "../../../components/UI/toaster";
import { useState } from "react";
import axios from "axios";
import InfoIcon from "@mui/icons-material/Info";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import SummarizeModal from "../../trucks-reports/components/SummarizeModal";
import {
  EnhancedTableHead,
  getComparator,
  stableSort,
} from "../../../components/Tables/EnhancedTableHead";
const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
  },
  tableBody: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
    fontSize: 14,
  },
  tableToolbar: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#eeeeee",
    "&>*": {
      marginRight: 12,
    },
  },
  moveBtn: {
    height: 40,
  },
  searchBar: {
    backgroundColor: "#FFF",
    width: 260,
    marginBottom: 8,
  },
  searchIcon: {
    color: theme.palette.grey[500],
  },
}));

const headCells = [
  {
    id: "truckDate",
    label: "التاريخ",
  },
  {
    id: "farmerName",
    label: "الاسم",
  },
  {
    label: "رقم النقلة",
    id: "truckIdForThisDayAndClient",
  },
  {
    label: "النوع",
  },
  {
    id: "itemsCount",
    label: "عدد الاصناف",
  },
  {
    id: "innerItemsCount",
    label: "العدد الاساسي",
  },
  {
    label: "العدد المباع",
  },
  {
    id: "remaining",
    label: "العدد المتبقي",
  },
  {
    id: "overFlow",
    label: "الزيادة",
  },
  {
    id: "overFlow",
    label: "تفاصيل",
  },
  {
    id: "overFlow",
    label: "بيان",
  },
  {
    label: "الفاتورة",
  },
  {
    label: "ترحيل",
  },
];

export default function InvoicesTable({ trucks }) {
  console.log(trucks);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("truckDate");
  const [openSummarize, setOpenSummarize] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [openDetails, setOpenDetails] = useState(false);
  const [truck, setTruck] = useState(false);
  const [details, setDetails] = useState([]);
  const onDetailsClicked = (truck) => {
    setOpenDetails(true);
    setTruck(truck);
    axios
      .get(
        `Truck/${truck.truckTypeId === 1 ? "CommisionItems" : "BuyingItems"}/${
          truck.truckId
        }`
      )
      .then((res) => {
        setDetails(res.data);
      })
      .catch((e) => Alerto(e));
  };
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%" }}>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          cells={headCells}
        />

        <TableBody>
          {stableSort(trucks, getComparator(order, orderBy)).map(
            (truck, index) => {
              return (
                <TableRow key={truck.truckId}>
                  <TableCell align="center" style={{ minWidth: 130 }}>
                    {ConvertToArabicNumbers(renderDate(truck.truckDate))}
                  </TableCell>
                  <TableCell style={{ minWidth: 180 }} align="center">
                    {truck.farmerName}
                  </TableCell>
                  <TableCell align="center">
                    {ConvertToArabicNumbers(truck.truckIdForThisDayAndClient)}
                  </TableCell>
                  <TableCell align="center">{truck.truckType}</TableCell>
                  <TableCell align="center">
                    {truck.itemsCount === 0
                      ? "ﻻ يوجد"
                      : ConvertToArabicNumbers(truck.itemsCount)}
                  </TableCell>
                  <TableCell align="center">
                    {truck.innerItemsCount === 0
                      ? "ﻻ يوجد"
                      : ConvertToArabicNumbers(truck.innerItemsCount)}
                  </TableCell>
                  <TableCell align="center">
                    {truck.innerItemsCount === truck.remaining
                      ? "ﻻ يوجد"
                      : ConvertToArabicNumbers(
                          truck.innerItemsCount -
                            truck.remaining +
                            truck.overFlow
                        )}
                  </TableCell>
                  <TableCell align="center">
                    {truck.remaining === 0
                      ? "ﻻ يوجد"
                      : ConvertToArabicNumbers(truck.remaining)}
                  </TableCell>
                  <TableCell align="center">
                    {truck.overFlow === 0
                      ? "ﻻ يوجد"
                      : ConvertToArabicNumbers(truck.overFlow)}
                  </TableCell>
                  <TableCell align="center">
                    <InfoIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => onDetailsClicked(truck)}
                      className="center"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <InsertDriveFileOutlinedIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setTruck(truck);
                        setOpenSummarize(true);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component={Link}
                    to={`/invoice/${truck.truckId}`}
                    className="btnMore"
                    align="center"
                    style={{ width: "32px" }}
                  >
                    فاتورة
                  </TableCell>
                  <TableCell
                    component={Link}
                    className="btnMore"
                    to={`/loads/${truck.truckId}`}
                    align="center"
                  >
                    ترحيل
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
      <LoadDetails
        truck={truck}
        rows={details}
        open={openDetails}
        onClose={() => setOpenDetails(false)}
      />
      {truck && (
        <SummarizeModal
          open={openSummarize}
          setOpen={setOpenSummarize}
          truck={truck}
        />
      )}
    </TableContainer>
  );
}
import {
  addDeposit,
  getAllDeposits,
  getDepositCategories,
} from "./treasurySlice";
import { fetchPartners } from "../main-data/dataSlice";
import { useDispatch, useSelector } from "react-redux";

import DepositForm from "./components/DepositForm";
import DepositsTable from "./components/DepositsTable";
import { Helmet } from "react-helmet";
import React from "react";
import Mydate from "../../components/Helpers/MyDateParser";
import { Container } from "react-bootstrap";
import MainHeader from "../../components/mainHeader/MainHeader";

function DepositPage() {
  const dispatch = useDispatch();
  const fetchdata = () => {
    dispatch(getAllDeposits(Mydate(new Date())));
  };
  React.useEffect(() => {
    dispatch(fetchPartners());
    dispatch(getDepositCategories());
    fetchdata();
  }, []);

  const handleSubmit = (data) => {
    if (data) {
      dispatch(addDeposit(data)).then((res) => {
        fetchdata();
      });
    }
  };

  const allDeposits = useSelector((state) => state.treasury.allDeposits);

  return (
    <div
      className=" w-100 p-0 py-4 pb-5 position-relative  "
      style={{
        background: "#534d3b",
        marginTop: " -15px !important",
        minHeight: "107vh",
        outline: " 8px solid #534d3b",
      }}
    >
      <div className="my-4">
        <MainHeader title="اضف تحصيل" />
      </div>
      <Container fluid>
        <Helmet>
          <title> اضافة تحصيل </title>
        </Helmet>

        <DepositForm onSubmit={handleSubmit} />
        {allDeposits && allDeposits.length > 0 && (
          <DepositsTable refresh={fetchdata} deposits={allDeposits} />
        )}
      </Container>
    </div>
  );
}

export default DepositPage;

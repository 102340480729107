import { Button, IconButton } from "@material-ui/core";
import CustomDialog from "../../../components/CustomDialog";
import DepositForm from "./DepositForm";
import React from "react";
import AccountForm from "./AccountForm";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

function UpdateDeposit({
  onUpdate,
  deposit,
  open,
  onClose,
  onDelete,
  fromAccount,
}) {
  const onUpdateClicked = (data) => {
    onUpdate(data);
    onClose();
  };

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      action1={
        <Button
          onClick={onClose}
          color="secondary"
          size="small"
          style={{ width: 56, height: 40 }}
        >
          الغاء
        </Button>
      }
      action2={
        fromAccount && (
          <IconButton style={{ color: "red" }} onClick={() => onDelete()}>
            <DeleteOutlineIcon />
          </IconButton>
        )
      }
    >
      {!fromAccount ? (
        <DepositForm
          fromAccount
          deposit={deposit}
          onUpdateClicked={onUpdateClicked}
          isUpdate={true}
        />
      ) : (
        <AccountForm deposit={deposit} onUpdateClicked={onUpdateClicked} />
      )}
    </CustomDialog>
  );
}

export default UpdateDeposit;

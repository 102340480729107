import {
  Button,
  DialogContentText,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import CustomDialog from "../../../components/CustomDialog";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import Alerto from "../../../components/UI/toaster";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const paymentTypes = [
  { id: 1, label: "اجل" },
  { id: 2, label: "نقدية" },
];
function AddBuyer({ name, open, onClose, onAddBuyer }) {
  const classes = useStyles();

  const [partnerName, setPartnerName] = useState("");
  const [balance, setBalance] = useState(20000);
  const [openbalance, setopenBalance] = useState(0);
  const [paymenttype, setpaymenttype] = useState(2);
  const [collectingAddresses, setCollectingAddresses] = useState([]);
  const [collectingAddress, setCollectingAddress] = useState("");

  const fetchCollectingAddresses = () => {
    axios
      .get("/CollectingAddress")
      .then((res) => {
        setCollectingAddresses(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  useEffect(() => {
    fetchCollectingAddresses();
  }, []);
  const inputRef = React.useRef(null);
  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (name) {
        setPartnerName(name);
      }
    }
    return () => {
      mounted = false;
    };
  }, [name]);
  const reset = () => {
    setBalance(20000);
    setopenBalance(0);
    setpaymenttype(2);
  };
  const handleSubmit = () => {
    onAddBuyer({
      partnerName,
      balance,
      openbalance,
      paymenttype,
      collectingAddress,
    });
    onClose();
    reset();
  };
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      action1={
        <Button
          onClick={onClose}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ width: 56, height: 40 }}
        >
          الغاء
        </Button>
      }
      action2={
        <Button
          variant="contained"
          color="secondary"
          style={{ width: 56, height: 40, marginLeft: 16 }}
          size="small"
          disabled={!(partnerName.length > 0 && balance > 0)}
          onClick={handleSubmit}
        >
          اضافة
        </Button>
      }
    >
      <DialogContentText>
        {paymenttype === 1
          ? ` اسم البائع غير موجود اختار نعم وادخل تنبيه الرصيد اذا كان جديد او ﻻ
        للبحث في القائمة`
          : ` اسم البائع غير موجود اختار نعم اذا كان جديد او ﻻ
     للبحث في القائمة`}
      </DialogContentText>
      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          onChange={(e) => setPartnerName(e.target.value)}
          value={partnerName}
          label="اسم البائع"
          size="small"
          variant="outlined"
          style={{ width: 240 }}
        />
        <TextField
          style={{ backgroundColor: "#fff", width: 180 }}
          size="small"
          select
          label="الدفع"
          onChange={(e) => setpaymenttype(e.target.value)}
          value={paymenttype}
          variant="outlined"
        >
          {paymentTypes.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {paymenttype === 1 && (
          <TextField
            onChange={(e) => setopenBalance(Number.parseFloat(e.target.value))}
            value={openbalance >= 0 ? openbalance : ""}
            label="رصيد افتتاحي "
            size="small"
            variant="outlined"
            style={{ width: 160 }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleSubmit();
              }
            }}
          />
        )}
        {paymenttype === 1 && (
          <TextField
            onChange={(e) => setBalance(Number.parseFloat(e.target.value))}
            value={balance >= 0 ? balance : ""}
            label="تنبيه الرصيد"
            size="small"
            variant="outlined"
            style={{ width: 160 }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleSubmit();
              }
            }}
          />
        )}
        {paymenttype === 1 && (
          <Autocomplete
            options={collectingAddresses}
            getOptionSelected={(option, value) => option.value === value?.value}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            size="small"
            style={{ width: 160 }}
            autoComplete
            onChange={(e, value) => {
              if (value) {
                setCollectingAddress(value);
              } else {
                setCollectingAddress("");
              }
            }}
            value={collectingAddress}
            noOptionsText="لم يتم العثور على خيارات"
            renderInput={(params) => (
              <TextField
                {...params}
                label="خط التحصيل"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleSubmit();
                  }
                }}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            )}
          />
        )}
      </form>
    </CustomDialog>
  );
}

export default AddBuyer;

import { Card, CardContent, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import ProfitsTable from "./components/ProfitsTable";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import MainHeader from "../../components/mainHeader/MainHeader";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
}));

function MonthlyProfits() {
  const [monthlyProfits, setMonthlyProfits] = useState([]);
  const [monthlyDate, setMonthlyDate] = useState(new Date());
  const classes = useStyles();
  const location = useLocation();
  const fromTreasury = location.state?.fromTreasury;
  const getMonthlyProfits = () => {
    axios
      .get(`/Statistics/Parametrized?year=${monthlyDate.getFullYear()}`)
      .then((res) => {
        setMonthlyProfits(res.data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getMonthlyProfits();
  }, [monthlyDate]);

  const headCells = [
    "شهر",
    "عدد الطرود المباعة",
    "المبيع",
    "المشتريات",
    "فواتير عمولات",
    "التحصيل",
    "المصروفات",
    "الهالك",
  ];
  const headCellsForTreasury = ["شهر", "التحصيل", "المصروفات", "رصيد شهر"];
  return (
    <div className={classes.root}>
      <div className="my-2">
        <MainHeader title="  تقارير شهرية" />
      </div>
      <CardContent
        className="headerSearch center  col-12 col-md-4 m-auto mt-3"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <DatePicker
          selected={monthlyDate}
          onChange={(date) => setMonthlyDate(date)}
          showYearPicker
          dateFormat="yyyy"
          customInput={
            <TextField
              className="m-auto w-100 "
              variant="outlined"
              size="small"
              label="السنة"
            />
          }
        />
      </CardContent>
      <div style={{ marginTop: 8 }}>
        <ProfitsTable
          headCells={!fromTreasury ? headCells : headCellsForTreasury}
          fromTreasury={fromTreasury}
          profits={monthlyProfits}
          type="monthly"
        />
      </div>
    </div>
  );
}

export default MonthlyProfits;

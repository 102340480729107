import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { ConvertToArabicNumbers, renderDate } from "../../utils";
import React, { useEffect } from "react";
import {
  deleteImporting,
  deleteImportingDetailsItem,
  getImporting,
  getRemainingDetails,
  updateImportingDetails,
  updateImportingItem,
} from "./importSlice";
import { fetchBuyers, fetchClients, fetchTools } from "../main-data/dataSlice";
import { useDispatch, useSelector } from "react-redux";

import DeleteBox from "../../components/DeleteBox";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteOutline";
import EditImportingDetails from "./components/EditImportingDetails";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ImportDetailTable from "./components/ImportDetailTable";
import { makeStyles } from "@material-ui/core/styles";
import { parseISO } from "date-fns";
import { useParams } from "react-router-dom";
import { isDayPast } from "../../components/Helpers/InsputSorting";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100%",
  },
  editIcon: {
    color: theme.palette.info.light,
  },
  trash: {
    color: theme.palette.error.light,
  },
  label: {
    fontSize: 16,
    marginRight: 8,
    display: "inline-block",
  },
}));

function ImportingDetails() {
  const { importingId } = useParams();
  const role = localStorage.getItem("role");

  const [isEditFormOpen, setIsEditFormOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const remainingTools = useSelector((state) => state.importing.remainingTools);
  const importing = useSelector((state) => state.importing.importing);
  const clients = useSelector((state) => state.data.clients);
  const buyers = useSelector((state) => state.data.buyers).filter((buyer) => {
    return buyer.partners_TypeId !== 3;
  });

  const partners = [...clients, ...buyers];
  const dispatch = useDispatch();

  const handleUpdateImporting = (data) => {
    dispatch(updateImportingDetails(data));
  };
  const disableAll = role == "موظف" && isDayPast(importing.data.dateTime);

  const handleDelete = () => {
    dispatch(deleteImporting(importing.data.id));
    setOpenDelete(false);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(fetchClients());
      dispatch(fetchBuyers());
      dispatch(fetchTools());
      if (importingId) {
        dispatch(getImporting(importingId)).then(({ payload }) => {
          if (payload.id) {
            dispatch(getRemainingDetails(payload.partner.id));
          }
        });
      }
    }
    return () => {
      mounted = false;
    };
  }, []);

  const handleUpdateItem = (item) => {
    dispatch(updateImportingItem(item));
  };

  const onDeleteItem = (id) => {
    dispatch(deleteImportingDetailsItem(id));
  };
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {importing.data && (
        <Card>
          <CardContent>
            <Grid container spacing={5} alignItems="center">
              <Grid item container spacing={5} md={7} xs={12}>
                {importing.data &&
                  importing.data.partner &&
                  importing.data.partner.name && (
                    <Grid item>
                      <Typography
                        color="textSecondary"
                        className={classes.label}
                      >
                        اﻻسم:
                      </Typography>
                      <Typography className={classes.label}>
                        {importing.data.partner.name}
                      </Typography>
                    </Grid>
                  )}
                <Grid item>
                  <Typography color="textSecondary" className={classes.label}>
                    التاريخ:{" "}
                  </Typography>
                  {importing.data.dateTime && (
                    <Typography className={classes.label}>
                      {ConvertToArabicNumbers(
                        renderDate(importing.data.dateTime)
                      )}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item container md={3}>
                <Grid item>
                  <Button
                    variant="outlined"
                    style={{ height: 40 }}
                    size="small"
                    onClick={() => {
                      window.location = "/tools-in";
                    }}
                  >
                    اضف تحصيل اخر
                  </Button>
                </Grid>
                <Grid item>
                  <IconButton
                    className={classes.editIcon}
                    disabled={disableAll}
                    onClick={() => {
                      setIsEditFormOpen(true);
                    }}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={disableAll}
                    className={classes.trash}
                    onClick={() => setOpenDelete(true)}
                  >
                    <DeleteForeverOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      <Grid container justifyContent="space-evenly">
        <Grid itemxs={12} md={5}>
          {remainingTools &&
            remainingTools.length > 0 &&
            importing &&
            importing.data.id && (
              <Card style={{ width: 360, marginTop: 12 }}>
                <CardContent>
                  <Typography
                    style={{ textAlign: "center", marginBottom: 10 }}
                    variant="body1"
                  >
                    متبقي العدة
                  </Typography>
                  {remainingTools.map((item, index) => (
                    <Box
                      key={item.tool.id}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: 16,
                      }}
                    >
                      <Typography color="textSecondary">
                        {" "}
                        {item.tool.name} :{" "}
                      </Typography>
                      <Typography style={{ marginRight: 16 }}>
                        {" "}
                        {ConvertToArabicNumbers(item.count)}{" "}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
              </Card>
            )}
        </Grid>
        <Grid item xs={12} md={5}>
          <ImportDetailTable
            disableAll={disableAll}
            items={importing.items}
            onUpdateItemClicked={handleUpdateItem}
            onDeleteItemClicked={onDeleteItem}
          />
        </Grid>
      </Grid>
      <EditImportingDetails
        disableTime={role == "موظف"}
        open={isEditFormOpen}
        onClose={() => setIsEditFormOpen(false)}
        importing={importing.data}
        partners={partners}
        onEditItemClicked={handleUpdateImporting}
      />
      <DeleteBox
        action={handleDelete}
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        message="هل تريد حذف الوارد"
      />
    </div>
  );
}

export default ImportingDetails;

import { Card, CardContent, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import ProfitsTable from "./components/ProfitsTable";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import MainHeader from "../../components/mainHeader/MainHeader";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
}));

function MonthlyProfits() {
  const [monthlyProfits, setMonthlyProfits] = useState([]);
  const [monthlyDate, setMonthlyDate] = useState(new Date());
  const classes = useStyles();

  const getMonthlyProfits = () => {
    axios
      .get(`/profits/Parametrized?year=${monthlyDate.getFullYear()}`)
      .then((res) => {
        setMonthlyProfits(res.data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getMonthlyProfits();
    }
    return () => {
      mounted = false;
    };
  }, [monthlyDate]);

  const headCells = [
    "الشهر",
    "بياعة البائعين",
    "عمولات النقلات",
    "فرق فواتير العمولة",
    "ربح المشتريات",
    "مشال",
    "مكتب",
    "ايجار عدة",
    "ثلاجة",
    "وارد الربح",
    "سماح",
    "هوالك",
    "خارج الربح",
    "اجمالي الربح",
  ];
  return (
    <div className={classes.root}>
      <div className="my-4">
        <MainHeader title=" الارباح الشهرية" />
      </div>
      <CardContent
        className="headerSearch center  col-12 col-md-4 m-auto mt-3"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <DatePicker
          selected={monthlyDate}
          onChange={(date) => setMonthlyDate(date)}
          style={{ with: "75%" }}
          showYearPicker
          dateFormat="yyyy"
          customInput={
            <TextField
              className="w-100 text-center"
              variant="outlined"
              size="small"
              label="السنة"
            />
          }
        />
      </CardContent>
      <div style={{ marginTop: 8 }}>
        <ProfitsTable
          headCells={headCells}
          profits={monthlyProfits}
          type="monthly"
        />
      </div>
    </div>
  );
}

export default MonthlyProfits;

import { Checkbox, FormControlLabel } from "@material-ui/core";

export default function DamageTypeSwitch({ isAnonymous, setIsAnonymous }) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isAnonymous}
          onChange={() => setIsAnonymous((prev) => !prev)}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label="هالك محل"
    />
  );
}

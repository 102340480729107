import { Paper, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";

import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { min } from "date-fns";
import { EnhancedTableHead } from "./Tables/EnhancedTableHead";

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      //padding: 8,
    },
  },

  body: {
    fontSize: 16,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      padding: 4,
    },
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme, props) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {},
  row: {
    border: "3px solid black",
  },
});

export default function CustomizedTable({
  tableHeader,
  children,
  bgColor,
  color,
  withSort,
  order,
  orderBy,
  handleRequestSort,
}) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        {!!withSort ? (
          <EnhancedTableHead
            onRequestSort={handleRequestSort}
            cells={tableHeader}
            order={order}
            orderBy={orderBy}
          />
        ) : (
          <TableHead>
            <TableRow>
              {tableHeader.map((title, index) => (
                <StyledTableCell
                  style={{
                    backgroundColor: "#F3C637",
                    minWidth: "130px",
                  }}
                  key={index}
                  align="center"
                >
                  <Typography variant="body2" style={{ fontWeight: 600 }}>
                    {typeof title === "string" ? title : title.label}
                  </Typography>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
}

import { CardContent, Typography } from "@material-ui/core";
import React from "react";

function MainHeader({ title }) {
  return (
    <CardContent
      className="headerSearch w-50 m-auto mt-3"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Typography variant="h6" className="text-black">
        {" "}
        {title}{" "}
      </Typography>
    </CardContent>
  );
}

export default MainHeader;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { indexOfId } from "../../utils";

import axios from "axios";
import { toast } from "react-toastify";
import Alerto from "../../components/UI/toaster";
import Mydate from "../../components/Helpers/MyDateParser";

export const addDamage = createAsyncThunk(
  "damage/addDamage",
  async (
    { partner, dateTime, damageType, money },
    { rejectWithValue, getState }
  ) => {
    try {
      if (damageType === "1") {
        const response = await axios.post("/MovementTools/damage/client", {
          partnerId: partner.id,
          adderId: getState().auth.user.UserId,
          dateTime: dateTime,
          money,
        });
        return response.data;
      } else if (damageType === "2") {
        const response = await axios.post("/MovementTools/damage/buyer", {
          partnerId: partner.id,
          adderId: getState().auth.user.UserId,
          dateTime: dateTime,
          money,
        });
        return response.data;
      } else if (damageType === "3") {
        const response = await axios.post("/MovementTools/damage/owner", {
          adderId: getState().auth.user.UserId,
          dateTime: dateTime,
        });

        return response.data;
      }
    } catch (error) {
      Alerto(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateDamage = createAsyncThunk(
  "damage/updateDamage",
  async (
    { partner, dateTime, money, damageType },
    { getState, rejectWithValue }
  ) => {
    const damage = getState().damage.damage.data;
    try {
      if (damageType === "1") {
        const response = await axios.put(
          `/MovementTools/damage/client/${damage.id}`,
          {
            partnerId: partner.id,
            adderId: getState().auth.user.UserId,
            dateTime,
            money,
          }
        );
        return response.data;
      } else if (damageType === "2") {
        const response = await axios.put(
          `/MovementTools/damage/buyer/${damage.id}`,
          {
            partnerId: partner.id,
            adderId: getState().auth.user.UserId,
            dateTime: dateTime,
            money,
          }
        );
        return response.data;
      } else if (damageType === "3") {
        const response = await axios.put(
          `/MovementTools/damage/owner/${getState().damage.damage.data.id}`,
          {
            adderId: getState().auth.user.UserId,
            dateTime: Mydate(dateTime),
          }
        );
        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDamage = createAsyncThunk(
  "damage/getDamage",
  async (damageId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/MovementTools/damage/${damageId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteDamage = createAsyncThunk(
  "damage/deleteDamage",
  async (_, { getState, rejectWithValue }) => {
    const id = getState().damage.damage.data.id;
    try {
      const response = await axios.delete(`/MovementTools/damage/${id}`);
      return response.data;
    } catch (error) {
      Alerto(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteDamageItem = createAsyncThunk(
  "damage/deleteDamageItem",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/MovementTools/damage/item/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addDamageItem = createAsyncThunk(
  "damage/addDamageItem",
  async (data, { getState, rejectWithValue }) => {
    const damageId = getState().damage.damage.data.id;
    try {
      const response = await axios.post("/MovementTools/damage/item", {
        damageId,
        movementToolId: data.movementTool.id,
        count: data.count,
      });
      toast.success("تمت الاضافة");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateDamageItem = createAsyncThunk(
  "damage/updateDamageItem",
  async (data, { getState, rejectWithValue }) => {
    const damageId = getState().damage.damage.data.id;
    try {
      const response = await axios.put(
        `/MovementTools/damage/item/${data.id}`,
        {
          id: data.id,
          damageId,
          movementToolId: data.movementTool.id,
          count: data.count,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchDamages = createAsyncThunk(
  "importing/searchDamages",
  async (dateTime, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/MovementTools/damage/search?dateTime=${Mydate(dateTime)}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  damage: {
    data: {},
    items: [],
    status: "idle",
  },
  damages: [],
};

const damageSlice = createSlice({
  name: "damage",
  initialState,

  extraReducers: {
    [addDamage.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        state.damage.data = action.meta.arg;
        state.damage.data.id = action.payload.id;
      }
    },
    [addDamage.rejected]: (state) => {
      state.damage.data = {};
      state.damage.items = [];
    },
    [updateDamage.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        state.damage.data = action.meta.arg;
        state.damage.data.id = action.payload.id;
      }
    },
    [getDamage.pending]: (state, action) => {
      state.damage.status = "loading";
    },

    [getDamage.fulfilled]: (state, action) => {
      state.damage.data = action.payload;
      state.damage.items = action.payload.items;
      state.damage.status = "succeeded";
    },

    [getDamage.rejected]: (state, action) => {
      state.damage.status = "failed";
    },
    [deleteDamage.fulfilled]: (state) => {
      state.damage = {};
    },

    [addDamageItem.pending]: (state, action) => {
      state.damage.items.push(action.meta.arg);
    },

    [addDamageItem.fulfilled]: (state, action) => {
      state.damage.items[state.damage.items.length - 1].id = action.payload.id;
    },
    [addDamageItem.rejected]: (state) => {
      if (state.damage.items) {
        state.damage.items.pop();
      }
    },
    [updateDamageItem.pending]: (state, action) => {
      const index = indexOfId(state.damage.items, action.meta.arg.id);
      state.damage.items[index] = action.meta.arg;
    },
    [deleteDamageItem.fulfilled]: (state, action) => {
      state.damage.items = state.damage.items.filter((item) => {
        return item.id !== action.meta.arg;
      });
    },

    [searchDamages.fulfilled]: (state, action) => {
      state.damages = action.payload;
    },
  },
});

//export const { addItem } = toolsSlice.actions;

export default damageSlice.reducer;

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import DeleteBox from '../../../components/DeleteBox';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    minHeight: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
    borderColor: '#222',
  },
  trash: {
    color: theme.palette.error.light,
  },
  searchIcon: {
    color: '#888',
  },
}));

function RoleForm({ userId, partnerId, partnerName }) {
  const [role, setRole] = useState('');
  const [newRole, setNewRole] = useState('');
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const getUserRole = () => {
    axios
      .get(`/Security/role?userId=${userId}`)
      .then((res) => {
        if (res.status === 200) {
          setRole(res.data);
        }
      })
      .catch((err) => {
      });
  };

  const addRoleToUser = () => {
    axios
      .post(`/Security/AddUserToARole?partnerId=${partnerId}&role=${newRole}`)
      .then((res) => {
        if (res.status === 200) {
          setRole(newRole);
          toast.success('تم تحديث الوظيفة');
          setIsEditFormOpen(false);
          window.location.reload(false);
        }
      })
      .catch((error) => {
      });
  };
  const deleteUserRole = () => {
    axios
      .delete(
        `/Security/RemoveUserFromARole?partnerId=${partnerId}&role=${role}`
      )
      .then((res) => {
        if (res.status === 200) {
          window.location.reload(false);
        }
      });
  };
  const deleteUserRoleAndAdd = () => {
    axios
      .delete(
        `/Security/RemoveUserFromARole?partnerId=${partnerId}&role=${role}`
      )
      .then((res) => {
        if (res.status === 200) {
          addRoleToUser();
        }
      });
  };
  const updateUserRole = () => {
    if (role) {
      deleteUserRoleAndAdd();
    } else {
      addRoleToUser();
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUserRole();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const roles = ['مدير', 'مشرف', 'موظف', 'محصل'];

  const classes = useStyles();
  return (
    <Card>
      <CardHeader title="تعديل الصلاحيات للمستخدم" />
      <Divider />
      <CardContent>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item>
            <Typography variant="h5"> {partnerName}</Typography>
          </Grid>
          {!isEditFormOpen && (
            <>
              <Grid item>
                <div style={{ display: 'flex' }}>
                  <Typography variant="body1" color="textSecondary">
                    {' '}
                    الصلاحية:{' '}
                  </Typography>
                  <Typography style={{ marginRight: 4 }} variant="body1">
                    {role ? role : 'عميل او بائع'}
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <Button
                  component={Link}
                  style={{
                    color: '#2F9DDD',
                    height: 0,
                    backgroundColor: 'transparent',
                  }}
                  variant="text"
                  endIcon={<EditOutlinedIcon />}
                  onClick={() => setIsEditFormOpen(true)}
                >
                  تعديل
                </Button>
              </Grid>
              {role && (
                <Grid item>
                  <Button
                    component={Link}
                    style={{
                      color: '#f13',
                    }}
                    variant="text"
                    onClick={() => setOpenDelete(true)}
                  >
                    حذف
                  </Button>
                </Grid>
              )}
              <DeleteBox
                action={() => {
                  deleteUserRole();
                  setOpenDelete(false);
                }}
                message="هل تريد حذف الصلاحية للمستخدم"
                open={openDelete}
                onClose={() => setOpenDelete(false)}
              />
            </>
          )}

          {isEditFormOpen && (
            <Grid item container justifyContent="center" alignItems="center">
              <Grid item>
                <FormControl
                  variant="outlined"
                  size="small"
                  className={classes.formControl}
                >
                  <InputLabel style={{ color: '#222' }}>الصلاحية</InputLabel>
                  <Select
                    value={newRole}
                    onChange={(e) => setNewRole(e.target.value)}
                    style={{ borderColor: '#222' }}
                  >
                    {roles.map((r) => (
                      <MenuItem key={r} value={r}>
                        {' '}
                        {r}{' '}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={false}
                  style={{ height: 40, width: 80 }}
                  onClick={updateUserRole}
                >
                  تعديل
                </Button>
              </Grid>
              <Grid item>
                <Button variant="text" onClick={() => setIsEditFormOpen(false)}>
                  الغاء
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default RoleForm;

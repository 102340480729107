import { ConvertToArabicNumbers } from '../../../utils';
import React from 'react';
import { Typography } from '@material-ui/core';

function ConclusionLabel({ label, value }) {
  return (
    <div
      style={{
        padding: 4,
        border: '1px solid #777',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="body1"
        style={{
          padding: 2,
          border: '1px solid #777',
          margin: '4px 0',
        }}
      >
        {label}
      </Typography>
      <Typography variant="h6" style={{ backgroundColor: '#f1f1f1' }}>
        {ConvertToArabicNumbers(value)}
      </Typography>
    </div>
  );
}

export default ConclusionLabel;

import * as React from "react";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@material-ui/core";
import { ConvertToArabicNumbers, getWeekDay, renderDate } from "../../../utils";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { makeStyles } from "@material-ui/styles";
import EditImportingItem from "../../movement-tools/components/EditImportingItem";
import DeleteBox from "../../../components/DeleteBox";
import { dynamicReq } from "../../../components/Helpers/ajax";
import { isDayPast } from "../../../components/Helpers/InsputSorting";
const limitedTransactionIds = [0, 6, 9, 10];

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "collaps",
    borderSpacing: "2px 4px",
    border: "3px solid #FFF",
  },
  tableRow: {
    // backgroundColor: "#003466",
    // new
    backgroundColor: "#F3C637",
  },
  tableCell: {
    border: "1px solid #919191",
    color: "#000",
    padding: 0,
    fontSize: 16,
  },
}));

export default function CurrentToolsTable({
  currentAccount,
  onPrintBorders,
  indexesArr,
  isPrint,
  partner,
  refresh,
}) {
  const getStateColor = (stateId) => {
    switch (stateId) {
      case 1:
        return { backgroundColor: "#FFFE01" };
      case 2:
        return { backgroundColor: "#FFFE01" };

      case 3:
        return { backgroundColor: "#ff3232", color: "#FFF" };

      case 4:
        return { backgroundColor: "#3c3ff2", color: "#FFF" };

      default:
        return { backgroundColor: "#D9D9DB" };
    }
  };
  const getBStateColor = (stateId) => {
    switch (stateId) {
      case 1:
        return { backgroundColor: "#FFFE01" };
      case 2:
        return { backgroundColor: "#FFFE01" };

      case 3:
        return { backgroundColor: "#fff", color: "#black" };

      case 4:
        return { backgroundColor: "#fff", color: "black" };

      default:
        return { backgroundColor: "#D9D9DB" };
    }
  };

  const headCells = !isPrint
    ? [
        "تعديل",
        "حالة",
        "الرهن",
        "العدد",
        "الحالة",
        "النوع",
        "العدد",
        "الصنف",
        "التاريخ",
        "اليوم",
        "الهالك",
        "طباعة",
      ]
    : [
        "حالة",
        "الرهن",
        "العدد",
        "الحالة",
        "النوع",
        "العدد",
        "الصنف",
        "التاريخ",
        "اليوم",
        "الهالك",
      ];
  function handleChosedRow(row) {
    const data = { ...row, partner };
    setSelectedItem(data);
  }
  const classes = useStyles();
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({});
  const role = localStorage.getItem("role");
  const eligableRoles = ["إدارة", "مدير", "مشرف", "موظف"];
  const isEligableToEdit = eligableRoles.includes(role);
  const baseApis = {
    3: "MovementTools/importing/",
    5: "MovementTools/exporting/",
    7: "MovementTools/Damage/",
  };
  function determineApi(stateId, data) {
    switch (stateId) {
      case 0:
      case 9:
        return `Selling/UpdateSellingTool?truckTypeId=${
          stateId === 0 ? 1 : 2
        }&id=${data.id}&toolId=${data.toolId}&count=${data.count}`;
      case 10:
      case 6:
        return `Truck/UpdateItemTool?type=${stateId === 10 ? 1 : 2}&id=${
          data.id
        }&toolId=${data.toolId}&count=${data.count}`;

      default:
        return undefined;
    }
  }
  const updateHandler = (data) => {
    let baseApi = baseApis[selectedItem.stateId];
    if (!baseApi)
      baseApi = determineApi(selectedItem.stateId, data.operations[0]);
    if (!baseApi) return;
    const isLimited = limitedTransactionIds.includes(selectedItem.stateId);
    const targetApi = isLimited ? baseApi : baseApi + "addOrUpdate";
    const targetBody = isLimited ? {} : data;
    const reqType = isLimited ? "put" : "post";
    dynamicReq({
      type: reqType,
      api: targetApi,
      callback: refresh,
      data: targetBody,
      msg: "تم التعديل",
    });
  };

  const deleteHandler = (target) => {
    const baseApi = baseApis[target.stateId];
    if (!baseApi) return;
    dynamicReq({
      type: "delete",
      api: baseApi + target.click.id,
      callback: refresh,
      msg: "تم الحذف",
    });
  };
  return (
    <TableContainer
      className="p-1 border-0 bg-transparents overflow-auto mb-0"
      style={!isPrint ? { width: "100%" } : {}}
      component={Paper}
    >
      <Table
        className={classes.table}
        style={{ border: "2px solid", width: "100%" }}
      >
        <TableHead className={classes.tableRow}>
          <TableRow style={{ padding: 0 }}>
            {headCells.map((headCell, index) => (
              <TableCell style={{ padding: 6 }} key={index} align="center">
                <Typography style={{ color: "#000", fontWeight: "500" }}>
                  {headCell}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {currentAccount.map((row, index) => (
            <TableRow key={index}>
              {!isPrint && (
                <TableCell
                  className="p-0"
                  align="center"
                  style={{
                    background:
                      row.click === null && !isPrint ? "#534d3b" : "black",
                  }}
                >
                  {isEligableToEdit && row.click && (
                    <IconButton
                      className=" h-100 w-100 text-white center"
                      disabled={role == "موظف" && isDayPast(row.dateTime)}
                      onClick={() => {
                        setOpenUpdate(true);
                        handleChosedRow(row);
                      }}
                    >
                      <EditOutlinedIcon
                        style={{ color: "white" }}
                        classname="p-0"
                      />
                    </IconButton>
                  )}
                </TableCell>
              )}

              <TableCell
                style={{
                  background:
                    !row.creditorOrDebtor && !isPrint ? "#534d3b" : "white",
                }}
                className={classes.tableCell}
                align="center"
              >
                {row.creditorOrDebtor}
              </TableCell>

              <TableCell
                style={{
                  background: !row.mortage && !isPrint ? "#534d3b" : "white",
                }}
                className={classes.tableCell}
                align="center"
              >
                {ConvertToArabicNumbers(row.mortage) || ""}
              </TableCell>

              <TableCell
                className={classes.tableCell}
                style={{
                  // backgroundColor: row.count === null ? "#534d3b" : "white",
                  backgroundColor:
                    row.count === null && !isPrint ? "#534d3b" : "white",
                }}
                align="center"
              >
                {" "}
                {row.count || row.stateId != 4
                  ? ConvertToArabicNumbers(row.count)
                  : "صفر"}
              </TableCell>

              {!isPrint ? (
                <TableCell
                  style={{
                    backgroundColor: isPrint ? "#534d3b" : "white",
                    ...getStateColor(row.stateId),

                    width: 180,
                  }}
                  className={classes.tableCell}
                  align="center"
                >
                  {row.stateName}
                </TableCell>
              ) : (
                <TableCell
                  style={{
                    backgroundColor: "white",
                    width: 180,
                  }}
                  className={classes.tableCell}
                  align="center"
                >
                  {row.stateName}
                </TableCell>
              )}

              {!isPrint ? (
                <TableCell
                  style={{
                    ...getStateColor(row.stateId),
                    width: 120,
                  }}
                  className={classes.tableCell}
                  align="center"
                >
                  {row.toolName}
                </TableCell>
              ) : (
                <TableCell
                  style={{
                    background: "#fff",
                  }}
                  className={classes.tableCell}
                  align="center"
                >
                  {row.toolName}
                </TableCell>
              )}

              {!isPrint ? (
                <TableCell
                  style={{
                    backgroundColor:
                      row.productCount === null
                        ? "#534d3b"
                        : getBStateColor(row.stateId).backgroundColor,
                  }}
                  className={classes.tableCell}
                  align="center"
                >
                  {ConvertToArabicNumbers(row.productCount)}
                </TableCell>
              ) : (
                <TableCell
                  style={{
                    backgroundColor: "#fff",
                  }}
                  className={classes.tableCell}
                  align="center"
                >
                  {ConvertToArabicNumbers(row.productCount)}
                </TableCell>
              )}

              {!isPrint ? (
                <TableCell
                  style={{
                    backgroundColor:
                      row.productCount === null
                        ? "#534d3b"
                        : getBStateColor(row.stateId).backgroundColor,
                    width: 120,
                  }}
                  className={classes.tableCell}
                  align="center"
                >
                  {row.productName}
                </TableCell>
              ) : (
                <TableCell
                  style={{
                    backgroundColor: "#fff",
                    width: 120,
                  }}
                  className={classes.tableCell}
                  align="center"
                >
                  {row.productName}
                </TableCell>
              )}

              {!isPrint ? (
                <TableCell
                  style={{
                    backgroundColor:
                      row.dateTime === null
                        ? "#534d3b"
                        : getBStateColor(row.stateId).backgroundColor,
                    border: ".1px solid gray",
                  }}
                  align="center"
                >
                  {row.dateTime
                    ? ConvertToArabicNumbers(renderDate(row.dateTime))
                    : ""}
                </TableCell>
              ) : (
                <TableCell
                  style={{
                    border: ".1px solid gray",
                  }}
                  align="center"
                >
                  {row.dateTime
                    ? ConvertToArabicNumbers(renderDate(row.dateTime))
                    : ""}
                </TableCell>
              )}

              {!isPrint ? (
                <TableCell
                  style={{
                    backgroundColor:
                      row.dateTime === null
                        ? "#534d3b"
                        : getBStateColor(row.stateId).backgroundColor,
                    border: ".1px solid gray",
                  }}
                  align="center"
                >
                  {row.dateTime ? getWeekDay(row.dateTime) : ""}
                </TableCell>
              ) : (
                <TableCell
                  style={{
                    border: ".1px solid gray",
                  }}
                  align="center"
                >
                  {row.dateTime ? getWeekDay(row.dateTime) : ""}
                </TableCell>
              )}
              {!isPrint ? (
                <TableCell
                  style={{
                    backgroundColor:
                      row.damagePrice === null
                        ? "#534d3b"
                        : getBStateColor(row.stateId).backgroundColor,
                    border: ".1px solid gray",
                  }}
                  align="center"
                >
                  {ConvertToArabicNumbers(row.damagePrice)}
                </TableCell>
              ) : (
                <TableCell
                  style={{
                    border: ".1px solid gray",
                  }}
                  align="center"
                >
                  {ConvertToArabicNumbers(row.damagePrice)}
                </TableCell>
              )}
              {!isPrint && (
                <TableCell className={classes.tableCell} align="center">
                  <Checkbox
                    disabled={
                      indexesArr &&
                      indexesArr.length === 2 &&
                      !indexesArr.includes(index)
                    }
                    onClick={() => {
                      onPrintBorders(index);
                    }}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {openUpdate && (
        <EditImportingItem
          isCurrentAccount
          open={openUpdate}
          item={selectedItem}
          onEditItemClicked={updateHandler}
          onClose={() => setOpenUpdate(false)}
          onDelete={() => setOpenDelete(true)}
        />
      )}
      {openDelete && (
        <DeleteBox
          action={() => {
            deleteHandler(selectedItem);
            setOpenDelete(false);
            setOpenUpdate(false);
          }}
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          message="هل تريد حذف العنصر"
        />
      )}
    </TableContainer>
  );
}

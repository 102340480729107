import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import ar from "date-fns/locale/ar-SA";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import axios from "axios";
import Alerto from "../../components/UI/toaster";
import { Autocomplete } from "@material-ui/lab";
import Mydate from "../../components/Helpers/MyDateParser";
import { useEffect } from "react";
import NameHistoryTable from "./components/NameHistoryTable";
import FromToDateHandler from "./components/FromToDateHandler";
import { Container } from "react-bootstrap";
import MainHeader from "../../components/mainHeader/MainHeader";
const actions = [
  { label: "اضافة", id: "1", state: true },
  { label: "تعديل", id: "2", state: false },
  { label: "حذف", id: "3", state: false },
];
registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  radio: {
    margin: "0vh 0vw 0vh 10vw",
    display: "block",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 12,
  },
  SearchForm: {
    width: "20vw",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    "& > *": {
      marginRight: 12,
    },
  },
}));

function NamesHistory() {
  const [mainData, setMainData] = useState([]);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [fromTime, setFromTime] = useState(new Date());
  const [toTime, setToTime] = useState(new Date());
  const [parsedFrom, setParsedFrom] = useState(Mydate(new Date()));
  const [parsedTo, setParsedTo] = useState(Mydate(new Date()));
  const [Namesearch, setNameSearch] = useState("");
  const [Actions, setActions] = useState(actions);

  const checkhandler2 = (id) => {
    let element = Actions.filter((ele) => ele.id == id);
    if (!element[0].state) {
      return false;
    }
    return true;
  };
  const handleAction = (id) => {
    let arr = [...Actions];
    let element = arr.filter((ele) => ele.id == id);
    let index = arr.indexOf(element[0]);
    element[0].state = !element[0].state;
    arr[index] = element[0];
    setActions(arr);
  };
  const getUsers = () => {
    axios
      .get(`/Security/users`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  React.useEffect(() => {
    getUsers();
  }, []);
  const mainDataHandler = async () => {
    try {
      const res = await axios.post(`/History/maindata/partners`, {
        recorderId: user.userId,
        from: parsedFrom,
        to: parsedTo,
        added: Actions[0].state,
        updated: Actions[1].state,
        deleted: Actions[2].state,
      });
      setMainData(res.data);
    } catch (err) {
      Alerto(err);
    }
  };

  useEffect(() => {
    mainDataHandler();
  }, [Actions, user, parsedFrom, parsedTo]);
  const classes = useStyles();
  const manageFromDate = (d) => {
    setFromTime(d);
    setParsedFrom(Mydate(d));
  };
  const manageToDate = (d) => {
    setToTime(d);
    setParsedTo(Mydate(d));
  };

  return (
    <div className={classes.root}>
      <div className="my-2">
        <MainHeader title="سجل قائمة الأسماء" />
      </div>
      <Container>
        <CardContent className="headerSearch mt-3 center  m-auto ">
          <Grid
            container
            spacing={2}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <FromToDateHandler
              toTime={toTime}
              fromTime={fromTime}
              handleFrom={manageFromDate}
              handleTo={manageToDate}
            />
            <Grid item xs={12} md={3}>
              <Autocomplete
                size="small"
                style={{ width: "100%" }}
                options={users}
                getOptionSelected={(option, value) => {
                  return option.value === value?.value;
                }}
                getOptionLabel={(option) =>
                  option.partnerName ? option.partnerName : ""
                }
                autoHighlight
                autoComplete
                noOptionsText="لم يتم العثور على خيارات"
                onChange={(event, value) => {
                  if (value) {
                    setUser(value);
                  } else {
                    setUser({});
                  }
                }}
                value={user}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="بحث بأسم المستخدم"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                placeholder="بحث بالأسم "
                className={classes.searchBar}
                size="small"
                value={Namesearch}
                style={{ width: "100%" }}
                onChange={(e) => setNameSearch(e.target.value)}
              />
            </Grid>

            <Grid className="center" xs={12}>
              <FormControl
                component="fieldset"
                style={{ marginBottom: 4, display: "block" }}
              >
                <RadioGroup
                  row
                  onClick={(event) => {
                    handleAction(event.target.value);
                  }}
                >
                  {actions.map((ele) => (
                    <FormControlLabel
                      value={ele.id}
                      control={
                        <Radio
                          style={{
                            color: "#fbc02d",
                          }}
                          checked={checkhandler2(ele.id)}
                        />
                      }
                      label={ele.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Container>
      <Container fluid>
        {mainData[0] && (
          <NameHistoryTable search={Namesearch} data={mainData} />
        )}
      </Container>
    </div>
  );
}

export default NamesHistory;

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { ConvertToArabicNumbers } from '../../../utils';
import LogoImage from '../../../components/LogoImage';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import Total from '../../../components/UI/Total';
import { useReactToPrint } from 'react-to-print';

const useStyles = makeStyles(() => ({
  total: {
    display: 'none',
  },
  logo: { display: 'none' },
  '@media print': {
    total: {
      display: 'flex',
      width: 350,
      justifyContent: 'space-between',
      margin: 8,
    },
    printIcon: {
      display: 'none',
    },
    logo: { display: 'block' },
  },
}));

function DepositsReport({
  deposits,
  reportName,
  totalPrice,
  totalAllow,
  searchQuery,
}) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: reportName,
  });

  const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.textPrimary,
      fontSize: 18,
    },
    body: {
      fontSize: 16,
      overflow: 'auto',
    },
  }))(TableCell);

  const classes = useStyles();
  const headCells = ['الاسم', 'المبلغ', 'السماح'];
  return (
    <div
      style={{
        marginTop: 16,
        display: 'flex',
        justifyContent: 'center',
      }}
      ref={componentRef}
    >
      {deposits && deposits.length > 0 && (
        <TableContainer component={Paper} style={{ width: '100%' }}>
          <div className={classes.logo}>
            <LogoImage />
          </div>
          <Toolbar
            style={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: '#f3c637',
            }}
          >
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {reportName}
            </Typography>

            <div className={classes.printIcon} >
              <Tooltip title="طباعة التقرير">
                <IconButton style={{color:'#000'}} onClick={handlePrint}>
                  <PrintOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((item, index) => (
                  <StyledTableCell key={index} align="center">
                    {item}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {deposits
                .filter((item) => item.partner.name.includes(searchQuery))
                .map((row, index) => (
                  <TableRow
                    key={row.partner.id}
                    align="center"
                    className={classes.tableBody}
                  >
                    <StyledTableCell align="center">
                      <Typography variant="body1" color="textPrimary">
                        {row.partner.name}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <Typography variant="body1" color="textPrimary">
                        {ConvertToArabicNumbers(row.price)}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <Typography variant="body1" color="textPrimary">
                        {row.allow !== 0
                          ? ConvertToArabicNumbers(row.allow)
                          : 'ﻻ يوجد'}
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div className={classes.total}>
            <Total label="اجمالي التحصيل" value={totalPrice} />
            <Total label="اجمالي السماح" value={totalAllow} />
          </div>
        </TableContainer>
      )}
    </div>
  );
}

DepositsReport.defaultProps = {
  searchQuery: '',
};

export default DepositsReport;

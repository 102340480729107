import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ConvertToArabicNumbers } from "../../../utils";
import Alerto from "../../../components/UI/toaster";
import { addCommisionItem } from "../loadsSlice";
const AddproductOnTheFly = ({ chosedProduct, chosedTruck, onClose }) => {
  const tools = useSelector((state) => state.data.tools);
  const [itemsCount, setItemsCount] = useState("");
  const [weight, setWeight] = useState(0);
  const [price, setPrice] = useState(0);
  const [commisionForClient, setCommisionForClient] = useState(0);
  const [mostMovementTool, setMostMovementTool] = useState("");
  function clear() {
    setPrice(0);
    setCommisionForClient(0);
    setItemsCount(0);
    setWeight(0);
    setMostMovementTool("");
  }
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    try {
      const data = {
        truck: chosedTruck,
        product: chosedProduct,
        price: price || 0,
        commisionForClient: commisionForClient || 0,
        weight: weight || 0,
        itemsCount: itemsCount || 0,
        mostCommision: 0,
        mostSellingPrice: 0,
        mostMovementTool: mostMovementTool,
      };
      dispatch(addCommisionItem(data));
      clear();
      onClose();
    } catch (e) {
      Alerto(e);
    }
  };

  return (
    <Box mt={1}>
      <Card
        style={{
          boxShadow:
            "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
        }}
      >
        <CardContent>
          <form noValidate autoComplete="off">
            <Grid container spacing={1} direction="row" alignItems="center">
              <Grid item xs={6} md={2}>
                <TextField
                  value={itemsCount >= 0 ? itemsCount : ""}
                  onChange={(e) =>
                    setItemsCount(Number.parseFloat(e.target.value))
                  }
                  className="w-100"
                  label="العدد"
                  variant="outlined"
                  size="small"
                  type="number"
                />
              </Grid>
              {chosedTruck?.truckTypeId === 2 && (
                <>
                  <Grid item xs={6} md={2}>
                    <TextField
                      value={weight >= 0 ? weight : ""}
                      onChange={(e) =>
                        setWeight(Number.parseFloat(e.target.value))
                      }
                      className="w-100"
                      label="الوزن"
                      variant="outlined"
                      size="small"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <TextField
                      value={price >= 0 ? price : ""}
                      onChange={(e) =>
                        setPrice(Number.parseFloat(e.target.value))
                      }
                      className="w-100"
                      label="سعر الشراء"
                      variant="outlined"
                      size="small"
                      type="number"
                    />
                  </Grid>

                  <Grid item xs={6} md={2}>
                    <TextField
                      value={commisionForClient >= 0 ? commisionForClient : ""}
                      onChange={(e) =>
                        setCommisionForClient(Number.parseFloat(e.target.value))
                      }
                      className="w-100"
                      label="بياعة الشراء"
                      variant="outlined"
                      size="small"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Typography
                      style={{
                        backgroundColor: "#f5f5f5",
                        borderRadius: 4,
                        height: 48,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0px 8px",
                      }}
                      variant="body2"
                    >
                      الاجمالي:
                      {price >= 0 &&
                        weight >= 0 &&
                        commisionForClient >= 0 &&
                        itemsCount >= 0 &&
                        ConvertToArabicNumbers(
                          price * weight + commisionForClient * itemsCount
                        )}
                    </Typography>
                  </Grid>
                </>
              )}

              <Grid item xs={6} md={2}>
                <Autocomplete
                  options={tools}
                  openOnFocus
                  getOptionSelected={(option, value) =>
                    option.value === value?.value
                  }
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  className="w-100"
                  autoHighlight
                  autoComplete
                  freeSolo
                  value={mostMovementTool}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setMostMovementTool(newValue);
                    } else {
                      setMostMovementTool("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="العدة"
                      variant="outlined"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              {/* <Grid item xs={6} md={2}>
                <TextField
                  value={mostSellingPrice >= 0 ? mostSellingPrice : ""}
                  onChange={(e) =>
                    setMostSellingPrice(Number.parseFloat(e.target.value))
                  }
                  style={{ width: 120 }}
                  label="سعر البيع"
                  variant="outlined"
                  size="small"
                  type="number"
                />
              </Grid>

              <Grid item xs={6} md={2}>
                <TextField
                  value={mostCommision >= 0 ? mostCommision : ""}
                  onChange={(e) =>
                    setMostCommision(Number.parseFloat(e.target.value))
                  }
                  style={{ width: 120 }}
                  label="بياعة البيع"
                  id="commission"
                  variant="outlined"
                  size="small"
                  type="number"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      handleSubmit();
                    }
                  }}
                />
              </Grid> */}

              <Grid item xs={12} md={12} className="center">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 140 }}
                  onClick={handleSubmit}
                  className="m-auto"
                >
                  اضافة
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};
export default AddproductOnTheFly;

function similarity(s1, s2) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  let arr1 = Array.from(s1);
  let arr2 = Array.from(s2);
  let valid1 = arr1.filter((ele, index) => ele.trim() != "");
  let valid2 = arr2.filter((ele, index) => ele.trim() != "");
  let ponus = 0;
  valid2.forEach((ele, indx) => {
    ele === valid1[indx] && ponus++;
  });
  return ponus / valid2.length;
}
function compare(a, b, inp) {
  const aV = similarity(a.name, inp);
  const bV = similarity(b.name, inp);
  return aV > bV ? -1 : aV < bV ? 1 : 0;
}
export const InputSorting = (data, inp) => {
  if (inp.trim().length == 0) return data;
  const filteredArr = data.filter((ele) => ele.name.includes(inp));
  return filteredArr.sort((a, b) => compare(a, b, inp));
};

export function isDayPast(dateString) {
  // Create a Date object for the input date
  const inputDate = new Date(dateString);
  inputDate.setHours(0, 0, 0, 0); // Normalize the time part to midnight for accurate comparison

  // Create a Date object for the current date and normalize its time part to midnight
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Compare the input date with today's date
  return inputDate < today;
}

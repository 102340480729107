import {
  Button,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ConvertToArabicNumbers, getDateDashed, renderDate } from "../../utils";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import LogoImage from "../../components/LogoImage";
import MoneyTable from "./components/MoneyTable";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import RemainingDetails from "../movement-tools/components/RemainingDetails";
import { getRemainingDetailsForPartners } from "../movement-tools/tools-reports/toolsSlice";
import { useDispatch, useSelector } from "react-redux";
import Alerto from "../../components/UI/toaster";
import "./clientAccount.css";
import { Col, Container, Row } from "react-bootstrap";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";

import HistoryIcon from "@mui/icons-material/History";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AccountHeader from "./components/accountHeader";
import ToolsInfo from "../../components/ToolsForm/partials/ToolsInfo";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  infoBox: {
    width: 120,
    padding: 8,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
  },
  printableTable: {
    overflow: "hidden",
    height: 0,
  },
  profileData: {
    backgroundColor: "#FD8D00",
    width: "100%",
    padding: 4,
    position: "relative",
  },
  "@media print": {
    printableTable: {
      height: "auto",
      overflowY: "auto",
    },
    printHidden: {
      display: "none",
    },
    profileData: {
      backgroundColor: "#FD8D00",
      width: "auto",
      padding: 4,
    },
  },
}));
function ClientAccount() {
  const { type, partnerId, factor, factor2 } = useParams();
  const [showDate, setShowDate] = useState(false);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");

  const [clientMoney, setClientMoney] = useState([]);
  const role = localStorage.getItem("role");
  const remainingDetails = useSelector((state) => state.tools.remainingDetails);
  const dispatch = useDispatch();
  const [openDetails, setOpenDetails] = useState(false);
  const onDetailsClicked = (partnerId) => {
    setOpenDetails(true);
    dispatch(getRemainingDetailsForPartners(partnerId));
  };
  const getClientsMoney = () => {
    axios
      .get(
        `/MoneyCurrentAccountReport/client${factor ? "/" + factor : ""}${
          factor2 ? "/" + factor2 : ""
        }?partnerId=${partnerId}&from=${getDateDashed(
          fromDate
        )}&to=${getDateDashed(toDate)}`
      )
      .then((res) => {
        setClientMoney(() => [...res.data]);
      })
      .catch((err) => {});
  };
  const getClientLastTreatment = () => {
    const api = !factor
      ? `/MoneyCurrentAccountReport/lastOperation/client/${partnerId}`
      : factor === "rep"
      ? `/MoneyCurrentAccountReport/lastOperation/client/trucks/rep/${partnerId}`
      : factor === "trucks"
      ? `/MoneyCurrentAccountReport/lastOperation/client/trucks/owner/${partnerId}`
      : `/MoneyCurrentAccountReport/LastOperation/client/loans/${partnerId}`;
    axios
      .get(api)
      .then((res) => {
        if (res.status === 204) {
          toast.info("ﻻ يوجد تعامل مع هذا المستخدم");
          return;
        }
        setShowDate(true);
        let lastDate = new Date(res.data);
        lastDate.setDate(lastDate.getDate() + 1);

        setToDate(lastDate);
        let myFrom = new Date(res.data);
        myFrom.setMonth(myFrom.getMonth() - 1);
        setFromDate(myFrom);
      })
      .catch((err) => {});
  };

  const [clientDebt, setClientDebt] = useState("");
  const getClientDebtDetails = () => {
    axios
      .get(`/MoneyCurrentAccountReport/debtDetails/client/${partnerId}`)
      .then((res) => {
        setClientDebt(res.data);
      })
      .catch((err) => {});
  };

  const [buyerDebt, setBuyerDebt] = useState("");
  const getBuyerDebtDetails = () => {
    axios
      .get(`/MoneyCurrentAccountReport/debtDetails/buyer/${partnerId}`)
      .then((res) => {
        setBuyerDebt(res.data);
      })
      .catch((err) => {});
  };
  const [allTools, setAllTools] = useState([]);

  const getAllTools = () => {
    axios
      .get(`/MovementTools/reports/Remaining/${partnerId}`)
      .then((res) => {
        setAllTools(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  const totalToolsCount = allTools.reduce((acc, val) => {
    return acc + val.count;
  }, 0);
  const [totalMortage, setTotalMortage] = useState("");

  const getPriceOfRemainingTools = () => {
    axios
      .get(`/MovementTools/reports/Remaining/price/${partnerId}`)
      .then((res) => {
        setTotalMortage(res.data);
      })
      .catch((err) => {});
  };
  const [partner, setPartner] = useState("");
  const getPartnerData = () => {
    axios.get(`/Partners/${partnerId}`).then((res) => {
      setPartner(res.data);
    });
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getPartnerData();
      getClientLastTreatment();
      getClientDebtDetails();
      getPriceOfRemainingTools();
      getBuyerDebtDetails();
      getAllTools();
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (fromDate && toDate) {
        getClientsMoney();
      }
    }
    return () => {
      mounted = false;
    };
  }, [fromDate]);

  const [last, setLast] = useState(true);
  const reloadhandler = () => {
    let partnerDate = new Date(partner.creationDate);
    const y = fromDate && fromDate.getFullYear() >= partnerDate.getFullYear();

    if (y || last) {
      const newFromDate = new Date(fromDate.setMonth(fromDate.getMonth() - 1));
      setFromDate(newFromDate);
      !y && setLast(false);
    }
  };

  const totalClientMoney =
    clientDebt.totalInvoices - (clientDebt.totalLoans + totalMortage);

  const [NetDebt, setNetDabt] = useState("");
  const getNetDebt = () => {
    let data = {
      trucksInvoices: clientDebt.totalInvoices,
      sellingsInvoices: buyerDebt.totalInvoices,
      totalClientLoans: clientDebt.totalLoans,
      totalBuyerLoans: buyerDebt.totalLoans,
      totalToolMortage: totalMortage != "" ? totalMortage : 0,
      //  totalSalaryDebt: employeeDebt != "" ? employeeDebt : 0,
    };
    axios
      .post(`MoneyCurrentAccountReport/calculator/debt/total`, data)
      .then((res) => {
        setNetDabt(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  useEffect(() => {
    getNetDebt();
  }, [clientDebt, buyerDebt, totalMortage]);
  const [indexesArr, setIndexesArr] = React.useState([]);
  const onPrintBorders = (index) => {
    let arr = [];
    if (indexesArr.includes(index)) {
      let indexes = [...indexesArr];
      indexes.splice(indexes.indexOf(index), 1);
      arr = indexes;
      setIndexesArr(indexes);
      return;
    }
    if (indexesArr.length < 2) {
      arr = [...indexesArr, index];
      setIndexesArr((oldIdxs) => [...oldIdxs, index]);
    }
    const tartgetIndexes = arr.sort(function (a, b) {
      return a - b;
    });
    setPrintable(clientMoney.slice(tartgetIndexes[0], tartgetIndexes[1] + 1));
  };

  const [printable, setPrintable] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    onBeforePrint: () => setIsPrint(true),
    content: () => componentRef.current,
    onAfterPrint: () => setIsPrint(false),
  });

  const element = document.getElementById("scrollList");
  const container = document.getElementById("container");

  useEffect(() => {
    isPrint && handlePrint();
  }, [isPrint]);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }, 1000);
  }, [partner]);

  function upList_Page() {
    setTimeout(() => {
      element.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  }
  const classes = useStyles();
  function downList_Page() {
    setTimeout(() => {
      window.scrollTo({
        top: element.scrollHeight,
        behavior: "smooth",
      });
    });
  }

  const [hiddenHeader, setHiddenHeader] = useState("none");
  window.addEventListener("scroll", (x) => {
    if (window.scrollY >= 180) setHiddenHeader("flex");
    else setHiddenHeader("none");
  });

  const [pagePrintStyle, setPagePrintStyle] = useState("size: A5 landscape;");
  return (
    <div
      className=" w-100 marginHolder p-0 py-4 pb-5  position-relative"
      style={{
        background: "#534d3b",

        minHeight: "107vh",
        outline: " 8px solid #534d3b",
      }}
    >
      <Helmet>
        <title>حساب جاري العميل</title>
      </Helmet>

      <div className=" p-0 container position-relative " id="scrollList">
        <AccountHeader
          onScrollBottom={downList_Page}
          indexesArr={indexesArr}
          handlePrint={handlePrint}
          fromDate={fromDate}
          toDate={toDate}
          partner={partner}
          reloadhandler={reloadhandler}
        />
        {clientMoney.length > 0 && (
          <MoneyTable
            data={clientMoney}
            type="client"
            squareInvisibile={
              factor &&
              factor !== "trucks" &&
              factor !== "rep" &&
              role != "محصل" &&
              role != "عميل_او_بائع"
                ? "invisible"
                : "visible"
            }
            showRepName={factor === "rep"}
            onPrintBorders={(index) => onPrintBorders(index)}
            indexesArr={indexesArr}
            className="w-100"
          />
        )}{" "}
        <Row className="p-0   m-auto   ">
          <Col xs={12} className=" text-center w-100 p-1 m-auto">
            <TableContainer className="p-0 m-0" component={Paper}>
              <Table aria-label="simple table">
                <TableHead style={{ background: "#FED049", height: "65px" }}>
                  <TableRow>
                    <TableCell
                      align="center"
                      className="headerTableFont center justify-content-between p-0"
                    >
                      <div className="center">
                        <p>الرهن</p>{" "}
                        {!isPrint && (
                          // <InfoIcon
                          //   onClick={() => onDetailsClicked(partner.id)}
                          //   style={{ cursor: "pointer" }}
                          //   className="mx-1"
                          // />
                          <ToolsInfo partnerId={partner.id} haveAction />
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="center" className="headerTableFont">
                      العدة
                    </TableCell>
                    <TableCell align="center" className="headerTableFont">
                      عملات مقدمة
                    </TableCell>
                    <TableCell align="center" className="headerTableFont">
                      {" "}
                      رهن مشتريات
                    </TableCell>
                    <TableCell align="center" className="headerTableFont">
                      {" "}
                      فواتير نقلات{" "}
                    </TableCell>
                    {partner.partners_TypeId === 3 && (
                      <>
                        <TableCell align="center" className="headerTableFont">
                          {" "}
                          سُلف
                        </TableCell>
                        <TableCell align="center" className="headerTableFont">
                          {" "}
                          فواتير المبيع{" "}
                        </TableCell>
                      </>
                    )}

                    {factor !== "mortage" &&
                      role != "محصل" &&
                      role != "عميل_او_بائع" && (
                        <TableCell align="center" className="headerTableFont">
                          {" "}
                          {""}{" "}
                        </TableCell>
                      )}

                    {partner.partners_TypeId === 1 && (
                      <TableCell align="center" className="headerTableFont">
                        الاجمالي{" "}
                      </TableCell>
                    )}
                    {partner.partners_TypeId === 3 && (
                      <TableCell align="center" className="headerTableFont">
                        الاجمالي{" "}
                      </TableCell>
                    )}
                    {factor &&
                      factor !== "trucks" &&
                      role != "محصل" &&
                      role != "عميل_او_بائع" && (
                        <TableCell align="center" className="headerTableFont">
                          {" "}
                        </TableCell>
                      )}
                    {partner.partners_TypeId === 3 && (
                      <TableCell align="center" className="headerTableFont">
                        {" "}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" className="BodyTableFont">
                      {Math.round(totalMortage) > 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(Math.round(totalMortage))
                          )} عليه `
                        : ""}
                      {Math.round(totalMortage) < 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(Math.round(totalMortage))
                          )} له `
                        : ""}
                      {Math.round(totalMortage) === 0 ? "لايوجد" : ""}
                    </TableCell>
                    <TableCell align="center" className="BodyTableFont">
                      {totalToolsCount > 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(Math.round(totalToolsCount))
                          )} عليه `
                        : ""}
                      {totalToolsCount < 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(Math.round(totalToolsCount))
                          )} له `
                        : ""}
                      {totalToolsCount === 0 ? "لايوجد" : ""}
                    </TableCell>
                    <TableCell align="center" className="BodyTableFont">
                      {Math.round(clientDebt.totalLoans) > 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(Math.round(clientDebt.totalLoans))
                          )} `
                        : ""}
                      {Math.round(clientDebt.totalLoans) === 0 ? "لايوجد" : ""}
                    </TableCell>
                    <TableCell align="center" className="BodyTableFont">
                      {Math.round(clientDebt.clientMortage) > 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(Math.round(clientDebt.clientMortage))
                          )} عليه`
                        : ""}
                      {Math.round(clientDebt.clientMortage) < 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(Math.round(clientDebt.clientMortage))
                          )} له  `
                        : ""}
                      {Math.round(clientDebt.clientMortage) === 0
                        ? "لايوجد"
                        : ""}
                    </TableCell>
                    <TableCell align="center" className="BodyTableFont">
                      {clientDebt.totalInvoices > 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(Math.round(clientDebt.totalInvoices))
                          )} عليه `
                        : ""}
                      {clientDebt.totalInvoices < 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(Math.round(clientDebt.totalInvoices))
                          )} له `
                        : ""}
                      {clientDebt.totalInvoices === 0 ? "لايوجد" : ""}
                    </TableCell>
                    {partner.partners_TypeId === 3 && (
                      <>
                        <TableCell align="center" className="headerTableFont">
                          {" "}
                          {buyerDebt.totalLoans}
                        </TableCell>
                        <TableCell align="center" className="headerTableFont">
                          {buyerDebt.totalInvoices > 0
                            ? `${ConvertToArabicNumbers(
                                Math.abs(Math.round(buyerDebt.totalInvoices))
                              )} عليه `
                            : ""}
                          {buyerDebt.totalInvoices < 0
                            ? `${ConvertToArabicNumbers(
                                Math.abs(Math.round(buyerDebt.totalInvoices))
                              )} له `
                            : ""}
                          {buyerDebt.totalInvoices === 0 ? "لايوجد" : ""}
                        </TableCell>
                      </>
                    )}

                    {factor !== "mortage" &&
                      factor !== "rep" &&
                      !isPrint &&
                      role != "محصل" &&
                      role != "عميل_او_بائع" && (
                        <TableCell
                          align="center"
                          component={Link}
                          to={`/add-expenses/partner/${partnerId}/${
                            factor == "loans" ? 4 : 2
                          }`}
                          className="headerTableFont btnMore m-3 m-0 h-100"
                        >
                          {" "}
                          {factor == "loans" ? "اضف عملة " : "اضافة دفع"}{" "}
                        </TableCell>
                      )}

                    {partner.partners_TypeId === 1 && (
                      <TableCell align="center" className="headerTableFont">
                        {totalClientMoney !== 0
                          ? ConvertToArabicNumbers(totalClientMoney)
                          : "ﻻ يوجد"}
                        {totalClientMoney > 0 ? "عليه" : "له"}
                      </TableCell>
                    )}

                    {partner.partners_TypeId === 3 && (
                      <TableCell align="center" className="headerTableFont">
                        {NetDebt !== 0
                          ? ConvertToArabicNumbers(NetDebt)
                          : "ﻻ يوجد"}
                        {NetDebt > 0 ? "عليه" : "له"}
                      </TableCell>
                    )}

                    {factor &&
                      factor !== "rep" &&
                      factor !== "trucks" &&
                      role != "محصل" &&
                      !isPrint &&
                      role != "عميل_او_بائع" && (
                        <TableCell
                          align="center"
                          component={Link}
                          to={`/add-deposit/${partnerId}/${
                            factor == "mortage" ? 8 : 1
                          }`}
                          className="headerTableFont btnMore p-0  h-100 m-3"
                        >
                          {factor == "mortage"
                            ? "سداد رهن مشتريات"
                            : "سداد عملة "}{" "}
                        </TableCell>
                      )}
                    {partner.partners_TypeId === 3 && (
                      <TableCell
                        align="center"
                        component={Link}
                        to={`/add-deposit/${partnerId}/${
                          factor == "mortage" ? 8 : 1
                        }`}
                        className="headerTableFont btnMore p-0  h-100 m-3"
                      >
                        {factor == "mortage"
                          ? "سداد رهن مشتريات"
                          : "سداد عملة "}{" "}
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Col>

          {partner.partners_TypeId === 3 && (
            <Grid item>
              <Button
                component={Link}
                style={{ backgroundColor: "#90DCD0", color: "#333" }}
                className={classes.printHidden}
                to={`/MoneyCurrentAccountReport/seller/${partnerId}`}
              >
                حساب البائع
              </Button>
            </Grid>
          )}
        </Row>
      </div>

      <div ref={componentRef}>
        <div className={classes.printableTable}>
          <div>
            <LogoImage />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                backgroundColor: "#ECECEC",
                color: "#2E3840",
                width: "90%",
                padding: 2,
                margin: 6,
                height: "3vh",
                border: "1px solid black",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                {"مطلوب من"}
              </Typography>
              <Typography
                style={{
                  fontWeight: "bolder",
                  fontSize: "14px",
                }}
              >
                {partner.name}
              </Typography>
            </div>
          </div>

          <MoneyTable
            type="client"
            data={printable}
            isPrint={true}
            squareInvisibile={
              factor &&
              factor !== "trucks" &&
              factor !== "rep" &&
              role != "محصل" &&
              role != "عميل_او_بائع"
                ? "invisible"
                : "visible"
            }
            onPrintBorders={(index) => onPrintBorders(index)}
            indexesArr={indexesArr}
          />
          <Row
            className="p-0 w-100 m-auto overflow-hidden     "
            style={{ minHeight: "10vh" }}
          >
            <Col xs={12} className=" text-center p-0 m-auto w-100 ">
              <TableContainer
                component={Paper}
                style={isPrint ? { width: "100%" } : { margin: "auto" }}
                className="p-1   bg-transparents printTable"
              >
                <Table
                  style={{
                    border: "1px black solid",
                  }}
                >
                  <TableHead
                    style={{
                      backgroundColor: "#FAA829",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        align="center"
                        className=" center justify-content-between "
                        style={{ fontSize: "14px" }}
                      >
                        الرهن
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "14px" }}
                        align="center"
                        className=""
                      >
                        العدة
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "14px" }}
                        align="center"
                        className=""
                      >
                        عملات مقدمة
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "14px" }}
                        align="center"
                        className=""
                      >
                        {" "}
                        رهن مشتريات
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "14px" }}
                        align="center"
                        className=""
                      >
                        {" "}
                        فواتير نقلات{" "}
                      </TableCell>
                      {partner.partners_TypeId === 3 && (
                        <>
                          <TableCell
                            style={{ fontSize: "14px" }}
                            align="center"
                            className=""
                          >
                            {" "}
                            سُلف
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "14px" }}
                            align="center"
                            className=""
                          >
                            {" "}
                            فواتير المبيع{" "}
                          </TableCell>
                        </>
                      )}

                      {partner.partners_TypeId === 1 && (
                        <TableCell
                          align="center"
                          style={{ fontSize: "14px" }}
                          className=""
                        >
                          الاجمالي{" "}
                        </TableCell>
                      )}
                      {partner.partners_TypeId === 3 && (
                        <TableCell
                          align="center"
                          style={{ fontSize: "14px" }}
                          className=""
                        >
                          الاجمالي{" "}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        style={{
                          borderRight: "1px solid #919191",
                          borderBottom: "1px solid #919191",
                          padding: 4,
                          fontSize: "14px",
                        }}
                        align="center"
                      >
                        {Math.round(totalMortage) > 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(totalMortage))
                            )} عليه `
                          : ""}
                        {Math.round(totalMortage) < 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(totalMortage))
                            )} له `
                          : ""}
                        {Math.round(totalMortage) === 0 ? "لايوجد" : ""}
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: "1px solid #919191",
                          borderBottom: "1px solid #919191",
                          padding: 4,
                          fontSize: "14px",
                        }}
                        align="center"
                      >
                        {totalToolsCount > 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(totalToolsCount))
                            )} عليه `
                          : ""}
                        {totalToolsCount < 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(totalToolsCount))
                            )} له `
                          : ""}
                        {totalToolsCount === 0 ? "لايوجد" : ""}
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: "1px solid #919191",
                          borderBottom: "1px solid #919191",
                          padding: 4,
                          fontSize: "14px",
                        }}
                        align="center"
                      >
                        {Math.round(clientDebt.totalLoans) > 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(clientDebt.totalLoans))
                            )} `
                          : ""}
                        {Math.round(clientDebt.totalLoans) === 0
                          ? "لايوجد"
                          : ""}
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: "1px solid #919191",
                          borderBottom: "1px solid #919191",
                          padding: 4,
                          fontSize: "14px",
                        }}
                        align="center"
                      >
                        {Math.round(clientDebt.clientMortage) > 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(clientDebt.clientMortage))
                            )} عليه`
                          : ""}
                        {Math.round(clientDebt.clientMortage) < 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(clientDebt.clientMortage))
                            )} له  `
                          : ""}
                        {Math.round(clientDebt.clientMortage) === 0
                          ? "لايوجد"
                          : ""}
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: "1px solid #919191",
                          borderBottom: "1px solid #919191",
                          padding: 4,
                          fontSize: "14px",
                        }}
                        align="center"
                      >
                        {clientDebt.totalInvoices > 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(clientDebt.totalInvoices))
                            )} عليه `
                          : ""}
                        {clientDebt.totalInvoices < 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(Math.round(clientDebt.totalInvoices))
                            )} له `
                          : ""}
                        {clientDebt.totalInvoices === 0 ? "لايوجد" : ""}
                      </TableCell>
                      {partner.partners_TypeId === 3 && (
                        <>
                          <TableCell
                            style={{
                              borderRight: "1px solid #919191",
                              borderBottom: "1px solid #919191",
                              padding: 4,
                              fontSize: "14px",
                            }}
                            align="center"
                            className="headerTableFont"
                          >
                            {" "}
                            {buyerDebt.totalLoans}
                          </TableCell>
                          <TableCell
                            style={{
                              borderRight: "1px solid #919191",
                              borderBottom: "1px solid #919191",
                              padding: 4,
                              fontSize: "14px",
                            }}
                            align="center"
                            className="headerTableFont"
                          >
                            {buyerDebt.totalInvoices > 0
                              ? `${ConvertToArabicNumbers(
                                  Math.abs(Math.round(buyerDebt.totalInvoices))
                                )} عليه `
                              : ""}
                            {buyerDebt.totalInvoices < 0
                              ? `${ConvertToArabicNumbers(
                                  Math.abs(Math.round(buyerDebt.totalInvoices))
                                )} له `
                              : ""}
                            {buyerDebt.totalInvoices === 0 ? "لايوجد" : ""}
                          </TableCell>
                        </>
                      )}

                      {factor !== "mortage" &&
                        !isPrint &&
                        role != "محصل" &&
                        role != "عميل_او_بائع" && (
                          <TableCell
                            align="center"
                            component={Link}
                            to={`/add-expenses/partner/${partnerId}/${
                              factor == "loans" ? 4 : 2
                            }`}
                            className="headerTableFont btnMore m-3 m-0 h-100"
                          >
                            {" "}
                            {factor == "loans" ? "اضف عملة " : "اضافة دفع"}{" "}
                          </TableCell>
                        )}

                      {partner.partners_TypeId === 1 && (
                        <TableCell
                          style={{
                            borderRight: "1px solid #919191",
                            borderBottom: "1px solid #919191",
                            padding: 4,
                            fontSize: "14px",
                          }}
                          align="center"
                          className="headerTableFont"
                        >
                          {totalClientMoney !== 0
                            ? ConvertToArabicNumbers(totalClientMoney)
                            : "ﻻ يوجد"}
                          {totalClientMoney > 0 ? "عليه" : "له"}
                        </TableCell>
                      )}

                      {partner.partners_TypeId === 3 && (
                        <TableCell
                          style={{
                            borderRight: "1px solid #919191",
                            borderBottom: "1px solid #919191",
                            padding: 4,
                            fontSize: "14px",
                          }}
                          align="center"
                          className="headerTableFont"
                        >
                          {NetDebt !== 0
                            ? ConvertToArabicNumbers(NetDebt)
                            : "ﻻ يوجد"}
                          {NetDebt > 0 ? "عليه" : "له"}
                        </TableCell>
                      )}

                      {factor &&
                        factor !== "trucks" &&
                        !isPrint &&
                        role != "محصل" &&
                        role != "عميل_او_بائع" && (
                          <TableCell
                            style={{ fontSize: "14px" }}
                            align="center"
                            component={Link}
                            to={`/add-deposit/${partnerId}/${
                              factor == "mortage" ? 8 : 1
                            }`}
                            className="headerTableFont btnMore p-0  h-100 m-3"
                          >
                            {factor == "mortage"
                              ? "سداد رهن مشتريات"
                              : "سداد عملة "}{" "}
                          </TableCell>
                        )}
                      {partner.partners_TypeId === 3 && (
                        <TableCell
                          style={{ fontSize: "14px" }}
                          align="center"
                          component={Link}
                          to={`/add-deposit/${partnerId}/${
                            factor == "mortage" ? 8 : 1
                          }`}
                          className="headerTableFont btnMore p-0  h-100 m-3"
                        >
                          {factor == "mortage"
                            ? "سداد رهن مشتريات"
                            : "سداد عملة "}{" "}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Col>

            {partner.partners_TypeId === 3 && (
              <Grid item>
                <Button
                  component={Link}
                  style={{ backgroundColor: "#90DCD0", color: "#333" }}
                  className={classes.printHidden}
                  to={`/MoneyCurrentAccountReport/seller/${partnerId}`}
                >
                  حساب البائع
                </Button>
              </Grid>
            )}
          </Row>
        </div>
      </div>

      <RemainingDetails
        rows={remainingDetails}
        open={openDetails}
        onClose={() => setOpenDetails(false)}
      />
    </div>
  );
}

//#E2FE01
export default ClientAccount;

import cls from "./Security.module.scss";
const Blocked = () => {
  return (
    <div className={cls.main2}>
      
      <h2>برجاء التواصل مع الدعم الفني</h2>
      <h2>01006375557</h2>
    </div>
  );
};
export default Blocked;

import { colors } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import shadows from './shadows';

const theme = createTheme({
  palette: {
    primary: {
      main: '#F3C637',
      contrastText: '#181301',
    },
    text: {
      primary: '#182933',
      secondary: '#A6A29D',
    },
    background: {
      default: '#F4F6F8',
      paper: colors.common.white,
    },
    secondary: {
      main: '#161101',
    },
    error: {
      main: '#ED4D4A',
    },
  },
  direction: 'rtl',

  shadows,
  typography: {
    fontFamily: 'DroidKufi-Regular',
  },

  //Anything listed in the CSS section of a component’s API can be overridden.
  overrides: {
    // Style sheet name ⚛️

    MuiButton: {
      root: {
        borderRadius: 7,
        border: 0,
        height: 48,
        padding: '0 30px',
        // boxShadow: '0 4px 10px 2px rgb(16, 156, 241, 0.2)',
        // '&:hover': {
        //   background: 'rgb(16, 156, 241, 0.9)',
        //   boxShadow: '-3 -6px 16px rgb(16, 156, 241, 0.2)',
        // },
      },
      text: {
        label: {
          color: '#181301',
        },
      },
      containedPrimary: {
        label: {
          color: '#fff',
        },
        backgroundColor: '#f4c93e',
        boxShadow:
          '0px 4px 8px rgb(243, 198, 55, 0.1), -1px -2px 4px	rgb(243, 198, 55, 0.1)',
        '&:hover': {
          backgroundColor: '#f5d056',
          boxShadow:
            '0px 4px 8px rgb(243, 198, 55, 0.2), -1px -2px 4px	rgb(243, 198, 55, 0.2)',
        },
      },
      containedSecondary: {
        color: '#FFF',

        boxShadow:
          '0px 4px 8px  rgba(0, 0, 0, 0.09), -1px -2px 4px  rgba(0, 0, 0, 0.09)',

        '&:hover': {
          boxShadow:
            '1px 2px 5px  rgba(0, 0, 0, 0.09), -1px -2px 3px  rgba(0, 0, 0, 0.09)',
        },
      },
      textSecondary: {
        fontSize: 15,
      },
    },

    MuiDrawer: {
      paper: {
        backgroundColor: '#161101',
        color: '#ffffff',
      },
    },

    MuiIconButton: {
      root: {
        color: '#FAC900',
      },
    },
  },
  props: {
    // Name of the component ⚛️
    MuiTextField: {
      autoComplete: 'off',
      variant: 'outlined',
      color: 'secondary',
    },
    MuiFilledInput: {
      autoComplete: 'off',
    },
    MuiButtonBase: {
      text: {
        textSecondary: 'secondary',
      },
    },
  },
});

export default theme;

//rgb(16, 156, 241, 0.2)

//main: '#109CF1',
//secondary: main: '#4FD675',
//background: 'linear-gradient(45deg, #56CCF2 100%, #109CF1 100%)',

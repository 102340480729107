import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { addInvoice, getSellingReport } from "./sellersInvoiceSlice";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";
import ReportTable from "./components/ReportTable";
import ar from "date-fns/locale/ar-SA";
import { fetchBuyers } from "../main-data/dataSlice";
import { makeStyles } from "@material-ui/core/styles";
import { parseISO } from "date-fns";
import { registerLocale } from "react-datepicker";
import { useHistory } from "react-router-dom";
import Mydate from "../../components/Helpers/MyDateParser";
import { Container } from "react-bootstrap";
import MainHeader from "../../components/mainHeader/MainHeader";

registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },

  notExist: {
    width: "100%",
    textAlign: "center",
    marginTop: 140,
  },
}));

function SellingReport() {
  const history = useHistory();
  const [date, setDate] = useState("");
  const [partner, setPartner] = useState("");
  const [invoiceType, setInvoiceType] = useState("");
  const sellingReport = useSelector(
    (state) => state.sellersInvoice.sellingReport
  );

  useEffect(() => {
    const data = sessionStorage.getItem("sellersInvoiceDate");
    if (data) {
      setDate(parseISO(data));
    } else {
      setDate(new Date());
    }
  }, []);
  useEffect(() => {
    if (date) {
      sessionStorage.setItem("sellersInvoiceDate", Mydate(date));
    }
  }, [date]);

  const buyers = useSelector((state) => state.data.buyers);

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(fetchBuyers());
    }
    return () => {
      mounted = false;
    };
  }, []);
  const dispatch = useDispatch();
  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(getSellingReport(date));
    }
    return () => {
      mounted = false;
    };
  }, [date]);

  const createInvoice = (bodyData) => {
    dispatch(addInvoice(bodyData)).then(({ payload }) => {
      if (payload && payload.id) {
        history.push(
          `/sellers-invoices/${bodyData.partnerId}/${bodyData.invoiceTypeId}`
        );
      }
    });
  };

  const invoiceTypes = [
    { id: 1, label: "اجل" },
    { id: 2, label: "نقدية" },
    { id: 3, label: "توريد" },
  ];

  let filteredByName = [];
  let filteredByType = [];
  filteredByName =
    partner && partner.id
      ? sellingReport.filter((r) => {
          return r.id === partner.id;
        })
      : sellingReport;

  filteredByType =
    invoiceType && filteredByName.length > 0
      ? filteredByName.filter((r) => {
          return r.sellingInvoiceTypeId === invoiceType;
        })
      : filteredByName;

  const classes = useStyles();
  return (
    <div
      className=" w-100  p-0 pb-4 pb-5 position-relative overflow-x-hidden  "
      style={{
        background: "#534d3b",
        marginTop: " -15px !important",
        minHeight: "107vh",
        outline: " 8px solid #534d3b",
      }}
    >
      <Container>
        <div className="mb-3">
          <MainHeader title="فواتير البائعين" />
        </div>
        <Card
          style={{
            marginBottom: 16,
            boxShadow:
              " rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px ",
          }}
        >
          <Divider />
          <CardContent>
            <Grid className="center" container spacing={2}>
              <Grid item xs={10} md={3} className=" text-center">
                <DatePicker
                  locale="ar"
                  selected={date}
                  onChange={(date) => setDate(date)}
                  dateFormat="dd/MM/yyyy"
                  customInput={
                    <TextField
                      size="small"
                      variant="outlined"
                      label="التاريخ"
                      style={{ width: "100%" }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={10} md={3} className=" text-center">
                <Autocomplete
                  options={buyers}
                  getOptionSelected={(option, value) =>
                    option.value === value?.value
                  }
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  style={{ width: "100%", backgroundColor: "#fff" }}
                  autoHighlight
                  autoComplete
                  noOptionsText="لم يتم العثور على خيارات"
                  onChange={(event, value) => {
                    if (value) {
                      setPartner(value);
                    } else {
                      setPartner("");
                    }
                  }}
                  value={partner}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="اسم البائع"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={10} md={3} className=" text-center">
                <TextField
                  size="small"
                  style={{ width: "100%" }}
                  select
                  label="النوع"
                  onChange={(e) => setInvoiceType(e.target.value)}
                  value={invoiceType}
                  variant="outlined"
                >
                  {invoiceTypes.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {sellingReport && sellingReport.length > 0 ? (
          <ReportTable data={filteredByType} onCreateInvoice={createInvoice} />
        ) : (
          <div className={classes.notExist}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginTop: 180 }}
            >
              {" "}
              ﻻ يوجد فواتير{" "}
            </Typography>
          </div>
        )}
      </Container>
    </div>
  );
}

export default SellingReport;

import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { ConvertToArabicNumbers, renderDate } from "../../utils";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CashInvoice from "./components/CashInvoice";
import ForwardInvoice from "./components/ForwardInvoice";
import { Helmet } from "react-helmet";
import PartnerSales from "./containers/PartnerSales";
import TruckDetails from "./components/TruckDetails";
import axios from "axios";
import { getSellingReport } from "./sellersInvoiceSlice";
import { makeStyles } from "@material-ui/styles";
import { parseISO } from "date-fns";
import { useParams } from "react-router-dom";
import Alerto from "../../components/UI/toaster";
import TransferSellingComp from "../loads/components/TransferSellingComp";
import { Container, Row } from "react-bootstrap";
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },

  label: {
    fontSize: 16,
    display: "inline",
    color: theme.palette.grey[600],
  },
  loadData: {
    fontSize: 18,
    marginLeft: 8,
    display: "inline",
  },
  invoiceCard: {
    marginBottom: 24,
    padding: 24,
  },
}));
function InvoiceDetails() {
  const { partnerId, sellingInvoiceTypeId, type } = useParams();
  const sellerInvoiceDate = useSelector(
    (state) => state.seller.sellerInvoiceDate
  );
  let report = useSelector((state) =>
    state.sellersInvoice.sellingReport.find((item) => {
      if (
        item.id === Number.parseInt(partnerId) &&
        item.sellingInvoiceTypeId == Number.parseInt(sellingInvoiceTypeId)
      ) {
        return item;
      }
    })
  );

  const partnerReport = report ? report : "";

  if (!partnerReport && !sellerInvoiceDate) {
    window.location = "/sellers-invoices";
  }

  const dispatch = useDispatch();
  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (type === "profile") {
        dispatch(getSellingReport(parseISO(sellerInvoiceDate)));
      }
    }
    return () => {
      mounted = false;
    };
  }, []);

  const invoice = useSelector((state) => state.sellersInvoice.invoice);

  const [openInvoice, setOpenInvoice] = useState(false);
  const [openCash, setOpenCash] = useState(false);

  const [deductions, setDeductions] = useState(0);
  const addDiscounts = () => {
    if (invoice && invoice.id) {
      axios
        .put(`/SellingsInvoices?invoiceId=${invoice.id}&discounts=${discounts}`)
        .then((res) => {
          setDeductions(res.data.discounts);
          setDiscounts("");
        })
        .catch((err) => {
          Alerto(err);
        });
    } else if (partnerReport.invoice && partnerReport.invoice.invoiceId) {
      axios
        .put(
          `/SellingsInvoices?invoiceId=${partnerReport.invoice.invoiceId}&discounts=${discounts}`
        )
        .then((res) => {
          if (res.status === 200) {
            setDeductions(res.data.discounts);
            setDiscounts("");
          }
        })
        .catch((err) => {
          Alerto(err);
        });
    }
  };

  const [discounts, setDiscounts] = useState("");
  const classes = useStyles();
  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (partnerReport.invoice && partnerReport.invoice.discounts) {
        setDeductions(partnerReport.invoice.discounts);
      }
    }
    return () => {
      mounted = false;
    };
  }, [partnerReport]);

  return (
    partnerReport &&
    partnerReport.id && (
      <div className=" w-100  p-0 pb-4 pb-5 position-relative overflow-x-hidden  " style={{ background: '#534d3b', marginTop: ' -15px !important', minHeight: '110vh', outline: ' 8px solid #534d3b' }}>
        <Container>
          <Helmet>
            <title> فواتير البائعين </title>
          </Helmet>

          <Grid className="w-100">
            <Grid xs={12} className='mt-3 mb-2' style={{ boxShadow: 'rgba(0, 0, 0, 0.56) 0px 22px 70px 4px' }} >
              {partnerReport && partnerReport.id && (
                <Card className="w-100 pt-2" >
                  <CardContent>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Grid item className="mb-2" >
                        <Typography className={classes.label}>
                          {" "}
                          فاتورة{" "}
                        </Typography>
                        <Typography className={classes.loadData}>
                          {partnerReport.name}
                        </Typography>
                      </Grid>

                      <Grid item className="mb-2">
                        <Typography className={classes.label}> النوع </Typography>
                        <Typography className={classes.loadData}>
                          {partnerReport.sellingTypeName}
                        </Typography>
                      </Grid>
                      <Grid item className="mb-2">
                        <Typography className={classes.label}>
                          {" "}
                          التاريخ{" "}
                        </Typography>
                        <Typography className={classes.loadData}>
                          {ConvertToArabicNumbers(
                            renderDate(partnerReport.sellingDate)
                          )}
                        </Typography>
                      </Grid>
                      {partnerReport.sellingInvoiceTypeId === 3 && (
                        <Grid item className="mb-2">
                          <Typography className={classes.label}>
                            {" "}
                            الخصومات{" "}
                          </Typography>

                          <Typography className={classes.loadData}>
                            {deductions > 0
                              ? ConvertToArabicNumbers(deductions)
                              : "صفر"}
                          </Typography>
                        </Grid>
                      )}
                      {partnerReport.sellingInvoiceTypeId !== 3 && (
                        <Grid item className="">
                          <button
                            variant="contained"
                            className="center justify-Content-evenly h-100 btnMore rounded-4"
                            onClick={() => {
                              if (
                                partnerReport &&
                                partnerReport.sellingInvoiceTypeId === 1
                              ) {
                                setOpenInvoice(true);
                              } else if (
                                partnerReport &&
                                partnerReport.sellingInvoiceTypeId === 2
                              ) {
                                setOpenCash(true);
                              }
                            }}
                          >
                            {" "}  معاينة الفاتورة {" "}
                            <ReceiptLongRoundedIcon className="mx-2"/>
                          </button>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              )}
            </Grid>

            {partnerReport.sellingInvoiceTypeId === 3 && (
              <Grid item md={4} xs={12}>
                <TextField
                  value={discounts >= 0 ? discounts : ""}
                  onChange={(e) =>
                    setDiscounts(Number.parseFloat(e.target.value))
                  }
                  style={{ width: 140, backgroundColor: "#f9f9f9" }}
                  label="الخصومات"
                  variant="outlined"
                  size="small"
                  type="number"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      addDiscounts();
                    }
                  }}
                />
                <Button
                  color="primary"
                  style={{
                    marginRight: 8,
                    height: 40,
                    backgroundColor: "#7DBB00",
                    color: "#fff",
                    fontWeight: 600,
                  }}
                  variant="contained"
                  onClick={addDiscounts}
                >
                  اضف الخصم
                </Button>
              </Grid>
            )}
          </Grid>

            <TransferSellingComp />
            {partnerReport.id &&
              partnerReport.trucksIds.map((truckId) => (
                <div key={truckId} className='mb-3' style={{border:"10px solid #906931"}}>
                  <TruckDetails truckId={truckId}  />
                  <PartnerSales
                    truckId={truckId}
                    invoiceid={partnerReport.invoice.invoiceId}
                    bgColor="#ffeb3b"
                    className='m-5'
                  />
                </div>
              ))}

            {partnerReport && partnerReport.sellingInvoiceTypeId === 1 && (
              <ForwardInvoice
                open={openInvoice}
                onClose={() => setOpenInvoice(false)}
                partnerReport={partnerReport}
                partnerId={partnerId}
                invoice={invoice}
              />
            )}

            <CashInvoice
              open={openCash}
              onClose={() => setOpenCash(false)}
              partnerReport={partnerReport}
              invoice={invoice}
            />



        </Container>
      </div>

    )
  );
}

export default InvoiceDetails;

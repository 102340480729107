import * as Tafgeet from "tafgeetjs";
import classy from "./invoice.module.css";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";

import { ConvertToArabicNumbers } from "../../../utils";
import CustomDialog from "../../../components/CustomDialog";
import LogoImage from "../../../components/LogoImage";
import MuiTableCell from "@material-ui/core/TableCell";
import React, { useEffect, useState } from "react";
import SoldItemsTable from "./SoldItemsTable";
import SummeryTable from "./SummeryTable";
import axios from "axios";
import { parseISO } from "date-fns";
import { renderDate } from "../../../utils";
import { useSelector } from "react-redux";
import Tafqeet from "../../../components/Helpers/ConvertNumToWord";

const TableCell = withStyles({
  root: {
    lineHeight: 0.1,
    borderBottom: "none",
  },
})(MuiTableCell);

//#FEFD06
//#FE96CA
//#47478C
const useStyles = makeStyles((theme) => ({
  root: {
    width: "18cm",
    height: "29.7cm",
  },

  tableBody: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  invoiceDetails: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    margin: "15px auto",
    border: "3px dashed",
    padding: "5px 0",
  },
  deductionSection: {
    marginTop: 12,
    border: "1px solid #888",
    padding: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "100%",
  },
  "@media print": {
    root: {
      margin: "auto",
      width: "18cm",
      height: "29.7cm",
      marginTop: 24,
    },
  },
}));

const InvoicePaper = React.forwardRef(
  (
    { openInvoice, truck, onClose, subTotal, summaryItems, invoiceItems },
    ref
  ) => {
    console.log("🚀 ~ subTotal:", subTotal);
    const d = parseISO(truck.truckDate).getDay();

    var weekday = new Array(7);
    weekday[0] = "الاحد";
    weekday[1] = "الاثنين";
    weekday[2] = "الثلاثاء";
    weekday[3] = "الاربعاء";
    weekday[4] = "الخميس";
    weekday[5] = "الجمعة";
    weekday[6] = "السبت";

    var day = weekday[d];
    const [summ, setSumm] = useState([]);
    const deductions = useSelector((state) => state.invoice.deduction);
    const invoiceDeductions = useSelector(
      (state) => state.invoice.loadExpenses.invoiceDeductions
    );

    const totalCommission =
      deductions && deductions.commisionPercentage >= 0
        ? deductions.independentCommision +
          subTotal * (deductions.commisionPercentage / 100)
        : 0;

    const totalInvoiceDeductions = invoiceDeductions?.reduce((acc, val) => {
      return acc + val.money;
    }, 0);
    const deductionsSum = deductions?.[0]
      ? deductions?.reduce((acc, item) => {
          acc += item.carryingPrice;
          acc += item.officeTips;
          acc += item.movementToolRent;
          acc += item.fridgeRent;
          return acc;
        }, 0)
      : 0;

    const classes = useStyles();
    const tafqeet = (amount) => {
      return new Tafgeet(amount, "EGP").parse();
    };
    useEffect(() => {
      let arr = [];
      if (invoiceItems[0]) {
        for (let i = 0; i < invoiceItems.length; i++) {
          let ele = invoiceItems[i];
          if (!arr[0]) {
            arr.push(ele);
            continue;
          } else {
            for (let a = 0; a < arr.length; a++) {
              let ele1 = arr[a];
              if (ele1.item.product.id == ele.item.product.id) {
                let dem = { ...ele1 };
                dem.itemCount += ele.itemCount;
                dem.weight += ele.weight;
                arr[a] = dem;
                break;
              } else if (a == arr.length - 1) {
                arr.push(ele);
                break;
              }
            }
          }
        }
      }
      setSumm(arr);
    }, [invoiceItems]);
    const [details, setDetails] = useState(null);

    async function fetchDetails(truckId) {
      try {
        const response = await axios.get(
          `truckInvoice/invoiceDetails?truckid=${truckId}`
        );
        setDetails(response.data);
      } catch (e) {
        console.log(e);
      }
    }
    useEffect(() => {
      !!truck?.id && fetchDetails(truck.id);
    }, [truck, invoiceItems]);
    return (
      <CustomDialog open={openInvoice} textAlign="center" onClose={onClose}>
        <div className="w-100" ref={ref}>
          <div style={{ border: "4px solid black" }}>
            <LogoImage />
          </div>
          <div className={classes.invoiceDetails}>
            <Typography display="inline" variant="body1">
              الوارد من السيد
            </Typography>
            <Typography
              style={{ fontWeight: 600, color: "#E4424A" }}
              display="inline"
            >
              {truck.farmer.name}
            </Typography>
            <Typography variant="body1">
              تاريخ النقلة :{" "}
              {ConvertToArabicNumbers(renderDate(truck.truckDate))}
            </Typography>
            <Typography variant="body1">{day}</Typography>
            <Typography variant="body1">
              رقم النقلة : {truck.truckIdForThisDayAndClient}
            </Typography>
          </div>

          <SoldItemsTable
            bgColor="#f3c637"
            color="#0C0C76"
            isPaper={true}
            soldItems={invoiceItems}
          />

          <div
            className=" center align-items-start justify-content-between py-2 mb-2"
            style={{ borderBottom: "3px solid ", borderTop: "3px solid " }}
          >
            <div style={{ width: "38%", margin: "6px 0" }}>
              <div>
                <div
                  style={{
                    border: "3px solid",
                    textAlign: "center",
                    backgroundColor: "#f3c637",
                    padding: 5,
                  }}
                >
                  <Typography display="inline" variant="h6" color="textPrimary">
                    {ConvertToArabicNumbers(subTotal.toFixed(1))}
                  </Typography>
                  <Typography
                    style={{ marginRight: 1 }}
                    display="inline"
                    color="textPrimary"
                  >
                    {" "}
                    الاجمالي{" "}
                  </Typography>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    padding: 5,
                    borderLeft: "3px solid ",
                    borderRight: "3px solid ",
                    borderBottom: "3px solid black ",
                  }}
                >
                  {!isNaN(details?.totalDiscount) && (
                    <Typography display="inline" color="textPrimary">
                      {ConvertToArabicNumbers(
                        Math.round(details?.totalDiscount)
                      )}
                    </Typography>
                  )}
                  <Typography
                    style={{ marginRight: 6 }}
                    display="inline"
                    color="textPrimary"
                  >
                    خصومات
                  </Typography>
                </div>

                {subTotal >= 0 && details?.totalDiscount >= 0 && (
                  <>
                    <div
                      style={{
                        padding: 5,
                        textAlign: "center",
                        backgroundColor: "#FFDE00",
                        borderLeft: "3px solid black ",
                        borderRight: "3px solid black ",
                        borderBottom: "3px solid black ",
                      }}
                    >
                      <Typography
                        display="inline"
                        variant="h6"
                        color="textPrimary"
                      >
                        {ConvertToArabicNumbers(
                          Math.round(subTotal - details?.totalDiscount)
                        )}
                      </Typography>
                      <Typography
                        style={{
                          marginRight: 6,
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                        display="inline"
                        color="textPrimary"
                      >
                        الصافي
                      </Typography>
                    </div>
                    <div
                      style={{
                        borderLeft: "3px solid ",
                        borderRight: "3px solid ",
                        borderBottom: "3px solid black ",
                        padding: "1vh 1vw 1vh 1vw",
                        textAlign: "center",
                        backgroundColor: "#fff",
                      }}
                    >
                      <Typography
                        style={{
                          marginRight: 6,
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                        display="inline"
                        color="textPrimary"
                      >
                        <span className="">
                          {Math.round(subTotal - details?.totalDiscount) > 0
                            ? "له : "
                            : " عليه : "}
                        </span>
                        {Math.round(subTotal - details?.totalDiscount) > 0
                          ? Tafqeet(
                              Math.round(subTotal - details?.totalDiscount)
                            )
                          : Tafqeet(
                              Math.abs(
                                Math.round(subTotal - details?.totalDiscount)
                              )
                            )}{" "}
                        جنيها
                      </Typography>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div style={{ width: "30%" }} className="center">
              <SummeryTable
                bgColor="transparent"
                color="#0C0C76"
                isPaper={true}
                summaryItems={summ}
                className="mt-0"
              />
            </div>

            {deductions && (
              <TableContainer
                style={{ width: "30%", margin: "6px 0" }}
                component={Paper}
              >
                <Table className={classes.table}>
                  <TableBody>
                    {details?.discounts.map((ele) => (
                      <TableRow className={classes.tableBody}>
                        <TableCell
                          style={{
                            color: "#333",
                            fontWeight: "bold",
                            border: "3px solid",
                          }}
                          align="center"
                        >
                          {ConvertToArabicNumbers(Math.round(ele.value))}
                        </TableCell>
                        <TableCell
                          style={{
                            padding: 0,
                            color: "#0C0C76",
                            fontWeight: "bold",
                            border: "3px solid",
                          }}
                          align="center"
                        >
                          {ele.name}
                        </TableCell>
                      </TableRow>
                    ))}

                    <TableRow style={{ border: "3px solid" }}>
                      <TableCell
                        className="text-center center"
                        style={{
                          backgroundColor: "#f3c637",
                        }}
                      >
                        {" "}
                        {!isNaN(details?.totalDiscount) && (
                          <Typography
                            display="inline"
                            variant="h8"
                            color="textPrimary"
                            className="text-center center"
                          >
                            {ConvertToArabicNumbers(
                              Math.round(details?.totalDiscount)
                            )}
                          </Typography>
                        )}{" "}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#f3c637",
                          fontSize: 16,
                          border: "3px solid",
                        }}
                        className="center text-center"
                      >
                        {" "}
                        الخصومات{" "}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
          <div style={{ marginRight: "0vh", display: "flex" }}>
            <div className={classy.footer1}>
              بعد السلام -موضح لكم صافي الرسالة إطلاعكم عليه كافي
            </div>
            <div className={classy.footer2}>
              ماعادا السهو والخطأ" لا يلغي إيصالات ولا شيكات
            </div>
          </div>
        </div>
      </CustomDialog>
    );
  }
);

export default InvoicePaper;

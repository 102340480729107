import axios from "axios";
import { useEffect, useState } from "react";
import Alerto from "../../../components/UI/toaster";
import { toast } from "react-toastify";
import classes from "./limit.module.css";
import { TextField } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import Mydate from "../../../components/Helpers/MyDateParser";

const Limit = () => {
  const isLocal = useSelector((state) => state.auth.isLocal);
  const [value, setvalue] = useState("");
  const [value2, setvalue2] = useState("");
  const [subsTime, setSubsTime] = useState(new Date());
  const [selectedPackage, setSelectedPackage] = useState({});

  const [packages, setPackages] = useState([]);

  function getSettings() {
    axios
      .get("Setting/generalSettings")
      .then((res) => {
        setvalue(res.data.openingBalance);
        setvalue2(res.data.latingLimitInDaysForBuyers);
        setSubsTime(new Date(res.data.subscribeUntill));
        setSelectedPackage(res.data.subscribtionType);
      })
      .catch((e) => {
        Alerto(e);
      });
  }
  function getPackages() {
    axios
      .get("/Setting/subscribtion/types")
      .then((res) => {
        setPackages(res.data);
      })
      .catch((e) => {
        Alerto(e);
      });
  }
  useEffect(() => {
    getSettings();
    getPackages();
  }, []);
  const update = () => {
    if (value === "" || value2 === "") {
      toast.error("يرجي ملئ جميع الخانات ");
    } else {
      axios
        .put("Setting/generalSettings", {
          openingBalance: value,
          latingLimitInDaysForBuyers: value2,
          subscribeUntill: !subsTime ? null : Mydate(subsTime),
          subscribtionType: +selectedPackage,
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success("تم التعديل بنجاح");
          }
        })
        .catch((err) => {
          Alerto(err);
        });
    }
  };
  const initializeGovs = () => {
    axios
      .post(`Setting/uploadGovsAndCities`)
      .then((res) => {
        if (res.status === 200) {
          toast.success("تمت العملية بنجاح");
        }
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  return (
    <div className={classes.holder}>
      <div>
        <input
          value={value}
          onChange={(e) => setvalue(e.target.value)}
          className={classes.input}
          placeholder=" الرصيد الافتتاحي"
          type="number"
        />
      </div>
      <div>
        <input
          value={value2}
          onChange={(e) => setvalue2(e.target.value)}
          className={classes.input}
          placeholder="تأخيرات البائعين"
          type="number"
        />
      </div>
      {!isLocal && (
        <SubDate
          packages={packages}
          setSelectedPackage={setSelectedPackage}
          selectedPackage={selectedPackage}
          setSubsTime={setSubsTime}
          subsTime={subsTime}
        />
      )}
      <button onClick={update} className={classes.btn}>
        تعديل
      </button>
      <button onClick={initializeGovs} className={classes.btn}>
        تحميل المحافظات
      </button>
    </div>
  );
};
function SubDate({
  subsTime,
  setSubsTime,
  packages,
  setSelectedPackage,
  selectedPackage,
}) {
  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <DatePicker
        locale="ar"
        selected={subsTime}
        onChange={setSubsTime}
        dateFormat="dd/MM/yyyy"
        customInput={
          <TextField
            size="small"
            style={{
              backgroundColor: "#fff",
            }}
            variant="outlined"
            label="تعديل تاريخ الاشتراك"
          />
        }
      />
      <select
        value={selectedPackage}
        onChange={(e) => setSelectedPackage(e.target.value)}
      >
        {packages.map((ele) => (
          <option value={ele.id}>{ele.name}</option>
        ))}
      </select>
    </div>
  );
}
export default Limit;

import { Button } from "@material-ui/core";
import CustomDialog from "../../../components/CustomDialog";
import ExpensesForm from "./ExpensesForm";
import React from "react";
import { updateExpenses } from "../expensesSlice";
import { useDispatch } from "react-redux";
import { updateInvoiceExpense } from "../../invoice/invoiceSlice";

function UpdateExpenses({ refresh, expense, open, onClose }) {
  const dispatch = useDispatch();
  const onUpdateClicked = (data, expense) => {
    if (expense.isFromTruck) {
      dispatch(updateInvoiceExpense(data));
    } else {
      dispatch(updateExpenses(data));
    }

    onClose();
    refresh();
  };

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      action1={
        <Button
          onClick={onClose}
          color="secondary"
          size="small"
          style={{ width: 56, height: 40 }}
        >
          الغاء
        </Button>
      }
    >
      <ExpensesForm
        expense={expense}
        onUpdateClicked={onUpdateClicked}
        isUpdate={true}
      />
    </CustomDialog>
  );
}

export default UpdateExpenses;

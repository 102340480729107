import {
  Button,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  ConvertToArabicNumbers,
  renderDate,
  sortAlphabetically,
} from "../../../utils";
import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DatePicker from "@mui/lab/DatePicker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomDialog from "../../../components/CustomDialog";
import CustomizedTable from "../../../components/Table";
import DeleteBox from "../../../components/DeleteBox";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import axios from "axios";
import { useSelector } from "react-redux";
import { isDayPast } from "../../../components/Helpers/InsputSorting";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",

    "& > *": {
      margin: theme.spacing(1),
    },
  },
  tableBody: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  trash: {
    color: theme.palette.error.light,
  },
  edit: {
    color: theme.palette.info.light,
  },
}));

function Expenses({
  onAddEXpensesClicked,
  onDeleteItemClicked,
  onUpdate,
  categories,
  loadExpenses,
  open,
  onClose,
}) {
  const classes = useStyles();
  const [openDelete, setOpenDelete] = useState(false);
  const [openedit, setOpenEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const load = useSelector((state) => state.loads.load);
  const truck = useSelector((state) => state.invoice?.truck);
  const isShared = truck?.sharedTruck || load?.data?.sharedTruck;
  const role = localStorage.getItem("role");
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 16,
      overflow: "auto",
    },
  }))(TableCell);
  const tableHeader = [
    "عميل مشترك",
    "المبلغ",
    "نوع المصروف",
    "ملحوظة",
    "تاريخ",
    "تعديل",
    "حذف",
  ];
  !isShared && tableHeader.splice(0, 1);

  return (
    <>
      <CustomDialog open={open} textAlign="center" onClose={onClose}>
        <>
          {!!open && (
            <ExpensesForm
              categories={categories}
              onSub={onAddEXpensesClicked}
            />
          )}
          {loadExpenses && loadExpenses.expenses?.length > 0 && (
            <CustomizedTable tableHeader={tableHeader}>
              {loadExpenses.expenses.map((item, index) => (
                <TableRow
                  key={index}
                  align="center"
                  className={classes.tableBody}
                >
                  {!!isShared && (
                    <StyledTableCell align="center">
                      <Typography variant="body1" color="textPrimary">
                        {item.sharedPartner?.name}
                      </Typography>
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="center">
                    <Typography variant="body1" color="textPrimary">
                      {ConvertToArabicNumbers(item.money)}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Typography variant="body1" color="textPrimary">
                      {item.name.replace(/_/g, " ")}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Typography variant="body1" color="textPrimary">
                      {item.notes}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Typography variant="body1" color="textPrimary">
                      {ConvertToArabicNumbers(renderDate(item.dateTime))}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <IconButton
                      disabled={role == "موظف" && isDayPast(item.dateTime)}
                      className={classes.edit}
                      onClick={() => {
                        setOpenEdit(true);
                        setSelectedItem(item);
                      }}
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton
                      disabled={role == "موظف" && isDayPast(item.dateTime)}
                      className={classes.trash}
                      onClick={() => {
                        setOpenDelete(true);
                        setSelectedItem(item);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ))}
            </CustomizedTable>
          )}
        </>
      </CustomDialog>
      <CustomDialog open={openedit} onClose={() => setOpenEdit(false)}>
        {!!openedit && (
          <ExpensesForm
            categories={categories}
            onSub={onUpdate}
            expense={selectedItem}
            onClose={() => setOpenEdit(false)}
          />
        )}
      </CustomDialog>
      {selectedItem && selectedItem.id && (
        <DeleteBox
          action={() => {
            onDeleteItemClicked(selectedItem);
            setOpenDelete(false);
          }}
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          message={`هل تريد حذف ${
            selectedItem.money
          } جنيه (${selectedItem.name.replace(/_/g, " ")})`}
        />
      )}
    </>
  );
}
const ExpensesForm = ({ categories, onSub, expense, onClose }) => {
  const classes = useStyles();
  const [category, setCategory] = useState({});
  const [money, setMoney] = useState("");
  const [sharedPartner, setSharedPartner] = useState("");
  const [notes, setNotes] = useState("");
  const [dateTime, setDateTime] = useState(null);
  const [sharedPartners, setSharedPartners] = useState([]);
  const load = useSelector((state) => state.loads.load);
  const truck = useSelector((state) => state.invoice?.truck);
  const isShared = truck?.sharedTruck || load?.data?.sharedTruck;

  useEffect(() => {
    setMoney(expense?.money);
    setNotes(expense?.notes);
    setDateTime(expense?.dateTime);
    setSharedPartner(expense?.partnerShared || expense?.sharedPartner);
    expense &&
      categories &&
      setCategory(categories.find((ele) => ele.name == expense.name));
  }, [expense, categories]);
  useEffect(() => {
    (load?.data?.id || truck?.id) && fetchSharedpartners();
  }, [load, truck]);
  async function fetchSharedpartners() {
    try {
      const res = await axios.get(
        `Truck/GetSharedPartners/${load?.data?.id || truck?.id}`
      );
      setSharedPartners(res.data);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid
        container
        className="mt-4 p-3 rounded-3"
        spacing={2}
        style={{
          boxShadow:
            "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
        }}
      >
        {!!isShared && (
          <Grid item>
            <Autocomplete
              options={sharedPartners}
              getOptionSelected={(option, value) =>
                option?.value === value?.value
              }
              getOptionLabel={(option) =>
                option.name ? option.name.replace(/_/g, " ") : ""
              }
              style={{ width: 180 }}
              autoHighlight
              value={sharedPartner}
              id="00"
              onChange={(e, newValue) => {
                if (newValue) {
                  setSharedPartner(newValue);
                }
                if (!newValue) {
                  setSharedPartner("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="عميل مشترك"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item>
          <TextField
            label="المبلغ"
            autoFocus
            variant="outlined"
            type="number"
            size="small"
            style={{ width: 120, marginRight: 0 }}
            onChange={(e) => setMoney(Number.parseFloat(e.target.value))}
            value={money >= 0 ? money : ""}
          />
        </Grid>
        {!!onClose && (
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Basic example"
                value={dateTime}
                onChange={(date) => setDateTime(date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    size="small"
                    label="التاريخ"
                    style={{ width: 150 }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        )}
        {!onClose && categories && categories.length > 0 && (
          <Grid item>
            <Autocomplete
              options={sortAlphabetically(categories)}
              getOptionSelected={(option, value) =>
                option.value === value?.value
              }
              getOptionLabel={(option) =>
                option.name ? option.name.replace(/_/g, " ") : ""
              }
              style={{ width: 180 }}
              autoHighlight
              value={category}
              onChange={(e, newValue) => {
                if (newValue) {
                  setCategory(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="نوع المصروف "
                  variant="outlined"
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
        )}
        {!onClose && (
          <Grid item>
            <TextField
              label="ملحوظة"
              variant="outlined"
              size="small"
              style={{ width: 200 }}
              multiline
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  onSub({ category, money, notes, dateTime }, expense);
                  setCategory({});
                  setMoney("");
                  setNotes("");
                  setDateTime(null);
                  setDateTime(null);
                  setSharedPartner({});
                }
              }}
            />
          </Grid>
        )}
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ height: 40, marginLeft: 0 }}
            className="px-3 globalFont"
            onClick={() => {
              onSub(
                {
                  category,
                  money,
                  notes,
                  dateTime,
                  SharedPartnerId: sharedPartner?.id,
                },
                expense
              );
              setCategory({});
              setMoney("");
              setNotes("");
              !!onClose && onClose();
            }}
          >
            {expense?.id ? "تعديل" : "اضافة"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Expenses;

import { Card, CardContent, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import ProfitsTable from "./components/ProfitsTable";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { Container } from "react-bootstrap";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
}));

function GeneralProfits() {
  const classes = useStyles();

  const [generalProfits, setGeneralProfits] = useState([]);
  const getGeneralProfits = () => {
    axios
      .get("/profits/general")
      .then((res) => {
        let D = [];
        D.push(res.data);
        setGeneralProfits(D);
      })
      .catch((err) => { });
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getGeneralProfits();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const headCells = [
    "بياعة البائعين",
    "عمولات النقلات",
    "فرق فواتير العمولة",
    "ربح المشتريات",
    "مشال",
    "مكتب",
    "ايجار عدة",
    "ثلاجة",
    "وارد الربح",
    "سماح",
    "هوالك",
    "خارج الربح",
    "اجمالي الربح",
  ];
  return (
    <div className={classes.root}>
      <CardContent className="headerSearch w-50 m-auto mt-3" style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h6"> الارباح العامة</Typography>
      </CardContent>
      <div style={{ marginTop: 8 }}>
        <Container>

          <ProfitsTable
            headCells={headCells}
            profits={generalProfits}
            type="general"
          />
        </Container>
      </div>
    </div>
  );
}

export default GeneralProfits;

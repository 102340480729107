import { useEffect, useState } from "react";
import c from "./TreasuryBalance.module.scss";
import { ConvertToArabicNumbers } from "../../utils";
import Alert from "../../components/UI/toaster";
import axios from "axios";
import Mydate from "../../components/Helpers/MyDateParser";
import MainHeader from "../../components/mainHeader/MainHeader";
import { useHistory } from "react-router-dom";
const head = ["اليوم", "رصيد امس", "تحصيل اليوم", "خارج اليوم", "باقي اليوم"];
const TreasuryBalance = () => {
  const [date, setdate] = useState("");
  const [Ydata, setYdata] = useState("");
  const [Tdata, setTdata] = useState("");
  const [Total, setTotal] = useState("");
  const { push } = useHistory();
  const t = new Date();
  const getTodaydate = () => {
    let today = `${t.getDate()}    /  ${
      t.getMonth() + 1
    }    /   ${t.getFullYear()}`;
    setdate(today);
    return today;
  };
  const fetchall = async () => {
    const timeStamp = new Date().getTime();
    const yesterdayTimeStamp = timeStamp - 24 * 60 * 60 * 1000;
    const yesterdayDate = new Date(yesterdayTimeStamp);

    try {
      const res1 = await axios.get(
        `Treasury/incomeAndOutcomeAt?at=${Mydate(yesterdayDate)}`
      );
      setYdata(res1.data.total);

      const res2 = await axios.get(
        `Treasury/incomeAndOutcomeAt?at=${Mydate(t)}`
      );
      setTdata(res2.data);

      const res3 = await axios.get(
        `/Treasury/incomeAndOutcomeUntill?untill=${Mydate(t)}`
      );
      setTotal(res3.data.total);
    } catch (e) {
      Alert(e);
    }
  };
  useEffect(() => {
    getTodaydate();
    fetchall();
  }, []);
  return (
    <div
      className=" w-100  p-0 py-4 pb-5 position-relative  "
      style={{
        background: "#534d3b",
        marginTop: " -15px !important",
        minHeight: "107vh",
        outline: " 8px solid #534d3b",
      }}
    >
      <div>
        <MainHeader title=" رصيد الخزينة" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "2rem",
          marginTop: "1rem",
        }}
      >
        <button
          style={{
            backgroundColor: "yellow",
            padding: "5px 1rem",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => push("/daily-reports", { fromTreasury: true })}
        >
          الرصيد اليومي
        </button>
        <button
          style={{
            backgroundColor: "yellow",
            padding: "5px 1rem",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => push("/monthly-reports", { fromTreasury: true })}
        >
          الرصيد الشهري
        </button>
        <button
          style={{
            backgroundColor: "yellow",
            padding: "5px 1rem",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => push("/annual-reports", { fromTreasury: true })}
        >
          الرصيد السنوي
        </button>
      </div>
      <div className={c.tableContainer}>
        <div className={c.main}>
          <div className={c.row1}>
            {head.map((head) => (
              <div className={c.head}>{head}</div>
            ))}
          </div>
          <div className={c.row2}>
            <div className={c.cell}>
              {ConvertToArabicNumbers(date.toString())}
            </div>
            <div className={c.cell}>
              {Ydata !== 0 ? ConvertToArabicNumbers(Ydata) : "صفر"}
            </div>
            <div className={c.cell}>
              {Tdata.deposits !== 0
                ? ConvertToArabicNumbers(Tdata.deposits)
                : "صفر"}
            </div>
            <div className={c.cell}>
              {Tdata.expenses !== 0
                ? ConvertToArabicNumbers(Tdata.expenses)
                : "صفر"}
            </div>
            <div className={c.cell}>
              {Tdata.total !== 0 ? ConvertToArabicNumbers(Tdata.total) : "صفر"}
            </div>
          </div>
        </div>
      </div>

      <div className={c.total}>
        <p className={c.head}>اجمالي الرصيد</p>
        <div className={c.body}>
          {ConvertToArabicNumbers(Math.abs(Total).toFixed(2))}{" "}
          {Math.round(Total) < 0 ? "عليه" : ""}
          {Math.round(Total) > 0 ? "له" : ""}
          {Total === 0 ? "صفر" : ""}
        </div>
      </div>
    </div>
  );
};
export default TreasuryBalance;

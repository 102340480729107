import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDateDashed, indexOfId } from "../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import Alerto from "../../components/UI/toaster";

export const addExpenses = createAsyncThunk(
  "expenses/addExpenses",
  async (
    { partner, category, dateTime, money, notes },
    { rejectWithValue, getState }
  ) => {
    try {
      const response = await axios.post(
        `/GeneralExpenses?categoryId=${category.id}`,
        {
          dateTime,
          money,
          notes,
          partnerId: partner.id,
          adderId: getState().auth.user.UserId,
        }
      );
      if (response.status === 200) {
        toast.success("تم الاضافة");
        return response.data;
      }
    } catch (error) {
      Alerto(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateExpenses = createAsyncThunk(
  "expenses/updateExpenses",
  async (
    {
      id,
      expenseId,
      partner,
      category,
      categoryTypeId,
      dateTime,
      money,
      notes,
      note,
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const response = await axios.put(`/GeneralExpenses/${id || expenseId}`, {
        treasuryTypeId: category?.id || categoryTypeId,
        money,
        notes: notes || note,
        partnerId: partner?.id,
        adderId: getState().auth.user.UserId,
        dateTime: dateTime,
      });

      toast.success("تم التعديل");
      return response.data;
    } catch (error) {
      Alerto(error);
      rejectWithValue(error.response.data);
      return error.response.data;
    }
  }
);

export const deleteExpenses = createAsyncThunk(
  "expenses/deleteExpenses",
  async (id) => {
    try {
      const response = await axios.delete(`GeneralExpenses?id=${id}`);
      if (response.status === 200) {
        toast.error("تم الحذف");
      }
      return response.data;
    } catch (error) {
      Alerto(error);
      return error.response.data;
    }
  }
);
export const deleteFromTruckExpenses = createAsyncThunk(
  "expenses/deleteFromTruckExpenses",
  async (row) => {
    try {
      const response = await axios.delete(
        `ExpenseContoller/RemoveExpenseById?isDamage=${row.isDamage}&id=${row.expenseId}`
      );
      if (response.status === 200) {
        toast.error("تم الحذف");
      }
      return response.data;
    } catch (error) {
      Alerto(error);
      return error.response.data;
    }
  }
);

export const getExpensesCategories = createAsyncThunk(
  "expenses/getExpensesCategories",
  async () => {
    try {
      const response = await axios.get("/GeneralExpenses/categories");
      return response.data;
    } catch (error) {
      toast.error(error.response.data);
      return error.response.data;
    }
  }
);

export const getAllExpenses = createAsyncThunk(
  "expenses/getAllExpenses",
  async (dateTime) => {
    try {
      const response = await axios.get(`/GeneralExpenses?date=${dateTime}`);

      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);

export const getPurchasesMortgageExpenses = createAsyncThunk(
  "expenses/getPurchasesMortgageExpenses",
  async (dateTime) => {
    try {
      const response = await axios.get(
        `/GeneralExpenses/GetReportMortgageCustomers?date=${getDateDashed(
          dateTime
        )}`
      );
      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);

export const getSellersMortgageExpenses = createAsyncThunk(
  "expenses/getSellersMortgageExpenses",
  async (dateTime) => {
    try {
      const response = await axios.get(
        `/GeneralExpenses/GetReportMortgageSeller?date=${getDateDashed(
          dateTime
        )}`
      );
      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);
export const getClientsMoney = createAsyncThunk(
  "expenses/getClientsMoney",
  async (dateTime) => {
    try {
      const response = await axios.get(
        `/GeneralExpenses/GetClientsCreditory?date=${getDateDashed(dateTime)}`
      );
      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);
export const getTrucksNolon = createAsyncThunk(
  "expenses/getTrucksNolon",
  async (dateTime) => {
    try {
      const response = await axios.get(
        `/GeneralExpenses/GetClientsNawlon?date=${getDateDashed(dateTime)}`
      );

      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);
export const getEmployeesSalary = createAsyncThunk(
  "expenses/getEmployeesSalary",
  async (dateTime) => {
    try {
      const response = await axios.get(
        `/GeneralExpenses/GetEmployeesSalary?date=${getDateDashed(dateTime)}`
      );
      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);
export const getTrucksDamage = createAsyncThunk(
  "expenses/getTrucksDamage",
  async (dateTime) => {
    try {
      const response = await axios.get(
        `/GeneralExpenses/GetTrucksDamages?date=${getDateDashed(dateTime)}`
      );
      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);
export const getGeneralDamage = createAsyncThunk(
  "expenses/getGeneralDamage",
  async (dateTime) => {
    try {
      const response = await axios.get(
        `/GeneralExpenses/GeGeneralDamages?date=${getDateDashed(dateTime)}`
      );
      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);

export const getSellerAdvanceExpenses = createAsyncThunk(
  "expenses/getSellerAdvanceExpenses",
  async (dateTime) => {
    try {
      const response = await axios.get(
        `/GeneralExpenses/GetReportCreditorySeller?date=${getDateDashed(
          dateTime
        )}`
      );
      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);

export const getEmployeeAdvanceExpenses = createAsyncThunk(
  "expenses/getEmployeeAdvanceExpenses",
  async (dateTime) => {
    try {
      const response = await axios.get(
        `/GeneralExpenses/GetReportCreditoryEmployee?date=${getDateDashed(
          dateTime
        )}`
      );
      return response.data;
    } catch (error) {
      Alerto(error);
    }
  }
);

const initialState = {
  expenses: [],
  expenseCategories: [],
  allExpenses: [],
  purchasesMortgageExpenses: [],
  sellersMortgageExpenses: [],
  ClientsMoney: [],
  TrucksNolon: [],
  EmployeesSalary: [],
  TrucksDamage: [],
  GeneralDamage: [],
  sellerAdvanceExpenses: [],
  employeeAdvanceExpenses: [],
};

const expensesSlice = createSlice({
  name: "expenses",
  initialState,

  extraReducers: {
    [addExpenses.pending]: (state, action) => {
      state.expenses.unshift(action.meta.arg);
    },

    [addExpenses.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        state.expenses[0].id = action.payload.id;
      }
    },
    [addExpenses.rejected]: (state) => {
      state.expenses.shift();
    },

    [updateExpenses.fulfilled]: (state, action) => {
      const index = indexOfId(state.expenses, action.meta.arg.id);
      state.expenses[index] = action.meta.arg;
    },

    [deleteExpenses.fulfilled]: (state, action) => {
      state.expenses = state.expenses.filter((e) => {
        return e.id !== action.meta.arg;
      });
    },

    [getExpensesCategories.fulfilled]: (state, action) => {
      state.expenseCategories = action.payload;
    },

    [getAllExpenses.fulfilled]: (state, action) => {
      state.expenses = action.payload;
    },

    [getPurchasesMortgageExpenses.fulfilled]: (state, action) => {
      state.purchasesMortgageExpenses = action.payload;
    },

    [getSellersMortgageExpenses.fulfilled]: (state, action) => {
      state.sellersMortgageExpenses = action.payload;
    },
    [getClientsMoney.fulfilled]: (state, action) => {
      state.ClientsMoney = action.payload;
    },
    [getTrucksNolon.fulfilled]: (state, action) => {
      state.TrucksNolon = action.payload;
    },
    [getEmployeesSalary.fulfilled]: (state, action) => {
      state.EmployeesSalary = action.payload;
    },
    [getTrucksDamage.fulfilled]: (state, action) => {
      state.TrucksDamage = action.payload;
    },
    [getGeneralDamage.fulfilled]: (state, action) => {
      state.GeneralDamage = action.payload;
    },

    [getSellerAdvanceExpenses.fulfilled]: (state, action) => {
      state.sellerAdvanceExpenses = action.payload;
    },

    [getEmployeeAdvanceExpenses.fulfilled]: (state, action) => {
      state.employeeAdvanceExpenses = action.payload;
    },
  },
});

export default expensesSlice.reducer;

import axios from "axios";
import { toast } from "react-toastify";
import Alerto from "../../UI/toaster";

export async function dynamicReq({ api, data, type, msg, callback }) {
  try {
    await axios[type](api, data);
    toast[type === "delete" ? "error" : "success"](msg);
    callback && callback();
  } catch (e) {
    Alerto(e);
  }
}
export async function dynamicGetReq({ api }) {
  try {
    const res = await axios.get(api);
    return res;
  } catch (e) {
    Alerto(e);
  }
}

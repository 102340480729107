import { Card, CardContent, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import SessionTable from "./components/SessionTable";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import MainHeader from "../../components/mainHeader/MainHeader";

const months = [
  "يناير",
  "فبراير",
  "مارس",
  "ابريل",
  "مايو",
  "يونيو",
  "يوليو",
  "اغسطس",
  "سبتمبر",
  "اكتوبر",
  "نوفمبر",
  "ديسمبر",
];

const days = [
  "السبت",
  "الاحد",
  "الاثنين",
  "الثلاثاء",
  "الاربعاء",
  "الخميس",
  "الجمعة",
];

const locale = {
  localize: {
    month: (n) => months[n],
    day: (n) => days[n],
  },
  formatLong: {},
};
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
}));

function UsersSessions() {
  const [usersSessions, setUsersSessions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const classes = useStyles();

  const getUsersSessions = () => {
    axios
      .get(
        `/Security/sessions?year=${selectedDate.getFullYear()}&month=${
          selectedDate.getMonth() + 1
        }`
      )
      .then((res) => {
        setUsersSessions(res.data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUsersSessions();
    }
    return () => {
      mounted = false;
    };
  }, [selectedDate]);

  return (
    <div>
      <div className="my-2">
        <MainHeader title="سجل اوقات المستخدمين" />
      </div>
      <CardContent
        className="headerSearch w-50 m-auto mt-3"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <DatePicker
          locale={locale}
          selected={selectedDate}
          onChange={(date) => {
            setSelectedDate(date);
          }}
          className="w-100"
          dateFormat="MM/yyyy"
          showMonthYearPicker
          showFourColumnMonthYearPicker
          customInput={
            <TextField variant="outlined" size="small" label="الشهر والسنة" />
          }
        />
      </CardContent>
      <div style={{ marginTop: 8, overflow: "auto" }}>
        <SessionTable data={usersSessions} />
      </div>
    </div>
  );
}

export default UsersSessions;

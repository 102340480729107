import { ConvertToArabicNumbers, renderDate } from '../../../utils';
import { IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import CustomizedTable from '../../../components/Table';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Link } from 'react-router-dom';
import React from 'react';
import { parseISO } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {},
  info: {
    color: theme.palette.info.light,
  },
}));
function SearchTable({ items, type }) {

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 16,
      overflow: 'auto',
    },
  }))(TableCell);
  const classes = useStyles();
  return (
    <div style={{ maxWidth: 600, margin: " 32px auto" }}>
      {items && items.length > 0 && (
        <CustomizedTable
          tableHeader={["مسلسل", "التاريخ", "العميل", "العدد", "التفاصيل"]}
        >
          {items.map((item, index) => (
            <TableRow key={index} align="center" className={classes.tableBody}>
              <StyledTableCell align="center">
                <Typography variant="body1" color="textPrimary">
                  {ConvertToArabicNumbers(index + 1)}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="body1" color="textPrimary">
                  {ConvertToArabicNumbers(renderDate(parseISO(item.dateTime)))}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="body1" color="textPrimary">
                  {item.partner?.name}
                </Typography>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Typography variant="body1" color="textPrimary">
                  {ConvertToArabicNumbers(item.count)}
                </Typography>
              </StyledTableCell>
              <TableCell align="center" style={{ width: "32px" }}>
                <IconButton
              
                  component={Link}
                  to={
                    type === "export"
                      ? `tools-out/${item.id}`
                      : `/importing-details/${item.id}`
                  }
                  className={classes.info}
                  onClick={() => {}}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </CustomizedTable>
      )}
    </div>
  );
}

export default SearchTable;

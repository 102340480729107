import * as Yup from "yup";

import { Form, Formik } from "formik";

import AppTextField from "../../../components/form/AppTextField";
import { Box } from "@material-ui/core";
import { Button } from "@material-ui/core";
import CustomDialog from "../../../components/CustomDialog";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

function UpdateCrop(props) {
  const user = useSelector((state) => state.auth.user);
  const classes = useStyles();

  return (
    <CustomDialog open={props.open} textAlign="center" onClose={props.onClose}>
      <Formik
        initialValues={{
          name: props.product.name ? props.product.name : "",
          code_Product: Boolean(props.product.code_Product)
            ? props.product.code_Product
            : "",
          description: Boolean(props.product.description)
            ? props.product.description
            : "",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("اجباري"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          props.updateCrop({
            ...values,
            userId: user.UserId,
            id: props.product.id,
          });
          props.onClose();
        }}
        enableReinitialize={true}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Box className={classes.root}>
              <AppTextField
                label="اسم الصنف"
                name="name"
                type="text"
                onBlur={() => props.checkhandler(values.name)}
                style={{ width: 140 }}
              />

              <AppTextField
                label="وصف الصنف"
                name="description"
                type="text"
                multiline
              />

              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ height: 40, width: 80 }}
              >
                حفظ
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
}

export default UpdateCrop;

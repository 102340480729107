import { useState } from "react";
import MassEditTable from "./tableUi";
import { useEffect } from "react";
import { SubmitBtn, ShowBtn } from "./stylds";
import { useSelector } from "react-redux";

export function MassEditControl(props) {
  const date = useSelector((state) => state.auth.webDate);
  const isEmployee = localStorage.getItem("role") === "موظف";
  const [filterState, setFilterState] = useState({
    partner: "",
    truckItem: "",
    productsCount: "",
    dateTime: isEmployee ? date : null,
    commision: "",
    priceForUnitWeight: "",
    weight: "",
    movementTool: "",
  });

  const [updateState, setUpdateState] = useState({
    partner: "",
    truckItem: "",
    productsCount: "",
    dateTime: null,
    commision: "",
    priceForUnitWeight: "",
    weight: "",
    movementTool: "",
  });

  function handleFilterState(value, key) {
    setFilterState((prev) => ({ ...prev, [key]: value }));
  }
  function handleUpdateState(value, key) {
    setUpdateState((prev) => ({ ...prev, [key]: value }));
  }
  useEffect(() => {
    props.handleFilter(filterState);
  }, [filterState]);

  return (
    <>
      <MassEditTable
        isEmployee={isEmployee}
        handleState={handleFilterState}
        state={filterState}
      />
      <MassEditTable
        isEmployee={isEmployee}
        handleState={handleUpdateState}
        state={updateState}
        isUpdate
      />
      <SubmitBtn onClick={() => props.handleUpdate(updateState)}>
        تعديل
      </SubmitBtn>
    </>
  );
}

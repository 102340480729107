import CustomizedTable, { StyledTableCell } from "../../../components/Table";
import { TableRow, Typography } from "@material-ui/core";

import { ConvertToArabicNumbers } from "../../../utils";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
  },
  head: {
    border: "2px solid black",
  },
  maincell: {
    backgroundColor: "#EEF66C",
    border: "3px solid black",
    padding: 0,
    overflow: "hidden",
  },
  cell: {
    backgroundColor: "#fff",
    border: "3px solid black",
    padding: 0,
    overflow: "hidden",
  },
  tableBody: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));
export default function SoldItemsTable({ soldItems, bgColor, color }) {
  const classes = useStyles();
  const truck = useSelector((state) => state.invoice.truck);
  const tableHeader = [
    "عميل مشترك",
    "المبلغ",
    "العدد",
    "الوزن",
    "السعر",
    "الصنف",
  ];
  !truck.sharedTruck && tableHeader.splice(0, 1);
  return (
    <div className={classes.root}>
      <CustomizedTable
        className={classes.head}
        tableHeader={tableHeader}
        bgColor={bgColor}
        color={color}
        isPaper={true}
      >
        {soldItems &&
          soldItems.length > 0 &&
          soldItems.map((item, index) => (
            <TableRow key={index} align="center" className={classes.tableBody}>
              {!!truck.sharedTruck && (
                <StyledTableCell className={classes.cell} align="center">
                  <Typography variant="body1" color="textPrimary">
                    {item.partner?.name}
                  </Typography>
                </StyledTableCell>
              )}
              <StyledTableCell className={classes.cell} align="center">
                <Typography variant="body1" color="textPrimary">
                  {ConvertToArabicNumbers(Math.round(item.totalMoney))}
                </Typography>
              </StyledTableCell>

              <StyledTableCell className={classes.cell} align="center">
                <Typography variant="body1" color="textPrimary">
                  {item.itemsCount
                    ? ConvertToArabicNumbers(item.itemsCount)
                    : ConvertToArabicNumbers(item.itemCount)}
                </Typography>
              </StyledTableCell>

              <StyledTableCell className={classes.cell} align="center">
                <Typography variant="body1" color="textPrimary">
                  {ConvertToArabicNumbers(item.weight)}
                </Typography>
              </StyledTableCell>

              <StyledTableCell className={classes.cell} align="center">
                <Typography variant="body1" color="textPrimary">
                  {item.price
                    ? ConvertToArabicNumbers(item.price)
                    : ConvertToArabicNumbers(item.priceForUnitWeight)}
                </Typography>
              </StyledTableCell>

              <StyledTableCell className={classes.maincell} align="center">
                <Typography variant="body1" color="textPrimary">
                  {item.item.product.name}
                </Typography>
              </StyledTableCell>
            </TableRow>
          ))}
      </CustomizedTable>
    </div>
  );
}

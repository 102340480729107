import { ConvertToArabicNumbers, renderDate } from "../../../utils";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ConclusionLabel from "./ConclusionLabel";
import CustomDialog from "../../../components/CustomDialog";
import LocalPrintshopOutlinedIcon from "@material-ui/icons/LocalPrintshopOutlined";
import LogoImage from "../../../components/LogoImage";
import TableInvoiceItems from "./TableInvoiceItems";
import axios from "axios";
import { getSellerInvoiceItems } from "../sellersInvoiceSlice";
import { makeStyles } from "@material-ui/core/styles";
import { parseISO } from "date-fns";
import { useReactToPrint } from "react-to-print";
import Alerto from "../../../components/UI/toaster";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";



const useStyles = makeStyles({
  root: {
    width: "19cm",
    height: "100%",
    margin: "auto",
    marginTop: 12,
    border: '3px solid',
    padding: '10px'
  },
  partnerName: {
    border: "2px solid #000",
    padding: 5,
  },
  "@media print": {
    shaderOwner: {
      textAlign: "center",
    },
    partnerName: {
      textAlign: "center",
    },
  },
  table: {
    borderSpacing: "4px 2px",
    border: "2px solid #000",
    width: "100%",
  },
  tableRow: {
    marginTop: "8px",
  },
  tableCell: {
    border: "2px solid #000",
    padding: 1,
    fontSize: 14
  },
});

function ForwardInvoice({ open, onClose, partnerReport, partnerId, invoice }) {
  const invoiceItems = useSelector(
    (state) => state.sellersInvoice.invoiceItems
  );

  const total = invoiceItems.reduce((acc, val) => {
    return acc + val.totalSellingPrice;
  }, 0);
  const totalMortage = invoiceItems.reduce((acc, val) => {
    return acc + val.totalToolPrice;
  }, 0);

  const [totalSales, setTotalSales] = useState(0);
  const getTotalSales = () => {
    axios
      .get(`/SellingsInvoices/total/${partnerReport.id}`)
      .then((res) => {
        setTotalSales(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  const [remainingToolsPrice, setRemainingToolsPrice] = useState(0);
  const getPriceOfRemainingTools = () => {
    axios
      .get(`/MovementTools/reports/Remaining/price/${partnerReport.id}`)
      .then((res) => {
        setRemainingToolsPrice(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  const [allTools, setAllTools] = useState([]);

  const getAllTools = () => {
    axios
      .get(`/MovementTools/reports/Remaining/${partnerReport.id}`)
      .then((res) => {
        setAllTools(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  const totalToolsCount = allTools.reduce((acc, val) => {
    return acc + val.count;
  }, 0);

  const dispatch = useDispatch();
  useEffect(() => {
    if (partnerReport.id) {
      getPriceOfRemainingTools();
      dispatch(getSellerInvoiceItems(partnerReport));
      getTotalSales();
      getAllTools();
    }
    return () => {
      setRemainingToolsPrice(0);
      setTotalSales(0);
      setAllTools([]);
    };
  }, [open]);

  const componentRef = useRef();
  const logPrint = () => {
    let id = "";
    if (invoice && invoice.id) {
      id = invoice.id;
    } else if (partnerReport.invoice && partnerReport.invoice.invoiceId) {
      id = partnerReport.invoice.invoiceId;
    }
    axios
      .put(`/SellingsInvoices/LogPrintingCount?invoiceId=${id}`)
      .then((res) => { })
      .catch((err) => {
        Alerto(err);
      });
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: logPrint,
    documentTitle: `فاتورة البائع ${partnerReport.name}`,
  });
  const [buyerDebt, setBuyerDebt] = useState("");
  const getBuyerDebtDetails = () => {
    axios
      .get(`/MoneyCurrentAccountReport/debtDetails/buyer/${partnerId}`)
      .then((res) => {
        setBuyerDebt(res.data);
      })
      .catch((err) => { });
  };
  useEffect(() => {
    partnerId && getBuyerDebtDetails();
  }, [partnerId]);
  const classes = useStyles();
  return (
    <CustomDialog open={open} textAlign="center" onClose={onClose}>
      <Tooltip title="طباعة الفاتورة">
        <IconButton onClick={handlePrint}>
          <LocalPrintshopOutlinedIcon />
        </IconButton>
      </Tooltip>

      <div ref={componentRef} className={classes.root}>
        <LogoImage />
        <Grid
          container
          justifyContent="space-between"
          style={{ border: '3px  dashed ' }}
          className='my-3 py-2 center'

        >
          <Grid item xs={6} className=' text-end'>
            <Typography variant="h6" className=' text-end pe-3' >
              الاسم : {" "}
              {partnerReport.name}{" "}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <div style={{ textAlign: "center" }}>
              <Typography style={{ marginBottom: 4 }}>
                {" "}
                تاريخ الفاتورة : {" "}
                {ConvertToArabicNumbers(
                  renderDate(partnerReport.sellingDate)
                )}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <TableInvoiceItems invoiceItems={invoiceItems} />



        <TableContainer
          style={{ marginBottom: 12, width: "100%" }}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow style={{ background: '#f3c637' }}>
                <TableCell className="p-2 m-0 center text-center w-50" style={{ fontSize: 18, border: '2px solid' }}>  أجمالي كشف  : {" "}</TableCell>
                <TableCell className="p-2 m-0 center text-center w-50" style={{ fontSize: 18, border: '2px solid' }}>الرهن</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ border: "1px solid #777" }}>
              <TableCell className="p-1 m-0 center text-center w-50" style={{ fontSize: 18, border: '2px solid' }} > {ConvertToArabicNumbers(Math.round(total))}{" "}</TableCell>
              <TableCell className="p-1 m-0 center text-center w-50" style={{ fontSize: 18, border: '2px solid' }} >{ConvertToArabicNumbers(Math.round(totalMortage) ? Math.round(totalMortage) : 'لا يوجد')}</TableCell>
            </TableBody>
          </Table>
        </TableContainer>






        <div style={{ marginTop: 6 }}>
          <Grid
            item
            container
            xs={12}
            style={{ border: '3px solid ', background: '#f3c637' }}
            className='center p-2 mt-2'           >
            <Grid item xs={3}   >
              <Typography     >
                أجمالي رهن : {" "}
                {ConvertToArabicNumbers(Math.round(remainingToolsPrice))}{" "}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography  >
                عدد العدة : {" "}
                {ConvertToArabicNumbers(totalToolsCount)}{" "}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography  >
                فواتير  : {" "}
                {ConvertToArabicNumbers(Math.round(buyerDebt?.totalInvoices))}{" "}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6"  >
                الاجمالي  : {" "}
                {ConvertToArabicNumbers(Math.round(buyerDebt?.totalInvoices + remainingToolsPrice))}{" "}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </CustomDialog>
  );
}

export default ForwardInvoice;

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import { ConvertToArabicNumbers, indexOfId, renderDate } from "../../utils";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@material-ui/lab/Autocomplete";
import BuyersAddresses from "./components/BuyersAddresses";
import BuyersReport from "./components/BuyersReport";
import LogoImage from "../../components/LogoImage";
import { addBuyersAddress } from "./collectingSlice";
import axios from "axios";
import { getBuyersCollectingMoney } from "./collectingSlice";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import Alerto from "../../components/UI/toaster";
import { Col, Row } from "react-bootstrap";
import MainHeader from "../../components/mainHeader/MainHeader";

const useStyles = makeStyles({
  root: {
    width: "100%",
    flexGrow: 1,
    height: "100%",
  },

  field: {
    display: "flex",
    justifyContent: "center",
  },
  markets: {
    margin: "auto",
    width: 880,
    display: "flex",
    flexDirection: "row",
    "& > *": {
      marginRight: 12,
    },
    marginBottom: 4,
    flexWrap: "wrap",
  },

  itemInfo: {
    padding: 8,
    marginRight: 16,
    borderRadius: 20,
    paddingRight: 12,
    paddingLeft: 12,
    color: "#222",
    backgroundColor: "#eeff41",
  },
  printableTable: {
    overflow: "hidden",
    height: 0,
  },
  "@media print": {
    printableTable: {
      height: "auto",
      overflow: "auto",
    },
  },
});

function BuyersCollecting() {
  const [collectingAddresses, setCollectingAddresses] = React.useState([]);
  const fetchCollectingAddresses = () => {
    axios
      .get("/CollectingAddress")
      .then((res) => {
        setCollectingAddresses(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchCollectingAddresses();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const [address, setAddress] = useState("");

  const dispatch = useDispatch();

  const report = useSelector((state) => state.collecting.buyersData);
  const printReport = useSelector((state) => state.collecting.buyersReport);
  const selected = useSelector((state) => state.collecting.buyersAddresses);
  const [fetching, setFetching] = useState(false);
  const addCollectingAddress = () => {
    if (fetching || !address.id) {
      return;
    }
    setFetching(true);
    dispatch(getBuyersCollectingMoney(address)).then(({ payload }) => {
      if (payload && payload.length > 0) {
        dispatch(addBuyersAddress(address));
      } else if (payload && payload.length === 0) {
        toast.info(`ﻻ يوجد تحصيل في  ${address.name}`);
      }
      const index = indexOfId(collectingAddresses, address.id);
      let addressesArr = [...collectingAddresses];
      addressesArr.splice(index, 1);
      setCollectingAddresses(addressesArr);
      setFetching(false);
      setAddress("");
    });
  };

  const componentRef = React.useRef();

  const pageStyle = `
  @page {
    background: white;
    width: 21cm;
    height: 29.7cm;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "كشف تحصيل البائعين",
  });

  const classes = useStyles();
  return (
    <div
      className=" w-100  p-0 pb-4 pb-5 position-relative overflow-x-hidden  "
      style={{
        background: "#534d3b",
        marginTop: " -15px !important",
        minHeight: "107vh",
        outline: " 8px solid #534d3b",
      }}
    >
      <div className="my-4">
        <MainHeader title="كشف تحصيل البائعين" />
      </div>
      <Container className="p-0">
        <Card
          className="headerSearch center m-0 p-0 "
          style={{ height: "68px" }}
        >
          <CardContent className="center w-100 h-100">
            <Autocomplete
              options={collectingAddresses}
              getOptionSelected={(option, value) =>
                option.value === value?.value
              }
              getOptionLabel={(option) => (option.name ? option.name : "")}
              className=" col-9 col-md-4"
              autoComplete
              noOptionsText="لم يتم العثور على خيارات"
              onChange={(e, newValue) => {
                if (newValue) {
                  setAddress(newValue);
                } else {
                  setAddress("");
                }
              }}
              size="small"
              value={address}
              renderInput={(params) => (
                <TextField
                  style={{
                    height: "100%",
                    transform: "translateX(12px)",
                  }}
                  className="col-8"
                  {...params}
                  label="خط التحصيل"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
            <Button
              onClick={() => {
                addCollectingAddress(fetching);
              }}
              variant="contained"
              color="primary"
              className="btnMore"
            >
              اضف
            </Button>
          </CardContent>
        </Card>
        <Row className="my-2 contaniner px-2">
          {selected.map((address, index) => (
            <Col
              xs={4}
              md={1}
              key={index}
              className=" m-2 center"
              style={{
                height: "40px",
                padding: "10px",
                color: "black",
                background: "#f3c637",
                boxShadow:
                  "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
              }}
            >
              {address.name} (
              {ConvertToArabicNumbers(
                printReport[index]
                  .filter((item) => {
                    return (
                      item.toolsCount !== 0 ||
                      item.toolsMoney !== 0 ||
                      item.buyerLoans !== 0 ||
                      item.sellingsInvoices !== 0
                    );
                  })
                  .filter((item) => {
                    return (
                      item.buyerLoans +
                        item.toolsMoney +
                        item.sellingsInvoices >
                      10
                    );
                  }).length
              )}
              )
            </Col>
          ))}
        </Row>

        <div ref={componentRef}>
          <div className={classes.printableTable}>
            {printReport.length > 0 && selected.length > 0 && (
              <>
                <LogoImage />
              </>
            )}
          </div>
          <BuyersReport
            selected={selected}
            data={report}
            sub={printReport}
            handlePrint={handlePrint}
          />

          <div
            className={classes.printableTable}
            style={{
              background: "#4b4b4b",
              color: "white",
              textAlign: "center",
              padding: "5px",
            }}
          >
            {printReport.length > 0 && selected.length > 0 && (
              <Typography style={{ margin: 3 }}>
                وقت الطباعة {ConvertToArabicNumbers(renderDate(new Date()))}
              </Typography>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default BuyersCollecting;

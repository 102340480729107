import { Button, TextField } from "@material-ui/core";
import CustomDialog from "../../../components/CustomDialog";
import React from "react";
import Alerto from "../../../components/UI/toaster";
import axios from "axios";

function UpdateAddress({ open, onClose, address, handleUpdate }) {
  const [addressName, setAddressName] = React.useState("");
  React.useEffect(() => {
    setAddressName(address.name);
  }, [address]);
  const checkHandler = (name) => {
    if (name.length > 0) {
      axios
        .get(`/CollectingAddress/isExist/${name.trim()}`)
        .then((res) => {
          if (res.data === true) {
            alert("الصنف موجود مسبقا");
          }
        })
        .catch((e) => {
          Alerto(e);
        });
    }
  };
  return (
    <CustomDialog
      action1={
        <Button
          style={{ height: 40 }}
          color="primary"
          variant="contained"
          onClick={() => {
            handleUpdate({ id: address.id, name: addressName });
            onClose();
          }}
        >
          {" "}
          تعديل{" "}
        </Button>
      }
      open={open}
      onClose={onClose}
    >
      <TextField
        value={addressName}
        onChange={(e) => setAddressName(e.target.value)}
        size="small"
        onBlur={(e) => checkHandler(e.target.value)}
      />
    </CustomDialog>
  );
}

export default UpdateAddress;

import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import store from "./redux/store";
import jwt_decode from "jwt-decode";
// axios.defaults.baseURL = localStorage.getItem("baseUrl");
// axios.defaults.baseURL = "https://Backendowlsonline.owltecheg.com/api";
axios.defaults.baseURL = "https://backendowlsdemo.owltecheg.net/api";
const token = localStorage.getItem("token");
console.log(token);
axios.defaults.headers.common["x-auth-token"] = token;
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
if (token) {
  let param = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
  const decoded = jwt_decode(token);
  let role =
    typeof decoded[param] == "object" ? decoded[param][1] : decoded[param];
  console.log(role);
  localStorage.setItem("role", role);
}
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}

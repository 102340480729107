import {

  Card,
  CardContent,
  CardHeader,

  Divider,
  Grid,

  Typography,
} from '@material-ui/core';
import React from 'react';

import LogoUpload from './LogoUpload';
import ProfileImg from './ProfileImg';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  fileInput: {
    borderBottom: '4px solid lightgray',
    borderRight: '4px solid lightgray',
    borderTop: '1px solid black',
    borderLeft: '1px solid black',
    padding: 10,
    margin: 15,
    cursor: 'pointer',
  },

  imgPreview: {
    textAlign: 'center',
    margin: '5px 15px',
    height: 'auto',
    maxWidth: '100%',
    width: 300,
    border: '1px dashed #333',
    marginTop: 12,
    overflow: 'none',
  },

  img: {
    width: '100%',
    height: '100%',
  },

  previewText: {
    width: '100%',
    marginTop: 20,
  },

  submitButton: {
    marginLeft: 10,
    height: 40,
    backgroundColor: '#2F9DDD',
    cursor: 'pointer',
    opacity: 0.8,
    '&:hover': {
      opacity: 1,
      backgroundColor: '#2F9DDD',
    },
  },

  centerText: {
    textAlign: 'center',
    width: 500,
  },
}));

function Images() {
  const classes = useStyles();
  return (
    <Card>
      <CardHeader title="الصور" />
      <Divider />
      <CardContent>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={4}
          direction="column"
        >
          <Grid item xs={10}>
            <Typography style={{ margin: 8 }} variant="h5">
              {' '}
              صورة اللوجو{' '}
            </Typography>
            <LogoUpload />
          </Grid>

          <Grid item xs={10}>
            <Typography style={{ margin: 8 }} variant="h5">
              {' '}
              صورة الشاشة الرئيسية{' '}
            </Typography>
            <ProfileImg />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Images;

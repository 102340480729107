import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { ConvertToArabicNumbers, renderDate } from "../../utils";
import { Link, useParams, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getLastDepositDate, getPartnersData } from "./clientSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../auth/authSlice";
import DatePicker from "react-datepicker";
import TrucksList from "./components/TrucksList";
import axios from "axios";
import { fetchBuyers } from "../main-data/dataSlice";
import { makeStyles } from "@material-ui/styles";
import Alerto from "../../components/UI/toaster";
import { getRemainingDetailsForPartners } from "../movement-tools/tools-reports/toolsSlice";
import RemainingDetails from "../movement-tools/components/RemainingDetails";
import { Col, Container, Row } from "react-bootstrap";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import CommissionTruck from "./components/CommissionTruck";
import BuyingTruck from "./components/BuyingTruck";
import "../seller-profile/seller-profile.css";
import { FormControlLabel, Switch } from "@mui/material";
const fixedvalue = 1;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100%",
  },
  info: {},
  invoiceSummery: {
    padding: 8,
    border: "1px solid #ddd",
    borderRadius: 4,
    backgroundColor: "#fff",
    margin: "32px auto",
  },
  middleSection: {
    padding: 8,
    border: "1px solid #ddd",
    borderRadius: 4,
    backgroundColor: "#fff",
    margin: "32px auto",
  },
  invoiceCell: {
    padding: 12,
    backgroundColor: "#FDF44D",
    textAlign: "center",
    borderRadius: 8,
  },
  cell: {
    padding: 12,
    backgroundColor: "#FF9900",
    textAlign: "center",
    borderRadius: 8,
  },
  cellInfo: {},
  label: {
    fontWeight: 600,
  },
  debtDetails: {
    padding: 12,
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    borderRadius: 4,
    marginTop: 16,
  },
}));
const ProfileItem = (num) =>
  num !== 0 && num ? ConvertToArabicNumbers(Math.abs(Math.round(num))) : "صفر";
function ClientProfile() {
  const history = useHistory();
  const { partnerId } = useParams();
  const [truckDate, setTruckDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [orderType, setOrderType] = useState("تنازلي");
  const [trucks, setTrucks] = useState([]);
  const remainingDetails = useSelector((state) => state.tools.remainingDetails);
  const [openDetails, setOpenDetails] = useState(false);
  const [showSharedTrucks, setShowSharedTrucks] = useState(false);
  const [farmerSharedTruckPage, setFarmerSharedTruckPage] = useState(1);
  const onDetailsClicked = (partnerId) => {
    setOpenDetails(true);
    dispatch(getRemainingDetailsForPartners(partnerId));
  };
  const getFramerTrucks = (pageNum) => {
    axios
      .get(
        `/Truck/farmer/AsRep/${partnerId}?pageNumber=${
          pageNum || 1
        }&pageSize=50`
      )
      .then((res) => {
        if (!!pageNum) setTrucks((prev) => [...prev, ...res.data]);
        else setTrucks(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  const getFramerSharedTrucks = (pageNum) => {
    axios
      .get(
        `/Truck/getParticipateTrucks?partnerId=${partnerId}&pageNumber=${
          pageNum || 1
        }&pageSize=50`
      )
      .then((res) => {
        if (!!pageNum) setTrucks((prev) => [...prev, ...res.data]);
        else setTrucks(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  function handleMore() {
    if (showSharedTrucks) {
      getFramerSharedTrucks(farmerSharedTruckPage + 1);
    } else {
      getFramerTrucks(farmerSharedTruckPage + 1);
    }
    setFarmerSharedTruckPage((prev) => prev + 1);
  }

  const getPartnerDueDate = () => {
    axios
      .get(`/Partners/payementDate?PartnerId=${partnerId}`)
      .then((res) => {
        if (res.status === 200) {
          setDueDate(res.data);
        }
      })
      .catch((err) => {
        if (partnerId) {
          history.push(`/seller-profile/${partnerId}`);
        }
      });
  };
  const [invoiceId, setInvoiceId] = useState("");
  const onInvoiceSelected = (id) => {
    setInvoiceId(id);
  };
  useEffect(() => {
    return () => setInvoiceId("");
  }, []);
  const [lastTreatment, setLastTreatment] = useState("");
  const getBuyerLastTreatment = () => {
    axios
      .get(`/MoneyCurrentAccountReport/LastOperation/client/${partnerId}`)
      .then((res) => {
        if (res.status === 200) {
          setLastTreatment(res.data);
        }
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  const [damagedToolsCount, setDamagedToolsCount] = useState(0);
  const getDamagedTools = () => {
    axios
      .get(`/MovementTools/damage?partnerId=${partnerId}`)
      .then((res) => {
        setDamagedToolsCount(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  const [truckStatistics, setTruckStatistics] = useState("");
  const getTruckStatistics = () => {
    axios
      .get(`/Truck/statistics/${partnerId}`)
      .then((res) => {
        if (res.status === 200) {
          setTruckStatistics(res.data);
        }
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  const [buyerDebt, setBuyerDebt] = useState(0);
  const getBuyerDebtDetails = async () => {
    try {
      const res = await axios.get(
        `/MoneyCurrentAccountReport/debtDetails/buyer/${partnerId}`
      );

      setBuyerDebt(res.data);
    } catch (e) {
      Alerto(e);
    }
  };

  const [clientDebt, setClientDebt] = useState(0);
  const [employeeDebt, setemployeeDebt] = useState(0);
  const getClientDebtDetails = () => {
    axios
      .get(`/MoneyCurrentAccountReport/debtDetails/client/${partnerId}`)
      .then((res) => {
        setClientDebt(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  const getemployeeDebtDetails = () => {
    axios
      .get(`MoneyCurrentAccountReport/debtDetails/employee/${partnerId}`)
      .then((res) => {
        setemployeeDebt(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  const [totalMortage, setTotalMortage] = useState(0);
  const getPriceOfRemainingTools = () => {
    axios
      .get(`/MovementTools/reports/Remaining/price/${partnerId}`)
      .then((res) => {
        setTotalMortage(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  const [allTools, setAllTools] = useState([]);
  const getAllTools = () => {
    axios
      .get(`/MovementTools/reports/Remaining/${partnerId}`)
      .then((res) => {
        setAllTools(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  const totalToolsCount = allTools.reduce((acc, val) => {
    return acc + val.count;
  }, 0);

  const logSearch = (id) => {
    axios
      .post(`/Tracking/LogSearching`, {
        userId: id,
        partnerSearchedForId: partnerId,
      })
      .then(() => {})
      .catch((err) => {
        Alerto(err);
      });
  };
  const [Partner, setPartner] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(fetchBuyers());
      if (partnerId) {
        getPartnerDueDate();
        getTruckStatistics();
        dispatch(getPartnersData(partnerId)).then((res) => {
          setPartner(res.payload);
        });
        dispatch(getLastDepositDate(partnerId));
        getDamagedTools();
        getBuyerLastTreatment();
        getBuyerDebtDetails();
        getPriceOfRemainingTools();
        getAllTools();
        dispatch(getCurrentUser()).then((res) => {
          logSearch(res.payload.UserId);
        });
      }
      getClientDebtDetails();
      getemployeeDebtDetails();
    }
    return () => {
      mounted = false;
    };
  }, [partnerId]);
  useEffect(() => {
    if (partnerId) {
      setFarmerSharedTruckPage(1);
      setOrderType("تنازلي");
      !!showSharedTrucks ? getFramerSharedTrucks() : getFramerTrucks();
    }
  }, [showSharedTrucks, partnerId]);

  const classes = useStyles();
  const [NetDebt, setNetDabt] = useState("");
  const getNetDebt = () => {
    let data = {
      trucksInvoices: clientDebt.totalInvoices,
      sellingsInvoices: buyerDebt.totalInvoices,
      totalClientLoans: clientDebt.totalLoans,
      totalBuyerLoans: buyerDebt.totalLoans,
      totalToolMortage: totalMortage != "" ? totalMortage : 0,
      totalSalaryDebt: employeeDebt != "" ? employeeDebt : 0,
    };
    axios
      .post(`MoneyCurrentAccountReport/calculator/debt/total`, data)
      .then((res) => {
        setNetDabt(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  useEffect(() => {
    getNetDebt();
  }, [clientDebt, buyerDebt, employeeDebt, totalMortage]);

  const changeOrderType = () => {
    if (orderType === "تنازلي") {
      setOrderType("تصاعدي");
    }
    if (orderType === "تصاعدي") {
      setOrderType("تنازلي");
    }
  };

  return (
    <div
      className=" w-100 p-0 py-4 pb-5 margin15px"
      style={{
        background: "#534d3b",
        marginTop: " -15px  !important",
        outline: " 8px solid #534d3b",
        minHeight: "107vh ",
      }}
    >
      <Container className="sellerpage">
        {/* ............. header start ..................... */}

        <Row xs={12} className="mt-3">
          {/* ............. اسم البائع ............. */}
          <Col className=" m-auto mb-3" xs={9} lg={4}>
            <Card
              style={{
                height: 88,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow:
                  "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
              }}
            >
              <CardContent>
                <Typography variant="h6">{Partner.name}</Typography>
              </CardContent>
            </Card>
          </Col>
          {/* .......................... */}

          {/* ............. تفاصيل الحساب............. */}

          <Col className=" mb-3 text-center" lg={8}>
            <Card>
              <CardContent
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
                }}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Link
                      style={{ color: "red !important" }}
                      to={`/add-partner/client/${partnerId}`}
                    >
                      <Tooltip title="بيانات العميل">
                        <IconButton>
                          <AccountCircleRoundedIcon
                            style={{ fontSize: "35px" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Button
                      component={Link}
                      style={{ backgroundColor: "#90DCD0", color: "#333" }}
                      to={`/MoneyCurrentAccountReport/client/${partnerId}`}
                      className="btnMore"
                    >
                      تفاصيل الحساب
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      component={Link}
                      style={{ backgroundColor: "#90DCD0", color: "#333" }}
                      to={`/MoneyCurrentAccountReport/client/${partnerId}/rep/trucks`}
                      className="btnMore"
                    >
                      نقلات مشتركة
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className="headerTableFont"
                    >
                      {" "}
                      تاريخ الاستحقاق:{" "}
                      <Typography
                        variant="body2"
                        component="span"
                        color="textPrimary"
                        className="headerTableFont"
                      >
                        {dueDate
                          ? ConvertToArabicNumbers(renderDate(dueDate))
                          : "صفر"}{" "}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className="headerTableFont"
                    >
                      {" "}
                      تاريخ اخر تعامل:{" "}
                      <Typography
                        variant="body2"
                        component="span"
                        color="textPrimary"
                        className="headerTableFont"
                      >
                        {lastTreatment
                          ? ConvertToArabicNumbers(renderDate(lastTreatment))
                          : "صفر"}{" "}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Col>
          {/* .......................... */}
        </Row>
        {/* ............. header end ..................... */}

        <Row sm={12} className="mb-3 pb-4">
          {/* .............  الجدول (الجانب الايمن) ............. */}

          <Col lg={4}>
            <Row xs={12}>
              <Col xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="px-2"
                >
                  <DatePicker
                    className="Calendar-container"
                    locale="ar"
                    selected={truckDate}
                    onChange={(date) => {
                      setTruckDate(date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    customInput={
                      <div className="Calendar-container btnMore">
                        <CalendarMonthIcon className="ms-2 headerTableFont " />
                        بحث بتاريخ النقلة
                      </div>
                    }
                  />
                  {truckDate === "" ? (
                    <div
                      className="filterIcons btnMore "
                      onClick={() => {
                        changeOrderType();
                        trucks.reverse();
                      }}
                    >
                      {" "}
                      <span>ترتيب {orderType}</span>{" "}
                      <SortOutlinedIcon style={{ cursor: "pointer" }} />
                    </div>
                  ) : (
                    <span
                      className="Calendar-container showAll btnMore"
                      onClick={() => {
                        setTruckDate("");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      عرض الكل
                    </span>
                  )}
                </div>
              </Col>

              <TrucksList
                trucks={trucks}
                onInvoiceSelected={onInvoiceSelected}
                date={truckDate}
                showSharedTrucks={showSharedTrucks}
              />

              <Col xs={6} style={{ color: "white" }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showSharedTrucks}
                      onChange={() => setShowSharedTrucks(!showSharedTrucks)}
                      color="warning"
                    />
                  }
                  label={showSharedTrucks ? "نقلات مشتركة" : "نقلات العميل"}
                />
              </Col>
              <Col xs={6}>
               <Button  onClick={handleMore}>
                    تحميل المزيد من الفواتير....
               </Button>
                
                
              </Col>
            </Row>
          </Col>
          {/* .......................... */}

          {/* .............  بعض التفاصيل (الجانب الايسر) ............. */}
          <Col lg={8}>
            {invoiceId.truckTypeId === 1 && (
              <CommissionTruck
                partnerId={partnerId}
                invoiceId={invoiceId.truckId}
              />
            )}
            {invoiceId.truckTypeId === 2 && (
              <BuyingTruck
                partnerId={partnerId}
                invoiceId={invoiceId.truckId}
              />
            )}

            <Row xs={12} className="m-auto text-center mt-3 p-0">
              <TableContainer className="p-0 m-0" component={Paper}>
                <Table aria-label="simple table">
                  <TableHead style={{ background: "#FED049", height: "65px" }}>
                    <TableRow>
                      <TableCell align="center" className="headerTableFont">
                        عدد هالك العدة
                      </TableCell>
                      <TableCell align="center" className="headerTableFont">
                        عدد النقلات
                      </TableCell>
                      <TableCell align="center" className="headerTableFont">
                        {" "}
                        النقلات المتبقية
                      </TableCell>
                      <TableCell align="center" className="headerTableFont">
                        {" "}
                        النقلات المتجمدة
                      </TableCell>
                      <TableCell align="center" className="headerTableFont">
                        {" "}
                        نقلات بدون فواتير
                      </TableCell>
                      <TableCell align="center" className="headerTableFont">
                        {" "}
                        عدد الفواتير
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center" className="BodyTableFont">
                        {" "}
                        {damagedToolsCount !== 0
                          ? ConvertToArabicNumbers(damagedToolsCount)
                          : "غير متوفر"}
                      </TableCell>
                      <TableCell align="center" className="BodyTableFont">
                        {" "}
                        {truckStatistics.allTruckCount > 0
                          ? ConvertToArabicNumbers(
                              truckStatistics.allTruckCount
                            )
                          : "غير متوفر"}
                      </TableCell>
                      <TableCell align="center" className="BodyTableFont">
                        {truckStatistics.remainingTrucksCount > 0
                          ? ConvertToArabicNumbers(
                              truckStatistics.remainingTrucksCount
                            )
                          : "غير متوفر"}
                      </TableCell>
                      <TableCell align="center" className="BodyTableFont">
                        {truckStatistics.freezedInvoicesCount > 0
                          ? ConvertToArabicNumbers(
                              truckStatistics.freezedInvoicesCount
                            )
                          : "غير متوفر"}
                      </TableCell>
                      <TableCell align="center" className="BodyTableFont">
                        {truckStatistics.trucksWithoutInvoicesCount > 0
                          ? ConvertToArabicNumbers(
                              truckStatistics.trucksWithoutInvoicesCount
                            )
                          : "غير متوفر"}
                      </TableCell>
                      <TableCell align="center" className="BodyTableFont">
                        {" "}
                        {truckStatistics.invoicesCount > 0
                          ? ConvertToArabicNumbers(
                              truckStatistics.invoicesCount
                            )
                          : "غير متوفر"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Row>

            <Row xs={12} className="gx-5">
              <Col xs={12}>
                <div
                  className="w-100 bg-white my-3 rounded-3"
                  style={{ height: "3px" }}
                ></div>
              </Col>

              <Col lg={6} className="mx-auto">
                <TableContainer
                  className="p-0 mt-3 hover-link"
                  component={Paper}
                >
                  <Link
                    to={`/MoneyCurrentAccountReport/client/${partnerId}/trucks`}
                  >
                    <Table aria-label="simple table">
                      <TableHead
                        style={{ background: "#FED049", height: "65px" }}
                      >
                        <TableRow>
                          <TableCell className="headerTableFont" align="center">
                            فواتير نقلات{" "}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center" className="BodyTableFont">
                            <span style={{ fontSize: "26px" }}>
                              {ProfileItem(clientDebt.totalInvoices)}{" "}
                              {clientDebt.totalInvoices > 0 ? "عليه" : ""}
                              {clientDebt.totalInvoices < 0 ? "له" : ""}
                              {clientDebt.totalInvoices === "" ? "" : ""}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Link>
                </TableContainer>
              </Col>

              <Col lg={6} className="mx-auto">
                <TableContainer
                  className="p-0 mt-3 hover-link"
                  component={Paper}
                >
                  <Link
                    to={`/MoneyCurrentAccountReport/client/${partnerId}/loans`}
                  >
                    <Table aria-label="simple table">
                      <TableHead
                        style={{ background: "#FED049", height: "65px" }}
                      >
                        <TableRow>
                          <TableCell align="center" className="headerTableFont">
                            عملات مقدمة{" "}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center" className="BodyTableFont">
                            <span variant="body1">
                              {ProfileItem(clientDebt.totalLoans)}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Link>
                </TableContainer>
              </Col>

              {Partner.partnerTypeId === 3 && (
                <Col lg={6} className="mx-auto">
                  <TableContainer className="p-0 mt-3" component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead
                        style={{ background: "#FED049", height: "65px" }}
                      >
                        <TableRow>
                          <TableCell align="center" className="headerTableFont">
                            {" "}
                          </TableCell>
                          <TableCell align="center" className="headerTableFont">
                            فواتير المبيع{" "}
                          </TableCell>
                          <TableCell align="center" className="headerTableFont">
                            {" "}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center"></TableCell>
                          <TableCell align="center" className="BodyTableFont">
                            <span style={{ fontSize: "26px" }}>
                              {ProfileItem(buyerDebt.totalInvoices)}{" "}
                              {buyerDebt.totalInvoices > 0 ? "عليه" : ""}
                              {buyerDebt.totalInvoices < 0 ? "له" : ""}
                              {buyerDebt.totalInvoices === "" ? "" : ""}
                            </span>
                          </TableCell>

                          <TableCell
                            align="center"
                            className=" btnMore BodyTableFont"
                          >
                            <Link
                              to={`/seller-profile/${partnerId}`}
                              style={{ color: "white" }}
                            >
                              حساب البائع
                            </Link>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Col>
              )}

              {Partner.partnerTypeId === 3 && (
                <Col lg={6} className="mx-auto mt-3">
                  <TableContainer className="p-0 " component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead
                        style={{ background: "#FED049", height: "65px" }}
                      >
                        <TableRow>
                          <TableCell align="center" className="headerTableFont">
                            السلف
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center" className="BodyTableFont">
                            {ProfileItem(buyerDebt.totalLoans)}
                            <span variant="body1"> </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Col>
              )}
              <Col xs={12}>
                <div
                  className="w-100 bg-white my-3 rounded-3"
                  style={{ height: "3px" }}
                ></div>
              </Col>

              <Col lg={6} className="mx-auto">
                <TableContainer
                  className="p-0 mb-3 hover-link"
                  component={Paper}
                >
                  <Link to={`/tools-current-account/${partnerId}`}>
                    <Table aria-label="simple table">
                      <TableHead
                        style={{ background: "#FED049", height: "65px" }}
                      >
                        <TableRow>
                          <TableCell align="center" className="headerTableFont">
                            عدد العدة
                          </TableCell>
                          <TableCell align="center" className="headerTableFont">
                            {" "}
                            الرهن
                          </TableCell>
                          <TableCell align="center" className="headerTableFont">
                            {" "}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center" className="BodyTableFont">
                            {ProfileItem(totalToolsCount)}
                            <span variant="body1"> </span>
                          </TableCell>
                          <TableCell align="center" className="BodyTableFont">
                            {ProfileItem(totalMortage)}
                            <span variant="body1">
                              {" "}
                              {totalToolsCount > 0 ? "عليه" : ""}
                              {totalToolsCount < 0 ? "له" : ""}
                              {totalToolsCount === "" ? "" : ""}
                            </span>
                          </TableCell>
                          <TableCell align="center" className="BodyTableFont">
                            <Link
                              style={{ color: "red !important" }}
                              onClick={() => onDetailsClicked(partnerId)}
                            >
                              <Tooltip title="العدد">
                                <IconButton>
                                  <InfoIcon style={{ fontSize: "35px" }} />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Link>
                </TableContainer>
              </Col>

              {Partner.isEmployee ? (
                <Col lg={6} className="mx-auto">
                  <TableContainer className="p-0  hover-link" component={Paper}>
                    <Link
                      to={`/MoneyCurrentAccountReport/employee/${partnerId}`}
                    >
                      <Table aria-label="simple table">
                        <TableHead
                          style={{ background: "#FED049", height: "65px" }}
                        >
                          <TableRow>
                            <TableCell
                              align="center"
                              className="headerTableFont"
                            >
                              {" "}
                              راتب
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center" className="BodyTableFont">
                              {ProfileItem(employeeDebt)}{" "}
                              {employeeDebt > 0 ? "عليه" : ""}
                              {employeeDebt < 0 ? "له" : ""}
                              {employeeDebt === "" ? "" : ""}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Link>
                  </TableContainer>
                </Col>
              ) : (
                ""
              )}

              <Col xs={12}>
                <TableContainer className="p-0 mt-3" component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead
                      style={{ background: "#f3c637", height: "65px" }}
                    >
                      <TableRow>
                        <TableCell
                          className="btnMore headerTableFont "
                          align="center"
                        >
                          اجمالي المبلغ
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" className="BodyTableFont">
                          <span style={{ fontSize: "26px" }}>
                            {ProfileItem(NetDebt)}
                          </span>
                          <span style={{ fontSize: "26px" }}>
                            {" "}
                            {NetDebt > 0 ? "عليه" : ""}
                            {NetDebt < 0 ? "له" : ""}
                            {NetDebt === "" ? "" : ""}
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Col>

              <Col lg={6} className="mx-auto">
                <TableContainer
                  className="p-0 mt-3 hover-link"
                  component={Paper}
                >
                  <Link
                    to={`/MoneyCurrentAccountReport/client/${partnerId}/mortage`}
                  >
                    <Table aria-label="simple table">
                      <TableHead
                        style={{ background: "#FED049", height: "65px" }}
                      >
                        <TableRow>
                          <TableCell align="center" className="headerTableFont">
                            رهن المشتريات
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center" className="BodyTableFont">
                            <span variant="body1">
                              {ProfileItem(clientDebt.clientMortage)}{" "}
                              {clientDebt.clientMortage > 0 ? "عليه" : ""}
                              {clientDebt.clientMortage < 0 ? "له" : ""}
                              {clientDebt.clientMortage === "" ? "" : ""}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Link>
                </TableContainer>
              </Col>
            </Row>
          </Col>
          {/* .......................... */}
        </Row>

        {/* .............  popup (بتاعت تفاصيل الرهن) ............. */}

        <RemainingDetails
          rows={remainingDetails}
          open={openDetails}
          onClose={() => setOpenDetails(false)}
        />
        {/* .......................... */}
      </Container>
    </div>
  );
}

export default ClientProfile;

import { ConvertToArabicNumbers, getWeekDay, renderDate } from '../../../utils';
import { InputAdornment, Paper, TextField, Toolbar } from '@material-ui/core';
import React, { useState } from 'react';

import SearchIcon from '@material-ui/icons/Search';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { parseISO } from 'date-fns';

const useStyles = makeStyles({
  table: {
    width: '100%',
  },

  tableCell: {},
});

var options = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
};

export default function HistoryTable({ data }) {
  const classes = useStyles();

  const [searchQuery, setSearchQuery] = useState('');
  const [searchQuery1, setSearchQuery1] = useState('');
  let ourData;
  return (
    <>
      <Toolbar
        style={{
          backgroundColor: '#f9f9f9',
          width: '95%'
        }}
        className='center m-auto justify-content-between'
      >
        <div className='col-5'>
          <TextField
            placeholder="اسم المستخدم"
            className={classes.searchBar}
            size="small"
            value={searchQuery}
            onChange={(e) => { setSearchQuery1(''); setSearchQuery(e.target.value) }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className='col-5'>
          <TextField
            placeholder="اسم العميل /البائع"
            className={classes.searchBar}
            size="small"
            value={searchQuery1}
            onChange={(e) => { setSearchQuery(''); setSearchQuery1(e.target.value) }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Toolbar>
      <TableContainer  style={{ width: '95%', margin: 'auto' }} component={Paper}>
        <Table className={classes.table}>
          <TableHead style={{ backgroundColor: 'rgb(243 198 55)' }}>
            <TableRow>
              <TableCell align="center">
                <Typography variant="body2" style={{ fontWeight: 600 }}>
                  المستخدم
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" style={{ fontWeight: 600 }}>
                  العميل / البائع
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" style={{ fontWeight: 600 }}>
                  اليوم
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" style={{ fontWeight: 600 }}>
                  التاريخ
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" style={{ fontWeight: 600 }}>
                  التوقيت
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .filter((item) => {

                if (item.searcherName && searchQuery.length >= 1) {

                  return item.searcherName.includes(searchQuery);

                } else if (searchQuery1.length >= 1 && item.searchedForName) {

                  return item.searchedForName.includes(searchQuery1);

                } else if (searchQuery.length < 1 && searchQuery1.length < 1) {
                  return item;
                }
              })
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCell} style={{minWidth:160}} align="center">
                    {row.searcherName ? row.searcherName : 'الادارة'}
                  </TableCell>
                  <TableCell style={{minWidth:180}} className={classes.tableCell} align="center">
                    {' '}
                    {row.searchedForName}{' '}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {getWeekDay(parseISO(row.dateTime))}
                  </TableCell>
                  <TableCell align="center" style={{minWidth:160}} >
                    {ConvertToArabicNumbers(renderDate(parseISO(row.dateTime)))}
                  </TableCell>
                  <TableCell align="center">
                    {ConvertToArabicNumbers(
                      parseISO(row.dateTime).toLocaleString('en-US', options)
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

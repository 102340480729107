import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import DeleteBox from "../../components/DeleteBox";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import UpdateTool from "../../features/main-data/components/UpdateTool";
import { Container } from "react-bootstrap";
import { Autocomplete } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },

  trash: {
    color: theme.palette.error.light,
  },
  searchIcon: {
    color: "#888",
  },
  select: {
    background: "white",
    width: "100%",
    "& .MuiInputLabel-filled": {
      color: "#000",
    },
  },
}));
let roles = ["الكل", "مدير", "مشرف", "موظف", "محصل", "عميل_او_بائع"];

function Users() {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [authority, setAuthority] = useState("الكل");
  const onStatusChanged = (partner) => {
    let state = "unActive";
    axios
      .put(`/Security/${state}/${partner.userId}`)
      .then((response) => {
        toast.success("نم التفعيل");
      })
      .catch((error) => {
        toast.success("حدث خطأ");
      });
  };
  const deleteUser = () => {
    axios
      .delete(`/Security?partnerId=${selectedUser.partnerId}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success("تم حذف المستخدم بنجاح");
          let filteredUsers = [...users];
          filteredUsers = filteredUsers.filter((u) => {
            return u.partnerId !== selectedUser.partnerId;
          });
          setUsers(filteredUsers);
        }
      })
      .catch((err) => {});
  };
  const updatehandler = (pass) => {
    const passvalid = pass.match(/(?=.*[a-zA-Z]).{6,}$/);
    if (passvalid) {
      axios
        .post(
          "Security/ResetPassword",
          {},
          {
            headers: {
              userId: selectedUser.userId,
              password: pass,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            toast.success("تم التعديل بنجاح");
            setOpenUpdate(false);
          }
        })
        .catch((err) => {
          toast.error("حدث خطأ ما");
          setOpenUpdate(false);
        });
    } else {
      toast.error("يرجي اضافة حروف");
    }
  };
  const [checked, setChecked] = React.useState(false);

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const getUsers = () => {
    axios
      .get(`/Security/users`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUsers();
    }
    return () => {
      mounted = false;
    };
  }, []);
  async function ToggleStatus(user) {
    try {
      await axios.put(
        `Security/${user.isActive ? "unActive" : "Active"}/${user.userId}`
      );
      getUsers();
      toast.success("تم التعديل بنجاح");
    } catch (error) {
      toast.error("حدث خطأ ما");
    }
  }
  const classes = useStyles();
  return (
    <div className="mt-5">
      <Container>
        <CardHeader
          style={{ background: "#f3c637" }}
          className="text-center"
          title=" المستخدمين"
        />
        <Grid container spacing={2}>
          <Grid item xs={5} className="my-3">
            <TextField
              placeholder="بحث..."
              style={{
                width: "100%",
              }}
              className="bg-white"
              size="small"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item className="my-3">
            <FormControlLabel
              className="text-white"
              control={
                <Switch
                  checked={checked}
                  onChange={toggleChecked}
                  name="checkedA"
                  color="primary"
                />
              }
              label="الموظفين"
            />
          </Grid>
          <Grid item md={3} sm={5} xs={8} className="mt-2">
            <FormControl variant="filled" className={classes.select}>
              <InputLabel id="demo-simple-select-filled-label">
                الصلاحية
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={authority}
                onChange={(e) => setAuthority(e.target.value)}
              >
                {roles.map((role, index) => {
                  return (
                    <MenuItem key={index} value={role}>
                      {role}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Card
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
            overflowX: "auto",
          }}
        >
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      الاسم
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      نوع المستخدم
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      الصلاحية
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      الصلاحيات
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      البيانات
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      الحالة
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      حذف
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  .filter((item) => {
                    if (checked) {
                      return item.isEmployee;
                    } else {
                      return item;
                    }
                  })
                  .filter(
                    (item) =>
                      item.partnerName.includes(searchQuery) &&
                      (item.role.includes(authority) || authority == "الكل")
                  )
                  .map((user, index) => (
                    <TableRow key={user.userId}>
                      <TableCell align="center">
                        <Typography variant="body1" style={{ minWidth: 180 }}>
                          {user.partnerName}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {user.isEmployee ? "موظف" : "مستخدم"}
                      </TableCell>
                      <TableCell align="center">{user.role}</TableCell>
                      <TableCell align="center">
                        <Button
                          style={{
                            color: "#2F9DDD",
                          }}
                          component={Link}
                          to={`/user-permissions/${user.partnerName}/${user.partnerId}/${user.userId}`}
                          endIcon={<EditOutlinedIcon />}
                        >
                          تعديل الصلاحيات
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          style={{
                            color: "#2F9DDD",
                          }}
                          onClick={() => {
                            setOpenUpdate(true);
                            setSelectedUser(user);
                          }}
                          endIcon={<EditOutlinedIcon />}
                        >
                          كلمة المرور
                        </Button>
                      </TableCell>
                      <TableCell align="center" style={{ width: "32px" }}>
                        <Switch
                          checked={user.isActive}
                          onClick={() => {
                            ToggleStatus(user);
                          }}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell align="center" style={{ width: "32px" }}>
                        <IconButton
                          className={classes.trash}
                          onClick={() => {
                            setSelectedUser(user);
                            setOpenDelete(true);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        <DeleteBox
          action={() => {
            deleteUser();
            setOpenDelete(false);
          }}
          message="هل تريد حذف المستخدم"
          open={openDelete}
          onClose={() => setOpenDelete(false)}
        />
        <UpdateTool
          update={updatehandler}
          ispassword={true}
          open={openUpdate}
          onClose={() => setOpenUpdate(false)}
          tool={selectedUser}
        />
      </Container>
    </div>
  );
}

export default Users;

import MainHeader from "../../components/mainHeader/MainHeader";
import Tools from "../../components/tools";

function ExportTools() {
  return (
    <div>
      <div className="my-4">
        <MainHeader title="اضف خارج عدة" />
      </div>
      {/* <Tools title="اضف خارج عدة" baseApi="MovementTools/exporting/" /> */}
      <Tools baseApi="MovementTools/exporting/" />
    </div>
  );
}

export default ExportTools;

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import React from "react";
import classy from "./loginform.module.css";
function PasswordStep({
  pass,
  setPass,
  onSub,
  label,
  isChecked,
  setIsChecked,
}) {
  return (
    <form>
      <Box
        component="main"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <Container maxWidth="sm">
          <div className={classy.regholder}>
            <p
              style={{
                margin: 0,
                fontWeight: "bolder",
              }}
            >
              {label}
            </p>
            <TextField
              fullWidth
              label="كلمة المرور"
              margin="normal"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              variant="outlined"
              style={{ backgroundColor: "#fff" }}
            />

            <Box sx={{ py: 2 }}>
              <Button
                className={classy.btn}
                size="large"
                type="submit"
                fullWidth
                onClick={onSub}
                //   endIcon={
                //     loginStatus === "loading" && (
                //       <CircularProgress size={24} style={{ color: "#DDD" }} />
                //     )
                //   }
                //   disabled={loginStatus === "loading" || disableLogin}
                variant="contained"
              >
                تسجيل الدخول
              </Button>
            </Box>
          </div>
        </Container>
      </Box>
        <FormControlLabel
          control={
            <Checkbox
              value="remember"
              checked={isChecked}
              style={{ color: "#fff" }}
            />
          }
          label="تذكرني لاحقا"
          onChange={() => setIsChecked(!isChecked)}
        />
    </form>
  );
}
export default PasswordStep;

import { ConvertToArabicNumbers, getWeekDay, renderDate } from "../../../utils";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomDialog from "../../../components/CustomDialog";
import LocalPrintshopOutlinedIcon from "@material-ui/icons/LocalPrintshopOutlined";
import LogoImage from "../../../components/LogoImage";
import TableInvoiceItems from "./TableInvoiceItems";
import axios from "axios";
import { getSellerInvoiceItems } from "../sellersInvoiceSlice";
import { makeStyles } from "@material-ui/core/styles";
import { useReactToPrint } from "react-to-print";
import Alerto from "../../../components/UI/toaster";

const useStyles = makeStyles({
  holder: {
    padding: "2px !important",
  },
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "10cm",
    padding: 0,
  },

  label: {
    border: "2px solid #000",
    padding: 3,
    fontSize:14
  },
  "@media print": {
    shaderOwner: {
      textAlign: "center",
    },
    root: {
      marginTop: 12,
    },
    label: {
      textAlign: "center",
    },
  },
});

function CashInvoice({ open, onClose, partnerReport, invoice }) {
  const invoiceItems = useSelector(
    (state) => state.sellersInvoice.invoiceItems
  );

  let total = invoiceItems.reduce((acc, val) => {
    return acc + val.totalSellingPrice;
  }, 0);
  const totalMortage = invoiceItems.reduce((acc, val) => {
    return acc + val.totalToolPrice;
  }, 0);

  const totalBoxes = invoiceItems.reduce((acc, val) => {
    return acc + val.productCount;
  }, 0);
  const Total = total + totalMortage;
  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      dispatch(getSellerInvoiceItems(partnerReport));
    }
    return () => {
      mounted = false;
    };
  }, [open]);

  const componentRef = useRef();
  const printLog = () => {
    let id = "";
    if (invoice && invoice.id) {
      id = invoice.id;
    } else if (partnerReport.invoice && partnerReport.invoice.invoiceId) {
      id = partnerReport.invoice.invoiceId;
    }
    axios
      .put(`/SellingsInvoices/LogPrintingCount?invoiceId=${id}`)
      .then((res) => {})
      .catch((err) => {
        Alerto(err);
      });
  };

  const pageStyle = `
  @page {
    background: white;
    width: 7.9cm;
    height: 29.7cm;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: printLog,
    documentTitle: `فاتورة البائع ${partnerReport.name}`,
  });
  let d = new Date();
  let h = d.getHours();
  let w = "ص";
  if (h > 12) {
    h -= 12;
    w = "م";
  }
  let m = d.getMinutes();
  if (m < 10) {
    m = "0" + m;
  }
  let time = h + ":" + m + "    " + w;
  const classes = useStyles();
  return (
    <CustomDialog  open={open} textAlign="center" onClose={onClose}>
      <Tooltip title="طباعة الفاتورة">
        <IconButton onClick={handlePrint}>
          <LocalPrintshopOutlinedIcon />
        </IconButton>
      </Tooltip>

      <div ref={componentRef} className={classes.root} style={{border:'3px solid',padding:6}}>
        <div >
          <LogoImage className='w-75' />
        </div>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          style={{ marginBottom: 4 }}
          spacing={1}
        >
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.label}>
              {" "}
              نقدية{" "}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body2" className={classes.label}>
              {partnerReport.name}
            </Typography>
          </Grid>
        </Grid>
        <TableInvoiceItems isCashInvoice={true} invoiceItems={invoiceItems} />

        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          style={{ marginBottom: 1 }}
          spacing={1}
        >
          <Grid item xs={4}>
            <Typography variant="body2" style={{background:'#f3c637'}} className={classes.label}>
              {" "}
              رهن{" "}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body2" className={classes.label}>
              {" "}
              {totalMortage !== 0
                ? ConvertToArabicNumbers(totalMortage)
                : "ﻻ يوجد"}{" "}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          style={{ marginBottom: 1 }}
          spacing={1}
        >
          <Grid item xs={4}>
            <Typography style={{background:'#f3c637'}} variant="body2" className={classes.label}>
              {" "}
              اجمالي كشف{" "}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body2" className={classes.label}>
              {" "}
              {ConvertToArabicNumbers(Math.round(Total))}{" "}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          style={{ marginBottom: 8 }}
          spacing={1}
        >
          <Grid item xs={4}>
            <Typography variant="body2" style={{background:'#f3c637'}} className={classes.label}>
              عدد الطرود
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body2" className={classes.label}>
              {" "}
              {ConvertToArabicNumbers(totalBoxes)}{" "}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          style={{ marginBottom: 8 }}
        >
          <Grid item xs={12}>
            <Typography className={classes.label} style={{background:'#f3c637'}} >
             ** برجاء مراجعة الحساب قبل الخروج من المكتب **
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          style={{ }}
          spacing={3}
        >
          <Grid item xs={12} className='mt-3 center'>
            <Typography
              style={{ textAlign: "center"}}
            >
              {"تحريرا في"} {getWeekDay(d)} {"   "}
              {"   "} ______{ConvertToArabicNumbers(renderDate(d))} _______{time}{"   "}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </CustomDialog>
  );
}

export default CashInvoice;

import {
  Button,
  Paper,
  TableSortLabel,
  Typography,
  TextField,
  Box,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";
import { ConvertToArabicNumbers } from "../../../utils";
import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  moveBtn: {
    height: 40,
  },
  totalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#f3f3f3",
    borderRadius: "5px",
    marginBottom: "16px",
  },
  totalLabel: {
    marginRight: "8px",
    fontWeight: "bold",
    fontSize: "20px",
  },
  totalValue: {
    color: "#000",
    width: "150px",
    textAlign: "center",
  },
}));

export default function ReportTable({ data, onCreateInvoice }) {
  const history = useHistory();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [totalValue, setTotalValue] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    const total = data.reduce((acc, item) => acc + Math.round(item.value), 0);
    setTotalValue(total);
  }, [data]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    { label: "اسم البائع", id: "name", disablePadding: true },
    { label: "نوع الفاتورة" },
    { label: "عدد الطرود", id: "productsCount", disablePadding: false },
    { label: "الوزن", id: "weight", disablePadding: false },
    { label: "قيمة الفاتورة", id: "value", disablePadding: false },
    { label: "الفاتورة" },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead style={{ backgroundColor: "#f3c637", color: "black" }}>
        <TableRow>
          {headCells.map((headCell, index) => (
            <TableCell
              key={index}
              align="center"
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ minWidth: 180 }}
            >
              {headCell.id ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  <Typography variant="body2" style={{ fontWeight: 600 }}>
                    {headCell.label}
                  </Typography>
                </TableSortLabel>
              ) : (
                <Typography variant="body2" style={{ fontWeight: 600 }}>
                  {headCell.label}
                </Typography>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#161101",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
      overflow: "auto",
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <Box className={classes.totalContainer}>
        <Typography className={classes.totalLabel}>الإجمالي:</Typography>
        <TextField
          className={classes.totalValue}
          value={ConvertToArabicNumbers(totalValue)}
          InputProps={{
            readOnly: true,
            style: {
              fontSize: "20px",
              textAlign: "center",
              fontWeight: "bold",
            },
          }}
          variant="outlined"
        />
      </Box>
      <TableContainer
        component={Paper}
        style={{ width: "100%", margin: "0", padding: 0 }}
      >
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map(
              (item, index) => {
                return (
                  <TableRow key={index} className={classes.tableBody}>
                    <StyledTableCell align="center">
                      {item.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {item.sellingTypeName}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {ConvertToArabicNumbers(item.productsCount)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {ConvertToArabicNumbers(item.weight)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {ConvertToArabicNumbers(Math.round(item.value))}
                    </StyledTableCell>
                    {item.invoice && item.invoice.invoiceId ? (
                      <TableCell
                        align="center"
                        variant="outlined"
                        color="secondary"
                        size="small"
                        className="btnMore"
                        onClick={() => {
                          history.push(
                            `/sellers-invoices/${item.id}/${item.sellingInvoiceTypeId}`
                          );
                        }}
                      >
                        الفاتورة
                      </TableCell>
                    ) : (
                      <TableCell
                        variant="contained"
                        color="primary"
                        size="small"
                        className="btnMore"
                        onClick={() => {
                          onCreateInvoice({
                            sellingDate: item.sellingDate,
                            partnerId: item.id,
                            invoiceTypeId: item.sellingInvoiceTypeId,
                          });
                        }}
                      >
                        انشاء فاتورة
                      </TableCell>
                    )}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  getDamagingsFromClients,
  getExportingFromClients,
  getImportingFromClients,
  getRemainingForClients,
} from "./toolsSlice";
import { useDispatch, useSelector } from "react-redux";

import DatePicker from "react-datepicker";
import Report from "../components/Report";
import SearchIcon from "@material-ui/icons/Search";
import Total from "../../../components/UI/Total";
import ar from "date-fns/locale/ar-SA";
import { indexOfId } from "../../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import MainHeader from "../../../components/mainHeader/MainHeader";
import { Container } from "react-bootstrap";

registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 12,
  },
  SearchForm: {
    marginTop: 12,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    "& > *": {
      marginRight: 12,
    },
  },
}));

function ClientsReports() {
  const [dateTime, setDateTime] = useState(new Date());
  const [reportType, setReportType] = useState(1);

  const dispatch = useDispatch();
  const remainingForClients = useSelector(
    (state) => state.tools.remainingForClients
  );

  const exportingFromClients = useSelector(
    (state) => state.tools.exportingFromClients
  );

  const importingFromClients = useSelector(
    (state) => state.tools.importingFromClients
  );

  const damagingsForClients = useSelector(
    (state) => state.tools.damagingsForClients
  );

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (reportType === 1) {
        dispatch(getRemainingForClients());
      } else if (reportType === 2 && dateTime) {
        dispatch(getExportingFromClients(dateTime));
      } else if (reportType === 3 && dateTime) {
        dispatch(getImportingFromClients(dateTime));
      } else if (reportType === 4 && dateTime) {
        dispatch(getDamagingsFromClients(dateTime));
      }
    }
    return () => {
      mounted = false;
    };
  }, [reportType, dateTime]);

  const reportsTypes = [
    { id: 1, name: "العدة المتبقية" },
    { id: 2, name: "العدة الخارجة" },
    { id: 3, name: "العدة الواردة" },
    { id: 4, name: "هالك العدة" },
  ];
  const [searchQuery, setSearchQuery] = useState("");
  const getReportName = () => {
    let r = indexOfId(reportsTypes, reportType);
    return reportsTypes[r].name + " للعملاء ";
  };

  let report = [];
  report =
    reportType === 1
      ? remainingForClients
      : reportType === 2
        ? exportingFromClients
        : reportType === 3
          ? importingFromClients
          : reportType === 4
            ? damagingsForClients
            : [];
  const total = report.reduce((acc, val) => {
    return acc + val.count;
  }, 0);

  const classes = useStyles();
  return (
    <Container >
      <div className="my-4">
        <MainHeader title="تقارير العدة للعملاء " />
      </div>
      <div className="my-4"></div>
      <CardContent className="headerSearch m-0 p-1 ">
        <Box className=' w-100 row center '>
          <div className=" col-10 col-md-4 col-lg-3 m-3 text-center"              >
            <TextField
              size="small"
              className="w-100"
              select
              label="نوع التقرير"
              onChange={(e) => setReportType(e.target.value)}
              value={reportType}
              variant="outlined"
            >
              {reportsTypes.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </div>

          {reportType !== 1 && (

            <div className=" col-10 col-md-4 col-lg-3 m-3 text-center"              >
              <DatePicker
                locale="ar"
                size="small"
                selected={dateTime}
                onChange={(d) => setDateTime(d)}
                dateFormat="dd/MM/yyyy"
                customInput={
                  <TextField
                    size="small"
                    variant="outlined"
                    id="dateTime"
                    className="text-center w-100 m-0 p-0"
                  />
                }
              />
            </div>
          )}
          {report && report.length > 0 && (
            <div className="col-10 col-md-5 col-lg-4 text-center  m-3 ">
              <TextField
                placeholder="بحث..."
                size="small"
                className="w-100"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
        </Box>
      </CardContent>
      {total !== 0 ?
        <div className="w-50 m-auto">

          <Total value={total} />

        </div>
        : "ﻻ يوجد"}
      {report.length > 0 ? (
        <Report
          items={report}
          reportName={getReportName()}
          hasDetails={reportType === 1}
          searchQuery={searchQuery}
          total={total}
        />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ marginTop: 180 }}
          >
            غير موجود
          </Typography>
        </div>
      )}
    </Container>
  );
}

export default ClientsReports;

import MainHeader from "../../components/mainHeader/MainHeader";
import Tools from "../../components/tools";

function ImportTools() {
  return (
    <div>
      <div className="my-4">
        <MainHeader title="اضف تحصيل عدة" />
      </div>
      <Tools haveAction baseApi="MovementTools/importing/" />
    </div>
  );
}

export default ImportTools;

import { ConvertToArabicNumbers, getDateDashed, renderDate } from "../../utils";
import {
  Button,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import CurrentToolsTable from "./components/CurrentToolsTable";
import { Helmet } from "react-helmet";
import LogoImage from "../../components/LogoImage";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Alerto from "../../components/UI/toaster";
import "../money-account/clientAccount.css";
import { Col, Container, Row } from "react-bootstrap";
import HistoryIcon from "@mui/icons-material/History";
import RemainingDetails from "./../movement-tools/components/RemainingDetails";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useSelector, useDispatch } from "react-redux";
import { getRemainingDetailsForPartners } from "./../movement-tools/tools-reports/toolsSlice";
import AccountHeader from "../money-account/components/accountHeader";
import ToolsInfo from "../../components/ToolsForm/partials/ToolsInfo";
import AccountReamainingTable from "../../components/accountRemainingTable";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  infoBox: {
    width: 200,
    padding: 8,
    fontWeight: 600,
    fontSize: 18,
    marginTop: 16,
    backgroundColor: "#ff0",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  btn: {
    padding: "1rem 2rem",
    fontWeight: 600,
    fontSize: 18,
    borderRadius: "8px",
    border: 0,
    backgroundColor: "rgb(243,198,55)",
    cursor: "pointer",
    margin: "1rem",
    "&:hover": {
      background: "rgb(233,188,55)",
    },
  },
  printableTable: {
    overflow: "hidden",
    height: 0,
  },
  "@media print": {
    printableTable: {
      height: "auto",
      overflowY: "auto",
    },
  },
}));

function ToolsAccount() {
  const { partnerId } = useParams();
  const remainingDetails = useSelector((state) => state.tools.remainingDetails);
  const [open, setOpen] = useState();
  const dispatch = useDispatch();
  const onDetailsClicked = (partnerId) => {
    setOpen(true);
    dispatch(getRemainingDetailsForPartners(partnerId));
  };
  const [printable, setPrintable] = React.useState([]);
  const role = localStorage.getItem("role");
  const eligableRoles = ["إدارة", "مدير", "مشرف", "موظف"];
  const isEligableToEdit = eligableRoles.includes(role);
  const [showDate, setShowDate] = useState(false);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");

  const [toolsAccount, setToolsAccount] = useState([]);
  const getToolsCurrentAccount = () => {
    axios
      .get(
        `/MovementTools/reports/CurrentAccount?partnerId=${partnerId}&from=${getDateDashed(
          fromDate
        )}&to=${getDateDashed(toDate)}`
      )
      .then((res) => {
        if (res.status === 200) {
          setToolsAccount((oldReport) => [...res.data]);
        }
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  const getClientLastTreatment = () => {
    axios
      .get(`/MovementTools/reports/LastOperation/${partnerId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.info("ﻻ يوجد تعامل مع هذا المستخدم");
          return;
        }
        if (res.status === 200) {
          setShowDate(true);
          setShowDate(true);

          let lastDate = new Date(res.data);
          lastDate.setDate(lastDate.getDate() + 1);

          setToDate(lastDate);
          let myFrom = new Date(res.data);
          myFrom.setMonth(myFrom.getMonth() - 1);
          setFromDate(myFrom);
        }
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  const reloadhandler = () => {
    const y =
      fromDate &&
      fromDate.getFullYear() >= new Date(partner.creationDate).getFullYear();

    if (y) {
      const newFromDate = new Date(fromDate.setMonth(fromDate.getMonth() - 1));
      setFromDate(newFromDate);
    }
  };
  const [remainingTools, setRemainingTools] = useState([]);
  const getRemainingToolsCount = () => {
    axios
      .get(`/MovementTools/reports/Remaining/${partnerId}`)
      .then((res) => {
        setRemainingTools(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  const remainingToolsCount = remainingTools.reduce((acc, val) => {
    return acc + val.count;
  }, 0);

  const [partner, setPartner] = useState("");
  const getPartnerData = () => {
    axios.get(`/Partners/${partnerId}`).then((res) => {
      setPartner(res.data);
    });
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getPartnerData();
      getClientLastTreatment();
      getRemainingToolsCount();
      setTimeout(() => {
        window.scroll({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 1000);
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (toDate && fromDate) {
        getToolsCurrentAccount();
      }
    }
    return () => {
      mounted = false;
    };
  }, [fromDate]);

  const element = document.getElementById("scrollList");
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: element.scrollHeight,
        behavior: "smooth",
      });
    }, 1000);
  }, [partner]);

  function downList_Page() {
    setTimeout(() => {
      window.scrollTo({
        top: element.scrollHeight,
        behavior: "smooth",
      });
    });
  }

  const [indexesArr, setIndexesArr] = React.useState([]);
  const onPrintBorders = (index) => {
    let arr = [];
    if (indexesArr.includes(index)) {
      let indexes = [...indexesArr];
      indexes.splice(indexes.indexOf(index), 1);
      setIndexesArr(indexes);
      arr = indexes;
      return;
    }
    if (indexesArr.length < 2) {
      arr = [...indexesArr, index];
      setIndexesArr((oldIdxs) => [...oldIdxs, index]);
    }
    const tartgetIndexes = arr.sort(function (a, b) {
      return a - b;
    });
    setPrintable(toolsAccount.slice(tartgetIndexes[0], tartgetIndexes[1] + 1));
  };

  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [hiddenHeader, setHiddenHeader] = useState("none");
  window.addEventListener("scroll", (x) => {
    if (window.scrollY >= 180) setHiddenHeader("flex");
    else setHiddenHeader("none");
  });

  const classes = useStyles();
  return (
    <div
      className=" w-100 marginHolder p-0 py-4 pb-5 position-relative  "
      style={{
        background: "#534d3b",
        minHeight: "107vh",
        outline: " 8px solid #534d3b",
      }}
    >
      <Helmet>
        <title>حساب جاري العدة</title>
      </Helmet>

      <div className=" p-0 container position-relative " id="scrollList">
        <AccountHeader
          onScrollBottom={downList_Page}
          indexesArr={indexesArr}
          handlePrint={handlePrint}
          fromDate={fromDate}
          toDate={toDate}
          partner={partner}
          reloadhandler={reloadhandler}
        />
        <div className="container">
          {toolsAccount.length > 0 && (
            <CurrentToolsTable
              partner={partner}
              onPrintBorders={(index) => onPrintBorders(index)}
              indexesArr={indexesArr}
              currentAccount={toolsAccount}
              refresh={getToolsCurrentAccount}
            />
          )}
        </div>

        <div ref={componentRef} className={classes.printableTable}>
          <LogoImage />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="container"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                color: "#2E3840",
                width: "99%",
                padding: 10,
                margin: "10px auto",
                border: "2px dashed black",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  marginTop: ".3vh",
                }}
              >
                {"مطلوب من : "}
              </Typography>
              <Typography
                style={{
                  fontWeight: "bolder",
                }}
              >
                {partner.name}
              </Typography>
            </div>
          </div>
          <div className="container">
            {toolsAccount.length > 0 && (
              <CurrentToolsTable
                partner={partner}
                onPrintBorders={(index) => onPrintBorders(index)}
                indexesArr={indexesArr}
                currentAccount={printable}
                refresh={getToolsCurrentAccount}
                isPrint={true}
              />
            )}
          </div>
          <Row className=" w-100 m-auto">
            <Col xs={12} className=" text-center  p-1">
              <TableContainer className="p-0 m-0 w-100" component={Paper}>
                <Table aria-label="simple table">
                  <TableHead style={{ background: "#ffc107", height: "33px" }}>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="headerTableFont center justify-content-between p-0"
                      >
                        اجمالي العدة
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ height: "33px" }}>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center" className="p-0 m-0 ">
                        {Math.round(remainingToolsCount) > 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(remainingToolsCount)
                            )} عليه `
                          : ""}
                        {Math.round(remainingToolsCount) < 0
                          ? `${ConvertToArabicNumbers(
                              Math.abs(remainingToolsCount)
                            )} له `
                          : ""}
                        {Math.round(remainingToolsCount) === 0 ? "لايوجد" : ""}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Col>
          </Row>

          <AccountReamainingTable partnerId={partnerId} />
        </div>

        <Row className="p-2 w-100   m-auto   ">
          <Col xs={12} className=" text-center  p-1">
            <TableContainer className="p-0 m-0 w-100" component={Paper}>
              <Table aria-label="simple table">
                <TableHead style={{ background: "#FED049", height: "33px" }}>
                  <TableRow>
                    <TableCell
                      align="center"
                      className="headerTableFont center justify-content-between"
                    >
                      <div className="center">
                        <p>اجمالي العدة</p>

                        <ToolsInfo
                          partnerId={partner.id}
                          haveAction
                          isInfoIcon
                        />
                      </div>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" className="BodyTableFont">
                      {Math.round(remainingToolsCount) > 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(remainingToolsCount)
                          )} عليه `
                        : ""}
                      {Math.round(remainingToolsCount) < 0
                        ? `${ConvertToArabicNumbers(
                            Math.abs(remainingToolsCount)
                          )} له `
                        : ""}
                      {Math.round(remainingToolsCount) === 0 ? "لايوجد" : ""}
                    </TableCell>
                    {isEligableToEdit && (
                      <TableCell
                        align="center"
                        component={Link}
                        to={`/tools-in/${partnerId}`}
                        className="headerTableFont btnMore m-3 m-0 h-100"
                      >
                        {" "}
                        اضف تحصيل{" "}
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
        </Row>

        <RemainingDetails
          rows={remainingDetails}
          open={open}
          onClose={() => setOpen(false)}
        />
      </div>
    </div>
  );
}

export default ToolsAccount;

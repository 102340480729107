import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { ConvertToArabicNumbers, renderDate } from "../../utils";
import { Link, useParams, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getLastDepositDate, getPartnersData } from "./clientSlice";
import { useDispatch, useSelector } from "react-redux";
import cls from "./clientProfile.module.scss";
import { getCurrentUser } from "../auth/authSlice";
import axios from "axios";
import DetailsIcon from "@material-ui/icons/Details";

import Alerto from "../../components/UI/toaster";
import RemainingDetails from "../movement-tools/components/RemainingDetails";
import { getRemainingDetailsForPartners } from "../movement-tools/tools-reports/toolsSlice";

import { Col, Container, Row } from "react-bootstrap";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import CommissionTruck from "./components/CommissionTruck";
import BuyingTruck from "./components/BuyingTruck";
import "../seller-profile/seller-profile.css";

const fixedvalue = 1;
const ProfileItem = (num) =>
  num !== 0 && num ? ConvertToArabicNumbers(Math.abs(Math.round(num))) : "صفر";

function ClientUserProfile() {
  const history = useHistory();
  const { partnerId } = useParams();
  const [dueDate, setDueDate] = useState("");
  const remainingDetails = useSelector((state) => state.tools.remainingDetails);
  const [openDetails, setOpenDetails] = useState(false);
  const onDetailsClicked = (partnerId) => {
    setOpenDetails(true);
    dispatch(getRemainingDetailsForPartners(partnerId));
  };
  const getPartnerDueDate = () => {
    axios
      .get(`/Partners/payementDate?PartnerId=${partnerId}`)
      .then((res) => {
        if (res.status === 200) {
          setDueDate(res.data);
        }
      })
      .catch((err) => {
        if (partnerId) {
          history.push(`/seller-user-profile/${partnerId}`);
        }
      });
  };

  const [lastTreatment, setLastTreatment] = useState("");
  const getBuyerLastTreatment = () => {
    axios
      .get(`/MoneyCurrentAccountReport/LastOperation/client/${partnerId}`)
      .then((res) => {
        if (res.status === 200) {
          setLastTreatment(res.data);
        }
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  const [buyerDebt, setBuyerDebt] = useState("");
  const getBuyerDebtDetails = async () => {
    try {
      const res = await axios.get(
        `/MoneyCurrentAccountReport/debtDetails/buyer/${partnerId}`
      );

      setBuyerDebt(res.data);
    } catch (e) {
      Alerto(e);
    }
  };

  const [clientDebt, setClientDebt] = useState("");
  const [employeeDebt, setemployeeDebt] = useState("");
  const getClientDebtDetails = () => {
    axios
      .get(`/MoneyCurrentAccountReport/debtDetails/client/${partnerId}`)
      .then((res) => {
        setClientDebt(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  const getemployeeDebtDetails = () => {
    axios
      .get(`MoneyCurrentAccountReport/debtDetails/employee/${partnerId}`)
      .then((res) => {
        setemployeeDebt(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  const [totalMortage, setTotalMortage] = useState("");

  const getPriceOfRemainingTools = () => {
    axios
      .get(`/MovementTools/reports/Remaining/price/${partnerId}`)
      .then((res) => {
        setTotalMortage(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  const [allTools, setAllTools] = useState([]);

  const getAllTools = () => {
    axios
      .get(`/MovementTools/reports/Remaining/${partnerId}`)
      .then((res) => {
        setAllTools(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  const logSearch = (id) => {
    axios
      .post(`/Tracking/LogSearching`, {
        userId: id,
        partnerSearchedForId: partnerId,
      })
      .then(() => {})
      .catch((err) => {
        Alerto(err);
      });
  };

  const totalToolsCount = allTools.reduce((acc, val) => {
    return acc + val.count;
  }, 0);
  const [Partner, setPartner] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (partnerId) {
        getPartnerDueDate();
        dispatch(getPartnersData(partnerId)).then((res) => {
          setPartner(res.payload);
        });
        dispatch(getLastDepositDate(partnerId));
        getBuyerLastTreatment();
        getBuyerDebtDetails();
        getPriceOfRemainingTools();
        getAllTools();
        dispatch(getCurrentUser()).then((res) => {
          logSearch(res.payload.UserId);
        });
      }
      getClientDebtDetails();
      getemployeeDebtDetails();
    }
    return () => {
      mounted = false;
    };
  }, [partnerId]);

  const [NetDebt, setNetDabt] = useState("");
  const getNetDebt = () => {
    let data = {
      trucksInvoices: clientDebt.totalInvoices,
      sellingsInvoices: buyerDebt.totalInvoices,
      totalClientLoans: clientDebt.totalLoans,
      totalBuyerLoans: buyerDebt.totalLoans,
      totalToolMortage: totalMortage != "" ? totalMortage : 0,
      totalSalaryDebt: employeeDebt != "" ? employeeDebt : 0,
    };
    axios
      .post(`MoneyCurrentAccountReport/calculator/debt/total`, data)
      .then((res) => {
        setNetDabt(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  useEffect(() => {
    getNetDebt();
  }, [clientDebt, buyerDebt, employeeDebt, totalMortage]);
  return (
    <div
      className=" w-100  p-0 py-4 pb-5 margin15px"
      style={{
        background: "#534d3b",
        marginTop: " -15px  !important",
        outline: " 8px solid #534d3b",
        minHeight: "107vh ",
      }}
    >
      <Container className="sellerpage">
        {/* ............. header start ..................... */}

        <Row xs={12} className="mt-3">
          {/* ............. اسم البائع ............. */}
          <Col className=" m-auto mb-3" xs={9} lg={4}>
            <Card
              style={{
                height: 88,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow:
                  "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
              }}
            >
              <CardContent>
                <Typography variant="h6">{Partner.name}</Typography>
              </CardContent>
            </Card>
          </Col>
          {/* .......................... */}

          {/* ............. تفاصيل الحساب............. */}

          <Col className=" mb-3 text-center" lg={8}>
            <Card>
              <CardContent
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
                }}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      component={Link}
                      style={{ backgroundColor: "#90DCD0", color: "#333" }}
                      to={`/MoneyCurrentAccountReport/client/${partnerId}`}
                      className="btnMore"
                    >
                      تفاصيل الحساب
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      component={Link}
                      style={{ backgroundColor: "#90DCD0", color: "#333" }}
                      to={`/MoneyCurrentAccountReport/client/${partnerId}/rep/trucks`}
                      className="btnMore"
                    >
                      نقلات مشتركة
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className="headerTableFont"
                    >
                      {" "}
                      تاريخ الاستحقاق:{" "}
                      <Typography
                        variant="body2"
                        component="span"
                        color="textPrimary"
                        className="headerTableFont"
                      >
                        {dueDate
                          ? ConvertToArabicNumbers(renderDate(dueDate))
                          : "صفر"}{" "}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className="headerTableFont"
                    >
                      {" "}
                      تاريخ اخر تعامل:{" "}
                      <Typography
                        variant="body2"
                        component="span"
                        color="textPrimary"
                        className="headerTableFont"
                      >
                        {lastTreatment
                          ? ConvertToArabicNumbers(renderDate(lastTreatment))
                          : "صفر"}{" "}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Col>
          {/* .......................... */}
        </Row>
        {/* ............. header end ..................... */}

        <Row sm={12} className="mb-3 pb-4">
          {/* .............  بعض التفاصيل (الجانب الايسر) ............. */}
          <Col lg={8} className="mx-auto">
            <Row xs={12} className="gx-5 ">
              <Col lg={6} className="mx-auto">
                <TableContainer
                  className="p-0 mt-3 hover-link"
                  component={Paper}
                >
                  <Link
                    to={`/MoneyCurrentAccountReport/client/${partnerId}/trucks`}
                  >
                    <Table aria-label="simple table">
                      <TableHead
                        style={{ background: "#FED049", height: "65px" }}
                      >
                        <TableRow>
                          <TableCell className="headerTableFont" align="center">
                            فواتير نقلات{" "}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center" className="BodyTableFont">
                            <span variant="body1" style={{ fontSize: "26px" }}>
                              {ProfileItem(clientDebt.totalInvoices)}{" "}
                              {clientDebt.totalInvoices > 0 ? "عليه" : ""}
                              {clientDebt.totalInvoices < 0 ? "له" : ""}
                              {clientDebt.totalInvoices === "" ? "" : ""}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Link>
                </TableContainer>
              </Col>

              <Col lg={6} className="mx-auto">
                <TableContainer
                  className="p-0 mt-3 hover-link"
                  component={Paper}
                >
                  <Link
                    to={`/MoneyCurrentAccountReport/client/${partnerId}/loans`}
                  >
                    <Table aria-label="simple table">
                      <TableHead
                        style={{ background: "#FED049", height: "65px" }}
                      >
                        <TableRow>
                          <TableCell align="center" className="headerTableFont">
                            عملات مقدمة{" "}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center" className="BodyTableFont">
                            <span variant="body1">
                              {ProfileItem(clientDebt.totalLoans)}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Link>
                </TableContainer>
              </Col>

              {Partner.partnerTypeId === 3 && (
                <Col lg={6} className="mx-auto">
                  <TableContainer className="p-0 mt-3" component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead
                        style={{ background: "#FED049", height: "65px" }}
                      >
                        <TableRow>
                          <TableCell align="center" className="headerTableFont">
                            {" "}
                          </TableCell>
                          <TableCell align="center" className="headerTableFont">
                            فواتير المبيع{" "}
                          </TableCell>
                          <TableCell align="center" className="headerTableFont">
                            {" "}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center"></TableCell>
                          <TableCell align="center" className="BodyTableFont">
                            <span variant="body1" style={{ fontSize: "26px" }}>
                              {ProfileItem(buyerDebt.totalInvoices)}{" "}
                              {buyerDebt.totalInvoices > 0 ? "عليه" : ""}
                              {buyerDebt.totalInvoices < 0 ? "له" : ""}
                              {buyerDebt.totalInvoices === "" ? "" : ""}
                            </span>
                          </TableCell>

                          <TableCell
                            align="center"
                            className=" btnMore BodyTableFont"
                          >
                            <Link
                              to={`/seller-user-profile/${partnerId}`}
                              style={{ color: "white" }}
                            >
                              حساب البائع
                            </Link>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Col>
              )}

              {Partner.partnerTypeId === 3 && (
                <Col lg={6} className="mx-auto mt-3">
                  <TableContainer className="p-0 " component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead
                        style={{ background: "#FED049", height: "65px" }}
                      >
                        <TableRow>
                          <TableCell align="center" className="headerTableFont">
                            السلف
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center" className="BodyTableFont">
                            {ProfileItem(buyerDebt.totalLoans)}
                            <span variant="body1"> </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Col>
              )}
              <Col xs={12}>
                <div
                  className="w-100 bg-white my-3 rounded-3"
                  style={{ height: "3px" }}
                ></div>
              </Col>

              <Col lg={6} className="mx-auto">
                <TableContainer
                  className="p-0 mb-3 hover-link"
                  component={Paper}
                >
                  <Link to={`/tools-current-account/${partnerId}`}>
                    <Table aria-label="simple table">
                      <TableHead
                        style={{ background: "#FED049", height: "65px" }}
                      >
                        <TableRow>
                          <TableCell align="center" className="headerTableFont">
                            عدد العدة
                          </TableCell>
                          <TableCell align="center" className="headerTableFont">
                            {" "}
                            الرهن
                          </TableCell>
                          <TableCell align="center" className="headerTableFont">
                            {" "}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center" className="BodyTableFont">
                            {ProfileItem(totalToolsCount)}
                            <span variant="body1"> </span>
                          </TableCell>
                          <TableCell align="center" className="BodyTableFont">
                            {ProfileItem(totalMortage)}
                            <span variant="body1">
                              {" "}
                              {totalToolsCount > 0 ? "عليه" : ""}
                              {totalToolsCount < 0 ? "له" : ""}
                              {totalToolsCount === "" ? "" : ""}
                            </span>
                          </TableCell>
                          <TableCell align="center" className="BodyTableFont">
                            <Link
                              style={{ color: "red !important" }}
                              onClick={() => onDetailsClicked(partnerId)}
                            >
                              <Tooltip title="العدد">
                                <IconButton>
                                  <InfoIcon style={{ fontSize: "35px" }} />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Link>
                </TableContainer>
              </Col>

              {Partner.isEmployee ? (
                <Col lg={6} className="mx-auto">
                  <TableContainer className="p-0  hover-link" component={Paper}>
                    <Link
                      to={`/MoneyCurrentAccountReport/employee/${partnerId}`}
                    >
                      <Table aria-label="simple table">
                        <TableHead
                          style={{ background: "#FED049", height: "65px" }}
                        >
                          <TableRow>
                            <TableCell
                              align="center"
                              className="headerTableFont"
                            >
                              {" "}
                              راتب
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center" className="BodyTableFont">
                              {ProfileItem(employeeDebt)}{" "}
                              {employeeDebt > 0 ? "عليه" : ""}
                              {employeeDebt < 0 ? "له" : ""}
                              {employeeDebt === "" ? "" : ""}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Link>
                  </TableContainer>
                </Col>
              ) : (
                ""
              )}

              <Col xs={12}>
                <TableContainer className="p-0 mt-3" component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead
                      style={{ background: "#f3c637", height: "65px" }}
                    >
                      <TableRow>
                        <TableCell
                          className="btnMore headerTableFont "
                          align="center"
                        >
                          اجمالي المبلغ
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" className="BodyTableFont">
                          <span style={{ fontSize: "26px" }}>
                            {ProfileItem(NetDebt)}
                          </span>
                          <span style={{ fontSize: "26px" }}>
                            {" "}
                            {NetDebt > 0 ? "عليه" : ""}
                            {NetDebt < 0 ? "له" : ""}
                            {NetDebt === "" ? "" : ""}
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Col>

              <Col lg={6} className="mx-auto">
                <TableContainer
                  className="p-0 mt-3 hover-link"
                  component={Paper}
                >
                  <Link
                    to={`/MoneyCurrentAccountReport/client/${partnerId}/mortage`}
                  >
                    <Table aria-label="simple table">
                      <TableHead
                        style={{ background: "#FED049", height: "65px" }}
                      >
                        <TableRow>
                          <TableCell align="center" className="headerTableFont">
                            رهن المشتريات
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center" className="BodyTableFont">
                            <span variant="body1">
                              {ProfileItem(clientDebt.clientMortage)}{" "}
                              {clientDebt.clientMortage > 0 ? "عليه" : ""}
                              {clientDebt.clientMortage < 0 ? "له" : ""}
                              {clientDebt.clientMortage === "" ? "" : ""}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Link>
                </TableContainer>
              </Col>
            </Row>
          </Col>
          {/* .......................... */}
        </Row>

        {/* .............  popup (بتاعت تفاصيل الرهن) ............. */}

        <RemainingDetails
          rows={remainingDetails}
          open={openDetails}
          onClose={() => setOpenDetails(false)}
        />
        {/* .......................... */}
      </Container>
    </div>
  );
}

export default ClientUserProfile;

import { Box, Container, Typography } from "@material-ui/core";

import { CompanyProfile } from "./components/CompanyProfile";
import { Helmet } from "react-helmet";
import Images from "./components/Images";
import Limit from "./components/Limit";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import MainHeader from "../../components/mainHeader/MainHeader";

const Settings = () => {
  const history = useHistory();
  const role = localStorage.getItem("role");
  useEffect(() => {
    if (role) {
      if (role != "إدارة") {
        history.goBack();
      }
    }
  }, [role]);
  return (
    <>
      <Helmet>
        <title>الاعدادات</title>
      </Helmet>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth="lg">
          <div className="my-2">
            <MainHeader title="الاعدادات" />
          </div>
          <Box sx={{ pt: 3 }}></Box>
          <Limit />
          <Images />
        </Container>
      </Box>
    </>
  );
};

export default Settings;

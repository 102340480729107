import React, { Fragment, useEffect } from 'react';
import {
  CardHeader,
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
  Grid,
} from '@material-ui/core';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import ToolsTable from '../main-data/components/ToolsTable';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
  },
  formContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& > *': {
      marginRight: 12,
    },
  },
});

function AddJob() {
  const [jobName, setJobName] = React.useState('');
  const [job, setjob] = React.useState([]);
useEffect(()=>{
  axios
  .get('/Employees/job')
  .then((res)=>{
    setjob(res.data)

  })
  .catch((err)=>{
    
  })
},[])
const updatedata=()=>{
  axios
  .get('/Employees/job')
  .then((res)=>{
    setjob(res.data)
  })
  .catch((err)=>{
    
  })

};
  const handleSubmit = () => {
    axios
      .post(`/Employees/job?name=${jobName}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success('تم اضافة الوظيفة بنجاح');
          setJobName('');
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          toast.error(err.response.data);
          setJobName('');
        }
      });
  };
  const classes = useStyles();
  return (<Fragment>

    <div className={classes.root}>
      <Card style={{ width: 360 }}>
        <CardHeader title="اضافة وظيفة" />
        <Divider />
        <CardContent>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <TextField
                label="اظيفة"
                variant="outlined"
                value={jobName}
                onChange={(e) => setJobName(e.target.value)}
                fullWidth
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleSubmit();
                  }
                }}
                />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                type="submit"
                onClick={handleSubmit}
                disabled={!jobName}
                style={{
                  width: '100%',
                  backgroundColor: '#0682FB',
                  fontWeight: 600,
                  color: '#FFF',
                }}
                >
                حفظ
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ToolsTable update={updatedata} tools={job} jobs={true}/>
      </div>
                </Fragment>
  );
}

export default AddJob;

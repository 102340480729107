import { useEffect, useRef, useState } from "react";
import { IoIosArrowDropupCircle } from "react-icons/io";
const UpCursor = () => {
  const [position, setposition] = useState(0);
  console.log("🚀 ~ file: UpCursor.js:5 ~ UpCursor ~ position:", position);
  const timeRef = useRef(0);
  const scrollhandler = () => {
    const accountsheader = document.getElementById("accountsHeader");
    clearTimeout(timeRef.current);
    const y = window.pageYOffset;
    timeRef.current = setTimeout(() => {
      if (!!accountsheader) {
        if (y > accountsheader.offsetTop)
          accountsheader.classList.remove("top1");
        else accountsheader.classList.add("top1");
      }
      setposition(y);
    }, 100);
  };
  const tophandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollhandler, true);
    return () => window.removeEventListener("scroll", scrollhandler);
  }, []);

  return (
    <div
      onClick={tophandler}
      className={position > 400 ? "scrollBtn" : "disable"}
    >
      <IoIosArrowDropupCircle />
    </div>
  );
};
export default UpCursor;

import {
  addExpenses,
  deleteExpenses,
  getBuyingTruckProfit,
  getExpenses,
  updateInvoiceExpense,
} from "../invoiceSlice";
import { useDispatch, useSelector } from "react-redux";

import Expenses from "../../loads/components/Expenses";
import React from "react";
import axios from "axios";
import Alerto from "../../../components/UI/toaster";
import { toast } from "react-toastify";
import Mydate from "../../../components/Helpers/MyDateParser";

function AddExpenses({ open, onClose, truck }) {
  const dispatch = useDispatch();
  const loadExpenses = useSelector((state) => state.invoice.loadExpenses);

  const [expensesCategories, setExpensesCategories] = React.useState([]);
  const getDedCategories = () => {
    axios
      .get("/ExpenseContoller/categories")
      .then((res) => {
        setExpensesCategories(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  React.useEffect(() => {
    getDedCategories();
  }, []);

  const onAddEXpenses = ({ category, money, notes, SharedPartnerId }) => {
    if (money && category && category.id) {
      dispatch(
        addExpenses({
          SharedPartnerId,
          category,
          name: category.name,
          money,
          notes,
        })
      ).then(() => {
        dispatch(getExpenses()).then(() => {
          if (truck.truckType === 2) {
            dispatch(getBuyingTruckProfit(truck.id));
          }
        });
      });
    }
  };

  function updateHandler(item, expense) {
    try {
      const data = {
        ...item,
        categoryId: item.category.id,
        id: expense.id,
        isDamage: expense.isDamage,
        dateTime: Mydate(new Date(item.dateTime)),
      };
      dispatch(updateInvoiceExpense(data)).then(() => {
        dispatch(getExpenses());
      });
    } catch (e) {
      Alerto(e);
    }
  }
  const onDelete = (item) => {
    dispatch(deleteExpenses(item)).then(() => {
      dispatch(getExpenses()).then(() => {
        if (truck.truckType === 2) {
          dispatch(getBuyingTruckProfit(truck.id));
        }
      });
    });
  };

  return (
    <div>
      <Expenses
        categories={expensesCategories}
        open={open}
        onClose={onClose}
        loadExpenses={loadExpenses}
        onAddEXpensesClicked={onAddEXpenses}
        onDeleteItemClicked={onDelete}
        onUpdate={updateHandler}
      />
    </div>
  );
}

export default AddExpenses;

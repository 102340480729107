import {
 
  Checkbox,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import classy from "./claims.module.css";
import axios from "axios";
import { toast } from "react-toastify";

function ClaimsForm({ partnerId, userId }) {
  const [data, setData] = useState([]);
  
  useEffect(() => {
    axios
      .get(`/Security/claims?userId=${userId}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
      });
      
    }, []);
    
    const addingChange = (index) => {
      let dataList = [...data];
      dataList[index].adding.checked = !data[index].adding.checked;
      setData(dataList);
      axios
      .put(`/Security/claims/${userId}`, {
        values: [
          {
            normalizedName: data[index].adding.normalizedName,
            checked: data[index].adding.checked,
          },
        ],
      })
      .then(() => {})
      .catch((err) => {
        toast.error(err.response.data);
      });
    };
    const readingChange = (index) => {
      let dataList = [...data];
      dataList[index].reading.checked = !data[index].reading.checked;
      setData(dataList);
      axios
      .put(`/Security/claims/${userId}`, {
        values: [
          {
            normalizedName: data[index].reading.normalizedName,
            checked: data[index].reading.checked,
          },
        ],
      })
      .then(() => {})
      .catch((err) => {
        toast.error(err.response.data);
      });
    };
    const deletingChange = (index) => {
      let dataList = [...data];
      dataList[index].deleting.checked = !data[index].deleting.checked;
      setData(dataList);
      axios
      .put(`/Security/claims/${userId}`, {
        values: [
          {
            normalizedName: data[index].deleting.normalizedName,
            checked: data[index].deleting.checked,
          },
        ],
      })
      .then(() => {})
      .catch((err) => {
        toast.error(err.response.data);
      });
    };
    const updatingChange = (index) => {
      let dataList = [...data];
      dataList[index].updating.checked = !data[index].updating.checked;
      setData(dataList);
      axios
      .put(`/Security/claims/${userId}`, {
        values: [
          {
            normalizedName: data[index].updating.normalizedName,
            checked: data[index].updating.checked,
          },
        ],
      })
      .then(() => {})
      .catch((err) => {
        toast.error(err.response.data);
      });
  };
  
  let d = data.filter((item) => item.root == "بيانات اساسية");
  let headlines = [];
  data.map((item) => {
    if (!headlines.find((i) => i === item.root)) {
      headlines.push(item.root);
    }
  });
  return headlines.map((head) => (
    <div className={classy.holder}>
      <div className={classy.main}>
        <p>{head}</p>
      </div>
      {data.map((item, index) => {
        if (item.root === head) {
          return (
            <div className={classy.sub}>
              <div className={classy.name}>
                <p>{item.rowName}</p>
              </div>
              {item.reading ? (
                <div className={classy.cell}>
                  <FormControlLabel
                    value="top"
                    control={
                      <Checkbox
                        style={{ color: "#4EC76E" }}
                        checked={item.reading.checked}
                        onChange={() => {
                          readingChange(index);
                        }}
                      />
                    }
                    label="قراءة"
                    labelPlacement="top"
                  />
                </div>
              ):(
                <div className={classy.cell}>
                  <FormControlLabel
                    value="top"
                    control={
                      <Checkbox
                        style={{ color: "#4EC76E" }}
                      disabled={true}
                      />
                    }
                    label="قراءة"
                    labelPlacement="top"
                  />
                </div>

              )}

              {item.adding ? (
                <div className={classy.cell}>
                  <FormControlLabel
                    value="top"
                    control={
                      <Checkbox
                        style={{ color: "#4EC76E" }}
                        checked={item.adding.checked}
                        onChange={() => {
                          addingChange(index);
                        }}
                      />
                    }
                    label="أضافة"
                    labelPlacement="top"
                  />
                </div>
              ):(
                <div className={classy.cell}>
                  <FormControlLabel
                    value="top"
                    control={
                      <Checkbox
                        style={{ color: "#4EC76E" }}
                      disabled={true}
                      />
                    }
                    label="أضافة"
                    labelPlacement="top"
                  />
                </div>

              )}
              {item.updating ? (
                <div className={classy.cell}>
                  <FormControlLabel
                    value="top"
                    control={
                      <Checkbox
                        style={{ color: "#4EC76E" }}
                        checked={item.updating.checked}
                        onChange={() => {
                          updatingChange(index);
                        }}
                      />
                    }
                    label="تعديل"
                    labelPlacement="top"
                  />
                </div>
              ):(
                <div className={classy.cell}>
                  <FormControlLabel
                    value="top"
                    control={
                      <Checkbox
                        style={{ color: "#4EC76E" }}
                      disabled={true}
                      />
                    }
                    label="تعديل"
                    labelPlacement="top"
                  />
                </div>

              )}
              {item.deleting ? (
                <div className={classy.cell}>
                  <FormControlLabel
                    value="top"
                    control={
                      <Checkbox
                        style={{ color: "#4EC76E" }}
                        checked={item.deleting.checked}
                        onChange={() => {
                          deletingChange(index);
                        }}
                      />
                    }
                    label="حذف"
                    labelPlacement="top"
                  />
                </div>
              ):(
                <div className={classy.cell}>
                  <FormControlLabel
                    value="top"
                    control={
                      <Checkbox
                        style={{ color: "#4EC76E" }}
                        disabled={true}
                       
                      />
                    }
                    label="حذف"
                    labelPlacement="top"
                  />
                </div>

              )}
            </div>
          );
        }
      })}
    </div>
  ));
}

export default ClaimsForm;

import { ConvertToArabicNumbers, getWeekDay, renderDate } from '../../../utils';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { parseISO } from 'date-fns';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: 8,
    maxHeight: '90vh',
    overflow: 'auto',
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#E3EEFA',
    },
  },
}));

function TrucksList({ invoices, onInvoiceSelected }) {
  const [selectedInvoice, setSelectedInvoice] = React.useState('');

  const classes = useStyles();
  const headCells = ['تاريخ الفاتورة', 'اليوم', 'النوع'];
  return (
    <div className={classes.root}>
      <TableContainer className='overflow-hidden m-auto' component={Paper} sx={{ width: '100% ' }} >
        <Table sx={{ width: '100% ' }}>
          <TableHead
            style={{
              backgroundColor: '#fed049',
              height:'67px'
            }}

          >
            <TableRow>
              {headCells.map((headCell, index) => (
                <TableCell key={index} align="center" className='headerTableFont'>
            
                    {headCell}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((row, index) => (
              <TableRow

                style={
                  selectedInvoice === row.id
                    ? { backgroundColor: '#E3EEFA' }
                    : {}
                }
  
                onClick={() => {
                  if (selectedInvoice !== row.id) {
                    onInvoiceSelected(
                      row.id,
                      row.sellingDate,
                      row.invoiceTypeId
                    );
                    setSelectedInvoice(row.id);
                  } else {
                    setSelectedInvoice('');
                  }
                }}
                className={classes.tableRow}
                key={index}
              >
                <TableCell align="center">
                  {row.sellingDate
                    ? ConvertToArabicNumbers(
                      renderDate(row.sellingDate)
                    )
                    : ''}
                </TableCell>
                <TableCell align="center" className='headerTableFont'>
                  {row.sellingDate ? getWeekDay(row.sellingDate) : ''}
                </TableCell>
                <TableCell align="center" className="headerTableFont">{row.invoiceTypeName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* <Pagination className='m-auto w-50 d-flex justify-content-between ' style={{direction:'rtl !important'}}>
          <Pagination.Prev />
          <Pagination.Item active>{1}</Pagination.Item>
          <Pagination.Item>{10}</Pagination.Item>
          <Pagination.Item>{11}</Pagination.Item>
          <Pagination.Item>{13}</Pagination.Item>
          <Pagination.Item>{13}</Pagination.Item>
          <Pagination.Ellipsis />
          <Pagination.Next />
        </Pagination> */}
      </TableContainer>
    </div>
  );
}

export default TrucksList;

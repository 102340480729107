import axios from "axios";
import { parseISO } from "date-fns";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import Alerto from "../../../components/UI/toaster";
import { ConvertToArabicNumbers } from "../../../utils";
import PaginationArrows from "./PaginationArrows";
import cls from "./TruckHistoryTable.module.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
const head = [
  "اسم العميل",
  "تاريخ ",
  "نوع المصروف",
  "المبلغ",
  "ملاحظات",
  "المستخدم",
  "تاريخ الاجراء",
  "الاجراء",
];
const options = {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
};
const TreasuryExpenseTable = (props) => {
  const Modal = (props) => {
    return (
      <div onClick={props.close} className={props.class} style={{ zIndex: 3 }}>
        <TableContainer overflowX="auto" style={{ overflowY: "hidden" }}>
          <Table className="w-100">
            <TableHead>
              <TableRow style={{ background: "#8c4312" }}>
                <TableCell align="center" colSpan={12} className="text-white">
                  قائمة مصروفات
                </TableCell>
              </TableRow>
              <TableRow style={{ background: "#F3C637" }}>
                {head.map((ele, index) => (
                  <TableCell
                    key={index}
                    style={{
                      minWidth: " 100px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {ele}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data
                .slice(pageNum * 20 - 20, pageNum * 20)
                .map((el, index) => (
                  <TableRow
                    key={index}
                    style={{ background: "#fff" }}
                    onClick={() => props.fetch(el.id, el.periodStart)}
                  >
                    <TableCell
                      align="center"
                      style={{
                        minWidth: " 100px",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      {el.partnerName}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        minWidth: " 180px",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      {ConvertToArabicNumbers(
                        parseISO(el.dateTime).getMonth() +
                          1 +
                          "/" +
                          parseISO(el.dateTime).getDate()
                      )}
                      {"  الساعة  "}
                      {ConvertToArabicNumbers(
                        parseISO(el.dateTime).toLocaleString("en-US", options)
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        minWidth: " 100px",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      {el.treasuryTypeName}
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{
                        minWidth: " 100px",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      {el.money}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        minWidth: " 100px",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      {el.notes}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        minWidth: " 100px",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      {el.recorderName}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        minWidth: " 100px",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      {ConvertToArabicNumbers(
                        parseISO(el.periodStart).getMonth() +
                          1 +
                          "/" +
                          parseISO(el.periodStart).getDate()
                      )}
                      {"  الساعة  "}
                      {ConvertToArabicNumbers(
                        parseISO(el.periodStart).toLocaleString(
                          "en-US",
                          options
                        )
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        minWidth: " 100px",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      {el.processTypeEnum == 1
                        ? "أضافة"
                        : el.processTypeEnum == 3
                        ? "حذف"
                        : "تعديل"}
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow style={{ background: "#ff0" }}>
                <TableCell align="center" colSpan={12} className="p-0">
                  {!props.close && (
                    <PaginationArrows
                      pageNum={pageNum}
                      onPageUp={onPageUp}
                      onPageDown={onPageDown}
                      availabe={data[pageNum * 20 + 1]}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };
  const [data, setData] = React.useState([]);
  const [GetData, setGetData] = React.useState([]);
  React.useEffect(() => {
    setPageNum(1);
    setData(props.data.filter((ele) => ele.partnerName.includes(props.search)));
  }, [props]);
  const getHandler = async (id, date) => {
    try {
      setGetData([]);
      const res = await axios.get(
        `History/Treasury/Expenses/before?entityId=${id}&before=${date.substring(
          0,
          date.length - 5
        )}`
      );
      if (res.data[0]) {
        setGetData(res.data);
      } else {
        toast.error("لا يوجد بيانات لعرضها");
      }
    } catch (e) {
      Alerto(e);
    }
  };
  const [pageNum, setPageNum] = React.useState(1);
  const onPageUp = () => {
    setPageNum(pageNum + 1);
  };
  const onPageDown = () => {
    setPageNum(pageNum - 1);
  };
  return (
    <Fragment>
      <Modal data={data} class={cls.table} fetch={getHandler} />
      {GetData[0] && (
        <Modal
          data={GetData}
          class={cls.modalnameTable}
          close={() => setGetData([])}
        />
      )}
    </Fragment>
  );
};
export default TreasuryExpenseTable;
import { Button, Grid, IconButton, TextField } from "@material-ui/core";
import React, { useState } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomDialog from "../../../components/CustomDialog";
import ar from "date-fns/locale/ar-SA";
import { parseISO } from "date-fns/esm";
import { registerLocale } from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchPartners, fetchTools } from "../../main-data/dataSlice";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Mydate from "../../../components/Helpers/MyDateParser";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

registerLocale("ar", ar);
const initialState = {
  count: "",
  price: "",
  tool: null,
  partner: null,
  dateTime: new Date(),
};
const limitedTransactionIds = [0, 6, 9, 10];
function EditImportingItem({
  item,
  open,
  onClose,
  onEditItemClicked,
  isCurrentAccount,
  onDelete,
}) {
  const isLimited = limitedTransactionIds.includes(item.stateId);
  const dispatch = useDispatch();

  const tools = useSelector((state) => state.data.tools);

  const role = localStorage.getItem("role");

  const [target, setTarget] = useState(initialState);

  const partners = useSelector((state) => state.data.partners);

  function onInputChange({ key, value }) {
    setTarget((prev) => ({ ...prev, [key]: value }));
  }
  useEffect(() => {
    dispatch(fetchTools());
    dispatch(fetchPartners());
  }, []);
  React.useEffect(() => {
    if (item.id) {
      setTarget({
        ...item,
        dateTime: parseISO(item.dateTime),
        count: item.tool?.count,
      });
    } else if (item.click?.id && tools?.[0]) {
      setTarget({
        count: item.count || item.productCount,
        price: item.damagePrice,
        tool: tools.find((ele) => ele.id === item.toolId),
        partner: item.partner,
        dateTime: parseISO(item.dateTime),
        id: item.click.id,
      });
    }
  }, [item, tools]);
  const prohibtedFromDelete = [0, 6, 9, 10];
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      action1={
        <Button
          onClick={onClose}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ width: 56, height: 40 }}
        >
          الغاء
        </Button>
      }
      action2={
        isCurrentAccount &&
        !prohibtedFromDelete.includes(item.stateId) &&
        item.stateId ? (
          <IconButton style={{ color: "red" }} onClick={onDelete}>
            <DeleteOutlineIcon />
          </IconButton>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            style={{ width: 56, height: 40, marginLeft: 16 }}
            size="small"
            onClick={() => {
              onEditItemClicked({
                operations: [
                  {
                    ...target,
                    dateTime: Mydate(target.dateTime),
                    partnerId: target.partner?.id,
                    toolId: target.tool?.id,
                  },
                ],
              });
              onClose();
            }}
          >
            تعديل
          </Button>
        )
      }
    >
      <Grid container spacing={1}>
        {!isLimited && (
          <Grid item>
            <Autocomplete
              options={partners}
              getOptionSelected={(option, value) =>
                option.value === value?.value
              }
              getOptionLabel={(option) => (option.name ? option.name : "")}
              style={{ width: 260 }}
              autoHighlight
              autoComplete
              noOptionsText="لم يتم العثور على خيارات"
              onChange={(event, value) => {
                onInputChange({ key: "partner", value: value || null });
              }}
              value={target.partner}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                }
              }}
              size="small"
              renderInput={(params) => (
                <TextField
                  autoFocus
                  {...params}
                  label="الاسم "
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item>
          <Autocomplete
            options={tools}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            getOptionSelected={(option, value) => option.id === value.id}
            style={{ width: 200 }}
            autoHighlight
            autoComplete
            noOptionsText="لم يتم العثور على خيارات"
            onChange={(event, value) => {
              onInputChange({ key: "tool", value: value || null });
            }}
            value={target.tool}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
              }
            }}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                label="العدة"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item>
          <TextField
            onChange={(e) =>
              onInputChange({
                key: "count",
                value: Number.parseFloat(e.target.value) || "",
              })
            }
            value={target.count}
            label="العدد"
            size="small"
            variant="outlined"
            style={{ width: 160 }}
          />
        </Grid>
        {!!target.price && !isLimited && (
          <Grid item>
            <TextField
              onChange={(e) =>
                onInputChange({
                  key: "price",
                  value: Number.parseFloat(e.target.value) || "",
                })
              }
              value={target.price}
              label="قيمة الهالك"
              size="small"
              variant="outlined"
              style={{ width: 160 }}
            />
          </Grid>
        )}

        {!isLimited && (
          <Grid item xs={6} md={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disableFuture
                disabled={role == "موظف"}
                label="التاريخ"
                openTo="day"
                views={["year", "month", "day"]}
                value={target.dateTime}
                onChange={(d) => onInputChange({ key: "dateTime", value: d })}
                renderInput={(params) => (
                  <TextField style={{ width: "100%" }} {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>
        )}
        {isCurrentAccount && !prohibtedFromDelete.includes(item.stateId) && (
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              style={{ width: 56, height: 40, marginRight: 20 }}
              size="small"
              onClick={() => {
                onEditItemClicked({
                  operations: [
                    {
                      ...target,
                      dateTime: Mydate(target.dateTime),
                      partnerId: target.partner?.id,
                      toolId: target.tool?.id,
                    },
                  ],
                });
                onClose();
              }}
            >
              تعديل
            </Button>
          </Grid>
        )}
      </Grid>
    </CustomDialog>
  );
}
export default EditImportingItem;

import VideoComp from "../Brief/VideoComp";
import cls from "../Brief/Brief.module.scss";
import { SiTeamviewer } from "react-icons/si";
import { SiAnydesk } from "react-icons/si";
import MainHeader from "../../mainHeader/MainHeader";
const videos = [
  { url: "https://www.youtube.com/embed/9mhq8-M90SQ", description: "" },
  { url: "https://www.youtube.com/embed/tEr0AEmdixQ", description: "" },
  { url: "https://www.youtube.com/embed/9PVHvnHne6g", description: "" },
  { url: "https://www.youtube.com/embed/9h2xKJ7vzRY", description: "" },
  { url: "https://www.youtube.com/embed/Fgty2g9qjbM", description: "" },
  { url: "https://www.youtube.com/embed/wDiD7xHaVYY", description: "" },
];
const LinksVideos = [
  { url: "https://www.youtube.com/embed/v6Dmak1eHsw", description: "" },
  { url: "https://www.youtube.com/embed/bFKZvk9nDmI", description: "" },
];
const Maintenance = () => {
  return (
    <div className={cls.container}>
      <div className="my-4">
        <MainHeader title="تحميل برامج التحكم" />
      </div>
      <div className={cls.downloadsHolder}>
        <div className={cls.item1}>
          <VideoComp ele={LinksVideos[0]} />
          <p>Any Desk Download</p>
          <a href="https://anydesk.com/en/downloads/windows" target="_blank">
            <SiAnydesk />
          </a>
        </div>
        <div className={cls.item}>
          <VideoComp ele={LinksVideos[1]} />
          <p>Team Viewer Download</p>
          <a
            href="https://www.teamviewer.com/en/download/windows/?utm_source=google&utm_medium=cpc&utm_campaign=africa%7Cb%7Cpr%7C22%7Caug%7Ctv-core-download-sn%7Cnew%7Ct0%7C0&utm_content=Download&utm_term=teamviewer+download&gclid=Cj0KCQjw7KqZBhCBARIsAI-fTKJJVqJ7LZuF5KRQxtPF86VAATRhQTa2ibj6x9xiW5NSP9yxWioiG6waAhD1EALw_wcB"
            target="_blank"
          >
            <SiTeamviewer />
          </a>
        </div>
      </div>
      <div className="my-4">
        <MainHeader title="فيديوهات الصيانة " />
      </div>
      <div className={cls.videoHolder}>
        {videos.map((ele) => (
          <VideoComp ele={ele} />
        ))}
      </div>
    </div>
  );
};
export default Maintenance;

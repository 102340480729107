import React from "react";
import SellingTable from "../../loads/components/SellingTable";
import { Typography } from "@material-ui/core";
import axios from "axios";
import { indexOfId } from "../../../utils";
import { toast } from "react-toastify";
import Alerto from "../../../components/UI/toaster";
import SalesTable from "../../loads/container/SalesTable";

function PartnerSales({ truckId, invoiceid, bgColor }) {
  const [soldItems, setSoldItems] = React.useState([]);
  const [truck, setTruck] = React.useState("");
  const getTruck = () => {
    axios
      .get(`/Truck/${truckId}`)
      .then((res) => {
        if (res.status === 200) {
          setTruck(res.data);
        }
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  const getSoldItems = () => {
    axios
      .get(
        `/Selling/partner?truckTypeId=${truck.truckTypeId}&truckId=${truck.id}&invoiceId=${invoiceid}`
      )
      .then((res) => {
        setSoldItems(res.data);
      })
      .catch((err) => {});
  };

  React.useEffect(() => {
    getTruck();

    return () => {
      setTruck("");
    };
  }, []);

  React.useEffect(() => {
    if (truck && truck.id) {
      getSoldItems();
    }

    return () => {
      setSoldItems([]);
    };
  }, [truck]);
  function checkIfReload(origin, update) {
    let orignDate = new Date(origin.dateTime).setHours(0, 0, 0, 0);
    let updatedDate = new Date(update.dateTime).setHours(0, 0, 0, 0);
    if (origin.partner?.id != update.partner?.id || orignDate != updatedDate)
      window.location.reload();
  }
  const onUpdate = (item) => {
    axios
      .put(`/Selling?truckTypeId=${truck.truckTypeId}&ItemId=${item.id}`, {
        id: item.id,
        partnerId: item.partner.id,
        priceForUnitWeight: item.priceForUnitWeight,
        productsCount: item.productsCount,
        commision: item.commision,
        movementToolId: item.movementTool?.id,
        weight: item.weight,
        truckItemId: item.truckItem.id,
        invoiceTypeId: item.invoiceTypeId,
        dateTime: item.dateTime,
        adderId: "5AF3630A-402E-423A-8AA0-EAA97525E1FB",
      })
      .then(() => {
        toast.success("تم التعديل");
        const index = indexOfId(soldItems, item.id);
        let soldItemsArr = [...soldItems];
        checkIfReload(soldItemsArr[index], item);
        soldItemsArr[index] = item;
        setSoldItems(soldItemsArr);
      })
      .catch((err) => {
        Alerto(err);
      });
  };
  const onDelete = (itemId) => {
    let filteredItems = [...soldItems];

    filteredItems = filteredItems.filter((item) => {
      return item.id !== itemId;
    });

    setSoldItems(filteredItems);

    if (truck.truckTypeId === 1) {
      axios
        .delete(`/Selling?truckTypeId=${truck.truckTypeId}&sellId=${itemId}`)
        .then()
        .catch((err) => {});
    } else if (truck.truckTypeId === 2) {
      axios
        .delete(`/Selling?truckTypeId=${truck.truckTypeId}&sellId=${itemId}`)
        .then()
        .catch((err) => {
          Alerto(err);
        });
    }
  };
  return (
    <div>
      {soldItems.length > 0 ? (
        <SalesTable
          bgColor={bgColor}
          soldItems={soldItems}
          onUpdate={onUpdate}
          onDelete={onDelete}
          isSellerHidden={true}
          truck={truck}
        />
      ) : (
        <Typography
          style={{ textAlign: "center", padding: 8 }}
          color="textSecondary"
          variant="h6"
        >
          ﻻ يوجد
        </Typography>
      )}
    </div>
  );
}

export default PartnerSales;

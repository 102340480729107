import { Box, CardHeader, Divider, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DatePicker from 'react-datepicker';
import SearchDamages from './components/SearchDamages';
import ar from 'date-fns/locale/ar-SA';
import { makeStyles } from '@material-ui/core/styles';
import { registerLocale } from 'react-datepicker';
import { searchDamages } from './damageSlice';

registerLocale('ar', ar);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
  },
  SearchForm: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    '& > *': {
      marginRight: 12,
    },
  },
}));

function Damages() {
  const [dateTime, setDateTime] = useState(new Date());

  const damages = useSelector((state) => state.damage.damages);
  const dispatch = useDispatch();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    let mounted = true;
    if (mounted) {
      if (dateTime) {
        dispatch(searchDamages(dateTime));
      }
    }

    return () => {
      mounted = false;
    };
  }, [dateTime]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box className={classes.SearchForm}>
        <DatePicker
          locale="ar"
          selected={dateTime}
          onChange={(d) => setDateTime(d)}
          dateFormat="dd/MM/yyyy"
          customInput={
            <TextField
              variant="outlined"
              id="dateTime"
              style={{ width: 220, backgroundColor: '#FFF' }}
            />
          }
        />
      </Box>

      <SearchDamages items={damages} />
    </div>
  );
}

export default Damages;

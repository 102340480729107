import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Alerto from "../../../UI/toaster";

const handleMoney = async (partnerId) => {
  try {
    const remain = await axios.get(
      `MovementTools/reports/Remaining/price/${partnerId}`
    );

    const tool = await axios.get(
      `MovementTools/reports/Remaining/${partnerId}`
    );
    const totalToolsCount =
      tool.data?.length > 0 &&
      tool.data.reduce((acc, val) => {
        return acc + val.count;
      }, 0);
    let obj = {
      remain: remain.data,
      tool: totalToolsCount,
    };

    return obj;
  } catch (e) {
    Alerto(e);
  }
};
export default function useInfoHook({ partnerId }) {
  const [money, setMoney] = useState({});
  useEffect(() => {
    handleMoney(partnerId).then((res) => {
      setMoney(res);
    });
  }, [partnerId]);
  return { money };
}

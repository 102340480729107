import {
  IconButton,
  Paper,
  TableSortLabel,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import {
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";

import { ConvertToArabicNumbers } from "../../../utils";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  table: {
    border: "1px solid #222",
  },
  tableRow: {
    marginTop: "8px",
  },
  tableCell: {
    border: "1px solid #222",
    fontSize: 14,
    padding: 5,
    width: 130,
  },
  searchBar: {
    backgroundColor: "#fff",
  },
  "@media print": {
    total: {
      display: "flex",
      width: 400,
      justifyContent: "space-between",
      margin: 8,
    },
    printIcon: {
      display: "none",
    },
    printHidden: {
      display: "none",
    },
    "@media print": {
      printableTable: {
        display: "none !important",
      },
    },
  },
}));

export default function ClientsReport({ data, handlePrint, sub, selected }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("partnerName");

  const report = data.filter((item) => {
    return (
      item.toolsCount !== 0 ||
      item.toolsMoney !== 0 ||
      item.trucksInvoices !== 0 ||
      item.clientLoans !== 0
    );
  });

  const filteredReports = sub.map((report) => {
    return report.filter((item) => {
      return (
        item.toolsCount !== 0 ||
        item.toolsMoney !== 0 ||
        item.trucksInvoices !== 0 ||
        item.clientLoans !== 0
      );
    });
  });

  const classes = useStyles();
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    { label: "عدد", id: "toolsCount" },
    { label: "رهن", id: "toolsMoney" },
    { label: "الاسم", id: "partnerName" },
    { label: "فواتير نقلات", id: "trucksInvoices" },
    { label: "عملات مقدمة", id: "clientLoans" },
  ];

  function SecondaryTableHead({ address }) {
    return (
      <TableHead>
        <TableRow className="p-0">
          <TableCell
            style={{
              background: "#4b4b4b",
              color: " white",
            }}
            className="p-2"
            align="center"
            colSpan={6}
          >
            {address}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow className="p-0">
          {headCells.map((headCell, index) => (
            <TableCell
              style={{ fontWeight: "bold", fontSize: 14, padding: "10px" }}
              key={index}
              align="center"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.id ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [searchQuery, setSearchQuery] = React.useState("");
  return filteredReports[0] ? (
    <>
      {
        <Toolbar
          style={{
            width: "inherit",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#f3c637",
          }}
          className={classes.printableTable}
        >
          <TextField
            placeholder="بحث..."
            className={classes.searchBar}
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title="طباعة التقرير">
            <IconButton
              onClick={() => {
                handlePrint();
              }}
            >
              <PrintOutlinedIcon className="text-black" />
            </IconButton>
          </Tooltip>
        </Toolbar>
      }

      <TableContainer
        style={{
          padding: "5px",
          boxShadow: " rgb(0 0 0 / 56%) 0px 22px 70px 4px",
        }}
        component={Paper}
      >
        {filteredReports.map((report, index) => (
          <Table className={classes.table}>
            {index === 0 ? (
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={15}
              />
            ) : null}
            {selected && selected.length > 0 && (
              <SecondaryTableHead
                address={selected[index] ? selected[index].name : ""}
              />
            )}
            <TableBody className={classes.tableBody}>
              {stableSort(report, getComparator(order, orderBy))
                .filter(
                  (item) =>
                    item.partnerName && item.partnerName.includes(searchQuery)
                )
                .map((item, index) => (
                  <TableRow key={item.partnerId} className={classes.tableBody}>
                    <TableCell className={classes.tableCell} align="center">
                      {!!item.toolsCount
                        ? `
                         ${ConvertToArabicNumbers(
                           Math.abs(Math.round(item.toolsCount))
                         )}
                         ${Math.round(item.toolsCount) > 0 ? "عليه" : ""}
                      ${Math.round(item.toolsCount) < 0 ? "له" : ""}
                     ${item.totalInvoices === 0 ? "صفر" : ""}`
                        : ""}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {!!item.toolsMoney
                        ? `
                         ${ConvertToArabicNumbers(
                           Math.abs(Math.round(item.toolsMoney))
                         )}
                         ${Math.round(item.toolsMoney) > 0 ? "عليه" : ""}
                      ${Math.round(item.toolsMoney) < 0 ? "له" : ""}
                     ${item.toolsMoney === 0 ? "صفر" : ""}`
                        : ""}
                    </TableCell>
                    <TableCell
                      style={{ minWidth: 260 }}
                      className={classes.tableCell}
                      align="center"
                    >
                      {item.partnerName}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {!!item.trucksInvoices
                        ? `
                         ${ConvertToArabicNumbers(
                           Math.abs(Math.round(item.trucksInvoices))
                         )}
                         ${Math.round(item.trucksInvoices) > 0 ? "له" : ""}
                      ${Math.round(item.trucksInvoices) < 0 ? "عليه" : ""}
                     ${item.trucksInvoices === 0 ? "صفر" : ""}`
                        : ""}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {!!item.clientLoans
                        ? `
                         ${ConvertToArabicNumbers(
                           Math.abs(Math.round(item.clientLoans))
                         )}
                         ${Math.round(item.clientLoans) > 0 ? "عليه" : ""}
                      ${Math.round(item.clientLoans) < 0 ? "له" : ""}
                     ${item.clientLoans === 0 ? "صفر" : ""}`
                        : ""}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ))}
      </TableContainer>
    </>
  ) : (
    "لا يوجد بيانات لعرضها"
  );
}

import { Redirect, Route } from "react-router-dom";

import React from "react";
import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux";

//import auth from "../../services/authService";

const ProtectedRoute = ({ component: Component, roles, redirect, ...rest }) => {
  let block = localStorage.getItem("block");
  let authRoles =
    localStorage.getItem("token") &&
    jwtDecode(localStorage.getItem("token"))[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ];

  if (!Array.isArray(authRoles)) {
    authRoles = [authRoles];
  }

  const isAllowed = roles.some((role) => authRoles.includes(role));

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") && isAllowed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: block ? "/block" : !!redirect ? redirect : "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

// ProtectedRoute.defaultProps = {
//   roles: [],
// };

export default ProtectedRoute;

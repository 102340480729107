import React from "react";
import axios from "axios";
import Alerto from "../../components/UI/toaster";
import { Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";

function UserImg(props) {
  const [banner, setBanner] = React.useState("");
  const user = useSelector((state) => state.auth.user);

  const getBanner = () => {
    axios
      .get(`Security/User/${user.UserId}/image`)
      .then((res) => {
        setBanner(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  React.useEffect(() => {
    !!user && getBanner();
  }, [user]);

  return !!banner ? (
    <img
      style={{ width: "100%", height: "auto", boxShadow: " white 0px 0px 7px" }}
      src={`data:image/jpeg;base64,${banner}`}
      className="rounded-3"
      alt="صورة الشركة"
      {...props}
    />
  ) : (
    <Avatar className="avatar" />
  );
}

export default UserImg;

import React from "react";
import SellingTable from "../components/SellingTable";
import TransferSellingComp from "../components/TransferSellingComp";
import { MassEditControl } from "../components/massEditTable";
import { useState } from "react";
import { isSameDay } from "date-fns";
import Mydate from "../../../components/Helpers/MyDateParser";
import Alerto from "../../../components/UI/toaster";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { authRoles } from "../../../AppRouter";
import { useDispatch } from "react-redux";
import { GetWebDate } from "../../auth/authSlice";

function SalesTable({
  soldItems,
  onUpdate,
  onDelete,
  isSellerHidden,
  bgColor,
  truck,
  refresh,
}) {
  const [soldState, setSoldState] = useState(soldItems);
  const [showCombinedWeight, setShowCombinedWeight] = useState(false);
  const { truckId } = useParams();
  const role = localStorage.getItem("role");
  const isAuthorizedToReplace = authRoles.officer.includes(role);
  const [showMassEdit, setShowMassEdit] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetWebDate());
  }, []);
  function handleFilter(state) {
    const data = !!state
      ? soldItems?.filter((item) => {
          for (const [key, value] of Object.entries(state)) {
            if (!value) {
              continue;
            }
            if (key === "dateTime" && !isSameDay(new Date(item[key]), value)) {
              return false;
            }
            if (
              (typeof value !== "object" && item[key] !== value) ||
              item[key]?.id !== value?.id
            ) {
              return false;
            }
          }

          return true;
        })
      : soldItems;
    setSoldState(data);
  }
  useEffect(() => {
    if (soldItems?.[0]) {
      if (showMassEdit) {
        soldItems.forEach((newObj) => {
          let indx = soldState.findIndex((obj) => obj.id === newObj.id);
          if (indx >= 0) {
            soldState[indx] = newObj;
          }
        });
        setSoldState([...soldState]);
      } else setSoldState(soldItems);
    }
  }, [soldItems]);
  useEffect(() => {
    !showMassEdit && setSoldState(soldItems);
  }, [showMassEdit]);
  async function handleUpdate(state) {
    try {
      if (!soldState[0]) return;
      const data = {
        ids: soldState.map((ele) => ele.id),
        truckItemId: state.truckItem?.id ?? state.truckItem,
        partnerId: state.partner?.id ?? state.partner,
        movementToolId: state.movementTool?.id ?? state.movementTool,
        priceForUnitWeight: state.priceForUnitWeight,
        weight: state.weight,
        productsCount: state.productsCount,
        commision: state.commision,
        truckId: +truckId,
        invoiceTypeId: soldState[0].invoiceTypeId,
        dateTime: state.dateTime ? Mydate(state.dateTime) : "",
      };
      for (const key in data) {
        if (typeof data[key] === "number" && key !== "truckId") {
          data[key] = data[key].toString();
        }
      }

      await axios.put(
        `Selling/bulkUpdate?truckTypeId=${soldState[0].invoiceTypeId}`,
        data
      );
      toast.success("تم التعديل");
      refresh();
    } catch (e) {
      Alerto(e);
    }
  }

  return (
    <div>
      {!isSellerHidden && (
        <TransferSellingComp
          setShowCombinedWeight={setShowCombinedWeight}
          showCombinedWeight={showCombinedWeight}
          showEtraBtn={true}
          setShowMassEdit={setShowMassEdit}
          showMassEdit={showMassEdit}
          isAuthorizedToReplace={isAuthorizedToReplace}
        />
      )}
      {!!isAuthorizedToReplace && !!showMassEdit && (
        <MassEditControl
          handleFilter={handleFilter}
          handleUpdate={handleUpdate}
        />
      )}
      {isSellerHidden ? (
        <SellingTable
          bgColor={bgColor}
          soldItems={soldState}
          onUpdate={onUpdate}
          onDelete={onDelete}
          isSellerHidden={true}
          truck={truck}
          style={{ with: "100%" }}
        />
      ) : (
        <SellingTable
          showCombinedWeight={showCombinedWeight}
          soldItems={soldState}
          onUpdate={onUpdate}
          onDelete={onDelete}
          style={{ with: "100%" }}
        />
      )}
    </div>
  );
}

export default SalesTable;

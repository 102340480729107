import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import AppTextField from "../../../components/form/AppTextField";
import { Box } from "@material-ui/core";
import { Button } from "@material-ui/core";
import CustomDialog from "../../../components/CustomDialog";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { updateTool } from "../dataSlice";
import { toast } from "react-toastify";
import axios from "axios";
import Alerto from "../../../components/UI/toaster";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

function UpdateCrop(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  let ispass=props.ispassword&&!props.jobs
  let isjob=!props.ispassword&&props.jobs
  let defau=!props.ispassword&&!props.jobs
  return (
    <CustomDialog open={props.open} textAlign="center" onClose={props.onClose}>
      <Formik
        initialValues={{
          name: props.tool.name ? props.tool.name : "",
          costMortgage: props.tool.costMortgage ? props.tool.costMortgage : "",
          qte: props.tool.qte ? props.tool.qte : "",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("اجباري"),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (isjob) {
            axios
              .put(`Employees/job?id=${props.tool.id}&name=${values.name}`)
              .then((res) => {
                toast.success("تم التعديل بنجاح");
                props.update();
              })
              .catch((err) => {
                Alerto(err);
              });
            props.onClose();
          } else if (defau) {
            dispatch(
              updateTool({
                ...values,
                userId: user.UserId,
                id: props.tool.id,
              })
            );
            props.onClose();
          } else {
            props.update(values.name);
            resetForm();
          }
        }}
        enableReinitialize={true}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Box className={classes.root}>
              {ispass && (
                <AppTextField label="كلمة المرور" name="name" type="text" />
              )}
              {isjob && (
                <AppTextField label=" اسم الوظيفة" name="name" type="text" />
              )}

              {defau && (
                <AppTextField
                  onBlur={() => props.check(values.name)}
                  label="نوع العدة"
                  name="name"
                  type="text"
                />
              )}
              {defau && (
                <AppTextField
                  label="سعر رهن العدة"
                  name="costMortgage"
                  type="number"
                />
              )}
              {defau && (
                <AppTextField label="رصيد العدة" name="qte" type="number" />
              )}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ height: 40, width: 80 }}
              >
                حفظ
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
}

export default UpdateCrop;

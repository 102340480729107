import c from "./AddEmployee.module.scss";
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import AlertO from "../../components/UI/toaster";
import { useEffect, useRef, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { fetchPartners } from "../main-data/dataSlice";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import Alerto from "../../components/UI/toaster";
const AddEmployee = () => {
  const [paycheck, setpaycheck] = useState(true);
  const [paystate, setpaystate] = useState(true);
  const [partners, setpartners] = useState([]);
  const [partner, setpartner] = useState(null);

  const allpartners = useSelector((state) => state.data.partners);

  const { id } = useParams();

  useEffect(async () => {
    try {
      if (id > 0) {
        const res = await axios.get("/Employees");

        if (res.status === 200) {
          const person = await res.data.filter(
            (ele) => ele.partner.id === parseInt(id)
          );

          formik.values.salary = person[0].salary;
          formik.values.payday = person[0].salaryDay;
          setpartner(person[0].partner);
          setpaystate(person[0].fromCurrentMonth);
          setpaycheck(person[0].isActiveSalary);
        }
      }
    } catch (e) {
      Alerto(e);
    }
  }, []);

  useEffect(() => {
    const part = allpartners.filter(
      (ele) => ele.isEmployee === false && ele.isDeleted === false
    );
    setpartners(part);
  }, [allpartners]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPartners());
  }, []);

  const submitemoployee = async (values) => {
    try {
      const val = {
        partnerId: partner.id,
        salaryDay: values.payday,
        salary: values.salary,
        fromCurrentMonth: paystate,
        isActiveSalary: paycheck,
      };
      if (id) {
        const res = await axios.put(`Employees`, val);

        if (res.status === 200) {
          toast.success("تم التعديل");
        }
      } else {
        const res = await axios.post("Employees", val);

        if (res.status === 200) {
          toast.success("تمت الاضافة");
        }
      }
    } catch (e) {
      AlertO(e);
    }
  };
  const formik = useFormik({
    initialValues: { salary: "", payday: 1 },
    validationSchema: Yup.object({
      payday: Yup.number()
        .min(1, "يوم الدفع يجب ان يكون اكبر من 0")
        .max(28, "يوم الدفع يجب ان يكون اصغر من 29")
        .required("يوم الدفع اجباري"),
      salary: Yup.number().required("المرتب اجباري"),
    }),
    onSubmit: (values) => {
      submitemoployee(values);
    },
  });
  return (
    <>
      <Box
        component="main"
        sx={{
          minHeight: "80%",
        }}
        className="mt-5"
      >
        <Container maxWidth="sm">
          <form
            className="regholder"
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
            }}
            onSubmit={formik.handleSubmit}
          >
            <Typography
              color="textPrimary"
              className="my-3 text-center"
              variant="h4"
            >
              أضف موظف
            </Typography>
            <Autocomplete
              options={partners}
              getOptionSelected={(option, value) => {
                return option.value === value?.value;
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              autoHighlight
              autoComplete
              noOptionsText="لم يتم العثور على خيارات"
              name="partner"
              onChange={(event, value) => {
                if (value) {
                  setpartner(value);
                } else {
                  setpartner("");
                }
              }}
              disabled={id}
              value={partner}
              renderInput={(params) => (
                <TextField
                  error={Boolean(
                    formik.touched.partner && formik.errors.partner
                  )}
                  helperText={formik.touched.partner && formik.errors.partner}
                  {...params}
                  label="الاسم"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
            <TextField
              error={Boolean(formik.touched.salary && formik.errors.salary)}
              fullWidth
              helperText={formik.touched.salary && formik.errors.salary}
              label="مرتب"
              margin="normal"
              name="salary"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="number"
              value={formik.values.salary}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.payday && formik.errors.payday)}
              fullWidth
              helperText={formik.touched.payday && formik.errors.payday}
              margin="normal"
              label="يوم الدفع"
              name="payday"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="number"
              value={formik.values.payday}
              variant="outlined"
            />

            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={paystate}
              onChange={() => setpaystate(!paystate)}
              className={c.radio}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="الشهر الجاري"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="الشهر القادم"
              />
            </RadioGroup>
            <RadioGroup
              className={c.radio}
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={paycheck}
              onChange={() => setpaycheck(!paycheck)}
            >
              <FormControlLabel value={true} control={<Radio />} label="مفعل" />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="معطل"
              />
            </RadioGroup>
            <Box sx={{ py: 2 }}>
              <Button
                style={{ backgroundColor: "#0C9BBD", color: "#fff" }}
                fullWidth
                size="large"
                variant="outlined"
                disabled={!partner}
                type="submit"
              >
                {id ? "تعديل" : "تسجيل"}
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </>
  );
};
export default AddEmployee;

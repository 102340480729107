import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRemainingDetailsForPartners } from "../../../../features/movement-tools/tools-reports/toolsSlice";
import { authRoles } from "../../../../AppRouter";

export default function useToolsInfo() {
  const [isOpen, setIsOpen] = useState(false);
  const role = localStorage.getItem("role");
  const claims = useSelector((state) => state.auth.claims);
  const isAuthorizedToAdd =
    authRoles.officer.includes(role) ||
    claims.find((ele) => ele.rootOrder === 6 && ele.rowOrderInTheRoot === 2)
      ?.adding?.checked;

  const dispatch = useDispatch();
  function onDetailsClicked(partnerId) {
    setIsOpen(true);
    dispatch(getRemainingDetailsForPartners(partnerId));
  }
  return { isOpen, setIsOpen, onDetailsClicked, isAuthorizedToAdd };
}

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { ConvertToArabicNumbers } from "../../../../utils";
import CustomDialog from "../../../../components/CustomDialog";
import React from "react";
import useRemainingDetails from "./modal";
export default function NewRemainingDetails({
  open,
  onClose,
  rows,
  partnerId,
  showAddUi,
}) {
  const { submitChanges, setInpVs, inpVs } = useRemainingDetails({
    onClose,
    partnerId,
  });
  return (
    <CustomDialog
      action1={
        showAddUi ? (
          <Button
            variant="contained"
            color="primary"
            onClick={submitChanges}
            size="small"
          >
            اتمام
          </Button>
        ) : (
          ""
        )
      }
      open={open}
      textAlign="center"
      onClose={onClose}
    >
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography style={{ fontWeight: 600, fontSize: 16 }}>
                  العدة
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography style={{ fontWeight: 600, fontSize: 16 }}>
                  العدد
                </Typography>
              </TableCell>
              {showAddUi && (
                <TableCell align="center">
                  <Typography style={{ fontWeight: 600, fontSize: 16 }}>
                    تحصيل
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, indx) => (
              <TableRow key={row.tool.id}>
                <TableCell align="center">{row.tool.name}</TableCell>
                <TableCell align="center">
                  {ConvertToArabicNumbers(row.count)}
                </TableCell>
                {showAddUi && (
                  <TableCell align="center">
                    <TextField
                      onChange={({ target }) =>
                        setInpVs((prev) => ({
                          ...prev,
                          [indx]: { count: target.value, toolId: row.tool.id },
                        }))
                      }
                      value={inpVs[indx]?.count || ""}
                      label="العدد"
                      size="small"
                      variant="outlined"
                      style={{ width: 80 }}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomDialog>
  );
}

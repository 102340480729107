import { ConvertToArabicNumbers } from '../../utils';
import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { style } from '@mui/system';

const useStyles = makeStyles((theme) => ({
  total: {
    borderRadius: 20,
    marginTop: 10,
    padding: 8,
    backgroundImage:
      'linear-gradient(to right top, #ffee58, #ffef60, #fff068, #fff06f, #fff176)',
    width: 'fit-content',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
}));

function Total({ label, value }) {
  const classes = useStyles();
  return (
    <Typography className="bg-global center mt-4 py-4 px-3" style={{fontSize:20,height:60}} >
      {label}: {ConvertToArabicNumbers(value)}
    </Typography>
  );
}

Total.defaultProps = {
  label: 'الاجمالي',
};

export default Total;

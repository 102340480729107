import { ConvertToArabicNumbers, getWeekDay, renderDate } from "../../../utils";
import { Paper, Typography } from "@material-ui/core";

import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
const handleNumbers = (num, removeNegative, reverse, disableLabel) => {
  return (
    <div>
      {removeNegative
        ? ConvertToArabicNumbers(Math.round(Math.abs(num)))
        : ConvertToArabicNumbers(Math.round(num))}
      {"    "}
      {removeNegative &&
        !disableLabel &&
        num != 0 &&
        (num > 0 ? (!reverse ? "دائن" : "مدين") : !reverse ? "مدين" : "دائن")}
    </div>
  );
};
const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  inc: {
    backgroundColor: "#C8C8C8",
    fontWeight: "bold",
  },
  exp: {
    backgroundColor: "#DB232D",
    fontWeight: "bold",
    color: "white",
  },
  tableCell: {
    border: "2px solid #777 ",
  },
});

export default function ProfitsTable({
  headCells,
  profits,
  type,
  dailyDate,
  removeNegative,
  fromTreasury,
}) {
  const months = [
    "يناير",
    "فبراير",
    "مارس",
    "ابريل",
    "مايو",
    "يونيو",
    "يوليو",
    "اغسطس",
    "سبتمبر",
    "اكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];

  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead style={{ backgroundColor: "#F3C637" }}>
          <TableRow>
            {headCells.map((headCell, index) => (
              <TableCell align="center" key={index}>
                {" "}
                <Typography style={{ fontWeight: 600, color: "#black" }}>
                  {headCell}{" "}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {profits.map((row, index) => (
            <TableRow key={index}>
              {type !== "general" && (
                <TableCell
                  className={classes.tableCell}
                  style={{ fontSize: 16, minWidth: 180 }}
                  align="center"
                >
                  {type === "annual" && ConvertToArabicNumbers(row.key)}
                  {type === "daily" &&
                    ConvertToArabicNumbers(
                      renderDate(
                        new Date(
                          dailyDate.getFullYear(),
                          dailyDate.getMonth(),
                          row.key
                        )
                      )
                    )}
                  {type === "monthly" && months[row.key - 1]}
                </TableCell>
              )}
              {!fromTreasury && (
                <TableCell className={classes.tableCell} align="center">
                  {handleNumbers(row.sellingsCount, removeNegative)}
                </TableCell>
              )}
              {!fromTreasury && (
                <TableCell className={classes.tableCell} align="center">
                  {handleNumbers(row.totalSellingsMoney, removeNegative)}
                </TableCell>
              )}
              {!fromTreasury && (
                <TableCell className={classes.tableCell} align="center">
                  {handleNumbers(row.totalBuyingsMoney, removeNegative)}
                </TableCell>
              )}
              {row.totalTrucksInvoicesMoney != undefined && !fromTreasury && (
                <TableCell className={classes.tableCell} align="center">
                  {handleNumbers(row.totalTrucksInvoicesMoney, removeNegative)}
                </TableCell>
              )}
              <TableCell className={classes.tableCell} align="center">
                {handleNumbers(row.totalDepositsMoney, removeNegative)}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {handleNumbers(row.totalExpensesMoney, removeNegative, true)}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {handleNumbers(
                  fromTreasury
                    ? row.totalDepositsMoney - row.totalExpensesMoney
                    : row.totalDamagesMoney,
                  !fromTreasury,
                  false,
                  !fromTreasury
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

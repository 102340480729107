import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { indexOfId, sortAlphabetically } from "../../utils";

import axios from "axios";
import { toast } from "react-toastify";
import Alert from "@material-ui/lab/Alert";
import Alerto from "../../components/UI/toaster";

export const fetchPartners = createAsyncThunk(
  "data/fetchPartners",
  async () => {
    try {
      const response = await axios.get("partners/light/active");
      return response.data;
    } catch (error) {
      Alert(error);
      return error.response.data;
    }
  }
);

export const addPartner = createAsyncThunk(
  "data/addPartner",
  async (data, { rejectWithValue, getState }) => {
    let fd = new FormData();
    fd.append("Name", data.Name);
    fd.append("OpeningBalance", data.OpeningBalance);
    fd.append("AdderId", getState().auth.user.UserId);
    try {
      const response = await axios.post("/Partners/client", fd);
      return response.data;
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data);
        return rejectWithValue(error.response.data);
      }
      toast.error("لقد حدث خطأ ما");
      return rejectWithValue(error);
    }
  }
);

export const addBuyer = createAsyncThunk(
  "data/addBuyer",
  async (data, { rejectWithValue, getState }) => {
    try {
      const res = await axios.get(`/Partners/isClient/${data.name}`);

      if (res.data.id) {
        const res2 = await axios.put(`/Partners/toClientBuyer/${res.data.id}`);
        if (res2.data) {
          toast.success("تمت الاضافة");
          return res2.data;
        }
      } else {
        let fd = new FormData();
        fd.append("Name", data.Name);
        fd.append("OpeningBalance", data.OpeningBalance);
        fd.append("PayementTypeId", data.PayementTypeId);
        fd.append("BalanceAlert", data.BalanceAlert);
        data.collectingAddress &&
          fd.append("CollectingAddressId", data.collectingAddress?.id);
        fd.append("AdderId", getState().auth.user.UserId);
        const res3 = await axios.post("/partners/buyer", fd);

        if (res3.data) {
          toast.success("تمت الاضافة");
          return res3.data;
        }
      }
    } catch (e) {
      Alerto(e);
      return rejectWithValue(e.response);
    }
  }
);

export const deletePartner = createAsyncThunk(
  "data/deletePartner",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/Partners/${id}`);
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data);
        return rejectWithValue(error.response.data);
      }
      toast.error("لقد حدث خطأ ما");
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchClients = createAsyncThunk("data/fetchClients", async () => {
  try {
    const response = await axios.get("/Partners/GetClientPartners");
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const getPartnersAllData = createAsyncThunk(
  "data/getPartnersAllData",
  async () => {
    try {
      const response = await axios.get("Partners/GetDataAll");
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchBuyers = createAsyncThunk("data/fetchBuyers", async () => {
  try {
    const response = await axios.get("/Partners/GetBuyars");
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const fetchCrops = createAsyncThunk("data/fetchCrops", async () => {
  try {
    const response = await axios.get("/Products");

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const addCrop = createAsyncThunk(
  "data/addCrop",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post("/Products", {
        ...data,
        adderId: getState().auth.user.UserId,
      });
      return response.data;
    } catch (error) {
      toast.error(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCrop = createAsyncThunk(
  "data/updateCrop",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.put(`/Products/${data.id}`, {
        id: data.id,
        ...data.product,
        userId: getState().auth.user.UserId,
      });
      return response.data;
    } catch (error) {
      toast.error(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCrop = createAsyncThunk("data/deleteCrop", async (id) => {
  try {
    const response = await axios.delete(`/Products/${id}`);
    return response.data;
  } catch (error) {
    toast.error(error.response.data);
    return error.response.data;
  }
});

export const fetchTools = createAsyncThunk("data/fetchTools", async () => {
  try {
    const response = await axios.get("/MovementTools");
    return response.data;
  } catch (error) {
    toast.error(error.response.data);
    return error.response.data;
  }
});

export const addTool = createAsyncThunk("data/addTool", async (data) => {
  try {
    const response = await axios.post("/MovementTools", {
      costMortgage: data.costMortgage > 0 ? data.costMortgage : 0,
      name: data.name.trim(),
      qte: data.qte > 0 ? data.qte : 0,
      AdderId: data.AdderId,
    });
    if (response.status === 200) {
      toast.success("تم اضافة العدة ");
      return response.data;
    }
  } catch (error) {
    throw new Error("");
  }
});

export const updateTool = createAsyncThunk(
  "data/updateTool",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.put(`MovementTools/${data.id}`, {
        costMortgage: data.costMortgage > 0 ? data.costMortgage : 0,
        name: data.name.trim(),
        qte: data.qte > 0 ? data.qte : 0,
        userId: data.userId,
      });
      if (res.status === 200) {
        toast.success("تم التعديل");
        return res.data;
      }
    } catch (e) {
      Alerto(e);
      return rejectWithValue(e.response);
    }
  }
);

export const deleteTool = createAsyncThunk("data/deleteTool", async (id) => {
  try {
    const response = await axios.delete(`/MovementTools/${id}`);
    return response.data;
  } catch (error) {
    toast.error(error.response.data);
    return error.response.data;
  }
});

export const getBuyersArrears = createAsyncThunk(
  "data/getBuyersArrears",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/Laters/latter/buyers");
      return response.data;
    } catch (error) {
      toast.error(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  partners: [],
  allPartnersData: [],
  clients: [],
  buyers: [],
  crops: [],
  tools: [],
  buyersArrears: [],
  targetPartner: {},
  arrearsError: "",
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setTargetPartner: (state, { payload }) => {
      state.targetPartner = payload;
    },
  },
  extraReducers: {
    [fetchPartners.fulfilled]: (state, action) => {
      state.partners = sortAlphabetically(action.payload);
    },

    [fetchClients.fulfilled]: (state, action) => {
      state.clients = sortAlphabetically(action.payload);
    },
    [getPartnersAllData.fulfilled]: (state, action) => {
      state.allPartnersData = sortAlphabetically(action.payload);
    },

    [addPartner.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        state.clients.unshift(action.payload);
      }
    },

    [addBuyer.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        state.buyers.unshift(action.payload);
      }
    },

    [fetchBuyers.fulfilled]: (state, action) => {
      state.buyers = sortAlphabetically(action.payload);
    },

    [fetchCrops.fulfilled]: (state, action) => {
      state.crops = sortAlphabetically(action.payload);
    },

    [addCrop.fulfilled]: (state, action) => {
      state.crops.unshift(action.payload);
    },

    [addCrop.rejected]: (state) => {
      state.crops.pop();
    },
    [deleteCrop.fulfilled]: (state, action) => {
      state.crops = state.crops.filter((crop) => {
        return crop.id !== action.meta.arg;
      });
    },

    [updateCrop.fulfilled]: (state, action) => {
      const index = indexOfId(state.crops, action.meta.arg.id);
      state.crops[index] = action.meta.arg.product;
      state.crops[index].id = action.meta.arg.id;
    },

    [fetchTools.fulfilled]: (state, action) => {
      state.tools = sortAlphabetically(action.payload);
    },

    [addTool.fulfilled]: (state, action) => {
      state.tools.unshift(action.payload);
    },

    [addTool.rejected]: (state) => {
      toast.error("العدة موجودة بالفعل");
    },

    [deleteTool.fulfilled]: (state, action) => {
      toast.error("تم الحذف");
      state.tools = state.tools.filter((tool) => {
        return tool.id !== action.meta.arg;
      });
    },

    [updateTool.fulfilled]: (state, action) => {
      const index = indexOfId(state.tools, action.meta.arg.id);
      state.tools[index] = action.meta.arg;
    },

    [getBuyersArrears.fulfilled]: (state, action) => {
      state.buyersArrears = action.payload;
    },
    [getBuyersArrears.rejected]: (state, action) => {
      state.arrearsError = action.payload;
    },
  },
});
export const { setTargetPartner } = dataSlice.actions;

export default dataSlice.reducer;

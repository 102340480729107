import { ConvertToArabicNumbers, renderDate } from '../../../utils';
import { IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import CustomizedTable from '../../../components/Table';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Link } from 'react-router-dom';
import React from 'react';
import { parseISO } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {},
  info: {
    color: theme.palette.info.light,
  },
}));
function SearchDamages({ items }) {
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 16,
      overflow: 'auto',
    },
  }))(TableCell);
  const classes = useStyles();
  return (
    <div style={{ maxWidth: 600, margin: ' 32px auto' }}>
      {items && items.length > 0 && (
        <CustomizedTable
          tableHeader={[
            'التاريخ',
            'العميل او البائع ',
            'العدد',
            'القيمة',
            'التفاصيل',
          ]}
        >
          {items.map((item, index) => (
            <TableRow key={index} align="center" className={classes.tableBody}>
              <StyledTableCell align="center">
                <Typography variant="body1" color="textPrimary">
                  {ConvertToArabicNumbers(renderDate(parseISO(item.dateTime)))}
                </Typography>
              </StyledTableCell>
              {item.partner && item.partner.name ? (
                <StyledTableCell align="center">
                  <Typography variant="body1" color="textPrimary">
                    {item.partner?.name}
                  </Typography>
                </StyledTableCell>
              ) : (
                <StyledTableCell align="center"> المحل </StyledTableCell>
              )}

              <StyledTableCell align="center">
                <Typography variant="body1" color="textPrimary">
                  {item.count !== 0
                    ? ConvertToArabicNumbers(item.count)
                    : 'ﻻ يوجد'}
                </Typography>
              </StyledTableCell>

              {item.money > 0 ? (
                <StyledTableCell align="center">
                  <Typography variant="body1" color="textPrimary">
                    {ConvertToArabicNumbers(item.money)}
                  </Typography>
                </StyledTableCell>
              ) : (
                <StyledTableCell align="center">_</StyledTableCell>
              )}
              <TableCell align="center" style={{ width: '32px' }}>
                <IconButton
                  component={Link}
                  to={`/damage-tools/${item.id}`}
                  className={classes.info}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </CustomizedTable>
      )}
    </div>
  );
}

export default SearchDamages;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { ConvertToArabicNumbers } from '../../../utils';
import { Paper } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  table: {
    borderSpacing: '8px 4px',
    border: '1px solid #222',
  },
  tableRow: {
    marginTop: 8,
  },
  tableCell: {
    marginTop: 8,
    width: 80,
    border: '1px solid #222',
    padding: 4,
  },
}));

export default function ClientsAddresses({ reports, addresses }) {
  const filteredReports = reports.map((report) => {
    return report.filter((item) => {
      return (
        item.toolsCount !== 0 ||
        item.toolsMoney !== 0 ||
        item.trucksInvoices !== 0 ||
        item.clientLoans !== 0
      );
    });
  });

  const classes = useStyles();
  const mainHeadCells = [
    { label: 'عدد', id: 'toolsCount' },
    { label: 'رهن', id: 'toolsMoney' },
    { label: 'الاسم', id: 'partnerName' },
    { label: 'فواتير نقلات', id: 'trucksInvoices' },
    { label: 'عملات مقدمة', id: 'clientLoans' },
  ];

  function SecondaryTableHead({ address }) {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            style={{ fontWeight: 'bold', padding: 0, height: 16 }}
            align="center"
            colSpan={5}
          >
            {address}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function MainTableHead() {
    return (
      <TableHead>
        <TableRow>
          {mainHeadCells.map((headCell, index) => (
            <TableCell
              style={{ fontWeight: 'bold' }}
              key={index}
              align="center"
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <TableContainer component={Paper}>
      {filteredReports.map((report, index) => (
        <Table key={index} className={classes.table}>
          {index === 0 ? <MainTableHead /> : null}
          {addresses && addresses.length > 0 && (
            <SecondaryTableHead
              address={addresses[index] ? addresses[index].name : ''}
            />
          )}
          {report.map((item, index) => (
            <TableBody key={index} className={classes.tableBody}>
              <TableRow key={item.partnerId}>
                <TableCell className={classes.tableCell} align="center">
                  {!!item.toolsCount
                    ? ConvertToArabicNumbers(item.toolsCount)
                    : ''}
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  {!!item.toolsMoney
                    ? ConvertToArabicNumbers(item.toolsMoney)
                    : ''}
                </TableCell>
                <TableCell
                  style={{ width: 240 }}
                  className={classes.tableCell}
                  align="center"
                >
                  {ConvertToArabicNumbers(item.partnerName)}
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  {!!item.trucksInvoices
                    ? ConvertToArabicNumbers(item.trucksInvoices)
                    : ''}
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  {!!item.clientLoans
                    ? ConvertToArabicNumbers(item.clientLoans)
                    : ''}
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      ))}
    </TableContainer>
  );
}

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  TablePagination,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import LogoImage from "../../../components/LogoImage";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import Total from "../../../components/UI/Total";
import { useReactToPrint } from "react-to-print";
import ToolsInfo from "../../../components/ToolsForm/partials/ToolsInfo";
import { useEffect } from "react";
import { ConvertToArabicNumbers } from "../../../utils";
const rowsPerPage = 100;
const useStyles = makeStyles((theme) => ({
  total: {
    display: "none",
  },
  info: {
    color: "#999",
  },
  logo: { display: "none" },
  "@media print": {
    total: {
      display: "flex",
      width: 400,
      justifyContent: "space-between",
      margin: 8,
    },
    printIcon: {
      display: "none",
    },
    printHidden: {
      display: "none",
    },
    logo: { display: "block" },
  },
}));
function Report({ items, reportName, hasDetails, total, searchQuery }) {
  const [isPrint, setIsPrint] = useState(false);
  const [page, setPage] = useState(0);
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    onBeforePrint: () => setIsPrint(true),
    content: () => componentRef.current,
    onAfterPrint: () => setIsPrint(false),
  });
  useEffect(() => {
    isPrint && handlePrint();
  }, [isPrint]);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.textPrimary,
      fontSize: 18,
    },
    body: {
      fontSize: 16,
      overflow: "auto",
    },
  }))(TableCell);

  const classes = useStyles();
  let headCells = !isPrint
    ? ["العميل", "العدد", "التفاصيل"]
    : ["العميل", "العدد"];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const slicedItems = !!isPrint
    ? items
    : items.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  return (
    <div className="w-100">
      {items && items.length > 0 ? (
        <TableContainer
          ref={componentRef}
          component={Paper}
          style={{ width: "100%" }}
        >
          <div className={classes.logo}>
            <LogoImage />
          </div>
          <Toolbar
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "rgb(243 198 55)",
            }}
          >
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {reportName}
            </Typography>

            <div className={classes.printIcon}>
              <Tooltip title="طباعة التقرير">
                <IconButton
                  onClick={() => {
                    handlePrint();
                    setIsPrint(false);
                  }}
                >
                  <PrintOutlinedIcon className="text-black" />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((item, index) => (
                  <StyledTableCell
                    style={{ border: "2px solid" }}
                    key={index}
                    align="center"
                  >
                    {item}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {slicedItems.map((item, index) => (
                <TableRow
                  key={index}
                  align="center"
                  className="hover-row"
                  style={{ border: "2px solid" }}
                >
                  <StyledTableCell
                    align="center"
                    className="p-0 m-0"
                    style={{ border: "2px solid" }}
                  >
                    {item.partner?.name}
                  </StyledTableCell>

                  <StyledTableCell
                    align="center"
                    className="p-0 m-0"
                    style={{ border: "2px solid" }}
                  >
                    {ConvertToArabicNumbers(Math.abs(Math.round(item.count)))}{" "}
                    {item.count > 0 ? "عليه" : ""}
                    {item.count < 0 ? "له" : ""}
                    {item.count === 0 ? "صفر" : ""}
                  </StyledTableCell>
                  {!isPrint ? (
                    <StyledTableCell
                      align="center"
                      className="p-0  "
                      style={{ border: "2px solid" }}
                    >
                      <ToolsInfo partnerId={item.partner.id} />
                    </StyledTableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {!isPrint && (
            <TablePagination
              count={items.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[]}
              labelDisplayedRows={({ from, to, count }) => (
                <p>
                  {from}-{to} of {count}
                </p>
              )}
            />
          )}
          {isPrint ? (
            <div className={classes.toatal}>
              <Total value={total} />
            </div>
          ) : (
            ""
          )}
        </TableContainer>
      ) : (
        ""
      )}
    </div>
  );
}

Report.defaultProps = {
  hasDetails: false,
};

export default Report;

import { Button, TextField } from "@material-ui/core";
import React, { useState } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomDialog from "../../../components/CustomDialog";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { updateInvoiceItem } from "../invoiceSlice";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

function EditInvoiceItem({ item, open, onClose, truck }) {
  const [truckItem, setTruckItem] = useState("");
  const [itemsCount, setItemsCount] = useState("");
  const [weight, setWeight] = useState("");
  const [price, setPrice] = useState("");
  const [owner, setOwner] = useState({});
  const [ownerName, setOwnerName] = useState("");
  const [sharedPartners, setSharedPartners] = useState([]);

  async function fetchSharedpartners() {
    try {
      const res = await axios.get(`Truck/GetSharedPartners/${truck?.id}`);
      setSharedPartners(res.data);
    } catch (e) {
      console.log(e);
    }
  }
  const dispatch = useDispatch();

  const [products, setProducts] = useState([]);
  const getProducts = () => {
    axios.get(`/Truck/products/${truck.id}`).then((res) => {
      setProducts(res.data);
    });
  };
  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      getProducts();
      fetchSharedpartners();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const onUpdateClicked = () => {
    dispatch(
      updateInvoiceItem({
        id: item.id,
        invoiceId: item.invoiceId,
        item: {
          id: truckItem.truckItemId,
          product: { id: truckItem.productId, name: truckItem.productName },
        },
        itemCount: itemsCount,
        weight: weight,
        priceForUnitWeight: price,
        sharedPartnerId: owner.id,
      })
    ).then(() => {
      onClose();
    });
  };
  const classes = useStyles();

  React.useEffect(() => {
    if (item && item.item) {
      setTruckItem({
        productName: item.item.product.name,
        truckItemId: item.item.id,
        productId: item.item.product.id,
      });
      setItemsCount(item.itemCount);
      setPrice(item.priceForUnitWeight);
      setWeight(item.weight);
      setOwner(item.partner);
    }
  }, [item.itemCount, item.priceForUnitWeight, item.weight, item]);
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      action1={
        <Button
          onClick={onClose}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ width: 56, height: 40 }}
        >
          الغاء
        </Button>
      }
      action2={
        <Button
          variant="contained"
          color="secondary"
          style={{ width: 56, height: 40, marginLeft: 16 }}
          size="small"
          onClick={onUpdateClicked}
        >
          تعديل
        </Button>
      }
    >
      {products.length >= 0 && item.id && (
        <form className={classes.root} noValidate autoComplete="off">
          {!!truck.sharedTruck && (
            <Autocomplete
              options={sharedPartners}
              getOptionSelected={(option, value) =>
                option.value === value?.value
              }
              getOptionLabel={(option) => (option.name ? option.name : "")}
              autoHighlight
              style={{ width: 180, display: "inline-block" }}
              value={owner}
              onChange={(e, newValue) => {
                if (newValue) {
                  setOwner(newValue);
                } else {
                  setOwner("");
                }
              }}
              onInputChange={(e, v) => {
                setOwnerName(v);
              }}
              inputValue={ownerName}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  {...params}
                  label="عميل مشترك"
                  variant="outlined"
                  size="small"
                  className="w-100"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          )}
          <Autocomplete
            options={products}
            getOptionSelected={(option, value) => option.value === value?.value}
            getOptionLabel={(option) => {
              return option.productName ? option.productName : "";
            }}
            style={{ width: 180, display: "inline-block" }}
            onChange={(e, value) => {
              if (value) {
                setTruckItem(value);
              }
            }}
            value={truckItem}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                {...params}
                label="اسم الصنف"
                variant="outlined"
                size="small"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            )}
          />

          <TextField
            label="العدد"
            variant="outlined"
            size="small"
            type="number"
            style={{ width: 120 }}
            onChange={(e) => setItemsCount(Number.parseFloat(e.target.value))}
            value={itemsCount >= 0 ? itemsCount : ""}
          />

          <TextField
            label="الوزن"
            variant="outlined"
            size="small"
            type="number"
            style={{ width: 120 }}
            onChange={(e) => setWeight(Number.parseFloat(e.target.value))}
            value={weight >= 0 ? weight : ""}
          />

          <TextField
            label="السعر"
            variant="outlined"
            size="small"
            style={{ width: 120 }}
            type="number"
            onChange={(e) => setPrice(Number.parseFloat(e.target.value))}
            value={price >= 0 ? price : ""}
          />
        </form>
      )}
    </CustomDialog>
  );
}

export default EditInvoiceItem;

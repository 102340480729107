import { TableCell, TableRow, Typography } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
function extractValue(obj, orderBy) {
  switch (orderBy) {
    case "partner":
    case "movementTool":
      return obj[orderBy]?.name;
    case "truckItem":
      return obj[orderBy]?.product?.name;
    case "dateTime":
      return new Date(obj[orderBy]);
    case "customTotal":
      return (
        obj.priceForUnitWeight * obj.weight + obj.productsCount * obj.commision
      );
    case "Uweight":
      return obj.weight / obj.productsCount;
    default:
      return obj[orderBy];
  }
}
function descendingComparator(a, b, orderBy) {
  if (extractValue(b, orderBy) < extractValue(a, orderBy)) {
    return -1;
  }
  if (extractValue(b, orderBy) > extractValue(a, orderBy)) {
    return 1;
  }
  return 0;
}
export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => -descendingComparator(a, b, orderBy)
    : (a, b) => descendingComparator(a, b, orderBy);
}
export function profileGetComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

export function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, condition, dual, cells, isPrint } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: "#F3C637" }}>
      <TableRow>
        {(dual ? (condition ? cells[0] : cells[1]) : cells)
          .filter((ele) => (isPrint ? ele.id != 0 : ele))
          .map((headCell, index) => (
            <TableCell
              key={index}
              style={{ fontWeight: "bold", fontSize: 14, padding: "10px" }}
              align="center"
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.id ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  style={{ color: "black" }}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

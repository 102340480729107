import { ConvertToArabicNumbers, renderDate } from "../../../utils";
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { parseISO } from "date-fns";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: 8,
    maxHeight: "90vh",
    overflow: "auto",
  },
  tableRow: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#E3EEFA",
    },
  },
}));

function TrucksList({ trucks, onInvoiceSelected, date, showSharedTrucks }) {
  const [selectedInvoice, setSelectedInvoice] = React.useState("");

  const classes = useStyles();
  const headCells = ["تاريخ النقلة", "رقم النقلة", "النوع"];
  !!showSharedTrucks && headCells.push("ممثل النقلة");
  return (
    <div className={classes.root}>
      <TableContainer
        className="overflow-hidden m-auto"
        component={Paper}
        sx={{ width: "100% " }}
      >
        <Table sx={{ width: "100% " }}>
          <TableHead
            style={{
              backgroundColor: "#fed049",
              height: "67px",
            }}
          >
            <TableRow>
              {headCells.map((headCell, index) => (
                <TableCell
                  key={index}
                  align="center"
                  className="headerTableFont"
                >
                  {headCell}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {trucks
              .filter((row) => {
                if (date) {
                  return (
                    parseISO(row.truckDate).getFullYear() ===
                      date.getFullYear() &&
                    parseISO(row.truckDate).getMonth() === date.getMonth() &&
                    parseISO(row.truckDate).getDate() === date.getDate()
                  );
                } else {
                  return row;
                }
              })
              .map((row, index) => (
                <TableRow
                  style={
                    selectedInvoice === row.truckId
                      ? { backgroundColor: "#E3EEFA" }
                      : {}
                  }
                  onClick={() => {
                    if (selectedInvoice !== row.truckId) {
                      onInvoiceSelected(row);
                      setSelectedInvoice(row.truckId);
                    } else {
                      setSelectedInvoice("");
                    }
                  }}
                  className={classes.tableRow}
                  key={index}
                >
                  <TableCell align="center" className="headerTableFont">
                    {row.truckDate
                      ? ConvertToArabicNumbers(
                          renderDate(parseISO(row.truckDate))
                        )
                      : ""}
                  </TableCell>
                  <TableCell align="center" className="headerTableFont">
                    {ConvertToArabicNumbers(row.truckNumberForClientAndDay)}
                  </TableCell>
                  <TableCell align="center" className="headerTableFont">
                    {row.truckTypeName}
                  </TableCell>
                  {showSharedTrucks && (
                    <TableCell align="center" className="headerTableFont">
                      <Link
                        href={`/client-profile/${row.rep?.id}`}
                        target="_blank"
                      >
                        {row.rep?.name}
                      </Link>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {/* <Pagination className='m-auto w-50 d-flex justify-content-between ' style={{direction:'rtl !important'}}>
          <Pagination.Prev />
          <Pagination.Item active>{1}</Pagination.Item>
          <Pagination.Item>{10}</Pagination.Item>
          <Pagination.Item>{11}</Pagination.Item>
          <Pagination.Item>{13}</Pagination.Item>
          <Pagination.Item>{13}</Pagination.Item>
          <Pagination.Ellipsis />
          <Pagination.Next />
        </Pagination> */}
      </TableContainer>
    </div>
  );
}

export default TrucksList;

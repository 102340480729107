import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,

} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';

// # بيانات الشركة
// اسم الشركة
// اسم المسئول
// وصف الشركة
// كود الشركة
// رقم الهاتف 4
// المحافظة
// المدينة
// العنوان
// لوجو الشركة

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 18,
    fontWeight: 500,
  },

  fileInput: {
    borderBottom: '4px solid lightgray',
    borderRight: '4px solid lightgray',
    borderTop: '1px solid black',
    borderLeft: '1px solid black',
    padding: 10,
    margin: 15,
    cursor: 'pointer',
  },

  imgPreview: {
    textAlign: 'center',
    margin: '5px 15px',
    height: 180,
    width: 600,
    borderLeft: '1px solid #DDD',
    borderRight: '1px solid #DDD',
    borderTop: '3px solid #DDD',
    borderBottom: '3px solid #DDD',
    overflow: 'none',
  },

  img: {
    width: '100%',
    height: '100%',
  },

  previewText: {
    width: '100%',
    marginTop: 20,
  },

  submitButton: {
    marginLeft: 10,
    height: 40,
    backgroundColor: '#2F9DDD',
    cursor: 'pointer',
    opacity: 0.8,
    '&:hover': {
      opacity: 1,
      backgroundColor: '#2F9DDD',
    },
  },

  centerText: {
    textAlign: 'center',
    width: 500,
  },
}));

export const CompanyProfile = (props) => {
  const [values, setValues] = useState({
    companyName: '',
    managerCompany: '',
    descriptionCompany: '',
    codeCompany: '',
    mobile: '',
    governorate: '',
    city: '',
    address: ' ',
  });

  const [file, setFile] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [logoPicture, setLogoPicture] = useState('');

  const getLogo = () => {
    axios
      .get('/CompanyProfiles/GetImages/1')
      .then((res) => {
        setLogoPicture(res.data);
      })
      .catch((err) => {
     
      });
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let f = e.target.files[0];

    reader.onloadend = () => {
      setFile(f);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(f);
  };

  const [logoImg, setLogoImg] = useState('');

  const handleImageUpload = (e) => {
    e.preventDefault();
   
    const fd = new FormData();
   
    fd.append('file', logoImg);
    if (logoImg) {
      axios.post('/CompanyProfiles/UploadLogo', fd).then((res) => {
        toast.success('تم تغيير الصورة بنجاح');
      });
    }
  };

  const [isDataExist, setIsDataExist] = useState(false);

  const getCompanyData = () => {
    axios
      .get('CompanyProfiles')
      .then((res) => {
        if (res.data.length > 0) {
          if (res.data[res.data.length - 1].companyName) {
            setIsDataExist(true);
            setValues(res.data[res.data.length - 1]);
          }
        } else {
          setIsDataExist(false);
        }
      })
      .catch((err) => {
      
      });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getCompanyData();
      getLogo();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const addNewData = () => {
    axios
      .post('/CompanyProfiles', values)
      .then((res) => {
    
        if (res.status === 201) {
          toast.success('تم تحديث البيانات بنجاح');
        }
      })
      .catch((err) => {
        toast.error(err.response && err.response.data);
      });
  };

  const updateProfileData = () => {
    axios
      .put(`/CompanyProfiles/${values.id}`, values)
      .then((res) => {
      
        toast.success('تم تحديث البيانات بنجاح');
      })
      .catch((err) => {
        toast.error(err.response && err.response.data);
      });
  };

  const handleSubmit = () => {
    if (isDataExist) {
      updateProfileData();
    } else {
      addNewData();
    }
  };

  const classes = useStyles();

  return (
    <div>
      <form {...props}>
        <Card>
          <CardHeader subheader="تحديث بيانات الشركة" title="بيانات الشركة" />
          <Divider />
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                xs={12}
                md={6}
              >
                <Grid xs={3} item>
                  <label className={classes.label} htmlFor="company_name">
                    اسم الشركة
                  </label>
                </Grid>
                <Grid xs={9} item>
                  <TextField
                    id="company_name"
                    name="companyName"
                    fullWidth
                    onChange={handleChange}
                    value={values.companyName}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                xs={12}
                md={6}
              >
                <Grid xs={3} item>
                  <label className={classes.label} htmlFor="manager">
                    اسم المسئول
                  </label>
                </Grid>
                <Grid xs={9} item>
                  <TextField
                    id="manager"
                    name="managerCompany"
                    fullWidth
                    onChange={handleChange}
                    value={values.managerCompany}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                xs={12}
                md={6}
              >
                <Grid xs={3} item>
                  <label className={classes.label} htmlFor="description">
                    وصف الشركة
                  </label>
                </Grid>
                <Grid xs={9} item>
                  <TextField
                    id="description"
                    name="descriptionCompany"
                    multiline
                    minRows={2}
                    fullWidth
                    onChange={handleChange}
                    value={values.descriptionCompany}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                xs={12}
                md={6}
              >
                <Grid xs={3} item>
                  <label className={classes.label} htmlFor="code">
                    كود الشركة
                  </label>
                </Grid>
                <Grid xs={9} item>
                  <TextField
                    id="code"
                    name="codeCompany"
                    fullWidth
                    onChange={handleChange}
                    value={values.codeCompany}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                xs={12}
                md={6}
              >
                <Grid xs={3} item>
                  <label className={classes.label} htmlFor="phone">
                    رقم الهاتف
                  </label>
                </Grid>
                <Grid xs={9} item>
                  <TextField
                    id="phone"
                    name="mobile"
                    inputProps={{ pattern: `/^01[0-6]{1}[0-9]{8}/` }}
                    fullWidth
                    onChange={handleChange}
                    value={values.mobile}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                xs={12}
                md={6}
              >
                <Grid xs={3} item>
                  <label className={classes.label} htmlFor="governorate">
                    المحافظة
                  </label>
                </Grid>
                <Grid xs={9} item>
                  <TextField
                    id="governorate"
                    name="governorate"
                    fullWidth
                    onChange={handleChange}
                    value={values.governorate}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                xs={12}
                md={6}
              >
                <Grid xs={3} item>
                  <label className={classes.label} htmlFor="city">
                    المدينة
                  </label>
                </Grid>
                <Grid xs={9} item>
                  <TextField
                    id="city"
                    name="city"
                    fullWidth
                    onChange={handleChange}
                    value={values.city}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                xs={12}
                md={6}
              >
                <Grid xs={3} item>
                  <label className={classes.label} htmlFor="address">
                    العنوان
                  </label>
                </Grid>
                <Grid xs={9} item>
                  <TextField
                    id="address"
                    name="address"
                    fullWidth
                    onChange={handleChange}
                    value={values.address}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>

              <Grid item></Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2,
            }}
          >
            <Button
              style={{
                backgroundColor: '#0682FB',
                fontWeight: 600,
                color: '#FFF',
              }}
              variant="contained"
              onClick={handleSubmit}
            >
              تحديث
            </Button>
          </Box>
        </Card>
      </form>
    </div>
  );
};

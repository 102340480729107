import { Grid, TextField } from "@material-ui/core";
import DatePicker from "react-datepicker";

const FromToDateHandler = ({ handleTo, handleFrom, toTime, fromTime }) => {
  function checkGap(d1, d2) {
    const diffrence = d1 - d2;
    const one_day = 1000 * 60 * 60 * 24;
    const difrInDays = Math.round(diffrence / one_day);
    if (difrInDays == 0) {
      handleTo(d1);
      handleFrom(d1);
      return;
    }
    if (difrInDays > 0) {
      if (difrInDays < 30) {
        handleTo(d1);
      } else {
        handleTo(d1);
        handleFrom(new Date(d1 - one_day * 30));
      }
    } else {
      if (difrInDays > -30) {
        handleFrom(d1);
      } else {
        handleFrom(d1);
        handleTo(new Date(d1 - one_day + one_day * 31));
      }
    }
  }
  return (
    <>
      <Grid item  xs={12} md={2}>
        <DatePicker
          maxDate={new Date()}
          locale="ar"
          selected={fromTime}
          onChange={(d) => d <= toTime && checkGap(d, toTime)}
          dateFormat="dd/MM/yyyy"
          customInput={
            <TextField
              helperText="من"
              size="small"
              variant="outlined"
              id="dateTime"
              style={{ width: '100%', backgroundColor: "#FFF" }}
            />
          }
        />
      </Grid>
      <Grid item  xs={12} md={2}>
        <DatePicker
          maxDate={new Date()}
          locale="ar"
          selected={toTime}
          onChange={(d) => d >= fromTime && checkGap(d, fromTime)}
          dateFormat="dd/MM/yyyy"
          customInput={
            <TextField
              helperText="الي"
              size="small"
              variant="outlined"
              id="dateTime"
              style={{ width: '100%', backgroundColor: "#FFF" }}
            />
          }
        />
      </Grid>
    </>
  );
};
export default FromToDateHandler;

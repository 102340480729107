import * as React from "react";

import { ConvertToArabicNumbers, renderDate } from "../../../utils";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { deleteDeposits, updateDeposits } from "../treasurySlice";

import DeleteBox from "../../../components/DeleteBox";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import UpdateDeposit from "./UpdateDeposit";
import { makeStyles } from "@material-ui/styles";
import { parseISO } from "date-fns";
import { useDispatch } from "react-redux";
import { isDayPast } from "../../../components/Helpers/InsputSorting";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  trash: {
    color: theme.palette.error.light,
  },
  edit: {
    color: theme.palette.info.light,
  },
  TableCell: {
    padding: 0,
  },
}));

export default function DepositsTable({ refresh, deposits, searchQuery }) {
  const headCells = [
    "الاسم",
    "الفئة",
    "المبلغ",
    "السماح",
    "التاريخ",
    "ملحوظات",
    "تعديل",
    "حذف",
  ];

  const dispatch = useDispatch();

  const [selectedDeposit, setSelectedDeposit] = React.useState("");
  const [deletedItem, setDeletedItem] = React.useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const role = localStorage.getItem("role");

  const handleUpdate = (data) => {
    dispatch(updateDeposits(data)).then((res) => {
      refresh();
    });
  };

  const onDeleteDeposit = () => {
    dispatch(deleteDeposits(deletedItem.id)).then(() => {
      setOpenDelete(false);
      refresh();
    });
  };
  const classes = useStyles();
  return (
    <div>
      <TableContainer style={{ marginTop: 10 }} component={Paper}>
        <Table>
          <TableHead style={{ backgroundColor: "#f3c637" }}>
            <TableRow>
              {headCells.map((item, index) => (
                <TableCell key={index} align="center">
                  {item}{" "}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {deposits
              .filter((item) => item.partner.name.includes(searchQuery))
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ minWidth: 200 }} align="center">
                    {row.partner.name}
                  </TableCell>
                  <TableCell align="center">
                    {row.depostCategoryName.replace(/_/g, " ")}
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{ConvertToArabicNumbers(row.price)}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    {row.allow !== 0
                      ? ConvertToArabicNumbers(row.allow)
                      : "ﻻ يوجد"}
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    {ConvertToArabicNumbers(renderDate(row.depositDate))}
                  </TableCell>
                  <TableCell align="center">{row.note}</TableCell>
                  <TableCell style={{ width: "32px" }} align="center">
                    <IconButton
                      disabled={role == "موظف" && isDayPast(row.depositDate)}
                      onClick={() => {
                        setSelectedDeposit(row);
                        setOpenUpdate(true);
                      }}
                      className={classes.edit}
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center" style={{ width: "32px" }}>
                    {row.depostCategoryName !== "هالك_عدة" ? (
                      <IconButton
                        className={classes.trash}
                        disabled={role == "موظف" && isDayPast(row.depositDate)}
                        onClick={() => {
                          setOpenDelete(true);
                          setDeletedItem(row);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <UpdateDeposit
        open={openUpdate}
        onClose={() => setOpenUpdate(false)}
        onUpdate={handleUpdate}
        deposit={selectedDeposit}
      />
      {deletedItem && deletedItem && (
        <DeleteBox
          action={onDeleteDeposit}
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          message={`حذف تحصيل ${
            deletedItem.partner && deletedItem.partner.name
          }`}
        />
      )}
    </div>
  );
}

DepositsTable.defaultProps = {
  searchQuery: "",
};

import { addExpenses, deleteExpenses, getExpenses } from "../loadsSlice";
import { useDispatch, useSelector } from "react-redux";

import Expenses from "../components/Expenses";
import React from "react";
import axios from "axios";
import { updateInvoiceExpense } from "../../invoice/invoiceSlice";
import Mydate from "../../../components/Helpers/MyDateParser";
import Alerto from "../../../components/UI/toaster";

function AddExpenses({ open, onClose }) {
  const dispatch = useDispatch();
  const loadExpenses = useSelector((state) => state.loads.loadExpenses);

  const [expensesCategories, setExpensesCategories] = React.useState([]);
  const getDedCategories = () => {
    axios
      .get("/ExpenseContoller/categories")
      .then((res) => {
        setExpensesCategories(res.data);
      })
      .catch((err) => {});
  };

  React.useEffect(() => {
    getDedCategories();
  }, []);
  function updateHandler(item, expense) {
    try {
      const data = {
        ...item,
        categoryId: item.category.id,
        id: expense.id,
        isDamage: expense.isDamage,
        dateTime: Mydate(new Date(item.dateTime)),
      };
      dispatch(updateInvoiceExpense(data)).then(() => {
        dispatch(getExpenses());
      });
    } catch (e) {
      Alerto(e);
    }
  }
  const onAddEXpenses = ({ category, money, notes, SharedPartnerId }) => {
    if (money && category && category.id) {
      dispatch(
        addExpenses({
          SharedPartnerId,
          category,
          name: category.name,
          money,
          notes,
        })
      );
    }
  };

  const onDelete = (item) => {
    const category = expensesCategories.find((el) => el.name === item.name);
    dispatch(
      deleteExpenses({
        id: item.id,
        treasuryTypeId: category.id,
        ...item,
      })
    );
  };

  return (
    <div>
      <Expenses
        categories={expensesCategories}
        open={open}
        onClose={onClose}
        loadExpenses={loadExpenses}
        onAddEXpensesClicked={onAddEXpenses}
        onDeleteItemClicked={onDelete}
        onUpdate={updateHandler}
      />
    </div>
  );
}

export default AddExpenses;

import {
  Button,
  Checkbox,
  DialogContentText,
  IconButton,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ConvertToArabicNumbers, renderDate } from "../../../utils";
import CustomizedTable, { StyledTableCell } from "../../../components/Table";
import AddproductOnTheFly from "./AddProductOnTheFly";
import CustomDialog from "../../../components/CustomDialog";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import React from "react";
import UpdateIcon from "@material-ui/icons/Update";
import UpdateSellingForm from "./UpdateSellingForm";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { transferRowsHandler } from "../loadsSlice";
import Alerto from "../../../components/UI/toaster";
import axios from "axios";
import { toast } from "react-toastify";
import "./SellingTable.css";
import {
  profileGetComparator,
  stableSort,
} from "../../../components/Tables/EnhancedTableHead";
import { isDayPast } from "../../../components/Helpers/InsputSorting";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginTop: 16,
    marginBottom: 12,
  },
  tableBody: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  delBtn: {
    backgroundColor: theme.palette.error.main,
    marginLeft: 16,
    height: 40,
  },
  deleteIcon: {
    color: theme.palette.error.light,
    fontSize: 80,
    marginBottom: 24,
  },
  trash: {
    color: theme.palette.error.light,
  },
  edit: {
    color: theme.palette.info.light,
  },
}));
function SellingTable({
  soldItems,
  onUpdate,
  onDelete,
  bgColor,
  isSellerHidden,
  truck,
  showCombinedWeight,
}) {
  const mainHeads = [
    { label: "اسم البائع", id: "partner" },
    { label: "العدد", id: "productsCount" },
    { label: "الوزن", id: "weight" },
    { label: "السعر", id: "priceForUnitWeight" },
    { label: "البياعة", id: "commision" },
    { label: "الاجمالي", id: "customTotal" },
    { label: "العدة", id: "movementTool" },
    { label: "الصنف", id: "truckItem" },
    { label: "التاريخ", id: "dateTime" },
    { label: "م وزن", id: "Uweight" },
    { label: "تعديل" },
    { label: "حذف" },
    { label: "نقل" },
    "",
  ];
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const truckTypeId = useSelector(
    (state) => state.loads.load?.data.truckTypeId
  );
  const checkedList = useSelector((state) => state.loads.checkedTransferRows);
  const show = useSelector((state) => state.loads.showTransfer);
  const isTransferEligable = useSelector(
    (state) => state.loads.isTransferEligable
  );
  const chosedTruckForTransfer = useSelector(
    (state) => state.loads.chosedTruckForTransfer
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [draftItem, setDraftItem] = useState({});
  const [updateForm, setUpdateForm] = useState(false);
  const [delItemId, setDelItemId] = useState("");
  const onUpdateClicked = (item) => {
    onUpdate(item);
    setSelectedItem(item);
  };
  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({});

  const deleteItem = () => {
    onDelete(delItemId);
    setOpen(false);
  };
  const handleCheck = async (item) => {
    try {
      const res = await axios.get(
        `Selling/GetSellingTransferErrors?toTruckId=${
          chosedTruckForTransfer?.truckId
        }&sellingId=${item.id}&sellingType=${truckTypeId || truck?.truckTypeId}`
      );
      if (res.status === 204) {
        const indx = checkedList.findIndex((ele) => ele.sellingId == item.id);
        let arr = [...checkedList];
        indx < 0
          ? arr.push({
              sellingId: item.id,
              type: !isSellerHidden ? truckTypeId : item.truck?.truckType,
            })
          : arr.splice(indx, 1);
        dispatch(transferRowsHandler(arr));
      } else if (res.data?.id === 1) {
        toast.error(res.data?.message);
      } else if (res.data?.id === 2) {
        setDraftItem(item);
        setModalOpen(true);
      }
    } catch (e) {
      Alerto(e);
    }
  };

  const classes = useStyles();
  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  !showCombinedWeight && mainHeads.splice(-5, 1);
  return (
    // contaniner delete from here
    <div className="SellingTable p-0 " style={{ overflowX: "auto" }}>
      <div style={{ minWidth: "900px" }}>
        {soldItems.length > 0 && (
          <CustomizedTable
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
            withSort={true}
            style={{
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            className=""
            tableHeader={
              !isSellerHidden
                ? show
                  ? mainHeads
                  : mainHeads.filter((ele) => ele.label !== "نقل")
                : show
                ? mainHeads.filter((ele) => ele.label !== "اسم البائع")
                : mainHeads.filter(
                    (ele) => ele.label !== "نقل" && ele.label !== "اسم البائع"
                  )
            }
          >
            {stableSort(soldItems, profileGetComparator(order, orderBy)).map(
              (item, index) => (
                <TableRow
                  key={index}
                  align="center"
                  className={classes.tableBody}
                >
                  {!isSellerHidden && (
                    <StyledTableCell align="center">
                      <Typography variant="body1" color="textPrimary">
                        {item && item.partner ? item.partner.name : ""}
                      </Typography>
                    </StyledTableCell>
                  )}

                  <StyledTableCell align="center">
                    <Typography variant="body1" color="textPrimary">
                      {item.productsCount
                        ? ConvertToArabicNumbers(item.productsCount)
                        : ""}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Typography variant="body1" color="textPrimary">
                      {item && ConvertToArabicNumbers(item.weight)}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Typography variant="body1" color="textPrimary">
                      {item.priceForUnitWeight > 0
                        ? ConvertToArabicNumbers(item.priceForUnitWeight)
                        : "صفر"}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Typography variant="body1" color="textPrimary">
                      {item.commision > 0
                        ? ConvertToArabicNumbers(item.commision)
                        : "صفر"}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Typography variant="body1" color="textPrimary">
                      {ConvertToArabicNumbers(
                        (
                          item.priceForUnitWeight * item.weight +
                          item.productsCount * item.commision
                        )?.toFixed(1)
                      )}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Typography variant="body1" color="textPrimary">
                      {item && item.movementTool
                        ? item.movementTool.name
                        : "ﻻ يوجد "}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Typography variant="body1" color="textPrimary">
                      {item.truckItem &&
                        item.truckItem.product &&
                        item.truckItem.product.name}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Typography variant="body1" color="textPrimary">
                      {ConvertToArabicNumbers(renderDate(item.dateTime))}
                    </Typography>
                  </StyledTableCell>
                  {!!showCombinedWeight && (
                    <StyledTableCell align="center">
                      <Typography variant="body1" color="textPrimary">
                        {ConvertToArabicNumbers(
                          Math.round(item.weight / item.productsCount)
                        )}
                      </Typography>
                    </StyledTableCell>
                  )}
                  <StyledTableCell
                    padding="none"
                    align="center"
                    style={{ width: 32 }}
                  >
                    <IconButton
                      className={classes.edit}
                      disabled={role == "موظف" && isDayPast(item.dateTime)}
                      onClick={() => {
                        setSelectedItem(item);
                        setUpdateForm(true);
                      }}
                    >
                      <UpdateIcon />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell
                    padding="none"
                    align="center"
                    style={{ width: 32 }}
                  >
                    <IconButton
                      className={classes.trash}
                      disabled={role == "موظف" && isDayPast(item.dateTime)}
                      onClick={() => {
                        setOpen(true);
                        setDelItemId(item.id);
                      }}
                    >
                      <DeleteForeverOutlinedIcon />
                    </IconButton>
                  </StyledTableCell>
                  {show && (
                    <StyledTableCell
                      padding="none"
                      align="center"
                      style={{ width: 32 }}
                    >
                      <Checkbox
                        checked={
                          checkedList?.findIndex(
                            (ele) => ele.sellingId == item.id
                          ) >= 0
                        }
                        onChange={() => handleCheck(item)}
                        disabled={
                          !isTransferEligable ||
                          (role == "موظف" &&
                            (new Date(item.dateTime).getMonth() <
                              new Date().getMonth() ||
                              new Date(item.dateTime).getDate() <
                                new Date().getDate()))
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </StyledTableCell>
                  )}
                </TableRow>
              )
            )}
          </CustomizedTable>
        )}
      </div>
      {/* stop here */}
      <CustomDialog onClose={() => setUpdateForm(false)} open={updateForm}>
        {!!selectedItem && (
          <UpdateSellingForm
            onClose={() => setUpdateForm(false)}
            item={selectedItem}
            onUpdateClicked={onUpdateClicked}
            isSellerHidden={isSellerHidden}
          />
        )}
      </CustomDialog>
      <CustomDialog
        open={open}
        title="هل انت متأكد؟"
        textAlign="center"
        onClose={() => setOpen(false)}
        action1={
          <Button
            style={{ height: 40 }}
            onClick={() => setOpen(false)}
            variant="text"
            size="small"
          >
            الغاء
          </Button>
        }
        action2={
          <Button
            variant="contained"
            className={classes.delBtn}
            onClick={deleteItem}
            size="small"
          >
            حذف
          </Button>
        }
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <DeleteForeverOutlinedIcon className={classes.deleteIcon} />
        </div>
        <DialogContentText>هل تريد حذف العملية؟</DialogContentText>
      </CustomDialog>
      <CustomDialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <h2>
          النقلة لا تحتوي علي{" "}
          <span
            style={{
              color: "#F4C93E",
              fontWeight: "bold",

              fontSize: "2rem",
            }}
          >
            {draftItem?.truckItem?.product?.name}
          </span>
        </h2>
        <h3>اضف الصنف الي النقلة</h3>
        <AddproductOnTheFly
          chosedTruck={chosedTruckForTransfer}
          chosedProduct={draftItem?.truckItem?.product}
          onClose={() => setModalOpen(false)}
        />
      </CustomDialog>
    </div>
  );
}

SellingTable.defaultProps = {
  isSellerHidden: false,
};

export default SellingTable;

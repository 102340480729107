import React, { useEffect } from "react";
import axios from "axios";

function LogoImage() {
  const [logoPicture, setLogoPicture] = React.useState("");

  const getLogo = () => {
    axios
      .get("/Setting/logoImage")
      .then((res) => {
        setLogoPicture(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getLogo();
     return () => {
       setLogoPicture("");
     };
  }, []);

  return (
    <img
      style={{ width: "100%", maxHeight: "15vw" }}
      src={`data:image/png;base64,${logoPicture}`}
      alt="logo"
    />
  );
}

export default LogoImage;

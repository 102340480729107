import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import { ConvertToArabicNumbers } from "../../../utils";
import CustomDialog from "../../../components/CustomDialog";
import React from "react";

function LoadDetails({ open, onClose, rows, truck }) {
  return (
    <CustomDialog open={open} textAlign="center" onClose={onClose}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          style={{ border: "2px solid" }}
        >
          <TableHead style={{ background: "#F3C637" }}>
            <TableRow>
              <TableCell align="center">
                <Typography style={{ fontWeight: 600, fontSize: 16 }}>
                  صنف
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography style={{ fontWeight: 600, fontSize: 16 }}>
                  العدد
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography style={{ fontWeight: 600, fontSize: 16 }}>
                  عدة
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography style={{ fontWeight: 600, fontSize: 16 }}>
                  متبقي
                </Typography>
              </TableCell>
              {truck?.truckTypeId === 2 && (
                <TableCell align="center">
                  <Typography style={{ fontWeight: 600, fontSize: 16 }}>
                    وزن
                  </Typography>
                </TableCell>
              )}
              {truck?.truckTypeId === 2 && (
                <TableCell align="center">
                  <Typography style={{ fontWeight: 600, fontSize: 16 }}>
                    سعر
                  </Typography>
                </TableCell>
              )}
              {truck?.truckTypeId === 2 && (
                <TableCell align="center">
                  <Typography style={{ fontWeight: 600, fontSize: 16 }}>
                    بياعة
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">{row.product.name}</TableCell>
                <TableCell align="center">
                  {ConvertToArabicNumbers(row.itemsCount)}
                </TableCell>
                <TableCell align="center">
                  {row.mostMovementTool ? row.mostMovementTool.name : "لا يوجد"}
                </TableCell>
                <TableCell align="center">
                  {row.remainingProductCount
                    ? ConvertToArabicNumbers(row.remainingProductCount)
                    : "صفر"}
                </TableCell>
                {truck?.truckTypeId === 2 && (
                  <TableCell align="center">
                    {row.weight ? ConvertToArabicNumbers(row.weight) : "صفر"}
                  </TableCell>
                )}
                {truck?.truckTypeId === 2 && (
                  <TableCell align="center">
                    {row.price ? ConvertToArabicNumbers(row.price) : "صفر"}
                  </TableCell>
                )}
                {truck?.truckTypeId === 2 && (
                  <TableCell align="center">
                    {row.mostSellingPrice
                      ? ConvertToArabicNumbers(row.mostSellingPrice)
                      : "صفر"}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomDialog>
  );
}

export default LoadDetails;
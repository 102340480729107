import { Card, CardContent, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import ProfitsTable from "./components/ProfitsTable";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import MainHeader from "../../components/mainHeader/MainHeader";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
}));

function AnnualProfits() {
  const classes = useStyles();

  const [annualProfits, setAnnualProfits] = useState([]);
  const location = useLocation();
  const fromTreasury = location.state?.fromTreasury;
  const getAnnualProfits = () => {
    axios
      .get("/Statistics/Parametrized")
      .then((res) => {
        setAnnualProfits(res.data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getAnnualProfits();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const headCells = [
    "السنة",
    "عدد الطرود المباعة",
    "المبيع",
    "المشتريات",
    "فواتير عمولات",
    "التحصيل",
    "المصروفات",
    "الهالك",
  ];
  const headCellsForTreasury = ["السنة", "التحصيل", "المصروفات", "رصيد سنة"];
  return (
    <div className={classes.root}>
      <div className="my-2">
        <MainHeader title="تقارير سنوية" />
      </div>
      <div style={{ marginTop: 8 }}>
        <ProfitsTable
          headCells={!fromTreasury ? headCells : headCellsForTreasury}
          fromTreasury={fromTreasury}
          profits={annualProfits}
          type="annual"
        />
      </div>
    </div>
  );
}

export default AnnualProfits;

import * as Yup from "yup";

import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import AppTextField from "../../components/form/AppTextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";
import UploadProfileImg from "./components/UploadProfileImg";
import ar from "date-fns/locale/ar-SA";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Alert from "../../components/UI/toaster";
import Alerto from "../../components/UI/toaster";
import Mydate from "../../components/Helpers/MyDateParser";
import { Col, Row } from "react-bootstrap";
import { authRoles } from "../../AppRouter";

registerLocale("ar", ar);

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
    overflowX: "auto",
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
  },
  formContent: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 300,
    justifyContent: "center",
  },
});

const types = [
  { id: 2, label: "بائع" },
  { id: 3, label: "عميل بائع" },
];
const types2 = [
  { id: 1, label: "عميل" },
  { id: 3, label: "عميل بائع" },
];

function AddPartner() {
  const { type, partnerId } = useParams();
  const history = useHistory();
  const [reset, setreset] = useState(false);
  const [partnerData, setPartnerData] = useState("");
  const [govs, setGovs] = React.useState([]);
  const [openImage, setOpenImage] = useState(true);
  const [defimg, setdefimg] = useState(null);
  const [paymentType, setPaymentType] = useState("2");
  const [fetchedpaymentType, setfetchedPaymentType] = useState("2");
  const role = localStorage.getItem("role");
  const canViewOpeningBalance =
    authRoles.supervisor.includes(role) &&
    (type === "client" || partnerData == "3" || paymentType !== "2");
  useEffect(() => {
    setPartnerData(type === "client" ? "1" : "2");
    setBalanceType(type === "client" ? "2" : "1");
  }, [type]);

  const fetchGovsCities = () => {
    axios
      .get("/Governorates")
      .then((res) => {
        setGovs(res.data);
      })
      .catch((err) => {
        Alert(err);
      });
  };

  const [collectingAddresses, setCollectingAddresses] = React.useState([]);
  const fetchCollectingAddresses = () => {
    axios
      .get("/CollectingAddress")
      .then((res) => {
        setCollectingAddresses(res.data);
      })
      .catch((err) => {
        Alert(err);
      });
  };

  const [gov, setGov] = useState("");
  const [city, setCity] = useState(null);
  const [image, setimage] = useState({});
  const [dueDate, setDueDate] = useState(new Date());
  const [initVals, setInitVals] = useState({
    name: "",
    opening_balance: 0,
    phone1: "",
    phone2: "",
    address: "",
    creationDate: new Date(),
    partners_TypeId: type === "client" ? 1 : 2,
    collecting_addressesId: null,
    governoratesId: null,
    citiesId: null,
    payementTypeId: 2,
    balanceAlert: 20000,
    isDeleted: false,
  });

  const fetchPartner = () => {
    axios
      .get(`/Partners/${partnerId}`)
      .then((res) => {
        setInitVals({
          name: res.data.name,
          opening_balance: Math.abs(res.data.openingBalance),
          phone1: res.data.phone1 ? res.data.phone1 : "",
          phone2: res.data.phone2 ? res.data.phone1 : "",
          address: res.data.address ? res.data.address : "",
          partners_TypeId: res.data.partnerTypeId,
          balanceAlert:
            res.data.balanceAlert !== null ? res.data.balanceAlert : 20000,
          payementTypeId: res.data.payementTypeId ? res.data.payementTypeId : 2,
        });

        setPaymentType(
          res.data.payementTypeId ? res.data.payementTypeId.toString() : null
        );
        setfetchedPaymentType(
          res.data.payementTypeId ? res.data.payementTypeId.toString() : null
        );
        setCollectingAddress(res.data.collectingAddress);
        res.data.clientPayement &&
          setDueDate(new Date(res.data.clientPayement.payementDate));
        setCity(res.data.city);
        setGov(res.data.city ? res.data.city.govornorate : null);
        setdefimg(res.data.photo ? res.data.photo.image : null);
        setPartnerData(res.data.partnerTypeId?.toString());
        res.data.openingBalance < 0 ? setBalanceType("2") : setBalanceType("1");
      })
      .catch((err) => {
        Alert(err);
      });
  };

  const classes = useStyles();

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchGovsCities();

      fetchCollectingAddresses();
    }
    return () => {
      mounted = false;
    };
  }, []);

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      setPartnerID(partnerId);
      if (partnerId) {
        fetchPartner();
      }
    }
    return () => {
      mounted = false;
    };
  }, [collectingAddresses.length]);

  let citiesList = [];

  const [govCities, setGovCities] = useState([]);

  const handleGovChange = (value) => {
    setGov(value);
    setCity("");

    setGovCities(value.cities);
  };
  const [collectingAddress, setCollectingAddress] = useState("");
  const [balanceType, setBalanceType] = useState("1");
  const [partnerID, setPartnerID] = useState("");
  const user = useSelector((state) => state.auth.user);
  const namecheck = (value) => {
    if (value && value !== initVals.name) {
      let arr = Array.from(value);
      let valid = arr.filter((ele, index) => {
        return ele.trim() != "" || arr[index + 1].trim() != "";
      });
      let str = valid.join("");
      axios
        .get(`/Partners/isExistedByName/${str}`)
        .then((res) => {
          if (res.data.id) {
            alert("الاسم موجود مسبقا");
          }
        })
        .catch((e) => {
          Alert(e);
        });
    }
  };
  const phonecheck = (value) => {
    if (value && value !== initVals.phone1) {
      axios
        .get(`/Partners/isExistedByPhone/${value}`)
        .then((res) => {
          if (res.data.id) {
            alert("الرقم موجود مسبقا");
          }
        })
        .catch((e) => {
          Alert(e);
        });
    }
  };
  const clearAll = () => {
    toast.success("تمت الاضافة");
    setGov("");
    setCity("");
    setCollectingAddress("");
    setreset(!reset);
    setimage({});
  };

  return (
    <div>
      <div className="headerSearch my-3 m-auto w-50 center p-3 ">
        {!partnerId &&
          (type === "seller" ? "اضف بائع او عميل بائع" : "اضف عميل")}
        {partnerId &&
          partnerData !== 3 &&
          (type === "seller" ? " تعديل البائع" : " تعديل العميل")}
        {partnerId && partnerData === 3 && " تعديل العميل بائع"}
      </div>
      <div className="headerSearch col-12 col-md-7  mt-0 center ">
        <Formik
          className="w-100"
          enableReinitialize={true}
          initialValues={initVals}
          validationSchema={Yup.object({
            img: Yup.object(),
            name: Yup.string().required("اجباري"),
            partners_TypeId: Yup.number().required("اجباري"),
            phone1: Yup.string()
              .matches(/^0[1-9][0-9]{8,9}$/, "رقم الهاتف غير صالح")
              //.matches(/^01[0-6]{1}[0-9]{8}/, "رقم الهاتف غير صالح")
              .min(10, "رقم الهاتف غير صالح")
              .max(11, "رقم الهاتف غير صالح"),
            phone: Yup.string()
              .matches(/^0[1-9][0-9]{8,9}$/, "رقم الهاتف غير صالح")
              //.matches(/^01[0-6]{1}[0-9]{8}/, "رقم الهاتف غير صالح")
              .min(10, "رقم الهاتف غير صالح")
              .max(11, "رقم الهاتف غير صالح"),
            opening_balance:
              type === "seller" &&
              paymentType === "1" &&
              Yup.number().required("اجباري"),
            balanceAlert: type === "seller" && Yup.number().required("اجباري"),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            try {
              let fd = new FormData();
              let arr = Array.from(values.name);
              let valid = arr.filter((ele, index) => {
                return ele.trim() != "" || arr[index + 1].trim() != "";
              });
              let str = valid.join("");
              fd.append("Name", str);
              if (balanceType === "1") {
                fd.append("OpeningBalance", Math.abs(values.opening_balance));
              } else {
                fd.append("OpeningBalance", -Math.abs(values.opening_balance));
              }
              fd.append("partnerTypeId", partnerData);
              fd.append("Phone1", values.phone1);
              fd.append("Phone2", values.phone2);
              fd.append("Image", image);
              fd.append("Address", values.address);
              fd.append("payementDate", Mydate(dueDate));
              if (collectingAddress) {
                fd.append("CollectingAddressId", collectingAddress.id);
              }
              if (city) {
                fd.append("CityId", city.id);
              }
              if (type === "seller" || partnerData === "3") {
                fd.append("balanceAlert", values.balanceAlert);
              }
              if (paymentType) {
                fd.append("payementTypeId", paymentType);
              }

              fd.append("AdderId", user.UserId);
              if (partnerId) {
                axios
                  .put(`/Partners/${partnerId}`, fd)
                  .then((res) => {
                    toast.success("تم التعديل");
                    history.push("../../partners");
                  })
                  .catch((e) => {
                    Alert(e);
                  });
                return;
              }

              if (type === "client" && partnerData != "3") {
                axios
                  .post(`/Partners/client`, fd)
                  .then((response) => {
                    if (response.status === 200) {
                      resetForm("");
                      clearAll();
                    }
                  })
                  .catch((e) => {
                    Alert(e);
                  });
              } else if (partnerData === "3") {
                axios
                  .post("/Partners/clientBuyer", fd)
                  .then((res) => {
                    clearAll();
                    resetForm("");
                  })
                  .catch((e) => {
                    Alert(e);
                  });
              } else {
                axios
                  .post("/Partners/buyer", fd)
                  .then((res) => {
                    clearAll();
                    resetForm("");
                  })
                  .catch((e) => {
                    Alert(e);
                  });
              }
            } catch (e) {
              Alerto(e);
            }
          }}
        >
          {({ values, setFieldValue }) => (
            <Form className="w-100 py-3">
              <Row>
                <div className="center mb-3">
                  <UploadProfileImg
                    open={openImage}
                    reset={reset}
                    def={defimg}
                    onClose={() => setOpenImage(false)}
                    upload={setimage}
                    partnerId={partnerID}
                  />
                </div>
                <Col md={8} className="mb-3 mx-auto">
                  <AppTextField
                    onBlur={(e) => namecheck(e.target.value)}
                    label="الاسم"
                    name="name"
                    type="text"
                  />
                </Col>
                {type === "seller" && (
                  <Col md={6} className="mb-3">
                    <select
                      value={partnerData}
                      style={{
                        height: "5vh",
                        width: "100%",
                        margin: "0vh 0vw 3vh 0vw",
                      }}
                      onChange={(e) => {
                        setBalanceType(balanceType == "1" ? "2" : "1");
                        setPartnerData(e.target.value);
                      }}
                    >
                      {types.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </Col>
                )}
                {type === "client" && (
                  <Col md={4} className="mb-3">
                    <select
                      value={partnerData}
                      style={{
                        height: "5vh",
                        width: "100%",
                        margin: "0vh 0vw 3vh 0vw",
                        textAlign: "center",
                      }}
                      onChange={(e) => {
                        setBalanceType(balanceType == "1" ? "2" : "1");
                        setPartnerData(e.target.value);
                      }}
                    >
                      {types2.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </Col>
                )}
                {type === "seller" &&
                  partnerData !== "3" &&
                  fetchedpaymentType === "2" && (
                    <Col md={6} className="mb-3 center">
                      <FormControl
                        className="center"
                        component="fieldset"
                        style={{ marginBottom: 4 }}
                      >
                        <RadioGroup
                          row
                          onChange={(event) => {
                            setPaymentType(event.target.value);
                          }}
                        >
                          <FormControlLabel
                            value="2"
                            control={<Radio checked={paymentType === "2"} />}
                            label="نقدي"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio checked={paymentType === "1"} />}
                            label="اجل"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Col>
                  )}
                {(partnerData === "3" ||
                  (type === "seller" && paymentType === "1")) && (
                  <AppTextField label="تنبيه الرصيد" name="balanceAlert" />
                )}
                {canViewOpeningBalance && (
                  <Col sm={6} md={8} className="mb-3">
                    <AppTextField
                      label="الرصيد الافتتاحي"
                      name="opening_balance"
                    />
                  </Col>
                )}
                {canViewOpeningBalance && (
                  <Col sm={6} md={4} className="mb-3 center">
                    <FormControl
                      component="fieldset"
                      style={{ marginBottom: 4 }}
                    >
                      <RadioGroup
                        row
                        onChange={(event) => {
                          setBalanceType(event.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="1"
                          control={
                            <Radio
                              style={{
                                color: "#fbc02d",
                              }}
                              checked={balanceType === "1"}
                            />
                          }
                          label="عليه"
                        />
                        <FormControlLabel
                          value="2"
                          control={
                            <Radio
                              style={{
                                color: "#fbc02d",
                              }}
                              checked={balanceType === "2"}
                            />
                          }
                          label="له"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Col>
                )}
                <Col sm={6} className="mb-3">
                  <AppTextField
                    onBlur={(e) => phonecheck(e.target.value)}
                    label="الهاتف 1"
                    name="phone1"
                    type="text"
                  />
                </Col>{" "}
                <Col sm={6} className="mb-3">
                  <AppTextField label="الهاتف 2" name="phone2" type="text" />
                </Col>
                <Col sm={6} className="mb-3">
                  {govs && govs.length > 0 && (
                    <Autocomplete
                      options={govs}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      getOptionLabel={(option) =>
                        option.name ? option.name : ""
                      }
                      size="small"
                      autoComplete
                      noOptionsText="لم يتم العثور على خيارات"
                      style={{ marginBottom: 16 }}
                      onChange={(event, value) => {
                        if (value) {
                          handleGovChange(value);
                        }
                      }}
                      value={gov}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="المحافظة"
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                </Col>
                <Col sm={6} className="mb-3">
                  <Autocomplete
                    options={govCities}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    size="small"
                    style={{ marginBottom: 16 }}
                    autoComplete
                    noOptionsText="لم يتم العثور على خيارات"
                    onChange={(event, value) => {
                      if (value) {
                        setCity(value);
                      }
                    }}
                    value={city}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="المدينة"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <AppTextField
                    label="العنوان"
                    name="address"
                    type="text"
                    multiline
                    minR
                    ows={3}
                  />
                </Col>
                {collectingAddresses.length > 0 && (
                  <Col sm={6} className="mb-3">
                    <Autocomplete
                      options={collectingAddresses}
                      getOptionSelected={(option, value) =>
                        option.value === value?.value
                      }
                      getOptionLabel={(option) =>
                        option.name ? option.name : ""
                      }
                      size="small"
                      style={{ marginBottom: 16 }}
                      autoComplete
                      onChange={(e, value) => {
                        if (value) {
                          setCollectingAddress(value);
                        } else {
                          setCollectingAddress("");
                        }
                      }}
                      value={collectingAddress}
                      noOptionsText="لم يتم العثور على خيارات"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="خط التحصيل"
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                        />
                      )}
                    />
                  </Col>
                )}
                {partnerData !== "2" && (
                  <Col sm={6} className="mb-3">
                    <DatePicker
                      locale="ar"
                      onChange={(date) => setDueDate(date)}
                      selected={dueDate}
                      dateFormat="dd/MM/yyyy"
                      customInput={
                        <TextField
                          label="تاريخ الاستحقاق"
                          variant="outlined"
                          id="date"
                          size="small"
                          className="w-100"
                        />
                      }
                    />
                  </Col>
                )}
                {partnerId ? (
                  <Button
                    className="btnMore mx-auto"
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{
                      height: 40,
                      backgroundColor: "#0682FB",
                      fontWeight: 600,
                      color: "#FFF",
                      width: "150px",
                    }}
                  >
                    تعديل
                  </Button>
                ) : (
                  <Button
                    className="btnMore mx-auto"
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{
                      height: 40,
                      backgroundColor: "#0682FB",
                      fontWeight: 600,
                      color: "#FFF",
                      width: "150px",
                    }}
                  >
                    حفظ
                  </Button>
                )}
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default AddPartner;

import * as Yup from "yup";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { addTool, fetchTools } from "./dataSlice";
import { useDispatch, useSelector } from "react-redux";

import AppTextField from "../../components/form/AppTextField";
import React from "react";
import ToolsTable from "./components/ToolsTable";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Alert from "../../components/UI/toaster";
import Mydate from "../../components/Helpers/MyDateParser";
import MainHeader from "../../components/mainHeader/MainHeader";
import { style } from "@mui/system";
import { Container } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "relative",
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
  },
});

function Tools() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tools = useSelector((state) => state.data.tools);
  const user = useSelector((state) => state.auth.user);
  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(fetchTools());
    }
    return () => {
      mounted = false;
    };
  }, []);
  const checkhandler = (name) => {
    if (name.length > 0) {
      axios
        .get(`/MovementTools/isExist/${name.trim()}`)
        .then((res) => {
          if (res.data === true) {
            alert("العدة موجودة مسبقا");
          }
        })
        .catch((e) => {
          Alert(e);
        });
    }
  };
  return (
    <div>
      <div className="my-4">
        <MainHeader title="العده والفوارغ" />
      </div>

      <div className="headerSearch p-3 center container">
        <Formik
          initialValues={{
            name: "",
            costMortgage: 0,
            qte: 0,
            creationDate: Mydate(new Date()),
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("اجباري"),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            dispatch(
              addTool({
                ...values,
                AdderId: user.UserId,
              })
            );

            resetForm({});
          }}
          enableReinitialize={true}>
          {({ values, setFieldValue }) => (
            <Form>
              <Box>
                <Grid
                  container
                  style={{
                    gap: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <Grid item xs={12} sm={3}>
                    <AppTextField
                      onBlur={() => checkhandler(values.name)}
                      label="نوع العدة"
                      name="name"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <AppTextField
                      label="سعر رهن العدة"
                      name="costMortgage"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <AppTextField label="رصيد العدة" name="qte" type="number" />
                  </Grid>
                  <Grid item xs={12} sm={2} className="center">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="btnMore">
                      حفظ
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </div>

      <Container>
        {Boolean(tools) && tools.length > 0 && (
          <ToolsTable check={checkhandler} tools={tools} />
        )}
      </Container>
    </div>
  );
}

export default Tools;

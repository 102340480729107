import { ConvertToArabicNumbers, renderDate } from "../../../utils";
import { IconButton, TableCell, TableRow, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import CustomizedTable from "../../../components/Table";
import DeleteBox from "../../../components/DeleteBox";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditImportingItem from "./EditImportingItem";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import React from "react";
import { isDayPast } from "../../../components/Helpers/InsputSorting";

const useStyles = makeStyles((theme) => ({
  root: {},
  details: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  delBtn: {
    backgroundColor: theme.palette.error.main,
    width: 80,
    marginLeft: 16,
    height: 40,
  },
  editIcon: {
    color: theme.palette.primary.light,
  },

  deleteIcon: {
    color: theme.palette.error.light,
    fontSize: 80,
    marginBottom: 24,
  },
  label: {
    fontSize: 16,
    marginRight: 32,
  },

  trash: {
    color: theme.palette.error.light,
  },
  edit: {
    color: theme.palette.info.light,
  },

  formContent: {
    display: "flex",
    flexDirection: "row",
    "& > *": {
      marginRight: 12,
    },
  },
}));

const headerGenerator = (isDamage) =>
  isDamage
    ? ["قيمة الهالك", "الاسم", "العدة", "العدد", "التاريخ", "تعديل", "حذف"]
    : ["الاسم", "العدة", "العدد", "التاريخ", "تعديل", "حذف"];

function ToolsTable({
  items,
  onDeleteItemClicked,
  onEditItemClicked,
  isDamage,
}) {
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deletedItemId, setDeletedItemId] = React.useState("");
  const role = localStorage.getItem("role");

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 16,
      overflow: "auto",
    },
  }))(TableCell);
  const classes = useStyles();
  return (
    <div style={{ marginTop: 16, width: "100%" }}>
      {items && items.length > 0 && (
        <CustomizedTable tableHeader={headerGenerator(isDamage)}>
          {items
            .filter((ele) => !!ele.tool)
            .map((item, index) => (
              <TableRow
                key={index}
                align="center"
                className={classes.tableBody}
              >
                {isDamage && (
                  <StyledTableCell align="center">
                    <Typography variant="body1" color="textPrimary">
                      {ConvertToArabicNumbers(item.price)}
                    </Typography>
                  </StyledTableCell>
                )}
                <StyledTableCell align="center">
                  <Typography variant="body1" color="textPrimary">
                    {item.partner?.name}
                  </Typography>
                </StyledTableCell>

                <StyledTableCell align="center">
                  <Typography variant="body1" color="textPrimary">
                    {item.tool.name}
                  </Typography>
                </StyledTableCell>

                <StyledTableCell align="center">
                  <Typography variant="body1" color="textPrimary">
                    {ConvertToArabicNumbers(item.tool.count)}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="body1" color="textPrimary">
                    {item.dateTime
                      ? ConvertToArabicNumbers(renderDate(item.dateTime))
                      : ""}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ padding: 0, width: 32 }}
                >
                  <IconButton
                    className={classes.edit}
                    disabled={role == "موظف" && isDayPast(item.dateTime)}
                    onClick={() => {
                      setOpen(true);
                      setItem(item);
                    }}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  style={{ padding: 0, width: 32 }}
                >
                  <IconButton
                    disabled={role == "موظف" && isDayPast(item.dateTime)}
                    className={classes.trash}
                    onClick={() => {
                      setOpenDelete(true);
                      setDeletedItemId(item.id);
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            ))}
        </CustomizedTable>
      )}
      <EditImportingItem
        open={open}
        item={item}
        onEditItemClicked={onEditItemClicked}
        onClose={() => setOpen(false)}
      />
      <DeleteBox
        action={() => {
          onDeleteItemClicked(deletedItemId);
          setOpenDelete(false);
        }}
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        message="هل تريد حذف العنصر"
      />
    </div>
  );
}

export default ToolsTable;

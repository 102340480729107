import { CircularProgress } from "@material-ui/core";
import PartnersTable from "./components/PartnersTable";
import React from "react";
import axios from "axios";
import { indexOfId } from "../../utils";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Alert from "../../components/UI/toaster";
import MainHeader from "../../components/mainHeader/MainHeader";
function Partners() {
  const [partners, setPartners] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getPartners = () => {
    setLoading(true);
    axios
      .get("/Partners/light")
      .then((res) => {
        setPartners(res.data);
        setLoading(false);
      })
      .catch((err) => {
        Alert(err);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      getPartners();
    }
    return () => {
      mounted = false;
    };
  }, []);

  let customData = [];
  if (partners.length > 0) {
    for (let i = 0; i < partners.length; i++) {
      customData.push({
        id: partners[i].id,
        name: partners[i].name,
        phone1: partners[i].phone1,
        phone2: partners[i].phone2,
        collectingAddress: partners[i].collectingAddress,
        partners_TypeId: partners[i].partnerTypeId,
        payementTypeId: partners[i].payementTypeId,
        isDeleted: partners[i].isDeleted,
        isEmployee: partners[i].isEmployee,
      });
    }
  }

  const deletePartner = (id) => {
    let partnersArr = [...partners];
    axios
      .delete(`/Partners/${id}`)
      .then((response) => {
        if (response.status === 200) {
          let filteredPartners = partners.filter((p) => {
            return p.id !== id;
          });
          setPartners(filteredPartners);
          toast.success("تم الحذف بنجاح");
        }
      })
      .catch((e) => {
        setPartners(partnersArr);
        if (e.response) {
          toast.error(e.response.data);
        } else {
          toast.error("فشل الحذف");
        }
      });
  };

  const onStatusChanged = async (id, state) => {
    const index = indexOfId(partners, id);
    try {
      const url = state
        ? `/Partners/recover/${id}`
        : `Partners/softDelete/${id}`;

      const res = await axios.put(url);
      if (res.status === 200) {
        if (state) {
          toast.success("تم التفعيل ");
          let allPartners = [...partners];
          allPartners[index].isDeleted = false;

          setPartners(allPartners);
        } else {
          toast.error("تم التعطيل ");
          let allPartners = [...partners];
          allPartners[index].isDeleted = true;

          setPartners(allPartners);
        }
      }
    } catch (e) {
      if (e.response) {
        toast.error(e.response.data);
      } else {
        toast.error("حدث خطأ ما");
      }
    }
  };

  return (
    <div style={{ flexGrow: 1, height: "100%", widht: "100%" }}>
      {loading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 200,
          }}
        >
          {" "}
          <CircularProgress
            style={{
              color: "#386CB9",
              display: "inline-block",
            }}
            size={80}
          />{" "}
        </div>
      )}
      {partners.length > 0 && (
        <PartnersTable
          onStatusChanged={onStatusChanged}
          data={customData}
          onDelete={deletePartner}
        />
      )}
    </div>
  );
}

export default Partners;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDateDashed, indexOfId } from "../../utils";

import axios from "axios";
import { toast } from "react-toastify";
import Alert from "../../components/UI/toaster";

export const addExporting = createAsyncThunk(
  "exporting/addExporting",
  async ({ partner, dateTime }, { rejectWithValue, getState }) => {
    try {
      const response = await axios.post("/MovementTools/exporting", {
        partnerId: partner.id,
        dateTime: dateTime,
        adderId: getState().auth.user.UserId,
      });

      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getExporting = createAsyncThunk(
  "exporting/getExporting",
  async (exportingId) => {
    try {
      const response = await axios.get(
        `/MovementTools/exporting/${exportingId}`
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateExporting = createAsyncThunk(
  "exporting/updateExporting",
  async ({ partner, dateTime }, { getState }) => {
    const id = getState().exporting.exporting.data.id;
    try {
      const response = await axios.put(`/MovementTools/exporting/${id}`, {
        id,
        partnerId: partner.id,
        adderId: getState().auth.user.UserId,
        dateTime: dateTime,
      });
      if (response.status === 200) {
        toast.success("تم التعديل بنجاح");
      }
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteExporting = createAsyncThunk(
  "exporting/deleteExporting",
  async (_, { getState, rejectWithValue }) => {
    const id = getState().exporting.exporting.data.id;
    try {
      const response = await axios.delete(`/MovementTools/exporting/${id}`);
      if (response.status === 200) {
        toast.success("تم الحدف بنجاح");
        window.location = "/tools-out";
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteExportingItem = createAsyncThunk(
  "exporting/deleteExportingItem",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `/MovementTools/exporting/item/${id}`
      );
      if (response.status === 200) {
        toast.success("تم حذف العنصر");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addExportingItem = createAsyncThunk(
  "exporting/addExportingItem",
  async (data, { getState, rejectWithValue }) => {
    const id = getState().exporting.exporting.data.id;
    try {
      const response = await axios.post("/MovementTools/exporting/item", {
        exportingId: id,
        movementToolId: data.movementTool.id,
        count: data.count,
      });
      return response.data;
    } catch (error) {
      Alert(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateExportingItem = createAsyncThunk(
  "exporting/updateExportingItem",
  async (data, { getState, rejectWithValue }) => {
    const exportingId = getState().exporting.exporting.data.id;
    try {
      const response = await axios.put(
        `/MovementTools/Exporting/item/${data.id}`,
        {
          id: data.id,
          exportingId,
          movementToolId: data.movementTool.id,
          count: data.count,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchExportingByDate = createAsyncThunk(
  "exporting/searchExportingByDate",
  async (dateTime) => {
    try {
      const response = await axios.get(
        `/MovementTools/exporting/search?dateTime=${getDateDashed(dateTime)}`
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const searchExportingByName = createAsyncThunk(
  "exporting/searchExportingByName",
  async (partnerId) => {
    try {
      const response = await axios.get(
        `/MovementTools/exporting/search?partnerId=${partnerId}`
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const initialState = {
  exporting: {
    data: "",
    items: [],
  },
  exports: [],
};

const exportSlice = createSlice({
  name: "exporting",
  initialState,

  extraReducers: {
    [addExporting.pending]: (state, action) => {
      state.exporting.data = action.meta.arg;
    },
    [addExporting.fulfilled]: (state, action) => {
      state.exporting.data.id = action.payload.id;
    },
    [addExporting.rejected]: (state) => {
      state.exporting.data = {};
      state.exporting.items = [];
    },

    [getExporting.fulfilled]: (state, action) => {
      state.exporting.data = action.payload;
      state.exporting.items = action.payload.items;
    },

    [updateExporting.fulfilled]: (state, action) => {
      state.exporting.data = action.meta.arg;
      state.exporting.data.id = action.payload.id;
    },
    [deleteExporting.fulfilled]: (state) => {
      state.exporting.data = {};
      state.exporting.items = [];
    },

    [deleteExporting.rejected]: (state) => {},

    [addExportingItem.pending]: (state, action) => {
      state.exporting.items.push(action.meta.arg);
    },
    [addExportingItem.fulfilled]: (state, action) => {
      state.exporting.items[state.exporting.items.length - 1].id =
        action.payload.id;
    },
    [addExportingItem.rejected]: (state) => {
      state.exporting.items.pop();
    },
    [deleteExportingItem.fulfilled]: (state, action) => {
      state.exporting.items = state.exporting.items.filter((item) => {
        return item.id !== action.meta.arg;
      });
    },

    [updateExportingItem.fulfilled]: (state, action) => {
      const index = indexOfId(state.exporting.items, action.meta.arg.id);
      state.exporting.items[index] = action.meta.arg;
    },

    [searchExportingByDate.fulfilled]: (state, action) => {
      state.exports = action.payload;
    },
    [searchExportingByName.fulfilled]: (state, action) => {
      state.exports = action.payload;
    },
  },
});

//export const { addItem } = toolsSlice.actions;

export default exportSlice.reducer;

import { Box, Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import {
  dangerCardHeader,
  infoCardHeader,
  warningCardHeader,
} from "../../../components/styles";

import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { ConvertToArabicNumbers } from "../../../utils";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { display } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding:10
  },
  itemInfo: {
    padding:10,
    borderRadius: 20,
    paddingRight: 12,
    paddingLeft: 12,
    color: "#FFF",
    ...infoCardHeader,
  },
  loadPayment: {
    padding:10,
    marginRight: 16,
    marginTop: 16,
    color: theme.palette.text.secondary,
  },
  selectedItem: {
    // backgroundColor: theme.palette.secondary.light,
    color: "#FFF",
    padding:10,
    ...warningCardHeader,
  },
  danger: {
    color: "#FFF",
    ...dangerCardHeader,
  },
}));

function SelectedItemInfo({ onChangeItem }) {
  const classes = useStyles();
  const [isChipHovered, setIsChipHovered] = useState(false);
  const selectedProduct = useSelector((state) => state.loads.selectedProduct);
  if (selectedProduct.product == undefined) {
    return null;
  }
  return (
    <div className={classes.root}>
      <div className="w-75 m-auto bg-white mb-3 rounded-3" style={{ height: '3px' }}>
      </div>
      <Box className="m-auto center " style={{width:'80%'}}>
        {selectedProduct ? (
          <Grid container spacing={1} className='m-auto center ' >
            <Grid item xs={6} lg={2} md={3} >
              <Button
                className={clsx(classes.itemInfo, classes.selectedItem)}
                endIcon={isChipHovered && <ArrowLeftIcon />}
                style={{
                  height: 43, width: '100%',
                  display: 'flex', justifyContent: 'center', alignItems: 'center',
                }}
                onClick={onChangeItem}
                onMouseOver={() => setIsChipHovered(true)}
                onMouseLeave={() => setIsChipHovered(false)}
              >
                {selectedProduct.product.name}
              </Button>
            </Grid>
            <Grid item xs={6} lg={2} md={3} >
              <Typography className={classes.itemInfo}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                العدة:{" "}
                {selectedProduct.mostMovementTool
                  ? selectedProduct.mostMovementTool.name
                  : "لايوجد"}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={2} md={3} >
              <Typography className={classes.itemInfo}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                العدد الاساسي:{" "}
                {ConvertToArabicNumbers(selectedProduct.itemsCount)}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={2} md={3} >
              <Typography className={classes.itemInfo}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                المباع:
                {!isNaN(selectedProduct.remainingProductCount) &&
                  selectedProduct.itemsCount ===
                  selectedProduct.remainingProductCount
                  ? "صفر"
                  : ConvertToArabicNumbers(
                    selectedProduct.itemsCount -
                    selectedProduct.remainingProductCount
                  )}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={2} md={3}  >
              <Typography
                className={clsx(classes.itemInfo, {
                  [classes.danger]: selectedProduct.remainingProductCount < 0,
                })}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                المتبقي:{" "}
                {!isNaN(selectedProduct.remainingProductCount) &&
                  selectedProduct.remainingProductCount === 0
                  ? "صفر"
                  : ConvertToArabicNumbers(
                    Math.abs(selectedProduct.remainingProductCount)
                  )}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </Box>
    </div>
  );
}

export default SelectedItemInfo;

import {
  Box,
  Button,
  CardContent,
  Grid,
  Card as MUICard,
  Typography,
} from "@material-ui/core";
import classes from "./Home.module.css";
import BannerImg from "./BannerImg";
import Card from "./components/Card";
import { Database } from "react-feather";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import React from "react";
import bannerImg from "../../assets/images/banner.jpg";
import qr from "../../assets/images/qr.png";
import { Container } from "react-bootstrap";
import CircleIcon from '@mui/icons-material/Circle';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
const Home = () => {
  const role = localStorage.getItem("role");
  const cardData = [

    {
      id: 2,
      title: "النقلات",
      actions: [
        { name: "اضف نقلة", link: "/loads" },
        { name: "نقلات اليوم", link: "/daily-trucks" },
        { name: "نقلات متبقية", link: "/remaining-trucks" },
        { name: "نقلات منتهية", link: "/completed-trucks" },
      ],
    },
    {
      id: 1,
      title: "الفواتير",
      Icon: Database,
      actions: [
        { name: "فواتير البائعين", link: "/sellers-invoices" },
        { name: "فواتير العملات", link: "/commission-invoices" },
        { name: "فواتير المشتريات", link: "/purchase-invoices" },
      ],
    },
    {
      id: 3,
      title: "العده و الفوارغ",
      actions: [
        { name: "اضف خارج", link: "/tools-out" },
        { name: "اضف تحصيل", link: "/tools-in" },
        { name: "اضف اهلاك", link: "/damage-tools" },
      ],
    },
    {
      id: 4,
      title: "الخزينة",
      actions: [
        { name: "اضف تحصيل", link: "/add-deposit" },
        { name: "اضف مصروفات", link: "add-expenses" },
      ],
    },
  ];
  const cardData2 = [
    {
      id: 1,

      actions: [],
    },
    {
      id: 2,

      actions: [],
    },
    {
      id: 3,

      actions: [],
    },
    {
      id: 4,

      actions: [],
    },
  ];

  return (
    <div className=" w-100  p-4 pb-5 margin15px" style={{ background: '#534d3b', marginTop: ' -15px  !important' }}>

      <Helmet>
        <title>الرئيسية</title>
      </Helmet>

      {role == "محصل" || role == "عميل_او_بائع" ? (
        ""
      ) : (


        <Grid container spacing={1}>

          <Grid item md={9} xs={12} className='d-block d-md-none mt-3 '>
            <BannerImg />
          </Grid>

          {cardData.map((item) => (
            <Grid
              key={item.id}
              item
              sm={6}
              lg={3}
              xs={12}
            >
              <Card {...item} />
            </Grid>
          ))}
        </Grid>
      )}
      <Grid container xs={12} spacing={2} alignItems="flex-start" className=" mx-auto">

        <Grid item md={9} xs={12} className=' d-none d-md-block'>
          <BannerImg />
        </Grid>
        <Grid className=' p-2  ' item xs={12} md={3}>
          <MUICard
            style={{
              background: "linear-gradient(to top,#0d8ff2 30% , #43aeff  , #b1ddff   , #43aeff ,#097fd8  )",
              boxShadow: 'rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset',
              textAlign: "center",
              height: '100%',
              width: '100%',
              color: "#FFF",
              cursor: "default",
            }}
            className='p-0 pb-3 hoverCard rounded-3'
          >
            {role == "عميل_او_بائع" ? (
              <CardContent
                className='p-0 text-start'
              ></CardContent>
            ) : (
              <CardContent
                className='p-0 text-start'

              >

                <Typography className=' center w-100 bg-global globalFont' style={{ height: '60px' }} >
                  <SupervisorAccountOutlinedIcon className='m-2' />
                  الحسابات

                </Typography>

                <Link
                  to={`/profiles/clients`}
                  className='center justify-content-start px-3 text-black py-1  mt-3 w-100  linkHover '
                >
                  <div className=' p-2' >

                    <CircleIcon style={{ fontSize: '10px' }} />
                  </div>
                  حسابات عملاء
                </Link>

                <Link
                  to={`/clients-collecting-addresses`}
                  className='center justify-content-start px-3 text-black py-1 w-100  linkHover '
                >
                  <div className=' p-2' >

                    <CircleIcon style={{ fontSize: '10px' }} />
                  </div>
                  كشف تحصيل عملاء
                </Link>

                <Link
                  to={`/profiles/sellers`}
                  variant="text"
                  className='center justify-content-start px-3 text-black py-1  w-100  linkHover '
                >
                  <div className=' p-2' >

                    <CircleIcon style={{ fontSize: '10px' }} />
                  </div>
                  حسابات بائعين                </Link>

                <Link
                  to={`/buyers-collecting-addresses`}
                  variant="text"
                  className='center justify-content-start px-3 text-black py-1   w-100  linkHover '
                >
                  <div className=' p-2' >

                    <CircleIcon style={{ fontSize: '10px' }} />
                  </div>
                  كشف تحصيل بائعين                </Link>
              </CardContent>
            )}
          </MUICard>
          <Grid item container spacing={1} xs={12} className='py-1  m-auto'>
            <Grid item xs={12}>
              <div
                style={{
                  background: "linear-gradient(to top, #f57542, #FFA500, #F3C637 70%, #FFD700, #FFD700,#FFD700, #FFA500)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 12,
                  borderRadius: 5,
                  boxShadow: ' rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px,rgb(0 0 0 / 20%) 0px -3px 0px inset',
                }}
              >
                <Typography color="textPrimary" variant="h5">
                  {" "}
                  QR Support{" "}
                </Typography>
                <img
                  style={{ width: 64, marginRight: 12 }}
                  src={qr}
                  alt="qr code"
                />
              </div>
            </Grid>


          </Grid>
        </Grid>

      </Grid>
    </div>
  );
};
export default Home;

//#F0284A
//#2ABBA7
//#1877F2
//#EF3C47

//#FF5672
//#FFB851
//#4D9EFF

import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import LocalPrintshopOutlinedIcon from "@material-ui/icons/LocalPrintshopOutlined";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import Alerto from "../UI/toaster";
import { useReactToPrint } from "react-to-print";
import LogoImage from "../LogoImage";
import Mydate from "../Helpers/MyDateParser";
import { Col, Container, Row } from "react-bootstrap";
import {
  EnhancedTableHead,
  getComparator,
  profileGetComparator, 
  stableSort,
} from "./EnhancedTableHead";
const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: "none",
  },
  inputs: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  searchIcon: {
    color: "#888",
  },
  table: {
    borderRadius: "15px",
    border: ".1px solid gray",
    width: "60vw",
    margin: 12,
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
      overflowY: "scroll",
    },
  },
}));
const paymentTypes = [
  { id: 1, label: "اجل" },
  { id: 2, label: "نقدية" },
  { id: 0, label: "الكل" },
];

export default function ProfilesTable({
  api,
  isPrinting,
  isFiltering,
  headCells,
  targetUrl,
  isLinkDisabled,
}) {
  const [OnlyPositiv, setOnlyPositiv] = useState(false);
  const [partners, setPartners] = useState([]);
  const [paymentType, setPaymentType] = useState(0);
  const invoiceDate = Mydate(new Date()).split("T")[0];
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const role = localStorage.getItem("role");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const classes = useStyles();
  const componentRef = useRef();
  const getAllPArtners = async () => {
    try {
      const res = await axios.get(api);

      setPartners(res.data);
    } catch (e) {
      Alerto(e);
    }
  };
  const [printing, setPrinting] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "تقرير البائعين",
    onAfterPrint: () => setPrinting(false),
  });

  useEffect(() => {
    getAllPArtners();
  }, [api]);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    printing &&
      setTimeout(() => {
        handlePrint();
      }, 600);
  }, [printing]);
  const timer = useRef();
  const handleOnlyPos = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setOnlyPositiv(!OnlyPositiv);
    }, 10);
  };

  return (
    <div
      className=" w-100 p-0 py-1 pb-5 margin15px"
      style={{
        background: "#534d3b",
        marginTop: " -15px  !important",
        outline: " 8px solid #534d3b",
        minHeight: "107vh ",
      }}
    >
      <Container className="sellerpage">
        <Row className="col-lg-9 headerSearch justify-content-around d-flex align-items-center mt-3  m-auto">
          <Col lg={6}>
            <div
              className=" mt-3 m-auto "
              style={{
                borderRadius: 4,
                width: "100%",
                padding: "7px 12px",
                boxShadow:
                  "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
              }}
            >
              <TextField
                placeholder="بحث..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  borderRadius: 25,
                  width: "100%",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  classes: { notchedOutline: classes.noBorder },
                }}
                variant="standard"
              />
            </div>
          </Col>

          {(isFiltering || isPrinting) && (
            <Col lg={6}>
              <div className="d-flex justify-content-evenly align-items-center mt-3 m-auto ">
                {isFiltering && (
                  <RadioGroup row onClick={handleOnlyPos}>
                    <FormControlLabel
                      control={
                        <Radio
                          value={1}
                          style={{
                            color: "#fbc02d",
                          }}
                          checked={OnlyPositiv}
                        />
                      }
                      label={"مدين"}
                    />
                  </RadioGroup>
                )}

                {isFiltering && (
                  <TextField
                    style={{
                      backgroundColor: "#fff",
                      width: 180,
                    }}
                    size="small"
                    select
                    label="الدفع"
                    onChange={(e) => setPaymentType(e.target.value)}
                    value={paymentType}
                    variant="outlined"
                  >
                    {paymentTypes.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}

                {isPrinting && (
                  <div>
                    <Tooltip title="طباعة التقرير">
                      <IconButton onClick={() => setPrinting(true)}>
                        <LocalPrintshopOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Col>
          )}
        </Row>

        <Row
          className=" col-lg-9 headerSearch bg-transparent p-0 mt-3 mb-5  m-auto"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
          }}
        >
          <TableContainer
            ref={componentRef}
            component={Paper}
            className="w-100 p-0  rounded-4"
          >
            {printing && (
              <div style={{ margin: 0 }}>
                <LogoImage />
              </div>
            )}
            {printing && (
              <p
                style={{
                  margin: 0,
                  textAlign: "center",
                  backgroundColor: "#E59A01",
                  color: "black",
                  fontWeight: "bolder",
                }}
                className="globalFont"
              >
                {invoiceDate}
              </p>
            )}
            <Table enableTopToolbar={true}>
              <EnhancedTableHead
                isPrint={printing}
                onRequestSort={handleRequestSort}
                cells={headCells}
                order={order}
                orderBy={orderBy}
              />
              {partners && (
                <TableBody>
                  {stableSort(partners, profileGetComparator(order, orderBy))
                    .filter((item) =>
                      OnlyPositiv ? item.totalBuyerDebt > 0 : item
                    )
                    .filter((item) =>
                      !!item.name
                        ? item.name.includes(searchQuery)
                        : item.partner?.name.includes(searchQuery)
                    )
                    .filter((item) =>
                      paymentType != 0 ? item.paymentType == paymentType : item
                    )
                    .slice(
                      !printing ? page * rowsPerPage : 0,
                      !printing
                        ? page * rowsPerPage + rowsPerPage
                        : partners.length - 1
                    )
                    .map((partner, index) => (
                      <TableRow
                        key={index}
                        className={!isLinkDisabled && "rowHover"}
                        component={!isLinkDisabled ? Link : undefined}
                        to={
                          role != "محصل"
                            ? `/${targetUrl}-profile/${
                                partner.partnerId || partner.partner?.id
                              }`
                            : `/${targetUrl}-user-profile/${
                                partner.partnerId || partner.partner?.id
                              }`
                        }
                      >
                        {headCells.map((ele) => {
                          const value = !!ele.id ? partner[ele.id] : partner;
                          const render = ele.render;

                          return (
                            <TableCell align="center" className="p-2 border-1">
                              {!!render ? render(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              )}
            </Table>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={partners.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage=""
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className="m-auto w-100 center"
            />
          </TableContainer>
        </Row>
      </Container>
    </div>
  );
}

import { Card, CardContent, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import HistoryTable from "./components/HistoryTable";
import axios from "axios";
import { getDateDashed } from "../../utils";
import { makeStyles } from "@material-ui/styles";
import MainHeader from "../../components/mainHeader/MainHeader";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
}));

function SearchHistory() {
  const [date, setDate] = useState(new Date());
  const [data, setData] = useState([]);

  const classes = useStyles();

  const getSearchHistory = () => {
    axios
      .get(
        `/Tracking/Searching?date=${getDateDashed(date)}
        `
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getSearchHistory();
    }
    return () => {
      mounted = false;
    };
  }, [date]);

  return (
    <div className={classes.root}>
      <div className="my-2">
        <MainHeader title="سجل البحث " />
      </div>
      <CardContent
        className="headerSearch w-50 m-auto mt-3"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <DatePicker
          locale="ar"
          selected={date}
          onChange={(d) => setDate(d)}
          dateFormat="dd/MM/yyyy"
          customInput={
            <TextField
              variant="outlined"
              size="small"
              style={{ width: "100%" }}
            />
          }
        />
      </CardContent>
      <div style={{ marginTop: 8 }}>
        <HistoryTable data={data} />
      </div>
    </div>
  );
}

export default SearchHistory;

import { Button, CardContent, Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ar from "date-fns/locale/ar-SA";
import { makeStyles } from "@material-ui/core/styles";
import { parseISO } from "date-fns/esm";
import { registerLocale } from "react-datepicker";
import Mydate from "../../../components/Helpers/MyDateParser";
import { infoCardHeader } from "../../../components/styles";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { fetchPartners, fetchTools } from "../../main-data/dataSlice";
import { Rowing } from "@material-ui/icons";

registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
    backgroundColor: "white",
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
  },
  detailArrow: {
    position: "absolute",
    top: 0,
  },
  formContent: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "& > *": {
      marginRight: 12,
    },
  },

  loadInfo: {
    padding: "8px 12px",
    borderRadius: 20,
    backgroundColor: "#EEF66C",
    boxShadow:
      "0 4px 20px  rgba(238, 246, 108, 0.3), 0 -7px -10px -5px rgba(238, 246, 108, 0.09)",
    "&:hover": {
      boxShadow: "0 0 0",
    },
  },
  qty: {
    display: "flex",
    padding: 8,
    borderRadius: 20,
    paddingRight: 12,
    paddingLeft: 12,
    color: "#FFF",
    ...infoCardHeader,
  },
  para: {
    margin: "0 0 0 10px",
  },
  close: {
    position: "absolute",
    top: 16,
    right: 16,
    color: theme.palette.grey[700],
    height: 36,
    width: 36,
    backgroundColor: "rgba(216, 218, 223, 0.5)",
    "&:hover": {
      backgroundColor: "#D8DADF",
    },
  },
}));

function AccountForm({ deposit, onUpdateClicked }) {
  const partners = useSelector((state) => state.data.partners);
  const tools = useSelector((state) => state.data.tools);
  const [depositDate, setDepositDate] = useState(new Date());
  const role = localStorage.getItem("role");
  const [price, setPrice] = useState("");
  const [commission, setCommission] = useState("");
  const [weight, setWeight] = useState("");
  const [count, setCount] = useState("");
  const [truckTool, setTruckTool] = useState({});
  const [partner, setPartner] = useState("");
  const dispatch = useDispatch();
  const [note, setNote] = useState("");
  const [allow, setAllow] = useState("");
  React.useEffect(() => {
    dispatch(fetchPartners());
    dispatch(fetchTools());

    if (deposit?.depositDate || deposit?.dateTime) {
      setDepositDate(parseISO(deposit.depositDate || deposit.dateTime));
      setPrice(
        deposit.price ||
          deposit.value ||
          deposit.money ||
          deposit.priceForUnitWeight
      );
      setCommission(deposit.commision);
      setWeight(deposit.weight);
      setNote(deposit.note);
      setCount(deposit.productsCount);
      setAllow(deposit.allow);
      setTruckTool(deposit.movementTool);
      setPartner(deposit.partner);
    }
  }, [deposit]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {deposit?.weight ? (
        <CardContent>
          <Grid item container spacing={1}>
            <Grid item>
              <Autocomplete
                options={partners}
                getOptionSelected={(option, value) =>
                  option.value === value?.value
                }
                getOptionLabel={(option) => {
                  return option ? option.name : "";
                }}
                style={{ width: 240 }}
                autoHighlight
                size="small"
                onChange={(e, value) => {
                  if (value) {
                    setPartner(value);
                  } else {
                    setPartner("");
                  }
                }}
                value={partner}
                renderInput={(params) => (
                  <TextField
                    autoComplete="off"
                    {...params}
                    label="البائع"
                    variant="filled"
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <TextField
                value={count >= 0 ? count : ""}
                onChange={(e) => setCount(Number.parseFloat(e.target.value))}
                style={{ width: 120 }}
                label="عدد"
                variant="filled"
                size="small"
                type="number"
              />
            </Grid>

            <Grid item>
              <TextField
                value={weight >= 0 ? weight : ""}
                onChange={(e) => setWeight(Number.parseFloat(e.target.value))}
                style={{ width: 120 }}
                label="وزن"
                variant="filled"
                size="small"
                type="number"
              />
            </Grid>
            <Grid item>
              <TextField
                value={price >= 0 ? price : ""}
                onChange={(e) => setPrice(Number.parseFloat(e.target.value))}
                style={{ width: 120 }}
                label="سعر"
                variant="filled"
                size="small"
                type="number"
              />
            </Grid>
            <Grid item>
              <TextField
                value={commission >= 0 ? commission : ""}
                onChange={(e) =>
                  setCommission(Number.parseFloat(e.target.value))
                }
                style={{ width: 120 }}
                label="بياعة"
                variant="filled"
                size="small"
                type="number"
              />
            </Grid>

            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disableFuture
                  disabled={role == "موظف"}
                  label="التاريخ"
                  openTo="day"
                  views={["year", "month", "day"]}
                  value={depositDate}
                  onChange={(d) => setDepositDate(d)}
                  renderInput={(params) => (
                    <TextField style={{ width: 200 }} {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <Autocomplete
                options={tools}
                getOptionSelected={(option, value) =>
                  option.value === value?.value
                }
                getOptionLabel={(option) => {
                  return option ? option.name : "";
                }}
                style={{ width: 140 }}
                autoHighlight
                size="small"
                onChange={(e, value) => {
                  if (value) {
                    setTruckTool(value);
                  } else {
                    setTruckTool("");
                  }
                }}
                value={truckTool}
                renderInput={(params) => (
                  <TextField
                    autoComplete="off"
                    {...params}
                    label="عدة"
                    variant="filled"
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  onUpdateClicked({
                    ...deposit,
                    id: deposit.id,
                    dateTime: Mydate(depositDate),
                    priceForUnitWeight: price,
                    commision: commission,
                    weight,
                    productsCount: count,
                    movementTool: truckTool,
                    partner,
                  });
                }}
              >
                تعديل
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      ) : deposit.stateName == "دفع_رهن_مشتريات" ? (
        <CardContent>
          <Grid item container spacing={1}>
            <Grid item>
              <TextField
                value={price >= 0 ? price : ""}
                onChange={(e) => setPrice(Number.parseFloat(e.target.value))}
                style={{ width: 140 }}
                label="المبلغ"
                variant="filled"
                size="small"
                type="number"
              />
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disableFuture
                  disabled={role == "موظف"}
                  label="التاريخ"
                  openTo="day"
                  views={["year", "month", "day"]}
                  value={depositDate}
                  onChange={(d) => setDepositDate(d)}
                  renderInput={(params) => (
                    <TextField style={{ width: 200 }} {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  onUpdateClicked({
                    id: deposit.click?.id,
                    price,
                    dateTime: Mydate(depositDate),
                  });
                }}
              >
                تعديل
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        <CardContent>
          <Grid item container spacing={1}>
            <Grid item>
              <Autocomplete
                options={partners}
                getOptionSelected={(option, value) =>
                  option.value === value?.value
                }
                getOptionLabel={(option) => {
                  return option ? option.name : "";
                }}
                style={{ width: 240 }}
                autoHighlight
                size="small"
                onChange={(e, value) => {
                  if (value) {
                    setPartner(value);
                  } else {
                    setPartner("");
                  }
                }}
                value={partner}
                renderInput={(params) => (
                  <TextField
                    autoComplete="off"
                    {...params}
                    label="البائع"
                    variant="filled"
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <TextField
                value={price >= 0 ? price : ""}
                onChange={(e) => setPrice(Number.parseFloat(e.target.value))}
                style={{ width: 140 }}
                label="المبلغ"
                variant="filled"
                size="small"
                type="number"
              />
            </Grid>
            {!!allow && (
              <Grid item>
                <TextField
                  value={allow >= 0 ? allow : ""}
                  onChange={(e) => setAllow(Number.parseFloat(e.target.value))}
                  style={{ width: 120 }}
                  label="سماح"
                  variant="filled"
                  size="small"
                  type="number"
                />
              </Grid>
            )}
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disableFuture
                  disabled={role == "موظف"}
                  label="التاريخ"
                  openTo="day"
                  views={["year", "month", "day"]}
                  value={depositDate}
                  onChange={(d) => setDepositDate(d)}
                  renderInput={(params) => (
                    <TextField style={{ width: 200 }} {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item>
              <TextField
                value={note}
                onChange={(e) => setNote(e.target.value)}
                style={{ width: 220 }}
                label="ملحوظة"
                variant="filled"
                size="small"
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  onUpdateClicked({
                    id: deposit.click?.id,
                    depositDate: Mydate(depositDate),
                    price,
                    note,
                    partner,
                    allow,
                  });
                }}
              >
                تعديل
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </div>
  );
}

export default AccountForm;

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DatePicker from "react-datepicker";
import ExpensesTable from "./components/ExpensesTable";
import SearchIcon from "@material-ui/icons/Search";
import Total from "../../components/UI/Total";
import ar from "date-fns/locale/ar-SA";
import { getAllExpenses, getExpensesCategories } from "./expensesSlice";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import { fetchPartners } from "../main-data/dataSlice";
import Mydate from "../../components/Helpers/MyDateParser";
import { Container } from "react-bootstrap";
import MainHeader from "../../components/mainHeader/MainHeader";

registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  radio: {
    margin: "0vh 0vw 0vh 10vw",
    display: "block",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 12,
  },
  SearchForm: {
    marginTop: 12,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    "& > *": {
      marginRight: 12,
    },
  },
}));

function AllExpenses() {
  const allExpenses = useSelector((state) => state.expenses.expenses);

  const [dateTime, setDateTime] = useState(new Date());
  const [searchQuery, setSearchQuery] = useState("");
  const [damagefilter, setdamagefilter] = useState("1");
  const [truckfilter, settruckfilter] = useState("1");
  const [expenses, setexpenses] = useState([]);
  const total = allExpenses.reduce((acc, val) => {
    return acc + val.money;
  }, 0);
  useEffect(() => {
    let filtered =
      damagefilter === "1"
        ? allExpenses
        : damagefilter === "2"
        ? allExpenses.filter((ele) => ele.isDamage === true)
        : allExpenses.filter((ele) => ele.isDamage === false);
    filtered =
      truckfilter === "1"
        ? filtered
        : truckfilter === "2"
        ? filtered.filter((ele) => ele.isFromTruck === true)
        : filtered.filter((ele) => ele.isFromTruck === false);
    setexpenses(filtered);
  }, [truckfilter, damagefilter, allExpenses]);
  const dispatch = useDispatch();
  const fetchdata = () => {
    dispatch(getAllExpenses(Mydate(dateTime)));
  };
  React.useEffect(() => {
    dispatch(fetchPartners());
    dispatch(getExpensesCategories());
    fetchdata();
  }, [dateTime]);

  const classes = useStyles();
  return (
    <div
      className=" w-100  p-0 py-4 pb-5 position-relative  "
      style={{
        background: "#534d3b",
        marginTop: " -15px !important",
        minHeight: "107vh",
        outline: " 8px solid #534d3b",
      }}
    >
      <Container>
        <div className="my-4">
          <MainHeader title=" المصروفات" />
        </div>
        <CardContent className="headerSearch m-0">
          <Grid container spacing={2} style={{ alignItems: "center" }}>
            <Grid item xs={12} md={6} lg={3}>
              <DatePicker
                locale="ar"
                selected={dateTime}
                onChange={(d) => setDateTime(d)}
                dateFormat="dd/MM/yyyy"
                customInput={
                  <TextField
                    size="small"
                    variant="outlined"
                    id="dateTime"
                    style={{ width: "100%", backgroundColor: "#FFF" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                style={{ width: "100%" }}
                placeholder="بحث..."
                className={classes.searchBar}
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid className={classes.radio}>
              <FormControl
                component="fieldset"
                style={{ marginBottom: 4, display: "block" }}
              >
                <RadioGroup
                  row
                  onChange={(event) => {
                    setdamagefilter(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="1"
                    control={
                      <Radio
                        style={{
                          color: "#fbc02d",
                        }}
                        checked={damagefilter === "1"}
                      />
                    }
                    label="الكل"
                  />
                  <FormControlLabel
                    value="2"
                    control={
                      <Radio
                        style={{
                          color: "#fbc02d",
                        }}
                        checked={damagefilter === "2"}
                      />
                    }
                    label="هالك"
                  />
                  <FormControlLabel
                    value="3"
                    control={
                      <Radio
                        style={{
                          color: "#fbc02d",
                        }}
                        checked={damagefilter === "3"}
                      />
                    }
                    label="غير هالك"
                  />
                </RadioGroup>
                <RadioGroup
                  row
                  onChange={(event) => {
                    settruckfilter(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="1"
                    control={
                      <Radio
                        style={{
                          color: "#fbc02d",
                        }}
                        checked={truckfilter === "1"}
                      />
                    }
                    label="الكل"
                  />
                  <FormControlLabel
                    value="2"
                    control={
                      <Radio
                        style={{
                          color: "#fbc02d",
                        }}
                        checked={truckfilter === "2"}
                      />
                    }
                    label="نقلات"
                  />
                  <FormControlLabel
                    value="3"
                    control={
                      <Radio
                        style={{
                          color: "#fbc02d",
                        }}
                        checked={truckfilter === "3"}
                      />
                    }
                    label="غير نقلات"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {total !== 0 && <Total value={total} label="اجمالي مصروفات اليوم" />}
        </div>
        <div
          style={{
            height: "100%",
          }}
        >
          {allExpenses && allExpenses.length > 0 ? (
            <ExpensesTable
              refresh={fetchdata}
              expenses={expenses}
              searchQuery={searchQuery}
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "66%",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                {" "}
                ﻻ يوجد مصروفات{" "}
              </Typography>
            </div>
          )}
        </div>
       
      </Container>
    </div>
  );
}

export default AllExpenses;

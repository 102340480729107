import React, { useState } from "react";

import ToolsArrearsTable from "./components/ToolsArrearsTable";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import Alerto from "../../components/UI/toaster";
import { Container } from "react-bootstrap";
import MainHeader from "../../components/mainHeader/MainHeader";
const toolsArrears = [
  {
    partnerId: 9,
    partnerName: "ahmed embaby8",
    collectingAddressId: 1,
    collectingAddressName: "a1",
    lastDepositDate: "2021-10-02T16:14:58.831",
    totalCount: -3,
    totalToolMortage: -32,
  },
  {
    partnerId: 2,
    partnerName: "ahmed embaby8",
    collectingAddressId: 1,
    collectingAddressName: "a1",
    lastDepositDate: "2021-10-02T16:14:58.831",
    totalCount: -3,
    totalToolMortage: -32,
  },
  {
    partnerId: 3,
    partnerName: "ahmed embaby8",
    collectingAddressId: 1,
    collectingAddressName: "a1",
    lastDepositDate: "2021-10-02T16:14:58.831",
    totalCount: -3,
    totalToolMortage: -32,
  },
];

function ToolsArrears() {
  const [toolsArrears, setToolsArrears] = useState([]);
  const role = localStorage.getItem("role");
  const getToolsArrears = () => {
    axios
      .get("/Laters/latter/tools/buyers")
      .then((res) => {
        let arr = res.data;
        if (role == "محصل") {
          arr = res.data.filter((ele) => ele.isEmployee == false);
        }
        setToolsArrears(arr);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      getToolsArrears();
    }

    return () => {
      mounted = false;
    };
  }, []);

  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "متأخرات  العدة",
  });

  return (
    <div
      className=" w-100  p-0 pb-4 pb-5 position-relative overflow-x-hidden  "
      style={{
        background: "#534d3b",
        marginTop: " -15px !important",
        minHeight: "107vh",
        outline: " 8px solid #534d3b",
      }}
    >
      <div className="my-4">
        <MainHeader title="متأخرات العدة" />
      </div>
      <Container>
        {toolsArrears.length > 0 ? (
          <div ref={componentRef}>
            <ToolsArrearsTable data={toolsArrears} handlePrint={handlePrint} />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography color="textSecondary" variant="h5">
              {" "}
              ﻻ يوجد متأخرات عدة{" "}
            </Typography>
          </div>
        )}
      </Container>
    </div>
  );
}

export default ToolsArrears;

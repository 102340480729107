import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchPartners, fetchTools } from "../../features/main-data/dataSlice";
import { dynamicGetReq, dynamicReq } from "../Helpers/ajax";
import Mydate from "../Helpers/MyDateParser";

export default function useToolsModal({ baseApi }) {
  const [tableData, setTableData] = useState([]);
  const [searchData, setSearchData] = useState({
    partner: "",
    dateTime: new Date(),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTools());
    dispatch(fetchPartners());
    refresh();
  }, []);
  useEffect(() => {
    refresh();
  }, [searchData]);
  const updateHandler = (data) => {
    dynamicReq({
      type: "post",
      api: baseApi + "addOrUpdate",
      callback: refresh,
      data,
      msg: "تم التعديل",
    });
  };

  const deleteHandler = (id) => {
    dynamicReq({ type: "delete", api: baseApi + id, callback: refresh });
  };
  const refresh = async () => {
    const api = `${baseApi}search?dateTime=${Mydate(
      searchData.dateTime
    )}&partnerId=${searchData.partner?.id || ''}`;
    const res = await dynamicGetReq({ api });
    res && setTableData(res.data);
  };
  return {
    tableData,
    searchData,
    setSearchData,
    updateHandler,
    deleteHandler,
    refresh,
  };
}
